import { createI18n } from 'vue-i18n'
import ptBR from '../locales/pt-br.json'
import en from '../locales/en.json'
import es from '../locales/es.json'

const i18n = createI18n({
  locale: window.navigator.language,
  legacy: false,
  messages: {
    pt: ptBR,
    en,
    es
  },
  globalInjection: true,
  allowComposition: true,
  silentTranslationWarn: true,

  silentFallbackWarn: true,
  fallbackWarn: false,
  missingWarn: false,
})

export default i18n
