<script setup>
import { computed } from 'vue'

const props = defineProps({
  server: {
    type: Object,
    required: true
  }
})

const isProd = computed(() => window.location.hostname.includes('cloakup.me'))
const SftpEndpoints = computed(() => {
  return {
    sftp: isProd.value
      ? 'https://sftp.cloakup.me/'
      : 'https://sftp.cloakup.dev/',
    logout: isProd.value
      ? 'https://app.cloakup.me/servers'
      : 'https://app.cloakup.dev/servers'
  }
})
</script>

<template>
  <form
    method="post"
    :action="SftpEndpoints.sftp"
    target="_blank"
    class="flex items-center justify-center"
  >
    <input
      type="hidden"
      name="MFTP_POST"
      value="true"
    >
    <input
      type="hidden"
      name="MFTP_INITIAL_DIRECTORY"
      value="/var/www/html"
    >
    <input
      type="hidden"
      name="MFTP_POST_LOGOUT_URL"
      :value="SftpEndpoints.logout"
    >
    <input
      type="hidden"
      name="MFTP_CONNECTION_TYPE"
      value="sftp"
    >
    <input
      type="hidden"
      name="MFTP_HOST"
      :value="server.ip"
    >
    <input
      type="hidden"
      name="MFTP_REMOTE_USERNAME"
      :value="server.username"
    >
    <input
      type="hidden"
      name="MFTP_AUTHENTICATION_MODE_NAME"
      value="Password"
    >
    <input
      type="hidden"
      name="MFTP_PASSWORD"
      :value="server.password"
    >
    <slot />
  </form>
</template>
