<script setup>
import { ArrowDownIcon, ArrowUpIcon, ChevronDownIcon, TrashIcon } from '@heroicons/vue/24/solid'
import { reactive } from 'vue'
import { useI18n } from 'vue-i18n'
import { useHead } from '@unhead/vue'
import Textarea from '@/components/ui/textarea/Textarea.vue'
import Button from '@/components/ui/button/Button.vue'
import Input from '@/components/ui/input/Input.vue'
import Label from '@/components/ui/label/Label.vue'
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu'

const { t } = useI18n()
useHead({
  title: t('pages.plugins.safe_page_generator.title')
})

const page = reactive([
  {
    name: t('pages.plugins.safe_page_generator.components.title'),
    tag: 'h1',
    attrs: {
      class: 'title'
    },
    content: 'Lorem'
  },
  {
    name: t('pages.plugins.safe_page_generator.components.image'),
    tag: 'img',
    attrs: {
      class: 'banner',
      src: 'https://picsum.photos/200/300'
    }
  },
  {
    name: t('pages.plugins.safe_page_generator.components.paragraph'),
    tag: 'p',
    attrs: {},
    content:
      'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry\'s standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum'
  }
])

function downloadHTML() {
  const result = `
<!DOCTYPE html>
<html lang="pt-BR">
<head>
<meta charset="UTF-8" />
<meta http-equiv="X-UA-Compatible" content="IE=edge" />
<meta name="viewport" content="width=device-width, initial-scale=1.0" />

 <style>
    @import url('https://fonts.googleapis.com/css2?family=Inter:wght@500&display=swap');
body {
  font-family: 'Inter', sans-serif;
}

.container {
  padding-top: 3rem;
  padding-bottom: 3rem;
  margin: 0 auto;
}

.title {
  margin-top: 0.5rem;
  display: block;

  text-align: center;
  line-height: 2rem;
  font-weight: 800;
  letter-spacing: -0.025em;
  --tw-text-opacity: 1;
  color: rgb(17 24 39 / var(--tw-text-opacity));
}

.content {
  margin-top: 1.5rem;
  margin-left: auto;
  margin-right: auto;
  padding-left: 3rem;
  padding-right: 3rem;
  width: 100%;
  max-width: 72rem;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  word-wrap: break-word;      /* IE 5.5-7 */
  white-space: -moz-pre-wrap; /* Firefox 1.0-2.0 */
  white-space: pre-wrap;      /* current browsers */
}

.banner {
  border-radius: 0.25rem;
  object-fit: cover;
  width: 100%;
}

p {
  font-size: 1.125rem;
  line-height: 1.5rem;
  font-weight: 400;
  --tw-text-opacity: 1;
  color: rgb(107 114 128 / var(--tw-text-opacity));

  display: block; /* or inline-block, at least its a block element */
  width: 100%; /* or width is certain by parent element */
  height: auto; /* height cannot be defined */
  word-break: break-all; /*  */
  word-wrap: break-word; /* if you want to cut the complete word */
  white-space: normal; /* be sure its not 'nowrap'! ! ! :/ */
}


@media (min-width: 768px) {
  .container {
    width: 100%;
  }

  .title {
    font-size: 2.5rem;
  }
}

@media (min-width: 1024px) {
  .container {
    max-width: 720px;
  }

  .title {
    font-size: 1.875rem;
  }
}
</style>
</head>
<body>
<main class="container">
        ${page
          .map((item) => {
            return `
            <${item.tag} ${Object.keys(item.attrs)
              .map((key) => {
                return `${key}="${item.attrs[key]}"`
              })
              .join(' ')}>
              ${item?.content ?? ''}
            </${item.tag}>
          `
          })
          .join('')}
</main>
</body>
</html>
`
  const element = document.createElement('a')
  element.setAttribute(
    'href',
    `data:text/plain;charset=utf-8,${encodeURIComponent(result)}`
  )
  element.setAttribute('download', 'result.html')
  element.style.display = 'none'

  document.body.appendChild(element)
  element.click()
  document.body.removeChild(element)
}

function addContent(value) {
  page.push(value)
}

function removeContent(index) {
  page.splice(index, 1)
}

function moveTo(index, newIndex) {
  const item = page.splice(index, 1)[0]
  page.splice(newIndex, 0, item)
}
</script>

<template>
  <div
    class="grid min-h-screen w-full grid-cols-1 overflow-hidden xl:grid-cols-12"
  >
    <div class="col-span-4 h-screen overflow-auto">
      <div class="p-12">
        <DropdownMenu>
          <DropdownMenuTrigger as-child>
            <Button variant="outline">
              {{ $t('pages.plugins.safe_page_generator.add_content') }}
              <ChevronDownIcon
                class="-mr-1 ml-2 size-5"
                aria-hidden="true"
              />
            </Button>
          </DropdownMenuTrigger>
          <DropdownMenuContent>
            <DropdownMenuItem
              @click="
                addContent({
                  name: $t('pages.plugins.safe_page_generator.components.paragraph'),
                  tag: 'p',
                  attrs: {},
                  content: '',
                })
              "
            >
              {{ $t('pages.plugins.safe_page_generator.components.paragraph') }}
            </DropdownMenuItem>
            <DropdownMenuItem
              @click="
                addContent({
                  name: $t('pages.plugins.safe_page_generator.components.image'),
                  tag: 'img',
                  attrs: {
                    class: 'banner',
                    src: 'https://picsum.photos/200/300',
                  },
                })
              "
            >
              {{ $t('pages.plugins.safe_page_generator.components.image') }}
            </DropdownMenuItem>
            <DropdownMenuItem
              @click="
                addContent({
                  name: $t('pages.plugins.safe_page_generator.components.title'),
                  tag: 'h1',
                  attrs: {
                    class: 'title',
                  },
                  content: '',
                })
              "
            >
              {{ $t('pages.plugins.safe_page_generator.components.title') }}
            </DropdownMenuItem>
          </DropdownMenuContent>
        </DropdownMenu>

        <div class="mt-8">
          <div class="min-h-screen space-y-5">
            <template
              v-for="(item, index) in page"
              :key="item"
            >
              <div class="flex">
                <div class="flex gap-2 pr-4">
                  <button
                    :disabled="index === 0"
                    @click="moveTo(index, index - 1)"
                  >
                    <ArrowUpIcon class="size-5 text-muted-foreground" />
                  </button>
                  <button
                    :disabled="index === page.length - 1"
                    @click="moveTo(index, index + 1)"
                  >
                    <ArrowDownIcon class="size-5 text-muted-foreground" />
                  </button>
                  <button>
                    <TrashIcon
                      class="size-5 text-red-500"
                      @click="removeContent(index)"
                    />
                  </button>
                </div>
                <div class="flex-1">
                  <Label>{{ item.name }}</Label>
                  <div class="mt-2">
                    <template v-if="item.tag === 'p'">
                      <Textarea
                        v-model="page[index].content"
                        rows="12"
                      />
                    </template>
                    <template v-else-if="item.tag === 'h1'">
                      <Input
                        v-model="page[index].content"
                        type="text"
                      />
                    </template>
                    <template v-else-if="item.tag === 'img'">
                      <Input
                        v-model="page[index].attrs.src"
                        type="url"
                      />
                    </template>
                  </div>
                </div>
              </div>
            </template>
            <div class="flex space-x-2 align-bottom">
              <Button
                @click="downloadHTML"
              >
                {{ $t('pages.plugins.safe_page_generator.download') }}
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="col-span-8 h-screen overflow-auto border-l bg-neutral-50">
      <div class="container">
        <template v-for="item in page">
          <component
            :is="item.tag"
            v-bind="item.attrs"
          >
            {{ item?.content }}
          </component>
        </template>
      </div>
    </div>
  </div>
</template>

<style scoped>
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@500&display=swap");

body {
  font-family: "Inter", sans-serif;
}

.container {
  padding-top: 3rem;
  padding-bottom: 3rem;
  margin: 0 auto;
}

.title {
  margin-top: 0.5rem;
  display: block;

  text-align: center;
  line-height: 2rem;
  font-weight: 800;
  letter-spacing: -0.025em;
  --tw-text-opacity: 1;
  color: rgb(17 24 39 / var(--tw-text-opacity));
}

.content {
  margin-top: 1.5rem;
  margin-left: auto;
  margin-right: auto;
  padding-left: 3rem;
  padding-right: 3rem;
  width: 100%;
  max-width: 72rem;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  word-wrap: break-word;
  /* IE 5.5-7 */
  white-space: -moz-pre-wrap;
  /* Firefox 1.0-2.0 */
  white-space: pre-wrap;
  /* current browsers */
}

.banner {
  border-radius: 0.25rem;
  object-fit: cover;
  width: 100%;
}

p {
  font-size: 1.125rem;
  line-height: 1.5rem;
  font-weight: 400;
  --tw-text-opacity: 1;
  color: rgb(107 114 128 / var(--tw-text-opacity));

  display: block;
  /* or inline-block, at least its a block element */
  width: 100%;
  /* or width is certain by parent element */
  height: auto;
  /* height cannot be defined */
  word-break: break-all;
  /*  */
  word-wrap: break-word;
  /* if you want to cut the complete word */
  white-space: normal;
  /* be sure its not 'nowrap'! ! ! :/ */
}

@media (min-width: 768px) {
  .container {
    width: 100%;
  }

  .title {
    font-size: 2.5rem;
  }
}

@media (min-width: 1024px) {
  .container {
    max-width: 720px;
  }

  .title {
    font-size: 1.875rem;
  }
}
</style>
