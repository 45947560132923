<script setup>
import { useI18n } from 'vue-i18n'
import BaseTable from '@/components/base/BaseTable.vue'
import EmptyState from '@/components/common/EmptyState.vue'
import Pagination from '@/components/common/Pagination.vue'
import ReferralStatusBadge from '@/components/partials/referral/ReferralStatusBadge.vue'
import { formatDate } from '@/helpers/date.js'

defineProps({
  users: {
    type: Array,
    default: () => []
  },
  count: {
    type: Number,
    default: 0
  },
  isLoading: {
    type: Boolean,
    default: false
  },
  hasError: {
    type: Boolean,
    default: false
  }
})

const { t, locale } = useI18n()

const userTable = [
  {
    label: t('common.created_at'),
    value: 'created_at',
    format: value => formatDate(value, {
      locale: locale.value
    })
  },
  {
    label: t('common.name'),
    value: 'name'
  },
  {
    label: t('common.status'),
    value: 'status',
    format: value => (value === 'active' ? 'Ativo' : 'Inativo')
  }
]
</script>

<template>
  <BaseTable
    :loading="isLoading"
    :error="hasError"
    :headers="userTable"
    :columns="users"
  >
    <template #col-status="{ row }">
      <ReferralStatusBadge :status="row" />
    </template>
    <template #pagination>
      <Pagination
        :total="count"
      />
    </template>
    <template #empty>
      <EmptyState
        title="Nenhuma indicação cadastrada"
      />
    </template>
  </BaseTable>
</template>
