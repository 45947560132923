<script setup>
import { onMounted, ref, watch } from 'vue'
import { PlusIcon, XMarkIcon } from '@heroicons/vue/24/solid'
import { useI18n } from 'vue-i18n'
import Button from '@/components/ui/button/Button.vue'
import Input from '@/components/ui/input/Input.vue'
import Label from '@/components/ui/label/Label.vue'
import Field from '@/components/ui/field/Field.vue'
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@/components/ui/select'

const props = defineProps({
  modelValue: {
    type: Object,
    required: true,
    default: () => ({
      params: {},
      condition: 'some',
      remove_params: false,
      rules: {}
    })
  }
})

const emit = defineEmits(['update:modelValue'])

const { t } = useI18n()

const operators = [
  {
    label: t('components.custom_params_modal.operators.equals'),
    value: 'equals'
  },
  {
    label: t('components.custom_params_modal.operators.contains'),
    value: 'contains'
  },
  {
    label: t('components.custom_params_modal.operators.starts_with'),
    value: 'starts_with'
  },
  {
    label: t('components.custom_params_modal.operators.ends_with'),
    value: 'ends_with'
  }
]

const params = ref([
  {
    key: '',
    value: '',
    operator: 'equals'
  }
])

/**
 * @param {object} query
 * @param {string} query.condition
 * @param {object} query.params
 * @param {boolean} query.remove_params
 * @param {object} query.rules
 */
function objectQueryToArray(query) {
  const params = Object.keys(query.params).map((key) => {
    return query.params[key].map((value) => {
      return {
        key,
        value,
        operator: query.rules[key] || 'equals'
      }
    })
  }).flat()

  return params
}

function addParam() {
  params.value.push({
    key: '',
    value: '',
    operator: 'equals'
  })
}

function removeParam(index) {
  params.value.splice(index, 1)
}

/**
 *
 * @param params Array<{key: string, value: string}>
 * @returns {Record<string, string[]>}
 */
function parseToObject(params) {
  const paramsObject = {}
  for (let i = 0; i < params.length; i++) {
    const param = params[i]
    if (param.key) {
      if (!paramsObject[param.key]) {
        paramsObject[param.key] = []
      }
      paramsObject[param.key].push(param.value)
    }
  }

  return paramsObject
}

watch(params, (newParams) => {
  emit('update:modelValue', {
    ...props.modelValue,
    params: parseToObject(newParams),
    rules: newParams.reduce((acc, param) => {
      acc[param.key] = param.operator
      return acc
    }, {})
  })
}, {
  deep: true
})

onMounted(() => {
  params.value = objectQueryToArray(props.modelValue)
})
</script>

<template>
  <div class="max-w-3xl rounded-lg border border-border bg-muted/25 p-6">
    <div class="divide-y divide-border">
      <div
        class="space-y-4"
      >
        <div
          v-for="(param, index) in params"
          :key="index"
          class="grid grid-cols-12 items-center gap-2"
        >
          <Field class="col-span-4">
            <Label
              :for="`param-${index}-key`"
            >
              {{ $t('components.custom_params_modal.params') }}
            </Label>
            <Input
              :id="`param-${index}-key`"
              v-model="param.key"
              type="text"
              :name="`param-${index}-key`"
              class="bg-background"
            />
          </Field>

          <Field class="col-span-3">
            <Label :for="`param-${index}-operator`">
              {{ $t('components.custom_params_modal.operator') }}
            </Label>
            <Select
              :id="`param-${index}-operator`"
              v-model="param.operator"
              :name="`param-${index}-operator`"
            >
              <SelectTrigger class="bg-background">
                <SelectValue placeholder="Operador" />
              </SelectTrigger>
              <SelectContent>
                <SelectGroup
                  v-for="operator in operators"
                  :key="operator.value"
                >
                  <SelectItem
                    :key="operator.value"
                    :value="operator.value"
                  >
                    {{ operator.label }}
                  </SelectItem>
                </SelectGroup>
              </SelectContent>
            </Select>
          </Field>

          <Field class="col-span-4">
            <Label :for="`param-${index}-value`">
              {{ $t('common.value') }}
            </Label>
            <Input
              :id="`param-${index}-value`"
              v-model="param.value"
              type="text"
              :name="`param-${index}-value`"
              class="bg-background"
            />
          </Field>

          <div class="mt-8">
            <Button
              variant="destructive"
              @click="removeParam(index)"
            >
              <XMarkIcon class="size-4" />
            </Button>
          </div>
        </div>

        <Button
          type="button"
          @click="addParam"
        >
          <PlusIcon class="mr-2 size-5" />
          {{ $t('components.custom_params_modal.add_params') }}
        </Button>
      </div>
      <!--
          <div class="p-6">
            <Label>
              {{ $t('components.custom_params_modal.preview') }}
            </Label>

            <div
              v-if="arrayToQueryString"
              class="mt-4"
            >
              <Card class="bg-foreground/5">
                <CardContent class="p-2">
                  <p class="break-all">
                    {{ arrayToQueryString }}
                  </p>
                </CardContent>
              </Card>
            </div>
          </div> -->
    </div>
  </div>
</template>
