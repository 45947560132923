<script setup>
import { useColorMode } from '@vueuse/core'
import { codeToHtml } from 'shiki'
import { ref, watch } from 'vue'

const props = defineProps({
  code: {
    type: String,
    required: true
  },
  lang: {
    type: String,
    required: true
  }
})

const html = ref('')
const theme = useColorMode()

async function updateCode() {
  return await codeToHtml(props.code, {
    lang: props.lang,
    theme: theme.value === 'dark' ? 'github-dark' : 'github-light',
  })
}

html.value = await updateCode()

watch(
  () => [props.code, theme.value],
  async (value) => {
    html.value = await updateCode()
  },
)
</script>

<template>
  <code class="relative">
    <pre
      class="lum-scrollbar break-words "
      v-html="html"
    />
  </code>
</template>

<style>
code {
  white-space: pre-wrap;
  font-size: 0.875rem;
}

pre {
	padding: 10px
}
</style>
