<script setup>
import { useRoute, useRouter } from 'vue-router'
import { Tabs, TabsList, TabsTrigger } from '@/components/ui/tabs'

const route = useRoute()
const router = useRouter()

function onStatusChanged(status) {
  router.push({
    query: {
      ...route.query,
      active: status === 'all' ? undefined : status
    }
  })
}
</script>

<template>
  <Tabs
    :default-value="$route.query.active || 'all'"
    class="w-[400px]"
    @update:model-value="onStatusChanged"
  >
    <TabsList>
      <TabsTrigger value="all">
        {{ $t('pages.campaign.status.all') }}
      </TabsTrigger>
      <TabsTrigger value="true">
        {{ $t('pages.campaign.status.active') }}
      </TabsTrigger>
      <TabsTrigger value="false">
        {{ $t('pages.campaign.status.disabled') }}
      </TabsTrigger>
    </TabsList>
  </Tabs>
</template>
