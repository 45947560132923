<script setup>
import { TooltipRoot, useForwardPropsEmits } from 'radix-vue'

const props = defineProps({
  defaultOpen: {
    type: Boolean,
    required: false
  },
  open: {
    type: Boolean,
    required: false
  },
  delayDuration: {
    type: Number,
    required: false,
    default: 0
  },
  disableHoverableContent: {
    type: Boolean,
    required: false
  },
  disableClosingTrigger: {
    type: Boolean,
    required: false
  },
  disabled: {
    type: Boolean,
    required: false
  },
  ignoreNonKeyboardFocus: {
    type: Boolean,
    required: false
  },
})
const emits = defineEmits(['update:open'])

const forwarded = useForwardPropsEmits(props, emits)
</script>

<template>
  <TooltipRoot v-bind="forwarded">
    <slot />
  </TooltipRoot>
</template>
