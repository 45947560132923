<script setup>
import { useRouter } from 'vue-router'
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@/components/ui/select'

const props = defineProps({
  tabs: {
    type: Array,
    required: true
  }
})

const router = useRouter()

function updateTab(pageName) {
  const page = props.tabs.find(tab => tab.name === pageName)
  if (!page) {
    return
  }
  router.push(page.href)
}
</script>

<template>
  <div>
    <div class="sm:hidden">
      <label
        for="tabs"
        class="sr-only"
      >Select a tab</label>
      <!-- Use an "onChange" listener to redirect the user to the selected tab URL. -->
      <Select
        id="tabs"
        name="tabs"
        @update:model-value="updateTab"
      >
        <SelectTrigger>
          <SelectValue placeholder="Selecione uma página" />
        </SelectTrigger>
        <SelectContent position="popper">
          <SelectItem
            v-for="item in tabs"
            :key="item.name"
            :value="item.name"
          >
            {{ item.name }}
          </SelectItem>
        </SelectContent>
      </Select>
    </div>
    <div class="hidden sm:block">
      <div class="border-b border-border">
        <nav
          class="-mb-px flex space-x-6"
          aria-label="Tabs"
        >
          <router-link
            v-for="tab in tabs"
            :key="tab.name"
            v-slot="{ isActive }"
            :to="tab.href"
          >
            <div
              class="group inline-flex items-center border-b-2 px-1 py-4 text-sm font-medium"
              :class="[
                isActive
                  ? 'border-primary text-primary'
                  : 'border-transparent text-muted-foreground hover:border-primary hover:text-foreground',
              ]"
            >
              <component
                :is="tab.icon"
                class="-ml-0.5 mr-2 size-5"
                :class="[
                  isActive
                    ? 'text-primary'
                    : 'text-neutral-400 group-hover:text-foreground',
                ]"
                aria-hidden="true"
              />
              <span>{{ tab.name }}</span>
            </div>
          </router-link>
        </nav>
      </div>
    </div>
  </div>
</template>
