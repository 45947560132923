export default [
  {
    path: '/pages',
    name: 'PageBuilder',
    component: () => import('@/views/page-builder/PagesView.vue'),
    meta: {
      layout: 'default',
      requiresAuth: true
    }
  },
  {
    path: '/pages/create',
    name: 'CreatePage',
    component: () => import('@/views/page-builder/PagesCreateView.vue'),
    meta: {
      layout: 'blank',
      requiresAuth: true
    }
  }
]
