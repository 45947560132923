import { isLocal } from '@/helpers/is-local'

export function activeCampaign() {
  function setup() {
    if (isLocal) {
      console.log('Active Campaign disabled in local environment')
	  return
    }

    const scriptContent = `(function(e,t,o,n,p,r,i){e.visitorGlobalObjectAlias=n;e[e.visitorGlobalObjectAlias]=e[e.visitorGlobalObjectAlias]||function(){(e[e.visitorGlobalObjectAlias].q=e[e.visitorGlobalObjectAlias].q||[]).push(arguments)};e[e.visitorGlobalObjectAlias].l=(new Date).getTime();r=t.createElement("script");r.src=o;r.async=true;i=t.getElementsByTagName("script")[0];i.parentNode.insertBefore(r,i)})(window,document,"https://diffuser-cdn.app-us1.com/diffuser/diffuser.js","vgo");
		vgo('setAccount', '613054016');
		vgo('setTrackByDefault', true);

		vgo('process');`

    const script = document.createElement('script')
    script.innerHTML = scriptContent
    document.head.appendChild(script)
  }

  return {
    setup
  }
}
