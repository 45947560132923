<script setup>
import { onBeforeUnmount, ref, watch } from 'vue'
import { toast } from 'vue-sonner'
import dayjs from 'dayjs'
import { useI18n } from 'vue-i18n'
import EmptyCard from '@/components/EmptyCard.vue'
import ProgressIndicator from '@/components/common/ProgressIndicator.vue'
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle
} from '@/components/ui/card'
import { formatNumber } from '@/helpers/format'
import { reportService } from '@/services/report-service'
import { formatRequestDeniedReason } from '@/helpers/format-request-denied-reason'
import BaseBarList from '@/components/base/BaseBarList.vue'
import { ScrollArea } from '@/components/ui/scroll-area'

const props = defineProps({
  campaignId: {
    type: String,
    required: false
  },
  startDate: {
    type: Date,
    required: false,
  },
  endDate: {
    type: Date,
    required: false,
  }
})

const { t } = useI18n()
const controller = new AbortController()

const isLoading = ref(false)
const deniedReasons = ref([])

async function fetchByDeniedReason(params) {
  isLoading.value = true
  try {
    const { data } = await reportService.groupByDeniedReason({
      params,
      signal: controller.signal
    })
    deniedReasons.value = Object.entries(data)
      .map((denied) => {
        return {
          name: formatRequestDeniedReason(denied[0], t) ?? 'Desconhecido',
          value: denied[1].count,
          percentage: denied[1].average,
          color: denied[0] === 'Aceito' ? 'bg-green-500 dark:bg-green-800' : 'bg-red-600 dark:bg-red-600'
        }
      })
      .sort((a, b) => b.value - a.value)
  } catch (e) {
    if (e.message === 'canceled') {
      return
    }

    toast.error(e.response?.data?.message, {
      description: e.response?.data?.action || e.message,
    })
  } finally {
    isLoading.value = false
  }
}

watch(() => [props.startDate, props.endDate, props.campaignId], () => {
  if (!props.campaignId) {
    return
  }

  fetchByDeniedReason({
    start_date: dayjs(props.startDate).unix() * 1000,
    end_date: dayjs(props.endDate).unix() * 1000,
    campaign: props.campaignId
  })
}, {
  immediate: true
})

onBeforeUnmount(() => {
  controller.abort()
})
</script>

<template>
  <Card>
    <CardHeader class="flex flex-col items-stretch space-y-0 border-b p-0 sm:flex-row">
      <div class="flex flex-1 flex-col justify-center gap-1 px-6 py-5 sm:py-6">
        <CardTitle>
          {{ $t('components.request_by_denied_reason.title') }}
        </CardTitle>
        <CardDescription>
          {{ $t('components.request_by_denied_reason.description') }}
        </CardDescription>
      </div>
    </CardHeader>
    <div class="h-2">
      <ProgressIndicator v-if="isLoading" />
    </div>

    <CardContent>
      <EmptyCard
        v-if="!deniedReasons?.length"
        :message="!campaignId ? $t('pages.reports.missing_selected_campaign') : undefined"
      />
      <ScrollArea
        v-else
        class="max-h-[300px] pr-4"
      >
        <BaseBarList

          :list="deniedReasons"
          :loading="isLoading"
        >
          <template #value="{ item }">
            <span class="text-sm font-semibold ">{{ formatNumber(item.value) }} ({{
              item.percentage
            }}%)</span>
          </template>
        </BaseBarList>
      </ScrollArea>
    </CardContent>
  </Card>
</template>
