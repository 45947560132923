<script setup lang="ts">
import { CaretSortIcon } from '@radix-icons/vue'
import { ref } from 'vue'
import { EllipsisVerticalIcon, TrashIcon } from '@heroicons/vue/24/solid'
import { GlobeAltIcon } from '@heroicons/vue/24/outline'
import { toast } from 'vue-sonner'
import { useI18n } from 'vue-i18n'
import { useMediaQuery } from '@vueuse/core'
import DomainStatusBadge from './DomainStatusBadge.vue'
import DomainCnameTable from './DomainCnameTable.vue'
import DomainDeleteModal from './DomainDeleteModal.vue'
import Button from '@/components/ui/button/Button.vue'
import { Card, CardContent, CardFooter, } from '@/components/ui/card'
import { Tabs, TabsContent, TabsList, TabsTrigger } from '@/components/ui/tabs'
import {
  Collapsible,
  CollapsibleContent,
  CollapsibleTrigger,
} from '@/components/ui/collapsible'
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu'
import { domainService } from '@/services/domain'

const props = defineProps({
  domain: {
    type: Object,
    required: true,
  }
})
const emit = defineEmits(['verified', 'deleted'])
const { t } = useI18n()
const isDesktop = useMediaQuery('(min-width: 920px)')

const showConfig = ref(!props.domain.verified)
const isVerifying = ref(false)
const showDeleteModal = ref(false)

/**
 * @param {string} id
 */
async function verifyDomain(id) {
  isVerifying.value = true

  try {
    await domainService.verify(id)

    toast.success(t('components.verify_domain_modal.toast.success.title'), {
      description: t('components.verify_domain_modal.toast.success.description'),
    })

    emit('verified')
  } catch (e) {
    toast.error(e.response?.data?.message, {
      description: e.response?.data?.action || e.message,
    })
  } finally {
    isVerifying.value = false
  }
}
</script>

<template>
  <Card>
    <Collapsible
      v-model:open="showConfig"
    >
      <CardContent class="p-4 sm:p-5">
        <div class="flex w-full  items-center justify-between gap-3 ">
          <div class="flex min-w-0 items-center gap-4">
            <div class="hidden rounded-full border border-border sm:block">
              <div class="rounded-full border border-border p-1 md:p-2">
                <GlobeAltIcon class="size-5 text-foreground" />
              </div>
            </div>
            <div class="overflow-hidden">
              <div class="flex items-center gap-1.5 sm:gap-2.5">
                <div v-if="!isDesktop">
                  <!-- Verified dots -->
                  <div
                    v-if="domain.verified"
                    class="block size-2 rounded-full bg-success sm:hidden"
                  />
                  <div
                    v-else
                    class="block size-2 rounded-full bg-destructive sm:hidden"
                  />
                </div>
                <a
                  :href="`https://${domain.name}`"
                  target="_blank"
                  rel="noreferrer"
                  class="truncate text-sm font-medium"
                  :title="domain.name"
                >{{ domain.name }}</a>
                <DomainStatusBadge
                  v-if="isDesktop"
                  :verified="domain.verified"
                  class="hidden sm:block"
                />
              </div>
            </div>
          </div>

          <div class="flex gap-2">
            <div
              v-if="isDesktop"
              class="flex items-center"
            >
              <Button
                v-if="!domain.verified"
                variant="secondary"
                :disabled="isVerifying"
                :loading="isVerifying"
                class="mr-2"
                @click="verifyDomain(domain.id)"
              >
                {{ $t('components.verify_domain_modal.button') }}
              </Button>

              <Button
                variant="ghost"
                @click="showDeleteModal = true"
              >
                <TrashIcon class="size-5" />
              </Button>
            </div>

            <CollapsibleTrigger as-child>
              <Button
                variant="ghost"
              >
                <CaretSortIcon class="size-5" />
                <span class="sr-only">Toggle</span>
              </Button>
            </CollapsibleTrigger>

            <div v-if="!isDesktop">
              <DropdownMenu>
                <DropdownMenuTrigger as-child>
                  <Button variant="ghost">
                    <EllipsisVerticalIcon class="size-5" />
                  </Button>
                </DropdownMenuTrigger>
                <DropdownMenuContent>
                  <DropdownMenuGroup>
                    <DropdownMenuItem
                      v-if="!domain.verified"
                      @click="verifyDomain(domain.id)"
                    >
                      <span>
                        {{ $t('components.verify_domain_modal.button') }}
                      </span>
                    </DropdownMenuItem>
                    <DropdownMenuItem @click="showDeleteModal = true">
                      <span>
                        {{ $t('common.delete') }}
                      </span>
                    </DropdownMenuItem>
                  </DropdownMenuGroup>
                </DropdownMenuContent>
              </DropdownMenu>
            </div>
          </div>
        </div>
      </CardContent>

      <CollapsibleContent class="w-full space-y-2">
        <CardFooter class="flex w-full flex-1 border-t-2 border-muted pt-4">
          <Tabs
            default-value="cname"
            class="w-full"
          >
            <TabsList class="w-full justify-start rounded-none  border-b-2 border-b-muted bg-background ">
              <TabsTrigger
                value="cname"
                class="block rounded-none border-b-2 border-b-primary bg-background p-2 text-primary data-[state=active]:shadow-none"
              >
                CNAME
              </TabsTrigger>
            </TabsList>
            <TabsContent value="cname">
              <div class="flex flex-col gap-4 pt-4 text-foreground">
                <span class="text-sm">{{ $t('components.domain_card.to_setup_your_subdomain') }} <span
                  class="inline-block rounded-md bg-primary-foreground px-1 py-0.5 font-mono"
                >{{ domain.name }}</span>,

                  {{ $t('components.domain_card.add_the_following_cname_record') }}:
                </span>

                <DomainCnameTable
                  :name="domain.name"
                />
              </div>
              <div class="mt-5">
                <p class="text-sm text-muted-foreground">
                  {{ $t('components.domain_card.note_start') }} <span
                    class="inline-block rounded-md bg-primary-foreground px-1 py-0.5 font-mono"
                  >86400</span>
                  {{ $t('components.domain_card.note_end') }}
                </p>
              </div>
            </TabsContent>
          </Tabs>
        </CardFooter>
      </CollapsibleContent>
    </Collapsible>
  </Card>
  <DomainDeleteModal
    :id="domain.id"
    v-model="showDeleteModal"
    @deleted="emit('deleted')"
  />
</template>
