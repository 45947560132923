<script setup>
import { MagnifyingGlassCircleIcon } from '@heroicons/vue/24/solid'
import { computed } from 'vue'
import { useI18n } from 'vue-i18n'

const props = defineProps({
  title: {
    type: String,
  },
  description: {
    type: String,
  },
  icon: {
    default: MagnifyingGlassCircleIcon,
  },
})

const { t } = useI18n()

const title = computed(() => props.title || t('components.empty_state.title'))
</script>

<template>
  <div class="relative">
    <div
      class="absolute inset-0 bg-[radial-gradient(theme(colors.border/90%)_1px,transparent_1px)] [background-size:20px_20px] [mask-image:radial-gradient(ellipse_50%_50%_at_50%_50%,#000_50%,transparent_100%)]"
    />
    <div class="relative z-10 flex flex-col items-center justify-center py-16 text-center lg:py-24">
      <slot name="icon">
        <component
          :is="props.icon"
          v-if="props.icon"
          class="size-12"
        />
      </slot>
      <slot name="title">
        <p
          class="mb-2 mt-6 text-balance text-xl font-bold tracking-tight"
          v-text="title"
        />
      </slot>
      <slot
        v-if="props.description || $slots.description"
        name="description"
      >
        <p
          class="text-muted-foreground"
          v-text="props.description"
        />
      </slot>
      <slot />
    </div>
  </div>
</template>
