import authRoutes from './modules/auth-routes'
import campaignRoutes from './modules/campaign-routes'
import checkoutRoutes from './modules/checkout-routes'
import cloudProviderRoutes from './modules/cloud-provider-routes'
import dashboardRoutes from './modules/dashboard-routes'
import errorRoutes from './modules/error-routes'
import pageBuilderRoutes from './modules/page-builder-routes'
import pluginsRoutes from './modules/plugins-routes'
import referralRoutes from './modules/referral-routes'
import reportRoutes from './modules/report-routes'
import requestRoutes from './modules/request-routes'
import serverRoutes from './modules/server-routes'
import settingsRoutes from './modules/settings-routes'
import domainRoutes from './modules/domain-routes'

export default [
  ...authRoutes,
  ...dashboardRoutes,
  ...errorRoutes,
  ...checkoutRoutes,
  ...pluginsRoutes,
  ...campaignRoutes,
  ...serverRoutes,
  ...referralRoutes,
  ...cloudProviderRoutes,
  ...settingsRoutes,
  ...reportRoutes,
  ...requestRoutes,
  ...pageBuilderRoutes,
  ...domainRoutes,
]
