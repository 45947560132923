<script setup>
import { useI18n } from 'vue-i18n'
import { useHead } from '@unhead/vue'
import EmptyState from '@/components/common/EmptyState.vue'
import SectionHeading from '@/components/common/SectionHeading.vue'
import SubscriptionCard from '@/components/partials/settings/subscriptions/SubscriptionCard.vue'
import { useUserStore } from '@/stores/user'
import Button from '@/components/ui/button/Button.vue'
import Card from '@/components/ui/card/Card.vue'

const store = useUserStore()
const { t } = useI18n()
useHead({
  title: t('pages.settings.subscription.title')
})
</script>

<template>
  <div class="space-y-5">
    <SectionHeading
      :title="$t('pages.settings.subscription.title')"
      :description="$t('pages.settings.subscription.description')"
    />

    <div class="space-y-5">
      <template v-if="!store.user?.cloakup_active && !store.user?.host_active">
        <Card>
          <EmptyState
            :title="$t('pages.settings.subscription.empty_state.title')"
            :description="$t('pages.settings.subscription.empty_state.description')"
          >
            <div class="mt-5 grid w-full grid-cols-1 justify-center gap-3 sm:flex sm:items-center">
              <Button
                as-child
              >
                <router-link to="/plans">
                  {{ $t('pages.settings.subscription.empty_state.button') }}
                </router-link>
              </Button>
            </div>
          </EmptyState>
        </Card>
      </template>

      <div
        v-for="subscription in store.user?.subscriptions"
        :key="subscription.id"
      >
        <SubscriptionCard
          :subscription="subscription"
        />
      </div>
    </div>
  </div>
</template>
