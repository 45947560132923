<script setup>
import { reactive, ref, watch } from 'vue'
import { toast } from 'vue-sonner'
import FormAddress from '../../FormAddress.vue'
import { userService } from '@/services/user'
import { useUserStore } from '@/stores/user'
import Button from '@/components/ui/button/Button.vue'

const store = useUserStore()

const isLoading = ref(false)
const errors = ref({})
const form = reactive({
  postal_code: store.user.address?.postal_code,
  number: store.user.address?.number,
  street: store.user.address?.street,
  district: store.user.address?.district,
  complement: store.user.address?.complement,
  city: store.user.address?.city,
  state: store.user.address?.state,
  country: store.user.address?.country
})

function submit() {
  const payload = {
    postal_code: form.postal_code?.replace(/\D/g, ''),
    number: form.number,
    street: form.street,
    district: form.district,
    complement: form.complement,
    city: form.city,
    state: form.state,
    country: form.country

  }

  updateAccount(payload)
}

async function updateAccount(user) {
  isLoading.value = true
  errors.value = {}
  try {
    await userService.updateAddress(user)

    toast.success('Sucesso!', {
      description: 'Endereço atualizado com sucesso',
    })
  } catch (e) {
    if (e.response?.status === 422) {
      errors.value = e.response.data?.data
    }

    toast.error(e.response?.data?.message, {
      description: e.response?.data?.action || e.message,
    })
  } finally {
    isLoading.value = false
  }
}
</script>

<template>
  <form @submit.prevent="submit">
    <fieldset :disabled="isLoading">
      <div class="grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
        <div class="col-span-6">
          <FormAddress
            v-model="form"
            :errors="errors"
          />
        </div>
      </div>
      <div class="mt-6 flex items-center justify-end">
        <Button
          type="submit"
          :loading="isLoading"
        >
          {{ $t('common.save') }}
        </Button>
      </div>
    </fieldset>
  </form>
</template>
