<script setup>
import { onMounted, reactive, ref, watch } from 'vue'
import { useI18n } from 'vue-i18n'
import { useHead } from '@unhead/vue'
import BaseCheckbox from '@/components/base/BaseCheckbox.vue'
import InputRange from '@/components/common/InputRange.vue'
import PageHeading from '@/components/common/PageHeading.vue'
import Button from '@/components/ui/button/Button.vue'
import { wait } from '@/helpers/wait.js'
import Label from '@/components/ui/label/Label.vue'
import { Card, CardContent, CardFooter } from '@/components/ui/card'
import Field from '@/components/ui/field/Field.vue'
import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  BreadcrumbList,
  BreadcrumbPage,
  BreadcrumbSeparator,
} from '@/components/ui/breadcrumb'

const { t } = useI18n()
useHead({
  title: t('pages.plugins.meta_remover.title')
})

const isLoading = ref(false)
const step = ref(0)

const filters = reactive({
  metadata: true,
  noise: 0
})

const canvas = ref(null)
const copy_canvas = ref(null)

function download() {
  const link = document.createElement('a')
  link.href = copy_canvas.value.toDataURL('image/png')
  link.download = 'image.png'
  link.click()
}
function onDrop(event) {
  const file = event.target.files[0]

  if (!file) {
    return
  }

  const reader = new FileReader()
  reader.readAsDataURL(file)

  reader.onloadend = function () {
    const img = new Image()
    img.src = reader.result

    img.onload = function () {
      const ctx = canvas.value.getContext('2d')

      canvas.value.width = img.width
      canvas.value.height = img.height
      ctx.drawImage(img, 0, 0, img.width, img.height)
      canvas.value.removeAttribute('data-caman-id')

      const copy_ctx = copy_canvas.value.getContext('2d')

      copy_canvas.value.width = img.width
      copy_canvas.value.height = img.height
      copy_ctx.drawImage(img, 0, 0, img.width, img.height)
      copy_canvas.value.removeAttribute('data-caman-id')

      step.value = 1
    }
  }
}
async function processImage() {
  isLoading.value = true

  // apply filters
  Caman('#copy_canvas', function () {
    this.revert(false)
    this.noise(filters.noise).render()
  })

  // fake timer
  await wait(1000)

  isLoading.value = false

  step.value = 2
}
function reset() {
  noise.value = 0
  step.value = 0
}

watch(
  () => filters.noise,
  (value) => {
    Caman('#canvas', function () {
      this.revert(false)
      this.noise(value).render()
    })
  }
)

function insertCamanScript() {
  const src
    = 'https://cdnjs.cloudflare.com/ajax/libs/camanjs/4.1.2/caman.full.min.js'
  const script = document.createElement('script')
  script.src = src
  script.async = true
  document.body.appendChild(script)
}

onMounted(() => {
  insertCamanScript()
})
</script>

<template>
  <div class="space-y-5">
    <div class="space-y-2">
      <Breadcrumb>
        <BreadcrumbList>
          <BreadcrumbItem>
            <BreadcrumbLink as-child>
              <RouterLink to="/">
                {{ $t('common.dashboard') }}
              </RouterLink>
            </BreadcrumbLink>
          </BreadcrumbItem>

          <BreadcrumbSeparator />

          <BreadcrumbItem>
            <BreadcrumbLink as-child>
              <RouterLink to="/plugins">
                {{
                  $t('pages.plugins.title')
                }}
              </RouterLink>
            </BreadcrumbLink>
          </BreadcrumbItem>

          <BreadcrumbSeparator />

          <BreadcrumbItem>
            <BreadcrumbPage>
              {{
                $t('pages.plugins.meta_remover.title')
              }}
            </BreadcrumbPage>
          </BreadcrumbItem>
        </BreadcrumbList>
      </Breadcrumb>

      <PageHeading
        :title="$t('pages.plugins.meta_remover.title')"
      />
    </div>

    <div>
      <Card v-show="step === 0">
        <CardContent>
          <Field>
            <Label>
              {{ $t('pages.plugins.meta_remover.upload_image') }}
            </Label>
            <div>
              <label
                for="upload-file"
                class="bg-surface flex h-64 w-full cursor-pointer flex-col items-center justify-center rounded-lg border-2 border-dashed border-border "
              >
                <div
                  class="flex flex-col items-center justify-center pb-6 pt-5"
                >
                  <svg
                    aria-hidden="true"
                    class="mb-3 size-10 text-neutral-400"
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12"
                    />
                  </svg>
                  <p class="mb-2 text-sm text-muted-foreground">
                    <span class="font-semibold">
                      {{ $t('pages.plugins.meta_remover.click_to_upload') }}
                    </span>
                  </p>
                  <p class="text-xs text-muted-foreground">
                    SVG, PNG, JPG or GIF (MAX. 800x400px)
                  </p>
                </div>
                <input
                  id="upload-file"
                  type="file"
                  accept="image/png, image/jpeg"
                  class="hidden"
                  @change="onDrop"
                >
              </label>
            </div>
          </Field>
        </CardContent>
      </Card>
      <div
        v-show="step === 1"
        class="space-y-5"
      >
        <Card>
          <CardContent>
            <div class="space-y-5 divide-y divide-border">
              <div>
                <h3 class="text-lg font-medium leading-6 ">
                  Preview
                </h3>
                <canvas
                  id="canvas"
                  ref="canvas"
                  class="w-1/2"
                />
              </div>
              <div class="space-y-5 pt-5">
                <div>
                  <h3 class="text-lg font-medium leading-6 ">
                    {{ $t('pages.plugins.meta_remover.filters') }}
                  </h3>
                </div>
                <div>
                  <Label
                    for="metadata"
                  >
                    {{ $t('pages.plugins.meta_remover.metadata') }}
                  </Label>
                  <BaseCheckbox
                    id="metadata"
                    v-model="filters.metadata"
                    label="Remover metadados"
                    disabled
                  />
                </div>
                <div>
                  <Label
                    for="noise"
                  >
                    {{ $t('pages.plugins.meta_remover.noise_in_image') }} - {{ filters.noise }}%
                  </Label>
                  <InputRange
                    v-model.number="filters.noise"
                    step="1"
                    min="0"
                    max="100"
                  />
                </div>
              </div>
            </div>
          </CardContent>

          <CardFooter>
            <div class="flex justify-end">
              <Button
                :loading="isLoading"
                :disabled="isLoading"
                @click="processImage"
              >
                {{ $t('pages.plugins.meta_remover.button') }}
              </Button>
            </div>
          </CardFooter>
        </Card>
      </div>
      <Card v-show="step === 2">
        <CardContent>
          <canvas
            id="copy_canvas"
            ref="copy_canvas"
            class="w-1/2"
          />
        </CardContent>
        <CardFooter>
          <div class="flex justify-end gap-2">
            <Button
              variant="secondary"
              @click="reset"
            >
              {{ $t('pages.plugins.meta_remover.upload_another') }}
            </Button>
            <Button @click="download">
              {{ $t('pages.plugins.meta_remover.download') }}
            </Button>
          </div>
        </CardFooter>
      </Card>
    </div>
  </div>
</template>
