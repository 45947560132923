import { createApp } from 'vue'
import App from './App.vue'

import '@/assets/index.css'
import '@/assets/base.css'
import 'nprogress/nprogress.css'
import '@/assets/nprogress.css'

import { setupDirectives } from '@/directives'
import { setupPlugins } from '@/plugins'

const app = createApp(App)

setupDirectives(app)
await setupPlugins(app)

app.mount('#app')
document.getElementById('preloading')?.remove()
