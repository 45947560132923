import NotFound from '@/views/NotFound.vue'

export default [
  {
    path: '/:pathMatch(.*)*',
    name: 'Error404',
    component: NotFound,
    meta: {
      layout: 'default',
      requiresAuth: true
    }
  }
]
