import Requests from '@/views/requests/Requests.vue'

export default [
  {
    path: '/requests',
    name: 'Requests',
    component: Requests,
    meta: {
      layout: 'default',
      requiresAuth: true,
      requiredCloakingSubscription: true
    }
  }
]
