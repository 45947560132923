<script setup>
import Badge from '@/components/ui/badge/Badge.vue'

const props = defineProps({
  status: {
    type: String,
    required: true
  }
})

function getVariant() {
  const map = {
    refunded: 'danger',
    succeeded: 'success',
    pending: 'warning',
    failed: 'danger',
    open: 'secondary',
    paid: 'success',
    void: 'secondary'
  }

  return map[props.status] || 'secondary'
}
</script>

<template>
  <Badge :variant="getVariant()">
    {{ $t(`components.invoice_status_badge.${props.status}`) }}
  </Badge>
</template>
