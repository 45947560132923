<script setup>
import { computed } from 'vue'
import BaseLabel from './BaseLabel.vue'

defineOptions({
  inheritAttrs: false
})
const props = defineProps({
  label: {
    type: String,
    default: ''
  },
  modelValue: {
    default: null
  },
  id: {
    type: [Number, String],
    default: () => `check_${Math.random().toString(36).substr(2, 9)}`
  },
  disabled: {
    type: Boolean,
    default: false
  },
  checkboxClass: {
    type: String,
    default:
      'h-4 w-4 transform  rounded border border-neutral-300 bg-white  duration-100 ease-in-out checked:bg-primary '
  },
  description: {
    type: String,
    default: null
  },
  descriptionInline: {
    type: Boolean,
    default: false
  }
})
const emits = defineEmits(['update:modelValue', 'change'])
const checked = computed({
  get: () => props.modelValue,
  set: (value) => {
    emits('update:modelValue', value)
    emits('change', value)
  }
})
const disabledClass = computed(() => {
  if (props.disabled) {
    return 'text-neutral-300 cursor-not-allowed opacity-50'
  }
  return ' focus:ring-2 focus:ring-neutral-900  '
})
</script>

<template>
  <div class="relative flex items-start">
    <div class="flex h-5 items-center">
      <input
        :id="id"
        v-model="checked"
        v-bind="$attrs"
        :disabled="disabled"
        type="checkbox"
        :class="[checkboxClass, disabledClass]"
      >
    </div>
    <div
      class="ml-3"
      :class="{ 'flex gap-2': descriptionInline }"
    >
      <BaseLabel
        v-if="label"
        :for="id"
        :class="`${disabled ? 'cursor-not-allowed' : ''} cursor-pointer `"
      >
        {{ label }}
      </BaseLabel>
      <p
        id="description"
        class="text-sm text-muted-foreground"
      >
        {{ description }}
      </p>
    </div>
  </div>
</template>
