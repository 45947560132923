<script  setup>
import { MagnifyingGlassIcon } from '@heroicons/vue/24/solid'
import { useDebounce } from '@vueuse/core'
import { ref, watch } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import Input from '@/components/ui/input/Input.vue'

const route = useRoute()
const router = useRouter()
const searchTerm = ref(route.query.name)

// Usando debounce para atrasar o valor de searchTerm
const debouncedSearchTerm = useDebounce(searchTerm, 500)

// Usando watch para monitorar as mudanças no termo de busca com debounce
watch(debouncedSearchTerm, (newTerm) => {
  router.push({
    query: {
      ...route.query,
      name: newTerm
    }
  })
})
</script>

<template>
  <div>
    <div>
      <Input
        id="search_campaign_name"
        v-model="searchTerm"
        :placeholder="$t('common.search_by_name')"
        name="search_campaign_name"
        type="search"
      >
        <template #leading>
          <MagnifyingGlassIcon class="size-5 text-muted-foreground" />
        </template>
      </Input>
    </div>
  </div>
</template>
