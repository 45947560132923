<script setup>
import { ref, watch } from 'vue'
import dayjs from 'dayjs'
import { useRoute, useRouter } from 'vue-router'
import { useHead } from '@unhead/vue'
import { useI18n } from 'vue-i18n'
import DatePicker from '@/components/base/DatePicker.vue'
import SectionHeading from '@/components/common/SectionHeading.vue'
import { usePresetDates } from '@/composables/usePresetDates'
import RequestsPerDayChart from '@/components/partials/reports/RequestsPerDayChart.vue'
import RequestsPerCountry from '@/components/partials/reports/RequestsPerCountry.vue'
import RequestByDeniedReason from '@/components/partials/reports/RequestByDeniedReason.vue'
import RequestsByPage from '@/components/partials/reports/RequestsByPage.vue'

const { t, locale } = useI18n()
useHead({
  title: t('pages.reports.title')
})
const {
  minDate,
  shortcuts
} = usePresetDates()
const router = useRouter()
const route = useRoute()

const range = ref({
  start: route.query.start || dayjs().startOf('day').toDate(),
  end: route.query.end || dayjs().endOf('day').toDate(),
})

watch(range, () => {
  router.push({
    query: {
      ...route.query,
      start: dayjs(range.value.start).toISOString(),
      end: dayjs(range.value.end).toISOString(),
    }
  })
}, {
  deep: true
})
</script>

<template>
  <div class="space-y-5">
    <div class="space-y-2">
      <SectionHeading
        :title="t('pages.reports.title')"
      >
        <template #actions>
          <div class="w-72">
            <DatePicker
              v-model="range"
              :presets="shortcuts"
              :min="minDate"
              range
              :locale="locale"
            />
          </div>
        </template>
      </SectionHeading>
    </div>

    <div class="space-y-5">
      <RequestsPerDayChart
        :campaign-id="$route.params.id?.toString()"
        :start-date="range.start"
        :end-date="range.end"
      />

      <RequestsPerCountry
        :campaign-id="$route.params.id?.toString()"
        :start-date="range.start"
        :end-date="range.end"
      />

      <div class="grid grid-cols-1 gap-2 sm:grid-cols-2">
        <RequestByDeniedReason
          :campaign-id="$route.params.id?.toString()"
          :start-date="range.start"
          :end-date="range.end"
        />
        <RequestsByPage
          :campaign-id="$route.params.id?.toString()"
          :start-date="range.start"
          :end-date="range.end"
        />
      </div>
    </div>
  </div>
</template>
