import EncryptText from '@/views/plugins/[name]/EncryptText.vue'
import LandingGenerator from '@/views/plugins/[name]/LandingGenerator.vue'
import PageDownloader from '@/views/plugins/[name]/PageDownloader.vue'
import Plugins from '@/views/plugins/PluginsView.vue'
import RemoveMetaDataImageView from '@/views/plugins/[name]/RemoveMetaDataImageView.vue'

export default [
  {
    path: '/plugins',
    component: Plugins,
    name: 'Plugins',
    meta: {
      layout: 'default',
      requiresAuth: true,
      requiredCloakingSubscription: true
    }
  },
  {
    path: '/plugins/encrypt-text',
    name: 'EncryptText',
    component: EncryptText,
    meta: {
      layout: 'default',
      requiresAuth: true,
      requiredCloakingSubscription: true,
      feature: 'crypto_text'
    }
  },
  {
    path: '/plugins/safe-page-generator-v1',
    name: 'SafePageGeneratorv1',
    component: LandingGenerator,
    meta: {
      layout: 'safePageGenerator',
      requiresAuth: true,
      requiredCloakingSubscription: true,
      feature: 'safe_page_generator'
    }
  },
  {
    path: '/plugins/meta-remover',
    name: 'MetaRemover',
    component: RemoveMetaDataImageView,
    meta: {
      layout: 'default',
      requiresAuth: true,
      requiredCloakingSubscription: true,
      feature: 'meta_remover'
    }
  },
  {
    path: '/plugins/page-downloader',
    name: 'PageDownloader',
    component: PageDownloader,
    meta: {
      layout: 'default',
      requiresAuth: true,
      requiredCloakingSubscription: true,
      feature: 'page_downloader'
    }
  }
]
