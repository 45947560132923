import DomainsView from '@/views/domains/DomainsView.vue'

export default [
  {
    path: '/domains',
    name: 'Domains',
    component: DomainsView,
    meta: {
      layout: 'default',
      requiresAuth: true,
      requiredCloakingSubscription: true
    },
  }
]
