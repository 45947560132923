<script setup>
import { ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { useHead } from '@unhead/vue'
import BaseAlert from '@/components/base/BaseAlert.vue'
import PageHeading from '@/components/common/PageHeading.vue'
import Button from '@/components/ui/button/Button.vue'
import Input from '@/components/ui/input/Input.vue'
import { Card, CardContent, CardFooter, CardHeader, CardTitle } from '@/components/ui/card'
import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  BreadcrumbList,
  BreadcrumbPage,
  BreadcrumbSeparator,
} from '@/components/ui/breadcrumb'

const { t } = useI18n()
useHead({
  title: t('pages.plugins.page_downloader.title')
})

const URL = import.meta.env.VITE_PAGE_DOWNLOADER_URL

const isDownloading = ref(false)
const hasError = ref(false)

function onSubmit(event) {
  const target = event.target
  const formData = new FormData(target)
  const websiteLink = formData.get('url')

  const options = {
    url: websiteLink
  }

  startScrapping(options)
}

async function startScrapping(options) {
  isDownloading.value = true
  hasError.value = false
  try {
    const response = await fetch(`${URL}/copy`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        url: options.url
      })
    })
    const body = await response.json()
    if (!response.ok) {
      hasError.value = true
      return
    }
    await checkProgress(body.id)
  } catch (err) {
    hasError.value = true
  } finally {
    isDownloading.value = false
  }
}

async function checkProgress(id) {
  const response = await fetch(`${URL}/status/${id}`, {
    method: 'GET'
  })
  const body = await response.json()

  if (!response.ok) {
    hasError.value = true
    return
  }

  if (!body.finished) {
    await new Promise(resolve => setTimeout(resolve, 3000))

    return checkProgress(id)
  }

  await fetchWebsite(id)
}

async function fetchWebsite(id) {
  window.open(`${URL}/download/${id}`)
}
</script>

<template>
  <div class="space-y-5">
    <div class="space-y-2">
      <Breadcrumb>
        <BreadcrumbList>
          <BreadcrumbItem>
            <BreadcrumbLink as-child>
              <RouterLink to="/">
                {{ $t('common.dashboard') }}
              </RouterLink>
            </BreadcrumbLink>
          </BreadcrumbItem>

          <BreadcrumbSeparator />

          <BreadcrumbItem>
            <BreadcrumbLink as-child>
              <RouterLink to="/plugins">
                {{
                  $t('pages.plugins.title')
                }}
              </RouterLink>
            </BreadcrumbLink>
          </BreadcrumbItem>

          <BreadcrumbSeparator />

          <BreadcrumbItem>
            <BreadcrumbPage>
              {{
                $t('pages.plugins.page_downloader.title')
              }}
            </BreadcrumbPage>
          </BreadcrumbItem>
        </BreadcrumbList>
      </Breadcrumb>

      <PageHeading
        :title="$t('pages.plugins.page_downloader.title')"
        :description="$t('pages.plugins.page_downloader.description')"
      />
    </div>

    <div>
      <form @submit.prevent="onSubmit">
        <fieldset :disabled="isDownloading">
          <Card>
            <CardHeader>
              <CardTitle>
                {{ $t('pages.plugins.page_downloader.url') }}
              </CardTitle>
            </CardHeader>

            <CardContent>
              <div>
                <Input
                  id="url"
                  type="text"
                  placeholder="https://"
                  name="url"
                />
              </div>
            </CardContent>

            <BaseAlert
              v-if="hasError"
              variant="error"
              class="mt-5"
              :message="$t('pages.plugins.page_downloader.alert.message')"
              :title="$t('pages.plugins.page_downloader.alert.title')"
            />

            <CardFooter>
              <Button
                type="submit"
                expanded
                :loading="isDownloading"
              >
                {{ $t('pages.plugins.page_downloader.download') }}
              </Button>
            </CardFooter>
          </Card>
        </fieldset>
      </form>
    </div>
  </div>
</template>
