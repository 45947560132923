<script setup>
import { CheckIcon } from '@radix-icons/vue'
import {
  CheckboxIndicator,
  CheckboxRoot,
  useForwardPropsEmits,
} from 'radix-vue'
import { computed } from 'vue'
import { cn } from '@/lib/utils'

const props = defineProps({
  defaultChecked: {
    type: Boolean,
    required: false
  },
  checked: {
    type: [Boolean, String],
    required: false
  },
  disabled: {
    type: Boolean,
    required: false
  },
  required: {
    type: Boolean,
    required: false
  },
  name: {
    type: String,
    required: false
  },
  value: {
    type: String,
    required: false
  },
  id: {
    type: String,
    required: false
  },
  asChild: {
    type: Boolean,
    required: false
  },
  as: {
    type: null,
    required: false
  },
  class: {
    type: null,
    required: false
  },
})
const emits = defineEmits(['update:checked'])

const delegatedProps = computed(() => {
  const { class: _, ...delegated } = props

  return delegated
})

const forwarded = useForwardPropsEmits(delegatedProps, emits)
</script>

<template>
  <CheckboxRoot
    v-bind="forwarded"
    :class="
      cn(
        'peer h-4 w-4 shrink-0 rounded-sm border border-border shadow focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring disabled:cursor-not-allowed disabled:opacity-50 data-[state=checked]:bg-primary data-[state=checked]:text-primary-foreground',
        props.class,
      )
    "
  >
    <CheckboxIndicator
      class="flex size-full items-center justify-center text-current"
    >
      <slot>
        <CheckIcon class="size-4" />
      </slot>
    </CheckboxIndicator>
  </CheckboxRoot>
</template>
