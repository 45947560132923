<script setup>
import { ref } from 'vue'
import { toast } from 'vue-sonner'
import { useI18n } from 'vue-i18n'
import { useHead } from '@unhead/vue'
import BaseTable from '@/components/base/BaseTable.vue'
import SectionHeading from '@/components/common/SectionHeading.vue'
import PaymentMethodAddCardModal from '@/components/partials/settings/payment-method/PaymentMethodAddCardModal.vue'
import { getBrandSVG } from '@/helpers/get-brand-svg'
import { cardService } from '@/services/card'
import Button from '@/components/ui/button/Button.vue'
import EmptyState from '@/components/common/EmptyState.vue'

const { t } = useI18n()
useHead({
  title: t('pages.settings.payment_methods.title')
})

const hasError = ref(false)
const isLoading = ref(false)
const creditCards = ref([])
const isDeletingCard = ref(false)
const isUpdatingDefaultCard = ref(false)

async function fetchCreditCards(params) {
  isLoading.value = true
  hasError.value = false
  try {
    const { data } = await cardService.findAll(params)
    creditCards.value = data.data
  } catch (e) {
    hasError.value = true
    toast.error('Erro!', {
      description: e.response?.data?.message || e.message,
    })
  } finally {
    isLoading.value = false
  }
}
async function updateDefaultCreditCard(id) {
  try {
    isUpdatingDefaultCard.value = true
    await cardService.toggleDefault(id)
    creditCards.value = creditCards.value.map((card) => {
      card.default = card.id === id
      return card
    })

    toast.success(t('pages.settings.payment_methods.update.toast.success.title'), {
      description: t('pages.settings.payment_methods.update.toast.success.description'),
    })
  } catch (e) {
    toast.error(e.response?.data?.message, {
      description: e.response?.data?.action || e.message,
    })
  } finally {
    isUpdatingDefaultCard.value = false
  }
}
async function deleteCreditCard(id) {
  try {
    isDeletingCard.value = true
    await cardService.remove(id)
    creditCards.value = creditCards.value.filter(card => card.id !== id)

    toast.success(t('pages.settings.payment_methods.delete.toast.success.title'), {
      description: t('pages.settings.payment_methods.delete.toast.success.description'),
    })
  } catch (e) {
    toast.error(e.response?.data?.message, {
      description: e.response?.data?.action || e.message,
    })
  } finally {
    isDeletingCard.value = false
  }
}
function addCard(card) {
  creditCards.value.unshift(card)
  if (!card.default) {
    return
  }

  creditCards.value = creditCards.value.map((c) => {
    c.default = c.id === card.id
    return c
  })
}

fetchCreditCards({
  page: 1,
  limit: 50
})

const header = [
  {
    label: t('pages.settings.payment_methods.brand'),
    value: 'brand'
  },
  {
    label: t('pages.settings.payment_methods.last_four_digits'),
    value: 'last_four_digits',
    format: (value) => {
      return `**** **** **** ${value}`
    }
  }
]
</script>

<template>
  <div>
    <div>
      <SectionHeading
        :title="$t('pages.settings.payment_methods.title')"
        :description="$t('pages.settings.payment_methods.description')"
      >
        <template #actions>
          <PaymentMethodAddCardModal
            @new-card="addCard"
          />
        </template>
      </SectionHeading>

      <div class="mt-8">
        <BaseTable
          :headers="header"
          :columns="creditCards"
          :loading="isLoading || isUpdatingDefaultCard || isDeletingCard"
          :error="hasError"
        >
          <template #col-brand="{ column }">
            <img
              :src="`/img/payment/${getBrandSVG(column.brand)}`"
              class="w-10"
              :alt="column.brand"
            >
          </template>
          <template #actions="{ column }">
            <div class="space-x-2">
              <Button
                :disabled="isUpdatingDefaultCard || column.default"
                type="button"
                variant="secondary"
                @click="updateDefaultCreditCard(column.id)"
              >
                {{ column.default ? $t('pages.settings.payment_methods.default') : $t('pages.settings.payment_methods.set_as_default') }}
              </Button>
              <Button
                :disabled="isDeletingCard"
                variant="destructive"
                @click="deleteCreditCard(column.id)"
              >
                {{ $t('common.delete') }}
              </Button>
            </div>
          </template>
          <template #empty>
            <EmptyState
              :title="$t('pages.settings.payment_methods.empty_state.title')"
              :description="$t('pages.settings.payment_methods.empty_state.description')"
            />
          </template>
        </BaseTable>
      </div>
    </div>
  </div>
</template>
