<script setup>
import { RadioGroup, RadioGroupLabel, RadioGroupOption } from '@headlessui/vue'

defineProps({
  regions: {
    type: Array,
    required: true,
    default: () => []
  },

})

const model = defineModel({
  required: true
})
</script>

<template>
  <RadioGroup v-model="model">
    <RadioGroupLabel class="sr-only">
      Server size
    </RadioGroupLabel>
    <div class="grid grid-cols-1 gap-4 md:grid-cols-2 lg:grid-cols-2">
      <RadioGroupOption
        v-for="region in regions"
        :key="region.name"
        v-slot="{ checked, active }"
        as="template"
        :value="region"
      >
        <div
          class="relative block cursor-pointer rounded-lg border  bg-background px-6 py-4 shadow-sm focus:outline-none sm:flex sm:justify-between"
          :class="[
            checked ? 'border-transparent' : 'border-border',
            active ? 'border-primary ring-2 ring-primary' : '',
          ]"
        >
          <span class="flex items-center">
            <span class="flex gap-2 text-sm">
              <img
                :src="`/img/flags/${region.flag}`"
                class="w-8"
              >
              <RadioGroupLabel
                as="span"
                class="font-medium "
              >{{
                region.name
              }}</RadioGroupLabel>
            </span>
          </span>

          <span
            class="pointer-events-none absolute -inset-px rounded-lg"
            :class="[
              active ? 'border' : 'border-2',
              checked ? 'border-primary' : 'border-transparent',
            ]"
            aria-hidden="true"
          />
        </div>
      </RadioGroupOption>
    </div>
  </RadioGroup>
</template>
