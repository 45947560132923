import Pricing from '@/views/Pricing.vue'
import CheckoutView from '@/views/CheckoutView.vue'
import ThankyouPageView from '@/views/ThankyouPageView.vue'

export default [
  {
    path: '/checkout/:priceId',
    name: 'Checkout',
    component: CheckoutView,
    meta: {
      layout: 'checkout',
      requiresAuth: true
    },
    props: true
  },
  {
    path: '/plans',
    name: 'CheckoutPlans',
    component: Pricing,
    meta: {
      layout: 'default',
      requiresAuth: true
    }
  },
  {
    path: '/thankyou',
    name: 'Thankyou',
    component: ThankyouPageView,
    meta: {
      layout: 'blank',
      requiresAuth: true
    }
  },
]
