<script>
export default {
  name: 'BaseError'
}
</script>

<template>
  <span class="text-sm text-destructive">
    <slot />
  </span>
</template>
