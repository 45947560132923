<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    viewBox="0 0 32 32"
  ><path
    fill="currentColor"
    d="M9.438 31.401a7.055 7.055 0 0 1-1.656-1.536a19.954 19.954 0 0 1-1.422-1.938a18.878 18.878 0 0 1-2.375-4.849c-.667-2-.99-3.917-.99-5.792c0-2.094.453-3.922 1.339-5.458a7.68 7.68 0 0 1 2.797-2.906a7.45 7.45 0 0 1 3.786-1.12c.469 0 .974.068 1.51.198c.385.109.854.281 1.427.495c.729.281 1.13.453 1.266.495c.427.156.786.224 1.068.224c.214 0 .516-.068.859-.172c.193-.068.557-.188 1.078-.411c.516-.188.922-.349 1.245-.469c.495-.146.974-.281 1.401-.349a6.663 6.663 0 0 1 1.531-.063a9.062 9.062 0 0 1 2.589.557c1.359.547 2.458 1.401 3.276 2.615a6.346 6.346 0 0 0-.969.734a8.152 8.152 0 0 0-1.641 2.005a6.825 6.825 0 0 0-.859 3.359c.021 1.443.391 2.714 1.12 3.813a7.165 7.165 0 0 0 2.047 2.047c.417.281.776.474 1.12.604c-.161.5-.333.984-.536 1.464a19.177 19.177 0 0 1-1.667 3.083c-.578.839-1.031 1.464-1.375 1.88c-.536.635-1.052 1.12-1.573 1.458c-.573.38-1.25.583-1.938.583a4.395 4.395 0 0 1-1.38-.167c-.385-.13-.766-.271-1.141-.432a9.132 9.132 0 0 0-1.203-.453a6.268 6.268 0 0 0-3.099-.005c-.417.12-.818.26-1.214.432c-.557.234-.927.391-1.141.458c-.427.125-.87.203-1.318.229c-.693 0-1.339-.198-1.979-.599zm9.14-24.615c-.906.453-1.771.646-2.63.583c-.135-.865 0-1.75.359-2.719a7.264 7.264 0 0 1 1.333-2.24A7.097 7.097 0 0 1 19.812.733c.88-.453 1.719-.698 2.521-.734c.104.906 0 1.797-.333 2.76a7.981 7.981 0 0 1-1.333 2.344a6.83 6.83 0 0 1-2.115 1.682z"
  /></svg>
</template>
