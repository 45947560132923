const devicesOptions = [
  {
    id: 'all',
    name: 'Todos'
  },
  {
    id: 'desktop',
    name: 'Computador'
  },
  {
    id: 'smartphone',
    name: 'Celular'
  },
  {
    id: 'tablet',
    name: 'Tablet'
  },
  {
    id: 'unknown',
    name: 'Desconhecido'
  }
]
const allowedOptions = [
  {
    id: 'all',
    name: 'Todas'
  },
  {
    id: true,
    name: 'Permitidas'
  },
  {
    id: false,
    name: 'Negadas'
  }
]

export { devicesOptions, allowedOptions }
