<script setup>
import { ref } from 'vue'
import { toast } from 'vue-sonner'
import { useClipboard } from '@vueuse/core'
import { ExternalLinkIcon } from '@radix-icons/vue'
import Button from '@/components/ui/button/Button.vue'
import { campaignService } from '@/services/campaign.js'
import { useCampaignStore } from '@/stores/campaign.js'
import CodeBlock from '@/components/common/CodeBlock.vue'

const campaignStore = useCampaignStore()

const { copy, copied } = useClipboard()

const isLoading = ref(false)
const wpConfig = ref(null)

async function downloadWordpressV2Integration() {
  isLoading.value = true
  try {
    const { data } = await campaignService.downloadFile(
      campaignStore.campaign.id,
      'wordpress'
    )

    const decoded = atob(data.file)
    const decodedData = JSON.parse(decoded)
    wpConfig.value = decodedData
  } catch (e) {
    toast.error(e.response?.data?.message, {
      description: e.response?.data?.action || e.message,
    })
  } finally {
    isLoading.value = false
  }
}
</script>

<template>
  <div class="py-5">
    <div>
      <ul class="list-inside list-disc text-muted-foreground">
        <li>
          {{ $t('components.campaign_integration_wordpress.download_and_install') }}
        </li>

        <li>
          {{
            $t('components.campaign_integration_wordpress.copy_config')
          }}
        </li>

        <li>
          {{ $t('components.campaign_integration_wordpress.check_plugin') }}
        </li>

        <li>
          {{
            $t('components.campaign_integration_wordpress.import_campaign')
          }}
        </li>
      </ul>

      <div class="mt-2 flex flex-col">
        <div class="mt-5 flex flex-col gap-2">
          <CodeBlock
            v-if="wpConfig"
            :code="JSON.stringify(wpConfig, null, 2)"
            lang="json"
            class="max-w-md"
          />

          <div class="flex gap-2">
            <Button
              v-if="!wpConfig"
              :loading="isLoading"
              @click="downloadWordpressV2Integration"
            >
              {{ $t('components.campaign_integration_wordpress.show_config_file') }}
            </Button>
            <Button
              v-else
              variant="secondary"
              @click="copy(JSON.stringify(wpConfig))"
            >
              {{ copied ? 'Copiado' : 'Copiar' }}
            </Button>
            <a
              href="/cloakup-plugin.zip"
              class="text-sm text-blue-500"
              target="_blank"
            >
              <Button>
                {{ $t('components.campaign_integration_wordpress.download_plugin') }}
              </Button>
            </a>
          </div>
        </div>
      </div>
      <div class="mt-2 flex shrink-0">
        <a
          href="https://help.cloakup.me/pt-br/article/como-integrar-via-wordpress-kl5om5/"
          class="flex items-center gap-2 text-sm text-blue-500 underline"
          target="_blank"
        >

          <ExternalLinkIcon class="size-4" />
          {{ $t('components.campaign_integration_wordpress.step_by_step') }}
        </a>
      </div>
    </div>
  </div>
</template>
