<script setup>
import { Primitive } from 'radix-vue'
import { buttonVariants } from '.'
import { cn } from '@/lib/utils'
import BaseSpinner from '@/components/base/BaseSpinner.vue'

const props = defineProps({
  variant: {
    type: null,
    required: false
  },
  size: {
    type: null,
    required: false
  },
  class: {
    type: null,
    required: false
  },
  asChild: {
    type: Boolean,
    required: false
  },
  as: {
    type: null,
    required: false,
    default: 'button'
  },
  block: {
    type: Boolean,
    required: false
  },
  loading: {
    type: Boolean,
    required: false
  }
})
</script>

<template>
  <Primitive
    :as="as"
    :as-child="asChild"
    :class="cn(buttonVariants({ variant, size }), props.class, block && 'w-full')"
  >
    <div
      v-if="loading"
      class="pr-2"
    >
      <BaseSpinner />
    </div>
    <slot />
  </Primitive>
</template>
