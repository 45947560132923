<script setup>
import { ref } from 'vue'
import { useRouter } from 'vue-router'
import { useColorMode } from '@vueuse/core'
import { ChevronUpDownIcon } from '@heroicons/vue/24/solid'
import { Avatar, AvatarFallback, AvatarImage } from '@/components/ui/avatar'
import { useUserStore } from '@/stores/user'
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu'
import { useAuthStore } from '@/stores/auth'
import { Switch } from '@/components/ui/switch'

const userStore = useUserStore()
const authStore = useAuthStore()
const router = useRouter()
const mode = useColorMode()

const isQuitting = ref(false)

async function logout() {
  try {
    isQuitting.value = true
    await authStore.signOut()
    await router.push({
      name: 'SignIn'
    })
  } catch (error) {
    console.log('error', error)
  } finally {
    isQuitting.value = false
  }
}

function changeTheme() {
  mode.value = mode.value === 'dark' ? 'light' : 'dark'
}
</script>

<template>
  <DropdownMenu>
    <DropdownMenuTrigger class="w-full">
      <div
        class="flex w-full items-center justify-between gap-x-4 px-4 py-3  leading-6 hover:bg-primary/10"
      >
        <Avatar class="size-8 rounded-lg">
          <AvatarImage
            :src="userStore.user?.name"
            :alt="userStore.user?.name"
          />
          <AvatarFallback class="rounded-lg">
            {{ userStore.user?.name[0] }}
          </AvatarFallback>
        </Avatar>
        <div class="grid flex-1 text-left text-sm leading-tight">
          <span class="truncate font-semibold">{{ userStore.user?.name }}</span>
          <span class="truncate text-xs text-muted-foreground">{{ userStore.user?.email }}</span>
        </div>

        <ChevronUpDownIcon class="size-5 text-muted-foreground" />
      </div>
    </DropdownMenuTrigger>
    <DropdownMenuContent class="w-56">
      <DropdownMenuLabel>
        <div class="flex items-center gap-2 px-1 py-1.5 text-left text-sm">
          <Avatar class="size-8 rounded-lg">
            <AvatarImage
              :src="userStore.user?.name"
              :alt="userStore.user?.name"
            />
            <AvatarFallback class="rounded-lg">
              {{ userStore.user?.name[0] }}
            </AvatarFallback>
          </Avatar>
          <div class="grid flex-1 text-left text-sm leading-tight">
            <span class="truncate font-semibold">
              {{ userStore.user?.name }}
            </span>
            <span class="truncate text-xs text-muted-foreground">
              {{ userStore.user?.email }}
            </span>
          </div>
        </div>
      </DropdownMenuLabel>
      <DropdownMenuSeparator />
      <DropdownMenuGroup>
        <DropdownMenuItem as-child>
          <router-link
            to="/settings/profile"
          >
            {{ $t('components.profile.profile') }}
          </router-link>
        </DropdownMenuItem>
        <DropdownMenuItem as-child>
          <router-link to="/settings/subscriptions">
            {{ $t('components.profile.subscriptions') }}
          </router-link>
        </DropdownMenuItem>
        <DropdownMenuItem as-child>
          <router-link
            :to="{ name: 'dashboard.referral.index' }"
          >
            {{ $t('components.profile.referral') }}
          </router-link>
        </DropdownMenuItem>
        <div class="relative flex w-full cursor-default select-none items-center justify-between rounded-sm px-2 py-1.5 text-sm outline-none transition-colors focus:bg-accent focus:text-accent-foreground data-[disabled]:pointer-events-none data-[disabled]:opacity-50">
          Dark mode
          <Switch
            id="dark-mode"
            :checked="mode === 'dark'"
            @update:checked="changeTheme"
          />
        </div>
      </DropdownMenuGroup>
      <DropdownMenuSeparator />

      <DropdownMenuItem as-child>
        <a
          href="http://help.cloakup.me/"
          target="_blank"
        >
          {{ $t('components.profile.help') }}
        </a>
      </DropdownMenuItem>

      <DropdownMenuSeparator />
      <DropdownMenuItem @click="logout">
        {{ $t('components.profile.logout') }}
      </DropdownMenuItem>
    </DropdownMenuContent>
  </DropdownMenu>
</template>
