<script setup>
import { ref } from 'vue'
import { toast } from 'vue-sonner'
import { TrashIcon } from '@heroicons/vue/24/solid'
import { useI18n } from 'vue-i18n'
import { serverService } from '@/services/server'
import Button from '@/components/ui/button/Button.vue'
import {
  AlertDialog,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger,
} from '@/components/ui/alert-dialog'

const props = defineProps({
  id: {
    type: Number,
    required: true
  }
})
const emit = defineEmits(['deleted'])
const { t } = useI18n()

const isOpen = ref(false)
const isLoading = ref(false)

async function deleteServer(id) {
  isLoading.value = true

  try {
    await serverService.delete(id)

    toast.success(t('components.delete_server_modal.toast.success.title'), {
      description: t('components.delete_server_modal.toast.success.description'),
    })

    isOpen.value = false
    emit('deleted', id)
  } catch (e) {
    toast.error(e.response?.data?.message, {
      description: e.response?.data?.action || e.message,
    })
  } finally {
    isLoading.value = false
  }
}
</script>

<template>
  <AlertDialog v-model:open="isOpen">
    <AlertDialogTrigger as-child>
      <Button
        variant="ghost"
      >
        <TrashIcon class="size-5" />
      </Button>
    </AlertDialogTrigger>
    <AlertDialogContent>
      <AlertDialogHeader>
        <AlertDialogTitle>
          {{ $t('components.delete_server_modal.title') }}
        </AlertDialogTitle>
        <AlertDialogDescription>
          {{ $t('components.delete_server_modal.description') }}
        </AlertDialogDescription>
      </AlertDialogHeader>
      <AlertDialogFooter>
        <AlertDialogCancel :disabled="isLoading">
          {{ $t('common.cancel') }}
        </AlertDialogCancel>
        <Button
          variant="destructive"
          :loading="isLoading"
          :disabled="isLoading"
          @click="deleteServer(props.id)"
        >
          {{ $t('common.delete') }}
        </Button>
      </AlertDialogFooter>
    </AlertDialogContent>
  </AlertDialog>
</template>
