<script setup>
import { computed } from 'vue'
import Label from '@/components/ui/label/Label.vue'

defineOptions({
  inheritAttrs: false
})
const props = defineProps({
  label: {
    type: String,
    default: ''
  },
  modelValue: {
    required: true
  },
  id: {
    type: [Number, String],
    default: () => `check_${Math.random().toString(36).substr(2, 9)}`
  },
  disabled: {
    type: Boolean,
    default: false
  },
  description: {
    type: String,
    default: null
  }
})
const emit = defineEmits(['update:modelValue', 'change'])
const checked = computed({
  get: () => props.modelValue,
  set: (value) => {
    emit('update:modelValue', value)
    emit('change', value)
  }
})
const disabledClass = computed(() => {
  if (props.disabled) {
    return 'text-neutral-300 cursor-not-allowed'
  }
  return 'text-primary focus:ring-primary'
})
</script>

<template>
  <div class="relative flex items-start">
    <div class="flex h-5 items-center">
      <input
        :id="id"
        v-model="checked"
        v-bind="$attrs"
        :disabled="disabled"
        type="radio"
        class="size-4 rounded-full border border-border bg-background duration-100  ease-in-out checked:bg-blue-500 focus:ring-2 focus:ring-primary"
        :class="[disabledClass]"
      >
    </div>
    <div class="ml-3 text-sm">
      <Label
        v-if="label"
        :for="id"
        :class="`${disabled ? 'cursor-not-allowed' : ''} cursor-pointer `"
      >
        {{ label }}
      </Label>
      <p
        v-if="description"
        :id="`${id}-description`"
        class="text-sm text-muted-foreground"
      >
        {{ description }}
      </p>
    </div>
  </div>
</template>
