<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    viewBox="0 0 256 256"
  >
    <path
      fill="#dd4814"
      d="M255.637 127.683c0 70.514-57.165 127.68-127.683 127.68C57.434 255.363.27 198.197.27 127.683C.27 57.165 57.436 0 127.954 0c70.519 0 127.683 57.165 127.683 127.683"
    />
    <path
      fill="#fff"
      d="M41.133 110.633c-9.419 0-17.05 7.631-17.05 17.05c0 9.414 7.631 17.046 17.05 17.046c9.415 0 17.046-7.632 17.046-17.046c0-9.419-7.631-17.05-17.046-17.05m121.715 77.478c-8.153 4.71-10.95 15.13-6.24 23.279c4.705 8.154 15.125 10.949 23.279 6.24c8.153-4.705 10.949-15.125 6.24-23.28c-4.705-8.148-15.131-10.943-23.279-6.239m-84.686-60.428c0-16.846 8.368-31.73 21.171-40.742L86.87 66.067c-14.914 9.97-26.012 25.204-30.624 43.047c5.382 4.39 8.826 11.075 8.826 18.568c0 7.489-3.444 14.174-8.826 18.565C60.852 164.094 71.95 179.33 86.87 189.3l12.463-20.88c-12.803-9.007-21.171-23.89-21.171-40.737m49.792-49.797c26.013 0 47.355 19.944 49.595 45.38l24.29-.358c-1.194-18.778-9.398-35.636-22.002-48.032c-6.482 2.449-13.97 2.074-20.44-1.656c-6.483-3.741-10.548-10.052-11.659-16.902a74.3 74.3 0 0 0-19.785-2.69a73.8 73.8 0 0 0-32.819 7.663l11.845 21.227a49.6 49.6 0 0 1 20.975-4.632m0 99.59a49.6 49.6 0 0 1-20.974-4.632l-11.845 21.225a73.7 73.7 0 0 0 32.82 7.671a74 74 0 0 0 19.784-2.697c1.111-6.85 5.177-13.155 11.658-16.902c6.476-3.737 13.959-4.105 20.44-1.656c12.605-12.396 20.808-29.254 22.004-48.032l-24.297-.358c-2.235 25.443-23.576 45.38-49.59 45.38m34.888-110.231c8.154 4.708 18.575 1.92 23.279-6.234c4.71-8.154 1.92-18.575-6.234-23.285c-8.154-4.704-18.574-1.91-23.285 6.244c-4.703 8.15-1.908 18.57 6.24 23.275"
    />
  </svg>
</template>
