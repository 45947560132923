export const serverImages = [
  {
    id: 2,
    name: 'Ubuntu',
    required_features: [],
    disabled_features: [],
    is_deprecated: false,
    images: [
      {
        id: 119383150,
        is_public: true,
        name: '22.10 x64',
        region_id: 8,
        region_ids: [1, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15],
        slug_name: 'ubuntu-22-04-x64',
        disk: 7,
        required_settings: [],
        distribution_name: 'Ubuntu',
        distribution_parameterized_name: 'ubuntu',
        droplet_id: null,
        created_at: '2022-10-22T19:58:00Z',
        region_slug: 'nyc3',
        is_backup: false,
        is_snapshot: false,
        droplet_name: null,
        user_id: 73694,
        disabled_features: [],
        billable_size_in_gb: 0.742123056,
        is_byoi: false,
        icon: null,
        kernel: null,
        allows_password_reset: true,
        allows_volume_automount: true,
        marketplace: null
      },
      {
        id: 129211873,
        is_public: true,
        name: '22.04 (LTS) x64',
        region_id: 8,
        region_ids: [1, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15],
        slug_name: 'ubuntu-22-04-x64',
        disk: 7,
        required_settings: [],
        distribution_name: 'Ubuntu',
        distribution_parameterized_name: 'ubuntu',
        droplet_id: null,
        created_at: '2023-03-22T19:17:23Z',
        region_slug: 'nyc3',
        is_backup: false,
        is_snapshot: false,
        droplet_name: null,
        user_id: 73694,
        disabled_features: [],
        billable_size_in_gb: 0.720588395,
        is_byoi: false,
        icon: null,
        kernel: null,
        allows_password_reset: true,
        allows_volume_automount: true,
        marketplace: null
      },
      {
        id: 112929454,
        is_public: true,
        name: '20.04 (LTS) x64',
        region_id: 8,
        region_ids: [1, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15],
        slug_name: 'ubuntu-20-04-x64',
        disk: 7,
        required_settings: [],
        distribution_name: 'Ubuntu',
        distribution_parameterized_name: 'ubuntu',
        droplet_id: null,
        created_at: '2022-07-18T19:40:04Z',
        region_slug: 'nyc3',
        is_backup: false,
        is_snapshot: false,
        droplet_name: null,
        user_id: 73694,
        disabled_features: [],
        billable_size_in_gb: 0.632625084,
        is_byoi: false,
        icon: null,
        kernel: null,
        allows_password_reset: true,
        allows_volume_automount: true,
        marketplace: null
      },
      {
        id: 112929540,
        is_public: true,
        name: '18.04 (LTS) x64',
        region_id: 8,
        region_ids: [1, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15],
        slug_name: 'ubuntu-18-04-x64',
        disk: 7,
        required_settings: [],
        distribution_name: 'Ubuntu',
        distribution_parameterized_name: 'ubuntu',
        droplet_id: null,
        created_at: '2022-07-18T19:42:42Z',
        region_slug: 'nyc3',
        is_backup: false,
        is_snapshot: false,
        droplet_name: null,
        user_id: 73694,
        disabled_features: [],
        billable_size_in_gb: 0.425858055,
        is_byoi: false,
        icon: null,
        kernel: null,
        allows_password_reset: true,
        allows_volume_automount: true,
        marketplace: null
      }
    ]
  },
  {
    id: 1,
    name: 'Fedora',
    required_features: ['ssh'],
    disabled_features: ['pipod'],
    is_deprecated: false,
    images: [
      {
        id: 132196217,
        is_public: true,
        name: '38 x64',
        region_id: 8,
        region_ids: [1, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15],
        slug_name: 'fedora-38-x64',
        disk: 15,
        required_settings: ['ssh'],
        distribution_name: 'Fedora',
        distribution_parameterized_name: 'fedora',
        droplet_id: null,
        created_at: '2023-05-07T23:18:31Z',
        region_slug: 'nyc3',
        is_backup: false,
        is_snapshot: false,
        droplet_name: null,
        user_id: 73694,
        disabled_features: ['pipod'],
        billable_size_in_gb: 0.546829723,
        is_byoi: false,
        icon: null,
        kernel: null,
        allows_password_reset: false,
        allows_volume_automount: true,
        marketplace: null
      },
      {
        id: 121028718,
        is_public: true,
        name: '37 x64',
        region_id: 8,
        region_ids: [1, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15],
        slug_name: 'fedora-37-x64',
        disk: 15,
        required_settings: ['ssh'],
        distribution_name: 'Fedora',
        distribution_parameterized_name: 'fedora',
        droplet_id: null,
        created_at: '2022-11-16T17:11:19Z',
        region_slug: 'nyc3',
        is_backup: false,
        is_snapshot: false,
        droplet_name: null,
        user_id: 73694,
        disabled_features: ['pipod'],
        billable_size_in_gb: 0.527691412,
        is_byoi: false,
        icon: null,
        kernel: null,
        allows_password_reset: false,
        allows_volume_automount: true,
        marketplace: null
      },
      {
        id: 118326629,
        is_public: true,
        name: '36 x64',
        region_id: 8,
        region_ids: [1, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15],
        slug_name: 'fedora-36-x64',
        disk: 15,
        required_settings: ['ssh'],
        distribution_name: 'Fedora',
        distribution_parameterized_name: 'fedora',
        droplet_id: null,
        created_at: '2022-10-06T16:50:33Z',
        region_slug: 'nyc3',
        is_backup: false,
        is_snapshot: false,
        droplet_name: null,
        user_id: 73694,
        disabled_features: ['pipod'],
        billable_size_in_gb: 0.531273711,
        is_byoi: false,
        icon: null,
        kernel: null,
        allows_password_reset: false,
        allows_volume_automount: true,
        marketplace: null
      }
    ]
  },
  {
    id: 8,
    name: 'Debian',
    required_features: [],
    disabled_features: ['pipod'],
    is_deprecated: false,
    images: [
      {
        id: 106557160,
        is_public: true,
        name: '11 x64',
        region_id: 8,
        region_ids: [1, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15],
        slug_name: 'debian-11-x64',
        disk: 7,
        required_settings: [],
        distribution_name: 'Debian',
        distribution_parameterized_name: 'debian',
        droplet_id: null,
        created_at: '2022-04-20T15:21:39Z',
        region_slug: 'nyc3',
        is_backup: false,
        is_snapshot: false,
        droplet_name: null,
        user_id: 73694,
        disabled_features: ['pipod'],
        billable_size_in_gb: 0.385713873,
        is_byoi: false,
        icon: null,
        kernel: null,
        allows_password_reset: true,
        allows_volume_automount: true,
        marketplace: null
      },
      {
        id: 106569146,
        is_public: true,
        name: '10 x64',
        region_id: 8,
        region_ids: [1, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15],
        slug_name: 'debian-10-x64',
        disk: 7,
        required_settings: [],
        distribution_name: 'Debian',
        distribution_parameterized_name: 'debian',
        droplet_id: null,
        created_at: '2022-04-20T20:16:10Z',
        region_slug: 'nyc3',
        is_backup: false,
        is_snapshot: false,
        droplet_name: null,
        user_id: 73694,
        disabled_features: ['pipod'],
        billable_size_in_gb: 0.354649077,
        is_byoi: false,
        icon: null,
        kernel: null,
        allows_password_reset: true,
        allows_volume_automount: true,
        marketplace: null
      }
    ]
  },
  {
    id: 3,
    name: 'CentOS',
    required_features: [],
    disabled_features: [],
    is_deprecated: false,
    images: [
      {
        id: 117732073,
        is_public: true,
        name: '9 Stream x64',
        region_id: 8,
        region_ids: [1, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15],
        slug_name: 'centos-stream-9-x64',
        disk: 10,
        required_settings: [],
        distribution_name: 'CentOS',
        distribution_parameterized_name: 'centos',
        droplet_id: null,
        created_at: '2022-09-27T19:24:59Z',
        region_slug: 'nyc3',
        is_backup: false,
        is_snapshot: false,
        droplet_name: null,
        user_id: 73694,
        disabled_features: [],
        billable_size_in_gb: 0.443581587,
        is_byoi: false,
        icon: null,
        kernel: null,
        allows_password_reset: true,
        allows_volume_automount: true,
        marketplace: null
      },
      {
        id: 106434191,
        is_public: true,
        name: '8 Stream x64',
        region_id: 8,
        region_ids: [1, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15],
        slug_name: 'centos-stream-8-x64',
        disk: 10,
        required_settings: [],
        distribution_name: 'CentOS',
        distribution_parameterized_name: 'centos',
        droplet_id: null,
        created_at: '2022-04-18T21:39:21Z',
        region_slug: 'nyc3',
        is_backup: false,
        is_snapshot: false,
        droplet_name: null,
        user_id: 73694,
        disabled_features: ['pipod'],
        billable_size_in_gb: 0.611963797,
        is_byoi: false,
        icon: null,
        kernel: null,
        allows_password_reset: true,
        allows_volume_automount: true,
        marketplace: null
      },
      {
        id: 106433672,
        is_public: true,
        name: '7 x64',
        region_id: 8,
        region_ids: [1, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15],
        slug_name: 'centos-7-x64',
        disk: 9,
        required_settings: [],
        distribution_name: 'CentOS',
        distribution_parameterized_name: 'centos',
        droplet_id: null,
        created_at: '2022-04-18T21:32:47Z',
        region_slug: 'nyc3',
        is_backup: false,
        is_snapshot: false,
        droplet_name: null,
        user_id: 73694,
        disabled_features: [],
        billable_size_in_gb: 0.427062609,
        is_byoi: false,
        icon: null,
        kernel: null,
        allows_password_reset: true,
        allows_volume_automount: true,
        marketplace: null
      }
    ]
  },
  {
    id: 21,
    name: 'AlmaLinux',
    required_features: [],
    disabled_features: ['pipod'],
    is_deprecated: false,
    images: [
      {
        id: 133078240,
        is_public: true,
        name: 'AlmaLinux 9',
        region_id: 8,
        region_ids: [1, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15],
        slug_name: 'almalinux-9-x64',
        disk: 10,
        required_settings: [],
        distribution_name: 'AlmaLinux',
        distribution_parameterized_name: 'almalinux',
        droplet_id: null,
        created_at: '2023-05-21T13:54:12Z',
        region_slug: 'nyc3',
        is_backup: false,
        is_snapshot: false,
        droplet_name: null,
        user_id: 73694,
        disabled_features: ['pipod'],
        billable_size_in_gb: 0.447715805,
        is_byoi: false,
        icon: null,
        kernel: null,
        allows_password_reset: true,
        allows_volume_automount: true,
        marketplace: null
      },
      {
        id: 119703732,
        is_public: true,
        name: 'AlmaLinux 8',
        region_id: 8,
        region_ids: [1, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15],
        slug_name: 'almalinux-8-x64',
        disk: 10,
        required_settings: [],
        distribution_name: 'AlmaLinux',
        distribution_parameterized_name: 'almalinux',
        droplet_id: null,
        created_at: '2022-10-27T12:17:02Z',
        region_slug: 'nyc3',
        is_backup: false,
        is_snapshot: false,
        droplet_name: null,
        user_id: 73694,
        disabled_features: ['pipod'],
        billable_size_in_gb: 0.514773606,
        is_byoi: false,
        icon: null,
        kernel: null,
        allows_password_reset: true,
        allows_volume_automount: true,
        marketplace: null
      }
    ]
  },
  {
    id: 20,
    name: 'Rocky Linux',
    required_features: [],
    disabled_features: ['pipod'],
    is_deprecated: false,
    images: [
      {
        id: 132728350,
        is_public: true,
        name: '9 x64',
        region_id: 8,
        region_ids: [1, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15],
        slug_name: 'rockylinux-9-x64',
        disk: 10,
        required_settings: ['ssh'],
        distribution_name: 'Rocky Linux',
        distribution_parameterized_name: 'rocky-linux',
        droplet_id: null,
        created_at: '2023-05-16T01:39:22Z',
        region_slug: 'nyc3',
        is_backup: false,
        is_snapshot: false,
        droplet_name: null,
        user_id: 73694,
        disabled_features: ['pipod'],
        billable_size_in_gb: 0.488361792,
        is_byoi: false,
        icon: null,
        kernel: null,
        allows_password_reset: false,
        allows_volume_automount: true,
        marketplace: null
      },
      {
        id: 132701394,
        is_public: true,
        name: '8 x64',
        region_id: 8,
        region_ids: [1, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15],
        slug_name: 'rockylinux-8-x64',
        disk: 10,
        required_settings: [],
        distribution_name: 'Rocky Linux',
        distribution_parameterized_name: 'rocky-linux',
        droplet_id: null,
        created_at: '2023-05-15T21:19:30Z',
        region_slug: 'nyc3',
        is_backup: false,
        is_snapshot: false,
        droplet_name: null,
        user_id: 73694,
        disabled_features: ['pipod'],
        billable_size_in_gb: 0.611991178,
        is_byoi: false,
        icon: null,
        kernel: null,
        allows_password_reset: true,
        allows_volume_automount: true,
        marketplace: null
      }
    ]
  }
]

export const serverSizes = [
  {
    id: 209,
    name: 's-1vcpu-512mb-10gb',
    cpu_count: 1,
    price_per_hour: 0.005950000137090683,
    price_per_month: 4,
    disk: 10,
    disk_in_bytes: 10737418240,
    bandwidth_in_bytes: 536870912000,
    memory_in_bytes: 536870912,
    restriction: null,
    size_category: {
      id: 1,
      name: 'Basic'
    },
    architecture: {
      type: 'INTEL',
      is_premium: false
    },
    region_ids: [1, 6, 9, 10, 14, 15]
  },
  {
    id: 105,
    name: 's-1vcpu-1gb',
    cpu_count: 1,
    price_per_hour: 0.00892999954521656,
    price_per_month: 6,
    disk: 25,
    disk_in_bytes: 26843545600,
    bandwidth_in_bytes: 1073741824000,
    memory_in_bytes: 1073741824,
    restriction: null,
    size_category: {
      id: 1,
      name: 'Basic'
    },
    architecture: {
      type: 'INTEL',
      is_premium: false
    },
    region_ids: [1, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15]
  },
  {
    id: 191,
    name: 's-1vcpu-1gb-amd',
    cpu_count: 1,
    price_per_hour: 0.010420000180602074,
    price_per_month: 7,
    disk: 25,
    disk_in_bytes: 26843545600,
    bandwidth_in_bytes: 1073741824000,
    memory_in_bytes: 1073741824,
    restriction: null,
    size_category: {
      id: 14,
      name: 'Basic - AMD'
    },
    architecture: {
      type: 'AMD',
      is_premium: true
    },
    region_ids: [1, 6, 7, 8, 9, 10, 11, 13, 14, 15]
  },
  {
    id: 185,
    name: 's-1vcpu-1gb-intel',
    cpu_count: 1,
    price_per_hour: 0.010420000180602074,
    price_per_month: 7,
    disk: 25,
    disk_in_bytes: 26843545600,
    bandwidth_in_bytes: 1073741824000,
    memory_in_bytes: 1073741824,
    restriction: null,
    size_category: {
      id: 13,
      name: 'Basic - Intel'
    },
    architecture: {
      type: 'INTEL',
      is_premium: true
    },
    region_ids: [1, 6, 7, 8, 9, 10, 11, 13, 14, 15]
  },
  {
    id: 106,
    name: 's-1vcpu-2gb',
    cpu_count: 1,
    price_per_hour: 0.01785999909043312,
    price_per_month: 12,
    disk: 50,
    disk_in_bytes: 53687091200,
    bandwidth_in_bytes: 2147483648000,
    memory_in_bytes: 2147483648,
    restriction: null,
    size_category: {
      id: 1,
      name: 'Basic'
    },
    architecture: {
      type: 'INTEL',
      is_premium: false
    },
    region_ids: [1, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15]
  },
  {
    id: 192,
    name: 's-1vcpu-2gb-amd',
    cpu_count: 1,
    price_per_hour: 0.020829999819397926,
    price_per_month: 14,
    disk: 50,
    disk_in_bytes: 53687091200,
    bandwidth_in_bytes: 2147483648000,
    memory_in_bytes: 2147483648,
    restriction: null,
    size_category: {
      id: 14,
      name: 'Basic - AMD'
    },
    architecture: {
      type: 'AMD',
      is_premium: true
    },
    region_ids: [1, 6, 7, 8, 9, 10, 11, 13, 14, 15]
  },
  {
    id: 186,
    name: 's-1vcpu-2gb-intel',
    cpu_count: 1,
    price_per_hour: 0.020829999819397926,
    price_per_month: 14,
    disk: 50,
    disk_in_bytes: 53687091200,
    bandwidth_in_bytes: 2147483648000,
    memory_in_bytes: 2147483648,
    restriction: null,
    size_category: {
      id: 13,
      name: 'Basic - Intel'
    },
    architecture: {
      type: 'INTEL',
      is_premium: true
    },
    region_ids: [1, 6, 7, 8, 9, 10, 11, 13, 14, 15]
  },
  {
    id: 107,
    name: 's-2vcpu-2gb',
    cpu_count: 2,
    price_per_hour: 0.02679000049829483,
    price_per_month: 18,
    disk: 60,
    disk_in_bytes: 64424509440,
    bandwidth_in_bytes: 3221225472000,
    memory_in_bytes: 2147483648,
    restriction: null,
    size_category: {
      id: 1,
      name: 'Basic'
    },
    architecture: {
      type: 'INTEL',
      is_premium: false
    },
    region_ids: [1, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15]
  },
  {
    id: 193,
    name: 's-2vcpu-2gb-amd',
    cpu_count: 2,
    price_per_hour: 0.03125,
    price_per_month: 21,
    disk: 60,
    disk_in_bytes: 64424509440,
    bandwidth_in_bytes: 3221225472000,
    memory_in_bytes: 2147483648,
    restriction: null,
    size_category: {
      id: 14,
      name: 'Basic - AMD'
    },
    architecture: {
      type: 'AMD',
      is_premium: true
    },
    region_ids: [1, 6, 7, 8, 9, 10, 11, 13, 14, 15]
  },
  {
    id: 187,
    name: 's-2vcpu-2gb-intel',
    cpu_count: 2,
    price_per_hour: 0.03125,
    price_per_month: 21,
    disk: 60,
    disk_in_bytes: 64424509440,
    bandwidth_in_bytes: 3221225472000,
    memory_in_bytes: 2147483648,
    restriction: null,
    size_category: {
      id: 13,
      name: 'Basic - Intel'
    },
    architecture: {
      type: 'INTEL',
      is_premium: true
    },
    region_ids: [1, 6, 7, 8, 9, 10, 11, 13, 14, 15]
  },
  {
    id: 109,
    name: 's-2vcpu-4gb',
    cpu_count: 2,
    price_per_hour: 0.03570999950170517,
    price_per_month: 24,
    disk: 80,
    disk_in_bytes: 85899345920,
    bandwidth_in_bytes: 4294967296000,
    memory_in_bytes: 4294967296,
    restriction: null,
    size_category: {
      id: 1,
      name: 'Basic'
    },
    architecture: {
      type: 'INTEL',
      is_premium: false
    },
    region_ids: [1, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15]
  },
  {
    id: 194,
    name: 's-2vcpu-4gb-amd',
    cpu_count: 2,
    price_per_hour: 0.041669998317956924,
    price_per_month: 28,
    disk: 80,
    disk_in_bytes: 85899345920,
    bandwidth_in_bytes: 4294967296000,
    memory_in_bytes: 4294967296,
    restriction: null,
    size_category: {
      id: 14,
      name: 'Basic - AMD'
    },
    architecture: {
      type: 'AMD',
      is_premium: true
    },
    region_ids: [1, 6, 7, 8, 9, 10, 11, 13, 14, 15]
  },
  {
    id: 188,
    name: 's-2vcpu-4gb-intel',
    cpu_count: 2,
    price_per_hour: 0.041669998317956924,
    price_per_month: 28,
    disk: 80,
    disk_in_bytes: 85899345920,
    bandwidth_in_bytes: 4294967296000,
    memory_in_bytes: 4294967296,
    restriction: null,
    size_category: {
      id: 13,
      name: 'Basic - Intel'
    },
    architecture: {
      type: 'INTEL',
      is_premium: true
    },
    region_ids: [1, 6, 7, 8, 9, 10, 11, 13, 14, 15]
  },
  {
    id: 84,
    name: 'c-2-4GiB',
    cpu_count: 2,
    price_per_hour: 0.0625,
    price_per_month: 42,
    disk: 25,
    disk_in_bytes: 26843545600,
    bandwidth_in_bytes: 4294967296000,
    memory_in_bytes: 4294967296,
    restriction: null,
    size_category: {
      id: 3,
      name: 'CPU Intensive'
    },
    architecture: {
      type: 'INTEL',
      is_premium: false
    },
    region_ids: [1, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15]
  },
  {
    id: 175,
    name: 'c2-2vcpu-4gb',
    cpu_count: 2,
    price_per_hour: 0.0699400007724762,
    price_per_month: 47,
    disk: 50,
    disk_in_bytes: 53687091200,
    bandwidth_in_bytes: 4294967296000,
    memory_in_bytes: 4294967296,
    restriction: null,
    size_category: {
      id: 16,
      name: 'CPU Intensive 2x SSD'
    },
    architecture: {
      type: 'INTEL',
      is_premium: false
    },
    region_ids: [1, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15]
  },
  {
    id: 110,
    name: 's-4vcpu-8gb',
    cpu_count: 4,
    price_per_hour: 0.07142999768257141,
    price_per_month: 48,
    disk: 160,
    disk_in_bytes: 171798691840,
    bandwidth_in_bytes: 5368709120000,
    memory_in_bytes: 8589934592,
    restriction: null,
    size_category: {
      id: 1,
      name: 'Basic'
    },
    architecture: {
      type: 'INTEL',
      is_premium: false
    },
    region_ids: [1, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15]
  },
  {
    id: 195,
    name: 's-4vcpu-8gb-amd',
    cpu_count: 4,
    price_per_hour: 0.08332999795675278,
    price_per_month: 56,
    disk: 160,
    disk_in_bytes: 171798691840,
    bandwidth_in_bytes: 5368709120000,
    memory_in_bytes: 8589934592,
    restriction: null,
    size_category: {
      id: 14,
      name: 'Basic - AMD'
    },
    architecture: {
      type: 'AMD',
      is_premium: true
    },
    region_ids: [1, 6, 7, 8, 9, 10, 11, 13, 14, 15]
  },
  {
    id: 189,
    name: 's-4vcpu-8gb-intel',
    cpu_count: 4,
    price_per_hour: 0.08332999795675278,
    price_per_month: 56,
    disk: 160,
    disk_in_bytes: 171798691840,
    bandwidth_in_bytes: 5368709120000,
    memory_in_bytes: 8589934592,
    restriction: null,
    size_category: {
      id: 13,
      name: 'Basic - Intel'
    },
    architecture: {
      type: 'INTEL',
      is_premium: true
    },
    region_ids: [1, 6, 7, 8, 9, 10, 11, 13, 14, 15]
  },
  {
    id: 124,
    name: 'g-2vcpu-8gb',
    cpu_count: 2,
    price_per_hour: 0.09375,
    price_per_month: 63,
    disk: 25,
    disk_in_bytes: 26843545600,
    bandwidth_in_bytes: 4294967296000,
    memory_in_bytes: 8589934592,
    restriction: null,
    size_category: {
      id: 4,
      name: 'General Purpose'
    },
    architecture: {
      type: 'INTEL',
      is_premium: false
    },
    region_ids: [1, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15]
  },
  {
    id: 130,
    name: 'gd-2vcpu-8gb',
    cpu_count: 2,
    price_per_hour: 0.1011900007724762,
    price_per_month: 68,
    disk: 50,
    disk_in_bytes: 53687091200,
    bandwidth_in_bytes: 4294967296000,
    memory_in_bytes: 8589934592,
    restriction: null,
    size_category: {
      id: 5,
      name: 'General Purpose 2x SSD'
    },
    architecture: {
      type: 'INTEL',
      is_premium: false
    },
    region_ids: [1, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15]
  },
  {
    id: 138,
    name: 'm-2vcpu-16gb',
    cpu_count: 2,
    price_per_hour: 0.125,
    price_per_month: 84,
    disk: 50,
    disk_in_bytes: 53687091200,
    bandwidth_in_bytes: 4294967296000,
    memory_in_bytes: 17179869184,
    restriction: null,
    size_category: {
      id: 6,
      name: 'Memory Optimized'
    },
    architecture: {
      type: 'INTEL',
      is_premium: false
    },
    region_ids: [1, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15]
  },
  {
    id: 85,
    name: 'c-4-8GiB',
    cpu_count: 4,
    price_per_hour: 0.125,
    price_per_month: 84,
    disk: 50,
    disk_in_bytes: 53687091200,
    bandwidth_in_bytes: 5368709120000,
    memory_in_bytes: 8589934592,
    restriction: null,
    size_category: {
      id: 3,
      name: 'CPU Intensive'
    },
    architecture: {
      type: 'INTEL',
      is_premium: false
    },
    region_ids: [1, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15]
  },
  {
    id: 174,
    name: 'c2-4vpcu-8gb',
    cpu_count: 4,
    price_per_hour: 0.1398800015449524,
    price_per_month: 94,
    disk: 100,
    disk_in_bytes: 107374182400,
    bandwidth_in_bytes: 5368709120000,
    memory_in_bytes: 8589934592,
    restriction: null,
    size_category: {
      id: 16,
      name: 'CPU Intensive 2x SSD'
    },
    architecture: {
      type: 'INTEL',
      is_premium: false
    },
    region_ids: [1, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15]
  },
  {
    id: 172,
    name: 's-8vcpu-16gb',
    cpu_count: 8,
    price_per_hour: 0.14285999536514282,
    price_per_month: 96,
    disk: 320,
    disk_in_bytes: 343597383680,
    bandwidth_in_bytes: 6442450944000,
    memory_in_bytes: 17179869184,
    restriction: null,
    size_category: {
      id: 1,
      name: 'Basic'
    },
    architecture: {
      type: 'INTEL',
      is_premium: false
    },
    region_ids: [1, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15]
  },
  {
    id: 144,
    name: 'm3-2vcpu-16gb',
    cpu_count: 2,
    price_per_hour: 0.15476000308990479,
    price_per_month: 104,
    disk: 150,
    disk_in_bytes: 161061273600,
    bandwidth_in_bytes: 4294967296000,
    memory_in_bytes: 17179869184,
    restriction: null,
    size_category: {
      id: 7,
      name: 'Memory Optimized 3x SSD'
    },
    architecture: {
      type: 'INTEL',
      is_premium: false
    },
    region_ids: [1, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15]
  },
  {
    id: 214,
    name: 'c-4-intel',
    cpu_count: 4,
    price_per_hour: 0.16220000386238098,
    price_per_month: 109,
    disk: 50,
    disk_in_bytes: 53687091200,
    bandwidth_in_bytes: 5368709120000,
    memory_in_bytes: 8589934592,
    restriction:
      'This size is currently restricted, please open a ticket to increase your account tier.',
    size_category: {
      id: 18,
      name: 'CPU Optimized - Premium Intel'
    },
    architecture: {
      type: 'INTEL',
      is_premium: true
    },
    region_ids: [1, 8, 9, 10, 11, 12, 13, 14, 15]
  },
  {
    id: 196,
    name: 's-8vcpu-16gb-amd',
    cpu_count: 8,
    price_per_hour: 0.16666999459266663,
    price_per_month: 112,
    disk: 320,
    disk_in_bytes: 343597383680,
    bandwidth_in_bytes: 6442450944000,
    memory_in_bytes: 17179869184,
    restriction: null,
    size_category: {
      id: 14,
      name: 'Basic - AMD'
    },
    architecture: {
      type: 'AMD',
      is_premium: true
    },
    region_ids: [1, 6, 7, 8, 9, 10, 11, 13, 14, 15]
  },
  {
    id: 190,
    name: 's-8vcpu-16gb-intel',
    cpu_count: 8,
    price_per_hour: 0.16666999459266663,
    price_per_month: 112,
    disk: 320,
    disk_in_bytes: 343597383680,
    bandwidth_in_bytes: 6442450944000,
    memory_in_bytes: 17179869184,
    restriction: null,
    size_category: {
      id: 13,
      name: 'Basic - Intel'
    },
    architecture: {
      type: 'INTEL',
      is_premium: true
    },
    region_ids: [1, 6, 7, 8, 9, 10, 11, 13, 14, 15]
  },
  {
    id: 215,
    name: 'c2-4vcpu-8gb-intel',
    cpu_count: 4,
    price_per_hour: 0.18154999613761902,
    price_per_month: 122,
    disk: 100,
    disk_in_bytes: 107374182400,
    bandwidth_in_bytes: 5368709120000,
    memory_in_bytes: 8589934592,
    restriction:
      'This size is currently restricted, please open a ticket to increase your account tier.',
    size_category: {
      id: 20,
      name: 'CPU Optimized - Premium Intel 2x SSD'
    },
    architecture: {
      type: 'INTEL',
      is_premium: true
    },
    region_ids: [1, 8, 9, 10, 11, 12, 13, 14, 15]
  },
  {
    id: 125,
    name: 'g-4vcpu-16gb',
    cpu_count: 4,
    price_per_hour: 0.1875,
    price_per_month: 126,
    disk: 50,
    disk_in_bytes: 53687091200,
    bandwidth_in_bytes: 5368709120000,
    memory_in_bytes: 17179869184,
    restriction: null,
    size_category: {
      id: 4,
      name: 'General Purpose'
    },
    architecture: {
      type: 'INTEL',
      is_premium: false
    },
    region_ids: [1, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15]
  },
  {
    id: 179,
    name: 'so-2vcpu-16gb',
    cpu_count: 2,
    price_per_hour: 0.1949400007724762,
    price_per_month: 131,
    disk: 300,
    disk_in_bytes: 322122547200,
    bandwidth_in_bytes: 4294967296000,
    memory_in_bytes: 17179869184,
    restriction: null,
    size_category: {
      id: 15,
      name: 'Storage Optimized'
    },
    architecture: {
      type: 'INTEL',
      is_premium: false
    },
    region_ids: [1, 6, 7, 8, 9, 10, 11, 13, 14, 15]
  },
  {
    id: 150,
    name: 'm6-2vcpu-16gb',
    cpu_count: 2,
    price_per_hour: 0.1949400007724762,
    price_per_month: 131,
    disk: 300,
    disk_in_bytes: 322122547200,
    bandwidth_in_bytes: 4294967296000,
    memory_in_bytes: 17179869184,
    restriction: null,
    size_category: {
      id: 8,
      name: 'Memory Optimized 6x SSD'
    },
    architecture: {
      type: 'INTEL',
      is_premium: false
    },
    region_ids: [1, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15]
  },
  {
    id: 131,
    name: 'gd-4vcpu-16gb',
    cpu_count: 4,
    price_per_hour: 0.2023800015449524,
    price_per_month: 136,
    disk: 100,
    disk_in_bytes: 107374182400,
    bandwidth_in_bytes: 5368709120000,
    memory_in_bytes: 17179869184,
    restriction: null,
    size_category: {
      id: 5,
      name: 'General Purpose 2x SSD'
    },
    architecture: {
      type: 'INTEL',
      is_premium: false
    },
    region_ids: [1, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15]
  },
  {
    id: 198,
    name: 'so1_5-2vcpu-16gb',
    cpu_count: 2,
    price_per_hour: 0.2425599992275238,
    price_per_month: 163,
    disk: 450,
    disk_in_bytes: 483183820800,
    bandwidth_in_bytes: 4294967296000,
    memory_in_bytes: 17179869184,
    restriction: null,
    size_category: {
      id: 17,
      name: 'Storage Optimized 1.5x SSD'
    },
    architecture: {
      type: 'INTEL',
      is_premium: false
    },
    region_ids: [1, 6, 7, 8, 9, 10, 11, 13, 14, 15]
  },
  {
    id: 139,
    name: 'm-4vcpu-32gb',
    cpu_count: 4,
    price_per_hour: 0.25,
    price_per_month: 168,
    disk: 100,
    disk_in_bytes: 107374182400,
    bandwidth_in_bytes: 6442450944000,
    memory_in_bytes: 34359738368,
    restriction: null,
    size_category: {
      id: 6,
      name: 'Memory Optimized'
    },
    architecture: {
      type: 'INTEL',
      is_premium: false
    },
    region_ids: [1, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15]
  },
  {
    id: 86,
    name: 'c-8-16GiB',
    cpu_count: 8,
    price_per_hour: 0.25,
    price_per_month: 168,
    disk: 100,
    disk_in_bytes: 107374182400,
    bandwidth_in_bytes: 6442450944000,
    memory_in_bytes: 17179869184,
    restriction:
      'This size is currently restricted, please open a ticket to increase your account tier.',
    size_category: {
      id: 3,
      name: 'CPU Intensive'
    },
    architecture: {
      type: 'INTEL',
      is_premium: false
    },
    region_ids: [1, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15]
  },
  {
    id: 176,
    name: 'c2-8vpcu-16gb',
    cpu_count: 8,
    price_per_hour: 0.2797600030899048,
    price_per_month: 188,
    disk: 200,
    disk_in_bytes: 214748364800,
    bandwidth_in_bytes: 6442450944000,
    memory_in_bytes: 17179869184,
    restriction:
      'This size is currently restricted, please open a ticket to increase your account tier.',
    size_category: {
      id: 16,
      name: 'CPU Intensive 2x SSD'
    },
    architecture: {
      type: 'INTEL',
      is_premium: false
    },
    region_ids: [1, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15]
  },
  {
    id: 145,
    name: 'm3-4vcpu-32gb',
    cpu_count: 4,
    price_per_hour: 0.30952000617980957,
    price_per_month: 208,
    disk: 300,
    disk_in_bytes: 322122547200,
    bandwidth_in_bytes: 6442450944000,
    memory_in_bytes: 34359738368,
    restriction: null,
    size_category: {
      id: 7,
      name: 'Memory Optimized 3x SSD'
    },
    architecture: {
      type: 'INTEL',
      is_premium: false
    },
    region_ids: [1, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15]
  },
  {
    id: 216,
    name: 'c-8-intel',
    cpu_count: 8,
    price_per_hour: 0.32440000772476196,
    price_per_month: 218,
    disk: 100,
    disk_in_bytes: 107374182400,
    bandwidth_in_bytes: 6442450944000,
    memory_in_bytes: 17179869184,
    restriction:
      'This size is currently restricted, please open a ticket to increase your account tier.',
    size_category: {
      id: 18,
      name: 'CPU Optimized - Premium Intel'
    },
    architecture: {
      type: 'INTEL',
      is_premium: true
    },
    region_ids: [1, 8, 9, 10, 11, 12, 13, 14, 15]
  },
  {
    id: 217,
    name: 'c2-8vcpu-16gb-intel',
    cpu_count: 8,
    price_per_hour: 0.36309999227523804,
    price_per_month: 244,
    disk: 200,
    disk_in_bytes: 214748364800,
    bandwidth_in_bytes: 6442450944000,
    memory_in_bytes: 17179869184,
    restriction:
      'This size is currently restricted, please open a ticket to increase your account tier.',
    size_category: {
      id: 20,
      name: 'CPU Optimized - Premium Intel 2x SSD'
    },
    architecture: {
      type: 'INTEL',
      is_premium: true
    },
    region_ids: [1, 8, 9, 10, 11, 12, 13, 14, 15]
  },
  {
    id: 126,
    name: 'g-8vcpu-32gb',
    cpu_count: 8,
    price_per_hour: 0.375,
    price_per_month: 252,
    disk: 100,
    disk_in_bytes: 107374182400,
    bandwidth_in_bytes: 6442450944000,
    memory_in_bytes: 34359738368,
    restriction:
      'This size is currently restricted, please open a ticket to increase your account tier.',
    size_category: {
      id: 4,
      name: 'General Purpose'
    },
    architecture: {
      type: 'INTEL',
      is_premium: false
    },
    region_ids: [1, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15]
  },
  {
    id: 180,
    name: 'so-4vcpu-32gb',
    cpu_count: 4,
    price_per_hour: 0.3898800015449524,
    price_per_month: 262,
    disk: 600,
    disk_in_bytes: 644245094400,
    bandwidth_in_bytes: 6442450944000,
    memory_in_bytes: 34359738368,
    restriction: null,
    size_category: {
      id: 15,
      name: 'Storage Optimized'
    },
    architecture: {
      type: 'INTEL',
      is_premium: false
    },
    region_ids: [1, 6, 7, 8, 9, 10, 11, 13, 14, 15]
  },
  {
    id: 151,
    name: 'm6-4vcpu-32gb',
    cpu_count: 4,
    price_per_hour: 0.3898800015449524,
    price_per_month: 262,
    disk: 600,
    disk_in_bytes: 644245094400,
    bandwidth_in_bytes: 6442450944000,
    memory_in_bytes: 34359738368,
    restriction: null,
    size_category: {
      id: 8,
      name: 'Memory Optimized 6x SSD'
    },
    architecture: {
      type: 'INTEL',
      is_premium: false
    },
    region_ids: [1, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15]
  },
  {
    id: 132,
    name: 'gd-8vcpu-32gb',
    cpu_count: 8,
    price_per_hour: 0.4047600030899048,
    price_per_month: 272,
    disk: 200,
    disk_in_bytes: 214748364800,
    bandwidth_in_bytes: 6442450944000,
    memory_in_bytes: 34359738368,
    restriction:
      'This size is currently restricted, please open a ticket to increase your account tier.',
    size_category: {
      id: 5,
      name: 'General Purpose 2x SSD'
    },
    architecture: {
      type: 'INTEL',
      is_premium: false
    },
    region_ids: [1, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15]
  },
  {
    id: 199,
    name: 'so1_5-4vcpu-32gb',
    cpu_count: 4,
    price_per_hour: 0.4851199984550476,
    price_per_month: 326,
    disk: 900,
    disk_in_bytes: 966367641600,
    bandwidth_in_bytes: 6442450944000,
    memory_in_bytes: 34359738368,
    restriction: null,
    size_category: {
      id: 17,
      name: 'Storage Optimized 1.5x SSD'
    },
    architecture: {
      type: 'INTEL',
      is_premium: false
    },
    region_ids: [1, 6, 7, 8, 9, 10, 11, 13, 14, 15]
  },
  {
    id: 140,
    name: 'm-8vcpu-64gb',
    cpu_count: 8,
    price_per_hour: 0.5,
    price_per_month: 336,
    disk: 200,
    disk_in_bytes: 214748364800,
    bandwidth_in_bytes: 7516192768000,
    memory_in_bytes: 68719476736,
    restriction:
      'This size is currently restricted, please open a ticket to increase your account tier.',
    size_category: {
      id: 6,
      name: 'Memory Optimized'
    },
    architecture: {
      type: 'INTEL',
      is_premium: false
    },
    region_ids: [1, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15]
  },
  {
    id: 79,
    name: 'c-16',
    cpu_count: 16,
    price_per_hour: 0.5,
    price_per_month: 336,
    disk: 200,
    disk_in_bytes: 214748364800,
    bandwidth_in_bytes: 7516192768000,
    memory_in_bytes: 34359738368,
    restriction:
      'This size is currently restricted, please open a ticket to increase your account tier.',
    size_category: {
      id: 3,
      name: 'CPU Intensive'
    },
    architecture: {
      type: 'INTEL',
      is_premium: false
    },
    region_ids: [1, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15]
  },
  {
    id: 177,
    name: 'c2-16vcpu-32gb',
    cpu_count: 16,
    price_per_hour: 0.5595200061798096,
    price_per_month: 376,
    disk: 400,
    disk_in_bytes: 429496729600,
    bandwidth_in_bytes: 7516192768000,
    memory_in_bytes: 34359738368,
    restriction:
      'This size is currently restricted, please open a ticket to increase your account tier.',
    size_category: {
      id: 16,
      name: 'CPU Intensive 2x SSD'
    },
    architecture: {
      type: 'INTEL',
      is_premium: false
    },
    region_ids: [1, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15]
  },
  {
    id: 146,
    name: 'm3-8vcpu-64gb',
    cpu_count: 8,
    price_per_hour: 0.6190500259399414,
    price_per_month: 416,
    disk: 600,
    disk_in_bytes: 644245094400,
    bandwidth_in_bytes: 7516192768000,
    memory_in_bytes: 68719476736,
    restriction:
      'This size is currently restricted, please open a ticket to increase your account tier.',
    size_category: {
      id: 7,
      name: 'Memory Optimized 3x SSD'
    },
    architecture: {
      type: 'INTEL',
      is_premium: false
    },
    region_ids: [1, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15]
  },
  {
    id: 218,
    name: 'c-16-intel',
    cpu_count: 16,
    price_per_hour: 0.6503000259399414,
    price_per_month: 437,
    disk: 200,
    disk_in_bytes: 214748364800,
    bandwidth_in_bytes: 7516192768000,
    memory_in_bytes: 34359738368,
    restriction:
      'This size is currently restricted, please open a ticket to increase your account tier.',
    size_category: {
      id: 18,
      name: 'CPU Optimized - Premium Intel'
    },
    architecture: {
      type: 'INTEL',
      is_premium: true
    },
    region_ids: [1, 8, 9, 10, 11, 12, 13, 14, 15]
  },
  {
    id: 219,
    name: 'c2-16vcpu-32gb-intel',
    cpu_count: 16,
    price_per_hour: 0.7276800274848938,
    price_per_month: 489,
    disk: 400,
    disk_in_bytes: 429496729600,
    bandwidth_in_bytes: 7516192768000,
    memory_in_bytes: 34359738368,
    restriction:
      'This size is currently restricted, please open a ticket to increase your account tier.',
    size_category: {
      id: 20,
      name: 'CPU Optimized - Premium Intel 2x SSD'
    },
    architecture: {
      type: 'INTEL',
      is_premium: true
    },
    region_ids: [1, 8, 9, 10, 11, 12, 13, 14, 15]
  },
  {
    id: 127,
    name: 'g-16vcpu-64gb',
    cpu_count: 16,
    price_per_hour: 0.75,
    price_per_month: 504,
    disk: 200,
    disk_in_bytes: 214748364800,
    bandwidth_in_bytes: 7516192768000,
    memory_in_bytes: 68719476736,
    restriction:
      'This size is currently restricted, please open a ticket to increase your account tier.',
    size_category: {
      id: 4,
      name: 'General Purpose'
    },
    architecture: {
      type: 'INTEL',
      is_premium: false
    },
    region_ids: [1, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15]
  },
  {
    id: 181,
    name: 'so-8vcpu-64gb',
    cpu_count: 8,
    price_per_hour: 0.7797600030899048,
    price_per_month: 524,
    disk: 1200,
    disk_in_bytes: 1288490188800,
    bandwidth_in_bytes: 7516192768000,
    memory_in_bytes: 68719476736,
    restriction:
      'This size is currently restricted, please open a ticket to increase your account tier.',
    size_category: {
      id: 15,
      name: 'Storage Optimized'
    },
    architecture: {
      type: 'INTEL',
      is_premium: false
    },
    region_ids: [1, 6, 7, 8, 9, 10, 11, 13, 14, 15]
  },
  {
    id: 152,
    name: 'm6-8vcpu-64gb',
    cpu_count: 8,
    price_per_hour: 0.7797600030899048,
    price_per_month: 524,
    disk: 1200,
    disk_in_bytes: 1288490188800,
    bandwidth_in_bytes: 7516192768000,
    memory_in_bytes: 68719476736,
    restriction:
      'This size is currently restricted, please open a ticket to increase your account tier.',
    size_category: {
      id: 8,
      name: 'Memory Optimized 6x SSD'
    },
    architecture: {
      type: 'INTEL',
      is_premium: false
    },
    region_ids: [1, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15]
  },
  {
    id: 133,
    name: 'gd-16vcpu-64gb',
    cpu_count: 16,
    price_per_hour: 0.8095200061798096,
    price_per_month: 544,
    disk: 400,
    disk_in_bytes: 429496729600,
    bandwidth_in_bytes: 7516192768000,
    memory_in_bytes: 68719476736,
    restriction:
      'This size is currently restricted, please open a ticket to increase your account tier.',
    size_category: {
      id: 5,
      name: 'General Purpose 2x SSD'
    },
    architecture: {
      type: 'INTEL',
      is_premium: false
    },
    region_ids: [1, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15]
  },
  {
    id: 200,
    name: 'so1_5-8vcpu-64gb',
    cpu_count: 8,
    price_per_hour: 0.9702399969100952,
    price_per_month: 652,
    disk: 1800,
    disk_in_bytes: 1932735283200,
    bandwidth_in_bytes: 7516192768000,
    memory_in_bytes: 68719476736,
    restriction:
      'This size is currently restricted, please open a ticket to increase your account tier.',
    size_category: {
      id: 17,
      name: 'Storage Optimized 1.5x SSD'
    },
    architecture: {
      type: 'INTEL',
      is_premium: false
    },
    region_ids: [1, 6, 7, 8, 9, 10, 11, 13, 14, 15]
  },
  {
    id: 141,
    name: 'm-16vcpu-128gb',
    cpu_count: 16,
    price_per_hour: 1,
    price_per_month: 672,
    disk: 400,
    disk_in_bytes: 429496729600,
    bandwidth_in_bytes: 8589934592000,
    memory_in_bytes: 137438953472,
    restriction:
      'This size is currently restricted, please open a ticket to increase your account tier.',
    size_category: {
      id: 6,
      name: 'Memory Optimized'
    },
    architecture: {
      type: 'INTEL',
      is_premium: false
    },
    region_ids: [1, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15]
  },
  {
    id: 83,
    name: 'c-32-64GiB',
    cpu_count: 32,
    price_per_hour: 1,
    price_per_month: 672,
    disk: 400,
    disk_in_bytes: 429496729600,
    bandwidth_in_bytes: 9663676416000,
    memory_in_bytes: 68719476736,
    restriction:
      'This size is currently restricted, please open a ticket to increase your account tier.',
    size_category: {
      id: 3,
      name: 'CPU Intensive'
    },
    architecture: {
      type: 'INTEL',
      is_premium: false
    },
    region_ids: [1, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15]
  },
  {
    id: 178,
    name: 'c2-32vpcu-64gb',
    cpu_count: 32,
    price_per_hour: 1.1190500259399414,
    price_per_month: 752,
    disk: 800,
    disk_in_bytes: 858993459200,
    bandwidth_in_bytes: 9663676416000,
    memory_in_bytes: 68719476736,
    restriction:
      'This size is currently restricted, please open a ticket to increase your account tier.',
    size_category: {
      id: 16,
      name: 'CPU Intensive 2x SSD'
    },
    architecture: {
      type: 'INTEL',
      is_premium: false
    },
    region_ids: [1, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15]
  },
  {
    id: 147,
    name: 'm3-16vcpu-128gb',
    cpu_count: 16,
    price_per_hour: 1.2381000518798828,
    price_per_month: 832,
    disk: 1200,
    disk_in_bytes: 1288490188800,
    bandwidth_in_bytes: 8589934592000,
    memory_in_bytes: 137438953472,
    restriction:
      'This size is currently restricted, please open a ticket to increase your account tier.',
    size_category: {
      id: 7,
      name: 'Memory Optimized 3x SSD'
    },
    architecture: {
      type: 'INTEL',
      is_premium: false
    },
    region_ids: [1, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15]
  },
  {
    id: 220,
    name: 'c-32-intel',
    cpu_count: 32,
    price_per_hour: 1.3006000518798828,
    price_per_month: 874,
    disk: 400,
    disk_in_bytes: 429496729600,
    bandwidth_in_bytes: 9663676416000,
    memory_in_bytes: 68719476736,
    restriction:
      'This size is currently restricted, please open a ticket to increase your account tier.',
    size_category: {
      id: 18,
      name: 'CPU Optimized - Premium Intel'
    },
    architecture: {
      type: 'INTEL',
      is_premium: true
    },
    region_ids: [8, 9, 10, 11, 12, 13, 14, 15]
  },
  {
    id: 221,
    name: 'c2-32vcpu-64gb-intel',
    cpu_count: 32,
    price_per_hour: 1.4553600549697876,
    price_per_month: 978,
    disk: 800,
    disk_in_bytes: 858993459200,
    bandwidth_in_bytes: 9663676416000,
    memory_in_bytes: 68719476736,
    restriction:
      'This size is currently restricted, please open a ticket to increase your account tier.',
    size_category: {
      id: 20,
      name: 'CPU Optimized - Premium Intel 2x SSD'
    },
    architecture: {
      type: 'INTEL',
      is_premium: true
    },
    region_ids: [8, 9, 10, 11, 12, 13, 14, 15]
  },
  {
    id: 212,
    name: 'c-48',
    cpu_count: 48,
    price_per_hour: 1.5,
    price_per_month: 1008,
    disk: 600,
    disk_in_bytes: 644245094400,
    bandwidth_in_bytes: 11811160064000,
    memory_in_bytes: 103079215104,
    restriction:
      'This size is currently restricted, please open a ticket to increase your account tier.',
    size_category: {
      id: 3,
      name: 'CPU Intensive'
    },
    architecture: {
      type: 'INTEL',
      is_premium: false
    },
    region_ids: [1, 6, 7, 8, 9, 10, 11, 13, 14, 15]
  },
  {
    id: 142,
    name: 'm-24vcpu-192gb',
    cpu_count: 24,
    price_per_hour: 1.5,
    price_per_month: 1008,
    disk: 600,
    disk_in_bytes: 644245094400,
    bandwidth_in_bytes: 9663676416000,
    memory_in_bytes: 206158430208,
    restriction:
      'This size is currently restricted, please open a ticket to increase your account tier.',
    size_category: {
      id: 6,
      name: 'Memory Optimized'
    },
    architecture: {
      type: 'INTEL',
      is_premium: false
    },
    region_ids: [1, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15]
  },
  {
    id: 128,
    name: 'g-32vcpu-128gb',
    cpu_count: 32,
    price_per_hour: 1.5,
    price_per_month: 1008,
    disk: 400,
    disk_in_bytes: 429496729600,
    bandwidth_in_bytes: 8589934592000,
    memory_in_bytes: 137438953472,
    restriction:
      'This size is currently restricted, please open a ticket to increase your account tier.',
    size_category: {
      id: 4,
      name: 'General Purpose'
    },
    architecture: {
      type: 'INTEL',
      is_premium: false
    },
    region_ids: [1, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15]
  },
  {
    id: 182,
    name: 'so-16vcpu-128gb',
    cpu_count: 16,
    price_per_hour: 1.5595200061798096,
    price_per_month: 1048,
    disk: 2400,
    disk_in_bytes: 2576980377600,
    bandwidth_in_bytes: 8589934592000,
    memory_in_bytes: 137438953472,
    restriction:
      'This size is currently restricted, please open a ticket to increase your account tier.',
    size_category: {
      id: 15,
      name: 'Storage Optimized'
    },
    architecture: {
      type: 'INTEL',
      is_premium: false
    },
    region_ids: [1, 6, 7, 8, 9, 10, 11, 13, 14, 15]
  },
  {
    id: 153,
    name: 'm6-16vcpu-128gb',
    cpu_count: 16,
    price_per_hour: 1.5595200061798096,
    price_per_month: 1048,
    disk: 2400,
    disk_in_bytes: 2576980377600,
    bandwidth_in_bytes: 8589934592000,
    memory_in_bytes: 137438953472,
    restriction:
      'This size is currently restricted, please open a ticket to increase your account tier.',
    size_category: {
      id: 8,
      name: 'Memory Optimized 6x SSD'
    },
    architecture: {
      type: 'INTEL',
      is_premium: false
    },
    region_ids: [1, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15]
  },
  {
    id: 134,
    name: 'gd-32vcpu-128gb',
    cpu_count: 32,
    price_per_hour: 1.6190500259399414,
    price_per_month: 1088,
    disk: 800,
    disk_in_bytes: 858993459200,
    bandwidth_in_bytes: 8589934592000,
    memory_in_bytes: 137438953472,
    restriction:
      'This size is currently restricted, please open a ticket to increase your account tier.',
    size_category: {
      id: 5,
      name: 'General Purpose 2x SSD'
    },
    architecture: {
      type: 'INTEL',
      is_premium: false
    },
    region_ids: [1, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15]
  },
  {
    id: 213,
    name: 'c2-48vcpu-96gb',
    cpu_count: 48,
    price_per_hour: 1.678570032119751,
    price_per_month: 1128,
    disk: 1200,
    disk_in_bytes: 1288490188800,
    bandwidth_in_bytes: 11811160064000,
    memory_in_bytes: 103079215104,
    restriction:
      'This size is currently restricted, please open a ticket to increase your account tier.',
    size_category: {
      id: 16,
      name: 'CPU Intensive 2x SSD'
    },
    architecture: {
      type: 'INTEL',
      is_premium: false
    },
    region_ids: [1, 6, 7, 8, 9, 10, 11, 13, 14, 15]
  },
  {
    id: 148,
    name: 'm3-24vcpu-192gb',
    cpu_count: 24,
    price_per_hour: 1.8571399450302124,
    price_per_month: 1248,
    disk: 1800,
    disk_in_bytes: 1932735283200,
    bandwidth_in_bytes: 9663676416000,
    memory_in_bytes: 206158430208,
    restriction:
      'This size is currently restricted, please open a ticket to increase your account tier.',
    size_category: {
      id: 7,
      name: 'Memory Optimized 3x SSD'
    },
    architecture: {
      type: 'INTEL',
      is_premium: false
    },
    region_ids: [1, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15]
  },
  {
    id: 136,
    name: 'g-40vcpu-160gb',
    cpu_count: 40,
    price_per_hour: 1.875,
    price_per_month: 1260,
    disk: 500,
    disk_in_bytes: 536870912000,
    bandwidth_in_bytes: 9663676416000,
    memory_in_bytes: 171798691840,
    restriction:
      'This size is currently restricted, please open a ticket to increase your account tier.',
    size_category: {
      id: 4,
      name: 'General Purpose'
    },
    architecture: {
      type: 'INTEL',
      is_premium: false
    },
    region_ids: [1, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15]
  },
  {
    id: 201,
    name: 'so1_5-16vcpu-128gb',
    cpu_count: 16,
    price_per_hour: 1.9404799938201904,
    price_per_month: 1304,
    disk: 3600,
    disk_in_bytes: 3865470566400,
    bandwidth_in_bytes: 8589934592000,
    memory_in_bytes: 137438953472,
    restriction:
      'This size is currently restricted, please open a ticket to increase your account tier.',
    size_category: {
      id: 17,
      name: 'Storage Optimized 1.5x SSD'
    },
    architecture: {
      type: 'INTEL',
      is_premium: false
    },
    region_ids: [1, 6, 7, 8, 9, 10, 11, 13, 14, 15]
  },
  {
    id: 222,
    name: 'c-48-intel',
    cpu_count: 48,
    price_per_hour: 1.9493999481201172,
    price_per_month: 1310,
    disk: 600,
    disk_in_bytes: 644245094400,
    bandwidth_in_bytes: 11811160064000,
    memory_in_bytes: 103079215104,
    restriction:
      'This size is currently restricted, please open a ticket to increase your account tier.',
    size_category: {
      id: 18,
      name: 'CPU Optimized - Premium Intel'
    },
    architecture: {
      type: 'INTEL',
      is_premium: true
    },
    region_ids: [8, 9, 10, 11, 12, 13, 14, 15]
  },
  {
    id: 143,
    name: 'm-32vcpu-256gb',
    cpu_count: 32,
    price_per_hour: 2,
    price_per_month: 1344,
    disk: 800,
    disk_in_bytes: 858993459200,
    bandwidth_in_bytes: 10737418240000,
    memory_in_bytes: 274877906944,
    restriction:
      'This size is currently restricted, please open a ticket to increase your account tier.',
    size_category: {
      id: 6,
      name: 'Memory Optimized'
    },
    architecture: {
      type: 'INTEL',
      is_premium: false
    },
    region_ids: [1, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15]
  },
  {
    id: 137,
    name: 'gd-40vcpu-160gb',
    cpu_count: 40,
    price_per_hour: 2.0238099098205566,
    price_per_month: 1360,
    disk: 1000,
    disk_in_bytes: 1073741824000,
    bandwidth_in_bytes: 9663676416000,
    memory_in_bytes: 171798691840,
    restriction:
      'This size is currently restricted, please open a ticket to increase your account tier.',
    size_category: {
      id: 5,
      name: 'General Purpose 2x SSD'
    },
    architecture: {
      type: 'INTEL',
      is_premium: false
    },
    region_ids: [1, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15]
  },
  {
    id: 223,
    name: 'c2-48vcpu-96gb-intel',
    cpu_count: 48,
    price_per_hour: 2.1815500259399414,
    price_per_month: 1466,
    disk: 1200,
    disk_in_bytes: 1288490188800,
    bandwidth_in_bytes: 11811160064000,
    memory_in_bytes: 103079215104,
    restriction:
      'This size is currently restricted, please open a ticket to increase your account tier.',
    size_category: {
      id: 20,
      name: 'CPU Optimized - Premium Intel 2x SSD'
    },
    architecture: {
      type: 'INTEL',
      is_premium: true
    },
    region_ids: [8, 9, 10, 11, 12, 13, 14, 15]
  },
  {
    id: 183,
    name: 'so-24vcpu-192gb',
    cpu_count: 24,
    price_per_hour: 2.339289903640747,
    price_per_month: 1572,
    disk: 3600,
    disk_in_bytes: 3865470566400,
    bandwidth_in_bytes: 9663676416000,
    memory_in_bytes: 206158430208,
    restriction:
      'This size is currently restricted, please open a ticket to increase your account tier.',
    size_category: {
      id: 15,
      name: 'Storage Optimized'
    },
    architecture: {
      type: 'INTEL',
      is_premium: false
    },
    region_ids: [1, 6, 7, 8, 9, 10, 11, 13, 14, 15]
  },
  {
    id: 154,
    name: 'm6-24vcpu-192gb',
    cpu_count: 24,
    price_per_hour: 2.339289903640747,
    price_per_month: 1572,
    disk: 3600,
    disk_in_bytes: 3865470566400,
    bandwidth_in_bytes: 9663676416000,
    memory_in_bytes: 206158430208,
    restriction:
      'This size is currently restricted, please open a ticket to increase your account tier.',
    size_category: {
      id: 8,
      name: 'Memory Optimized 6x SSD'
    },
    architecture: {
      type: 'INTEL',
      is_premium: false
    },
    region_ids: [1, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15]
  },
  {
    id: 149,
    name: 'm3-32vcpu-256gb',
    cpu_count: 32,
    price_per_hour: 2.4761900901794434,
    price_per_month: 1664,
    disk: 2400,
    disk_in_bytes: 2576980377600,
    bandwidth_in_bytes: 10737418240000,
    memory_in_bytes: 274877906944,
    restriction:
      'This size is currently restricted, please open a ticket to increase your account tier.',
    size_category: {
      id: 7,
      name: 'Memory Optimized 3x SSD'
    },
    architecture: {
      type: 'INTEL',
      is_premium: false
    },
    region_ids: [1, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15]
  },
  {
    id: 202,
    name: 'so1_5-24vcpu-192gb',
    cpu_count: 24,
    price_per_hour: 2.910710096359253,
    price_per_month: 1956,
    disk: 5400,
    disk_in_bytes: 5798205849600,
    bandwidth_in_bytes: 9663676416000,
    memory_in_bytes: 206158430208,
    restriction:
      'This size is currently restricted, please open a ticket to increase your account tier.',
    size_category: {
      id: 17,
      name: 'Storage Optimized 1.5x SSD'
    },
    architecture: {
      type: 'INTEL',
      is_premium: false
    },
    region_ids: [1, 6, 7, 8, 9, 10, 11, 13, 14, 15]
  },
  {
    id: 184,
    name: 'so-32vcpu-256gb',
    cpu_count: 32,
    price_per_hour: 3.1190500259399414,
    price_per_month: 2096,
    disk: 4800,
    disk_in_bytes: 5153960755200,
    bandwidth_in_bytes: 10737418240000,
    memory_in_bytes: 274877906944,
    restriction:
      'This size is currently restricted, please open a ticket to increase your account tier.',
    size_category: {
      id: 15,
      name: 'Storage Optimized'
    },
    architecture: {
      type: 'INTEL',
      is_premium: false
    },
    region_ids: [1, 6, 7, 8, 9, 10, 11, 13, 14, 15]
  },
  {
    id: 155,
    name: 'm6-32vcpu-256gb',
    cpu_count: 32,
    price_per_hour: 3.1190500259399414,
    price_per_month: 2096,
    disk: 4800,
    disk_in_bytes: 5153960755200,
    bandwidth_in_bytes: 10737418240000,
    memory_in_bytes: 274877906944,
    restriction:
      'This size is currently restricted, please open a ticket to increase your account tier.',
    size_category: {
      id: 8,
      name: 'Memory Optimized 6x SSD'
    },
    architecture: {
      type: 'INTEL',
      is_premium: false
    },
    region_ids: [1, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15]
  },
  {
    id: 203,
    name: 'so1_5-32vcpu-256gb',
    cpu_count: 32,
    price_per_hour: 3.8809499740600586,
    price_per_month: 2608,
    disk: 7200,
    disk_in_bytes: 7730941132800,
    bandwidth_in_bytes: 10737418240000,
    memory_in_bytes: 274877906944,
    restriction:
      'This size is currently restricted, please open a ticket to increase your account tier.',
    size_category: {
      id: 17,
      name: 'Storage Optimized 1.5x SSD'
    },
    architecture: {
      type: 'INTEL',
      is_premium: false
    },
    region_ids: [1, 6, 7, 8, 9, 10, 11, 13, 14, 15]
  }
]

export const serverRegions = [
  {
    id: 1,
    name: 'New York 1',
    slug: 'nyc1',
    flag: 'us.svg',
    geography: 'nyc',
    restriction: null,
    features: [
      'backups',
      'ipv6',
      'metadata',
      'install_agent',
      'storage',
      'image_transfer'
    ],
    disabled_features: [],
    is_default: false
  },
  // {
  //   id: 2,
  //   name: "Amsterdam 1",
  //   slug: "ams1",
  //   geography: "ams",
  //   restriction:
  //     "Events are currently disabled while maintenance is being performed.",
  //   features: ["backups", "ipv6", "metadata", "install_agent"],
  //   disabled_features: [],
  //   is_default: false
  // },
  {
    id: 3,
    name: 'San Francisco 1',
    slug: 'sfo1',
    flag: 'us.svg',
    geography: 'sfo',
    restriction: 'Restricted to users with existing resources only.',
    features: [
      'backups',
      'ipv6',
      'metadata',
      'install_agent',
      'image_transfer'
    ],
    disabled_features: [],
    is_default: false
  },
  {
    id: 4,
    name: 'New York 2',
    slug: 'nyc2',
    flag: 'us.svg',
    geography: 'nyc',
    restriction: 'Restricted to users with existing resources only.',
    features: [
      'backups',
      'ipv6',
      'metadata',
      'install_agent',
      'image_transfer'
    ],
    disabled_features: [],
    is_default: false
  },
  {
    id: 5,
    name: 'Amsterdam 2',
    slug: 'ams2',
    flag: 'nl.svg',
    geography: 'ams',
    restriction: 'Restricted to users with existing resources only.',
    features: [
      'backups',
      'ipv6',
      'metadata',
      'install_agent',
      'image_transfer'
    ],
    disabled_features: [],
    is_default: false
  },
  {
    id: 6,
    name: 'Singapore 1',
    slug: 'sgp1',
    flag: 'sg.svg',
    geography: 'sgp',
    restriction: null,
    features: [
      'backups',
      'ipv6',
      'metadata',
      'install_agent',
      'storage',
      'image_transfer'
    ],
    disabled_features: [],
    is_default: false
  },
  {
    id: 7,
    name: 'London 1',
    slug: 'lon1',
    flag: 'gb.svg',
    geography: 'lon',
    restriction: null,
    features: [
      'backups',
      'ipv6',
      'metadata',
      'install_agent',
      'storage',
      'image_transfer'
    ],
    disabled_features: [],
    is_default: false
  },
  {
    id: 8,
    name: 'New York 3',
    slug: 'nyc3',
    flag: 'us.svg',
    geography: 'nyc',
    restriction: null,
    features: [
      'backups',
      'ipv6',
      'metadata',
      'install_agent',
      'storage',
      'image_transfer'
    ],
    disabled_features: [],
    is_default: false
  },
  {
    id: 9,
    name: 'Amsterdam 3',
    slug: 'ams3',
    flag: 'nl.svg',
    geography: 'ams',
    restriction: null,
    features: [
      'backups',
      'ipv6',
      'metadata',
      'install_agent',
      'storage',
      'image_transfer'
    ],
    disabled_features: [],
    is_default: false
  },
  {
    id: 10,
    name: 'Frankfurt 1',
    slug: 'fra1',
    flag: 'de.svg',
    geography: 'fra',
    restriction: null,
    features: [
      'backups',
      'ipv6',
      'metadata',
      'install_agent',
      'storage',
      'image_transfer'
    ],
    disabled_features: [],
    is_default: false
  },
  {
    id: 11,
    name: 'Toronto 1',
    slug: 'tor1',
    flag: 'ca.svg',
    geography: 'tor',
    restriction: null,
    features: [
      'backups',
      'ipv6',
      'metadata',
      'install_agent',
      'storage',
      'image_transfer'
    ],
    disabled_features: [],
    is_default: false
  },
  {
    id: 12,
    name: 'San Francisco 2',
    slug: 'sfo2',
    flag: 'us.svg',
    geography: 'sfo',
    restriction: 'Restricted to users with existing resources only.',
    features: [
      'backups',
      'ipv6',
      'metadata',
      'install_agent',
      'storage',
      'image_transfer'
    ],
    disabled_features: [],
    is_default: false
  },
  {
    id: 13,
    name: 'Bangalore 1',
    slug: 'blr1',
    flag: 'in.svg',
    geography: 'blr',
    restriction: null,
    features: [
      'backups',
      'ipv6',
      'metadata',
      'install_agent',
      'storage',
      'image_transfer'
    ],
    disabled_features: [],
    is_default: false
  },
  {
    id: 14,
    name: 'San Francisco 3',
    slug: 'sfo3',
    flag: 'us.svg',
    geography: 'sfo',
    restriction: null,
    features: [
      'backups',
      'ipv6',
      'metadata',
      'install_agent',
      'storage',
      'image_transfer'
    ],
    disabled_features: [],
    is_default: false
  },
  {
    id: 15,
    name: 'Sydney 1',
    slug: 'syd1',
    flag: 'au.svg',
    geography: 'syd',
    restriction: null,
    features: [
      'backups',
      'ipv6',
      'metadata',
      'install_agent',
      'storage',
      'image_transfer'
    ],
    disabled_features: [],
    is_default: false
  }
]
