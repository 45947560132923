import axios from 'axios'
import NProgress from '../plugins/nprogress'
import router from '../router'
import { useAuthStore } from '@/stores/auth'
import { useUserStore } from '@/stores/user'

const client = axios.create({
  baseURL: import.meta.env.VITE_API,
  withCredentials: true,
})

async function errorInterceptor(error) {
  NProgress.done()

  // check if it's a server error
  if (!error.response) {
    return Promise.reject(error)
  }

  // all the other error responses
  switch (error.response.status) {
    case 401:
      {
        const authStore = useAuthStore()
        const userStore = useUserStore()

        if (userStore.user.email) {
          authStore.isExpired = true
        } else {
          await authStore.signOut()
        }
      }
      break
    default:
      if (import.meta.env.VITE_API.includes('localhost')) {
        console.error(error.response.status, error.message)
      }
      break
  }

  return Promise.reject(error)
}

function responseInterceptor(response) {
  NProgress.done()
  return response
}

function requestInterceptor(request) {
  NProgress.start()

  request.headers['Content-Type'] = 'application/json'

  return request
}

client.interceptors.response.use(responseInterceptor, errorInterceptor)
client.interceptors.request.use(requestInterceptor)

export default client
