<script setup>
import { ChevronDownIcon } from '@heroicons/vue/24/solid'
import { computed, reactive, ref, useSlots } from 'vue'
import Button from '../ui/button/Button.vue'
import EmptyTable from '@/components/common/EmptyTable.vue'
import FailedToLoad from '@/components/common/FailedToLoad.vue'
import ProgressIndicator from '@/components/common/ProgressIndicator.vue'
import { Skeleton } from '@/components/ui/skeleton'

const props = defineProps({
  headers: {
    type: Array,
    default: () => []
  },
  columns: {
    type: Array,
    default: () => []
  },
  loading: {
    type: Boolean,
    default: false
  },
  error: {
    type: Boolean,
    default: false
  },
  noDataLabel: {
    type: String,
    default: 'Nenhum registro encontrado'
  },
  errorLabel: {
    type: String,
    default: 'Ocorreu um erro ao carregar os dados'
  }
})
const slots = useSlots()

const sort = reactive({
  fieldName: '',
  order: ''
})
const opened = ref(undefined)

const loadingItems = computed(() => Array(15).keys())
const sortedRows = computed(() => {
  return props.columns.slice().sort((a, b) => {
    if (sort.fieldName === '') {
      return 0
    }
    if (sort.order === 'asc') {
      return a[sort.fieldName] > b[sort.fieldName] ? 1 : -1
    }
    return a[sort.fieldName] < b[sort.fieldName] ? 1 : -1
  })
})
const headersLength = computed(() => {
  let size = props.headers.length || 0

  if (hasSlot('expand')) {
    size++
  }

  if (hasSlot('actions')) {
    size++
  }

  return size
})
function toggleOpened(index) {
  opened.value = opened.value === index ? undefined : index
}
function sortByHeaderKey(header) {
  if (!header.sortable) {
    return
  }
  if (sort.fieldName !== header.value) {
    sort.fieldName = header.value
    sort.order = 'asc'
  } else {
    sort.order = sort.order === 'asc' ? 'desc' : 'asc'
  }
}
function hasSlot(name) {
  return !!slots[name]
}
</script>

<template>
  <div class="flex flex-col">
    <div class="-mx-6 -my-2 py-2">
      <div class="min-w-full px-6 align-middle">
        <div>
          <div
            class="rounded-x-lg overflow-auto rounded-lg bg-background shadow-sm ring-1 ring-border"
          >
            <div v-if="hasSlot('table-header')">
              <div class="px-5 py-3">
                <slot name="table-header" />
              </div>
            </div>
            <div class="overflow-auto">
              <table class="min-w-full divide-y divide-border">
                <thead>
                  <tr>
                    <th
                      v-if="hasSlot('expand') && columns.length"
                      scope="col"
                      class="relative px-7 sm:w-12 sm:px-4"
                    >
                      <span class="sr-only">Expandir</span>
                    </th>
                    <th
                      v-for="(header, index) in headers"
                      :key="header"
                      scope="col"
                      class="px-3 py-3.5 text-left text-sm font-medium tracking-wide text-foreground/60 first:pl-4 sm:first:pl-6"
                      @click="sortByHeaderKey(header)"
                    >
                      <div class="inline-flex">
                        <span>{{ header.label }}</span>
                        <span
                          v-if="header.sortable"
                          :class="[
                            sort.fieldName === header.value
                              ? 'ml-2 flex-none rounded bg-neutral-200 text-primary group-hover:bg-neutral-300'
                              : 'ml-2 flex-none rounded text-neutral-400  ',
                          ]"
                        >
                          <ChevronDownIcon
                            class="text-muted-foreground"
                            aria-hidden="true"
                          />
                        </span>
                      </div>
                    </th>
                    <th
                      v-if="hasSlot('actions')"
                      class="text-right text-sm"
                    />
                  </tr>
                  <tr v-if="loading && !loadingItems.length">
                    <th
                      colspan="100%"
                      class="sticky bottom-0 z-10 p-0"
                    >
                      <ProgressIndicator />
                    </th>
                  </tr>
                </thead>
                <tbody class="divide-y overflow-auto bg-background">
                  <!-- Loading state -->
                  <template v-if="loading && !columns.length">
                    <tr
                      v-for="item in 15"
                      :key="item"
                    >
                      <td
                        v-for="(item, index) in headersLength"
                        :key="index"
                        class="px-3 py-4"
                      >
                        <Skeleton class="h-4 w-full" />
                      </td>
                    </tr>
                  </template>
                  <!-- Error state -->
                  <template v-else-if="error">
                    <tr>
                      <td
                        class="whitespace-nowrap  px-3 py-2 text-sm leading-5  md:px-6 md:py-4"
                        colspan="100%"
                      >
                        <div class="flex items-center justify-center space-x-2">
                          <FailedToLoad :message="errorLabel" />
                        </div>
                      </td>
                    </tr>
                  </template>
                  <!-- Empty state -->
                  <template v-else-if="!loading && !error && !columns.length">
                    <td
                      colspan="100%"
                    >
                      <slot name="empty">
                        <EmptyTable :message="noDataLabel" />
                      </slot>
                    </td>
                  </template>
                  <!-- Success state -->
                  <template v-else-if="(!loading && !error) || columns.length">
                    <template
                      v-for="(column, colIndex) in sortedRows"
                      :key="colIndex"
                    >
                      <tr
                        :class="{
                          'group cursor-pointer transition-all duration-75 hover:bg-muted':
                            hasSlot('expand'),
                        }"
                      >
                        <!-- Expand button -->
                        <td
                          v-if="hasSlot('expand')"
                          class="relative px-7 sm:w-12 sm:px-4"
                        >
                          <Button
                            variant="ghost"
                            size="xs"
                            @click="toggleOpened(colIndex)"
                          >
                            <ChevronDownIcon
                              class="size-4 text-muted-foreground transition-transform duration-200"
                              aria-hidden="true"
                              :class="{
                                'rotate-180': opened === colIndex,
                              }"
                            />
                          </Button>
                        </td>

                        <td
                          v-for="(header, headIndex) in headers"
                          :key="headIndex"
                          class="whitespace-nowrap px-3 py-4 text-sm first:pl-4  sm:first:pl-6 "
                          :class="[
                            headIndex === 0 ? 'font-medium text-foreground' : 'text-muted-foreground',
                          ]"
                          @click="
                            hasSlot('expand')
                              ? toggleOpened(colIndex)
                              : undefined
                          "
                        >
                          <slot
                            v-if="hasSlot(`col-${header.value}`)"
                            :name="`col-${header.value}`"
                            :row="column[header.value]"
                            :column="column"
                            :index="colIndex"
                          />
                          <template v-else>
                            {{
                              header.hasOwnProperty("format")
                                ? header?.format(column[header.value])
                                : column[header.value]
                            }}
                          </template>
                        </td>
                        <td
                          v-if="hasSlot('actions')"
                          class="whitespace-nowrap text-right text-sm text-muted-foreground "
                        >
                          <slot
                            name="actions"
                            :column="column"
                            :index="colIndex"
                          />
                        </td>
                      </tr>
                      <tr v-if="opened === colIndex">
                        <td colspan="100%">
                          <slot
                            name="expand"
                            :column="column"
                          />
                        </td>
                      </tr>
                    </template>
                  </template>
                </tbody>
                <tfoot v-if="hasSlot('tfoot')">
                  <slot name="tfoot" />
                </tfoot>
              </table>
            </div>
          </div>
          <div
            v-if="hasSlot('pagination')"
            class="pt-2"
          >
            <slot name="pagination" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
