<script setup>
import ptBR from 'apexcharts/dist/locales/pt-br.json'
import { ref, watch } from 'vue'
import apexcharts from 'vue3-apexcharts'
import { useColorMode } from '@vueuse/core'

const props = defineProps({
  labels: {
    type: Array,
    default: () => []
  },
  data: {
    type: Array
  },
})

const mode = useColorMode()

const options = ref({
  chart: {
    id: 'group-by-page',
    animations: {
      enabled: true,
      easing: 'easeinout',
      speed: 800,
      animateGradually: {
        enabled: true,
        delay: 150
      },
      dynamicAnimation: {
        enabled: true,
        speed: 350
      }
    },
    locales: [ptBR],
    defaultLocale: 'pt-br',
    toolbar: {
      show: false
    },
    zoom: {
      enabled: false
    },
    reverse: true
  },
  colors: ['#e23670', '#2662d9', '#2eb88a', '#af57db', '#e88c30'],
  stroke: {
    curve: 'smooth',
    colors: ['transparent']
  },
  grid: {
    show: false
  },
  theme: {
    mode: mode.value === 'dark' ? 'dark' : 'light'
  },
  legend: {
    position: 'bottom',
  },
  yaxis: {
    show: false,
    crosshairs: {
      show: false
    },
  },
})

const series = ref([])

function updateChart() {
	  series.value = props.data?.map(item => item.count)

  options.value = {
    ...options.value,
    labels: props.data?.map(item => item.page)
  }
}

watch(props, updateChart, {
  deep: true,
  immediate: true
})

watch(mode, () => {
  options.value = {
    ...options.value,
    ...{
      theme: {
        mode: mode.value === 'dark' ? 'dark' : 'light'
      }
    }
  }
})
</script>

<template>
  <apexcharts
    :key="mode"
    type="pie"
    :options="options"
    height="300px"
    :series="series"
  />
</template>

<style>
.apexcharts-text.apexcharts-xaxis-label tspan,
.apexcharts-text.apexcharts-yaxis-label tspan {
  @apply text-muted-foreground !important;
}

.apexcharts-legend-text {
  @apply text-muted-foreground !important;
}

.apexcharts-canvas > svg {
  background-color: transparent !important;
}
</style>
