import Campaign from '@/views/campaign/[id]/Campaign.vue'
import CampaignTab from '@/views/campaign/[id]/CampaignTab.vue'
import CampaignsView from '@/views/campaign/CampaignsView.vue'
import CampaignRequests from '@/views/campaign/[id]/requests/CampaignRequestsView.vue'
import IntegrationView from '@/views/campaign/[id]/IntegrationView.vue'
import CampaignCreateView from '@/views/campaign/CampaignCreateView.vue'
import CampaignReports from '@/views/campaign/[id]/CampaignReports.vue'

export default [
  {
    path: '/campaigns',
    name: 'Campaigns',
    component: CampaignsView,
    meta: {
      layout: 'default',
      requiresAuth: true,
      requiredCloakingSubscription: true
    }
  },
  {
    path: '/campaigns/create',
    name: 'NewCampaign',
    component: CampaignCreateView,
    meta: {
      layout: 'default',
      requiresAuth: true,
      requiredCloakingSubscription: true
    },
  },
  {
    path: '/campaigns/:id',
    component: CampaignTab,
    meta: {
      layout: 'default',
      requiresAuth: true,
      requiredCloakingSubscription: true
    },
    children: [
      {
        path: '/campaigns/:id/',
        name: 'Campaign',
        component: Campaign,
        meta: {
          layout: 'default',
          requiresAuth: true,
          requiredCloakingSubscription: true
        },
      },
      {
        path: '/campaigns/:id/integrations',
        name: 'CampaignIntegrations',
        component: IntegrationView,
        meta: {
          layout: 'default',
          requiresAuth: true,
          requiredCloakingSubscription: true
        },
      },
      {
        path: '/campaigns/:id/requests',
        name: 'CampaignRequests',
        component: CampaignRequests,
        meta: {
          layout: 'default',
          requiresAuth: true,
          requiredCloakingSubscription: true
        },
      },
      {
        path: '/campaigns/:id/reports',
        name: 'CampaignReports',
        component: CampaignReports,
        meta: {
          layout: 'default',
          requiresAuth: true,
          requiredCloakingSubscription: true
        },
      }
    ]
  }
]
