<script setup>
import BaseCheckbox from './base/BaseCheckbox.vue'
import Checkbox from '@/components/ui/checkbox/Checkbox.vue'

import Label from '@/components/ui/label/Label.vue'

defineProps({
  options: {
    type: Array,
    default: () => [],
  }
})

const model = defineModel({
  required: true,
  default: () => [],
})

function onChange(option) {
  if (model.value.includes(option)) {
    model.value = model.value.filter(value => value !== option)
  } else {
    model.value = [...model.value, option]
  }
}
</script>

<template>
  <div class="grid w-full grid-cols-3 gap-3">
    <label
      v-for="option in options"
      :key="option.value"
      :for="option.value"
      class="relative flex cursor-pointer flex-col gap-4 overflow-hidden rounded-lg  p-4 shadow-sm ring-1 ring-border hover:bg-muted/50 has-[[data-state=checked]]:ring-2 has-[[data-state=checked]]:ring-ring"
    >
      <div class="flex justify-between gap-2">
        <Checkbox
          :id="option.value"
          type="checkbox"
          :value="option.value"
          :checked="model.includes(option.value)"
          class="peer order-1 size-4  after:absolute after:inset-0"
          @update:checked="() => onChange(option.value)"
        />
        <component
          :is="option.icon"
          class="size-6 text-foreground"
        />
      </div>
      <Label :for="option.value">
        {{ option.label }}
      </Label>
    </label>
  </div>
</template>
