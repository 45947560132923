<script setup>
import { useI18n } from 'vue-i18n'
import Badge from '@/components/ui/badge/Badge.vue'

defineProps({
  status: {
    type: Boolean,
    default: false
  }
})
const { t } = useI18n()

function formatStatus(value) {
  if (value === 'pending') {
    return t('components.transaction_status_badge.pending')
  } else if (value === 'success') {
    return t('components.transaction_status_badge.success')
  } else if (value === 'failed') {
    return t('components.transaction_status_badge.failed')
  }
}

function getStatusColor(value) {
  if (value === 'pending') {
    return 'warning'
  } else if (value === 'success') {
    return 'success'
  } else if (value === 'failed') {
    return 'destructive'
  }
}
</script>

<template>
  <Badge
    :variant="getStatusColor(status)"
    size="xs"
  >
    {{ formatStatus(status) }}
  </Badge>
</template>
