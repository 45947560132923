export function useLocaleCookie() {
  const COOKIE_NAME = 'cloakup-language'

  function getCookie() {
    const cookie = document.cookie.split(';')
    for (let i = 0; i < cookie.length; i++) {
      const c = cookie[i].trim()
      if (c.startsWith(`${COOKIE_NAME}=`)) {
        return c.substring(COOKIE_NAME.length + 1)
      }
    }
    return null
  }

  return {
    getCookie,
    setCookie: (value) => {
      document.cookie = `${COOKIE_NAME}=${value}; path=/; expires=Fri, 31 Dec 9999 23:59:59 GMT`
    }
  }
}
