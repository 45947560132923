import Referral from '@/views/referral/Referral.vue'
import ReferralTab from '@/views/referral/ReferralTab.vue'
import Transactions from '@/views/referral/TransactionsView.vue'

export default [
  {
    path: '/referral',
    component: ReferralTab,
    meta: {
      layout: 'default',
      requiresAuth: true
    },
    redirect: {
      name: 'dashboard.referral.index'
    },
    children: [
      {
        path: '/referral/',
        name: 'dashboard.referral.index',
        component: Referral,
        meta: {
          layout: 'default',
          requiresAuth: true
        }
      },
      {
        path: '/referral/transactions',
        name: 'Transactions',
        component: Transactions,
        meta: {
          layout: 'default',
          requiresAuth: true
        }
      },
    ]
  }
]
