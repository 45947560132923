<script setup>
import { reactive, ref } from 'vue'
import { useRouter } from 'vue-router'
import { toast } from 'vue-sonner'
import { useI18n } from 'vue-i18n'
import { useHead } from '@unhead/vue'
import { authService } from '@/services/auth'
import Button from '@/components/ui/button/Button.vue'
import Label from '@/components/ui/label/Label.vue'
import ErrorMessage from '@/components/ui/error-message/ErrorMessage.vue'
import Field from '@/components/ui/field/Field.vue'
import PasswordInput from '@/components/common/PasswordInput.vue'
import PageHeading from '@/components/common/PageHeading.vue'

const { t } = useI18n()
useHead({
  title: t('pages.auth.reset_password.title')
})
const router = useRouter()

const isLoading = ref(false)
const errors = ref({})
const form = reactive({
  password: '',
  password_confirmation: '',
})

function onSubmit() {
  changePassword({
    password: form.password,
    password_confirmation: form.password_confirmation,
    token: router.currentRoute.value.query?.token
  })
}

async function changePassword({ password, password_confirmation, token }) {
  isLoading.value = true

  try {
    await authService.resetPassword({
      token,
      password,
      password_confirmation
    })

    toast.success(t('pages.auth.reset_password.toast.success.title'), {
      description: t('pages.auth.reset_password.toast.success.description'),
    })

    await router.push({
      name: 'SignIn'
    })
  } catch (e) {
    if (e.response?.status === 422) {
      errors.value = e.response.data.data
    }

    toast.error(e.response?.data?.message, {
      description: e.response?.data?.action || e.message,
    })
  } finally {
    isLoading.value = false
  }
}
</script>

<template>
  <div>
    <div>
      <img
        class="h-12 w-auto dark:invert"
        src="../../assets/img/logo-black.svg"
        alt="Cloakup"
      >
      <PageHeading
        :title="$t('pages.auth.reset_password.title')"
        class="mt-6"
      />
      <p class="mt-2 text-sm">
        <span class="text-muted-foreground">
          {{ $t('common.or') }}
        </span>
        {{ " " }}
        <router-link
          :to="{ name: 'SignIn' }"
          class="font-medium"
        >
          {{ $t("pages.auth.reset_password.sign_in") }}
        </router-link>
      </p>
    </div>

    <form
      class="mt-6"
      @submit.prevent="onSubmit"
    >
      <fieldset
        class="space-y-6"
        :disabled="isLoading"
      >
        <Field>
          <Label
            required
            for="password"
          >
            {{ $t('pages.auth.reset_password.new_password') }}
          </Label>
          <PasswordInput
            id="password"
            v-model="form.password"
            name="password"
            autocomplete="new-password"
            required
          />

          <ErrorMessage :message="errors?.password" />
        </Field>
        <Field>
          <Label
            required
            for="password_confirmation"
          >
            {{ $t('pages.auth.reset_password.confirm_password') }}
          </Label>
          <PasswordInput
            v-model="form.password_confirmation"
            name="password_confirmation"
            autocomplete="new-password"
            required
          />
          <ErrorMessage :message="errors?.password_confirmation" />
        </Field>
        <Button
          type="submit"
          :loading="isLoading"
          block
        >
          {{ $t("common.save") }}
        </Button>
      </fieldset>
    </form>
  </div>
</template>
