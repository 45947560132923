<script setup>
import { computed } from 'vue'
import { ProgressIndicator, ProgressRoot } from 'radix-vue'
import { cn } from '@/lib/utils'

const props = defineProps({
  modelValue: {
    type: [Number, null],
    required: false,
    default: 0
  },
  max: {
    type: Number,
    required: false
  },
  getValueLabel: {
    type: Function,
    required: false
  },
  asChild: {
    type: Boolean,
    required: false
  },
  as: {
    type: null,
    required: false
  },
  class: {
    type: null,
    required: false
  },
})

const delegatedProps = computed(() => {
  const { class: _, ...delegated } = props

  return delegated
})

const colors = {
  red: {
    root: 'dark:bg-red-800 bg-red-200',
    indicator: 'dark:bg-red-500 bg-red-400',
  },
  yellow: {
    root: 'dark:bg-yellow-800 bg-yellow-200',
    indicator: 'dark:bg-yellow-500 bg-yellow-400',
  },
  default: {
    root: 'bg-primary/20',
    indicator: 'bg-primary',
  },
}

const color = computed(() => {
  if (props.modelValue >= 80) {
    return colors.red
  }

  if (props.modelValue >= 75) {
    return colors.yellow
  }

  return colors.default
})
</script>

<template>
  <ProgressRoot
    v-bind="delegatedProps"
    :class="
      cn(
        'relative h-2 w-full overflow-hidden rounded-full ',
        props.class,
        color.root,
      )
    "
  >
    <ProgressIndicator
      class="size-full flex-1 transition-all"
      :class="color.indicator"
      :style="`transform: translateX(-${100 - (props.modelValue ?? 0)}%);`"
    />
  </ProgressRoot>
</template>
