import client from '../plugins/axios'

export const plansService = {
  resource: '/plans',
  findAll(params = {}) {
    return client.get(`${this.resource}`, {
      params
    })
  },
  findById(id) {
    return client.get(`${this.resource}/${id}`)
  },
  validateCoupon(id, { code }) {
    return client.get(`${this.resource}/${id}/validate-coupon`, {
      params: {
        code
      }
    })
  }
}
