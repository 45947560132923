<script setup>
import { useCurrencyInput } from 'vue-currency-input'
import Input from '@/components/ui/input/Input.vue'

const props = defineProps({
  modelValue: Number,
  options: Object
})

const { inputRef } = useCurrencyInput(props.options)
</script>

<template>
  <Input
    ref="inputRef"
    type="text"
  />
</template>
