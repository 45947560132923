<script setup >
import { Label } from '@/components/ui/label'
import { RadioGroup, RadioGroupItem } from '@/components/ui/radio-group'

const props = defineProps({
  page: {
    type: String,
    required: true,
    default: 'offer'
  }
})

const model = defineModel({
  type: String,
  required: true,
})
</script>

<template>
  <RadioGroup
    v-model="model"
    default-value="content"
  >
    <div class="flex items-center space-x-2">
      <RadioGroupItem
        :id="`${props.page}-content`"
        value="content"
      />
      <Label
        :for="`${props.page}-content`"
      >
        {{ $t('show_content') }}
      </Label>
    </div>
    <div class="flex items-center space-x-2">
      <RadioGroupItem
        :id="`${props.page}-redirect`"
        value="redirect"
      />
      <Label :for="`${props.page}-redirect`">
        {{ $t('redirect') }}
      </Label>
    </div>
  </RadioGroup>
</template>
