<script setup>
import { computed } from 'vue'

defineOptions({
  inheritAttrs: false
})
const props = defineProps({
  modelValue: {
    type: Number,
    default: 0
  }
})
const emit = defineEmits(['update:modelValue'])
const model = computed({
  get: () => props.modelValue,
  set: (value) => {
    emit('update:modelValue', value)
  }
})
</script>

<template>
  <div>
    <input
      v-bind="$attrs"
      id="noise"
      v-model="model"
      type="range"
      name="noise"
      class="h-2 w-full cursor-pointer appearance-none rounded-lg bg-neutral-200 accent-neutral-900"
      :style="{
        background:
          `linear-gradient(to right, #171717 0%, #171717 ${
            model
          }%, #e5e5e5 ${
            model
          }%, #e5e5e5 100%)`,
      }"
    >
  </div>
</template>
