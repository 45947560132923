export default [
  {
    id: 'af',
    name: 'Africano',
    name_int: 'Afrikaans',
  },
  {
    id: 'sq',
    name: 'Albanês',
    name_int: 'Albanian',
  },
  {
    id: 'an',
    name: 'Aragonês',
    name_int: 'Aragonese',
  },
  {
    id: 'ar',
    name: 'Árabe',
    name_int: 'Arabic',
  },
  {
    id: 'ar-dz',
    name: 'Árabe (Argélia)',
    name_int: 'Arabic (Algeria)',
  },
  {
    id: 'ar-bh',
    name: 'Árabe (Bahrein)',
    name_int: 'Arabic (Bahrain)',
  },
  {
    id: 'ar-eg',
    name: 'Árabe (Egito)',
    name_int: 'Arabic (Egypt)',
  },
  {
    id: 'ar-iq',
    name: 'Árabe (Iraque)',
    name_int: 'Arabic (Iraq)',
  },
  {
    id: 'ar-jo',
    name: 'Árabe (Jordânia)',
    name_int: 'Arabic (Jordan)',
  },
  {
    id: 'ar-kw',
    name: 'Árabe (Kuwait)',
    name_int: 'Arabic (Kuwait)',
  },
  {
    id: 'ar-lb',
    name: 'Árabe (Líbano)',
    name_int: 'Arabic (Lebanon)',
  },
  {
    id: 'ar-ly',
    name: 'Árabe (Líbia)',
    name_int: 'Arabic (Libya)',
  },
  {
    id: 'ar-ma',
    name: 'Árabe (Marrocos)',
    name_int: 'Arabic (Morocco)',
  },
  {
    id: 'ar-om',
    name: 'Árabe (Omã)',
    name_int: 'Arabic (Oman)',
  },
  {
    id: 'ar-qa',
    name: 'Árabe (Qatar)',
    name_int: 'Arabic (Qatar)',
  },
  {
    id: 'ar-sa',
    name: 'Árabe (Arábia Saudita)',
    name_int: 'Arabic (Saudi Arabia)',
  },
  {
    id: 'ar-sy',
    name: 'Árabe (Síria)',
    name_int: 'Arabic (Syria)',
  },
  {
    id: 'ar-tn',
    name: 'Árabe (Tunísia)',
    name_int: 'Arabic (Tunisia)',
  },
  {
    id: 'ar-ae',
    name: 'Árabe (Emirados Árabes Unidos)',
    name_int: 'Arabic (United Arab Emirates)',
  },
  {
    id: 'ar-ye',
    name: 'Árabe (Iêmen)',
    name_int: 'Arabic (Yemen)',
  },
  {
    id: 'hy',
    name: 'Armênio',
    name_int: 'Armenian',
  },
  {
    id: 'as',
    name: 'Assamês',
    name_int: 'Assamese',
  },
  {
    id: 'ast',
    name: 'Asturiano',
    name_int: 'Asturian',
  },
  {
    id: 'az',
    name: 'Azerbaijano',
    name_int: 'Azerbaijani',
  },
  {
    id: 'eu',
    name: 'Basco',
    name_int: 'Basque',
  },
  {
    id: 'be',
    name: 'Bielorrusso',
    name_int: 'Belarusian',
  },
  {
    id: 'bg',
    name: 'Búlgaro',
    name_int: 'Bulgarian',
  },
  {
    id: 'bn',
    name: 'Bengali',
    name_int: 'Bengali',
  },
  {
    id: 'bs',
    name: 'Bósnio',
    name_int: 'Bosnian',
  },
  {
    id: 'br',
    name: 'Bretão',
    name_int: 'Breton',
  },
  {
    id: 'my',
    name: 'Birmanês',
    name_int: 'Burmese',
  },
  {
    id: 'ca',
    name: 'Catalão',
    name_int: 'Catalan',
  },
  {
    id: 'ch',
    name: 'Chamorro',
    name_int: 'Chamorro',
  },
  {
    id: 'ce',
    name: 'Checheno',
    name_int: 'Chechen',
  },
  {
    id: 'zh',
    name: 'Chinês',
    name_int: 'Chinese',
  },
  {
    id: 'zh-cn',
    name: 'Chinês (China)',
    name_int: 'Chinese (China)',
  },
  {
    id: 'zh-hk',
    name: 'Chinês (Hong Kong)',
    name_int: 'Chinese (Hong Kong)',
  },
  {
    id: 'zh-mo',
    name: 'Chinês (Macau)',
    name_int: 'Chinese (Macau)',
  },
  {
    id: 'zh-sg',
    name: 'Chinês (Singapura)',
    name_int: 'Chinese (Singapore)',
  },
  {
    id: 'zh-tw',
    name: 'Chinês (Taiwan)',
    name_int: 'Chinese (Taiwan)',
  },
  {
    id: 'cv',
    name: 'Chuvash',
    name_int: 'Chuvash',
  },
  {
    id: 'co',
    name: 'Corso',
    name_int: 'Corsican',
  },
  {
    id: 'cr',
    name: 'Cree',
    name_int: 'Cree',
  },
  {
    id: 'hr',
    name: 'Croata',
    name_int: 'Croatian',
  },
  {
    id: 'cs',
    name: 'Tcheco',
    name_int: 'Czech',
  },
  {
    id: 'da',
    name: 'Dinamarquês',
    name_int: 'Danish',
  },
  {
    id: 'nl',
    name: 'Holandês',
    name_int: 'Dutch',
  },
  {
    id: 'nl-be',
    name: 'Holandês (Bélgica)',
    name_int: 'Dutch (Belgium)',
  },
  {
    id: 'en',
    name: 'Inglês',
    name_int: 'English',
  },
  {
    id: 'en-au',
    name: 'Inglês (Austrália)',
    name_int: 'English (Australia)',
  },
  {
    id: 'en-bz',
    name: 'Inglês (Belize)',
    name_int: 'English (Belize)',
  },
  {
    id: 'en-ca',
    name: 'Inglês (Canadá)',
    name_int: 'English (Canada)',
  },
  {
    id: 'en-gb',
    name: 'Inglês (Reino Unido)',
    name_int: 'English (United Kingdom)',
  },
  {
    id: 'en-ie',
    name: 'Inglês (Irlanda)',
    name_int: 'English (Ireland)',
  },
  {
    id: 'en-jm',
    name: 'Inglês (Jamaica)',
    name_int: 'English (Jamaica)',
  },
  {
    id: 'en-nz',
    name: 'Inglês (Nova Zelândia)',
    name_int: 'English (New Zealand)',
  },
  {
    id: 'en-tt',
    name: 'Inglês (Trinidad e Tobago)',
    name_int: 'English (Trinidad and Tobago)',
  },
  {
    id: 'en-us',
    name: 'Inglês (Estados Unidos)',
    name_int: 'English (United States)',
  },
  {
    id: 'en-za',
    name: 'Inglês (África do Sul)',
    name_int: 'English (South Africa)',
  },
  {
    id: 'en-zw',
    name: 'Inglês (Zimbábue)',
    name_int: 'English (Zimbabwe)',
  },
  {
    id: 'eo',
    name: 'Esperanto',
    name_int: 'Esperanto',
  },
  {
    id: 'et',
    name: 'Estoniano',
    name_int: 'Estonian',
  },
  {
    id: 'fo',
    name: 'Feroês',
    name_int: 'Faroese',
  },
  {
    id: 'fa',
    name: 'Persa',
    name_int: 'Persian',
  },
  {
    id: 'fj',
    name: 'Fijiano',
    name_int: 'Fijian',
  },
  {
    id: 'fi',
    name: 'Finlandês',
    name_int: 'Finnish',
  },
  {
    id: 'fr',
    name: 'Francês',
    name_int: 'French',
  },
  {
    id: 'fr-be',
    name: 'Francês (Bélgica)',
    name_int: 'French (Belgium)',
  },
  {
    id: 'fr-ca',
    name: 'Francês (Canadá)',
    name_int: 'French (Canada)',
  },
  {
    id: 'fr-ch',
    name: 'Francês (Suíça)',
    name_int: 'French (Switzerland)',
  },
  {
    id: 'fr-lu',
    name: 'Francês (Luxemburgo)',
    name_int: 'French (Luxembourg)',
  },
  {
    id: 'fr-mc',
    name: 'Francês (Mônaco)',
    name_int: 'French (Monaco)',
  },
  {
    id: 'fy',
    name: 'Frísio',
    name_int: 'Frisian',
  },
  {
    id: 'fur',
    name: 'Friulano',
    name_int: 'Friulian',
  },
  {
    id: 'gd',
    name: 'Gaelico Escocês',
    name_int: 'Scottish Gaelic',
  },
  {
    id: 'gd-ie',
    name: 'Gaelico Escocês (Irlanda)',
    name_int: 'Scottish Gaelic (Ireland)',
  },
  {
    id: 'gl',
    name: 'Galego',
    name_int: 'Galician',
  },
  {
    id: 'ka',
    name: 'Georgiano',
    name_int: 'Georgian',
  },
  {
    id: 'de',
    name: 'Alemão',
    name_int: 'German',
  },
  {
    id: 'de-at',
    name: 'Alemão (Áustria)',
    name_int: 'German (Austria)',
  },
  {
    id: 'de-ch',
    name: 'Alemão (Suíça)',
    name_int: 'German (Switzerland)',
  },
  {
    id: 'de-li',
    name: 'Alemão (Liechtenstein)',
    name_int: 'German (Liechtenstein)',
  },
  {
    id: 'de-lu',
    name: 'Alemão (Luxemburgo)',
    name_int: 'German (Luxembourg)',
  },
  {
    id: 'el',
    name: 'Grego',
    name_int: 'Greek',
  },
  {
    id: 'kl',
    name: 'Groenlandês',
    name_int: 'Greenlandic',
  },
  {
    id: 'gn',
    name: 'Guarani',
    name_int: 'Guarani',
  },
  {
    id: 'gu',
    name: 'Guzerate',
    name_int: 'Gujarati',
  },
  {
    id: 'ha',
    name: 'Hauçá',
    name_int: 'Hausa',
  },
  {
    id: 'haw',
    name: 'Havaiano',
    name_int: 'Hawaiian',
  },
  {
    id: 'he',
    name: 'Hebraico',
    name_int: 'Hebrew',
  },
  {
    id: 'hi',
    name: 'Hindi',
    name_int: 'Hindi',
  },
  {
    id: 'hu',
    name: 'Húngaro',
    name_int: 'Hungarian',
  },
  {
    id: 'is',
    name: 'Islandês',
    name_int: 'Icelandic',
  },
  {
    id: 'id',
    name: 'Indonésio',
    name_int: 'Indonesian',
  },
  {
    id: 'iu',
    name: 'Inuktitut',
    name_int: 'Inuktitut',
  },
  {
    id: 'ga',
    name: 'Irlandês',
    name_int: 'Irish',
  },
  {
    id: 'it',
    name: 'Italiano',
    name_int: 'Italian',
  },
  {
    id: 'it-ch',
    name: 'Italiano (Suíça)',
    name_int: 'Italian (Switzerland)',
  },
  {
    id: 'ja',
    name: 'Japonês',
    name_int: 'Japanese',
  },
  {
    id: 'kn',
    name: 'Canarês',
    name_int: 'Kannada',
  },
  {
    id: 'ks',
    name: 'Caxemira',
    name_int: 'Kashmiri',
  },
  {
    id: 'kk',
    name: 'Cazaque',
    name_int: 'Kazakh',
  },
  {
    id: 'km',
    name: 'Khmer',
    name_int: 'Khmer',
  },
  {
    id: 'ky',
    name: 'Quirguiz',
    name_int: 'Kyrgyz',
  },
  {
    id: 'tlh',
    name: 'Klingon',
    name_int: 'Klingon',
  },
  {
    id: 'ko',
    name: 'Coreano',
    name_int: 'Korean',
  },
  {
    id: 'ko-kp',
    name: 'Coreano (Coreia do Norte)',
    name_int: 'Korean (North Korea)',
  },
  {
    id: 'ko-kr',
    name: 'Coreano (Coreia do Sul)',
    name_int: 'Korean (South Korea)',
  },
  {
    id: 'ksh',
    name: 'Colônia',
    name_int: 'Colognian',
  },
  {
    id: 'ku',
    name: 'Curdo',
    name_int: 'Kurdish',
  },
  {
    id: 'lo',
    name: 'Laosiano',
    name_int: 'Lao',
  },
  {
    id: 'la',
    name: 'Latim',
    name_int: 'Latin',
  },
  {
    id: 'lv',
    name: 'Letão',
    name_int: 'Latvian',
  },
  {
    id: 'li',
    name: 'Limburguês',
    name_int: 'Limburgish',
  },
  {
    id: 'li-be',
    name: 'Limburguês (Bélgica)',
    name_int: 'Limburgish (Belgium)',
  },
  {
    id: 'lt',
    name: 'Lituano',
    name_int: 'Lithuanian',
  },
  {
    id: 'lb',
    name: 'Luxemburguês',
    name_int: 'Luxembourgish',
  },
  {
    id: 'mk',
    name: 'Macedônio',
    name_int: 'Macedonian',
  },
  {
    id: 'ms',
    name: 'Malaio',
    name_int: 'Malay',
  },
  {
    id: 'ml',
    name: 'Malaiala',
    name_int: 'Malayalam',
  },
  {
    id: 'mt',
    name: 'Maltês',
    name_int: 'Maltese',
  },
  {
    id: 'mi',
    name: 'Maori',
    name_int: 'Maori',
  },
  {
    id: 'mr',
    name: 'Marata',
    name_int: 'Marathi',
  },
  {
    id: 'mo',
    name: 'Moldávio',
    name_int: 'Moldavian',
  },
  {
    id: 'nv',
    name: 'Navajo',
    name_int: 'Navajo',
  },
  {
    id: 'ng',
    name: 'Ndonga',
    name_int: 'Ndonga',
  },
  {
    id: 'ne',
    name: 'Nepali',
    name_int: 'Nepali',
  },
  {
    id: 'no',
    name: 'Norueguês',
    name_int: 'Norwegian',
  },
  {
    id: 'nb',
    name: 'Norueguês Bokmål',
    name_int: 'Norwegian Bokmål',
  },
  {
    id: 'nn',
    name: 'Norueguês Nynorsk',
    name_int: 'Norwegian Nynorsk',
  },
  {
    id: 'oc',
    name: 'Occitânico',
    name_int: 'Occitan',
  },
  {
    id: 'or',
    name: 'Oriya',
    name_int: 'Oriya',
  },
  {
    id: 'om',
    name: 'Oromo',
    name_int: 'Oromo',
  },
  {
    id: 'ps',
    name: 'Pashto',
    name_int: 'Pashto',
  },
  {
    id: 'fa-af',
    name: 'Persa (Afeganistão)',
    name_int: 'Persian (Afghanistan)',
  },
  {
    id: 'pl',
    name: 'Polonês',
    name_int: 'Polish',
  },
  {
    id: 'pt',
    name: 'Português',
    name_int: 'Portuguese',
  },
  {
    id: 'pt-br',
    name: 'Português (Brasil)',
    name_int: 'Portuguese (Brazil)',
  },
  {
    id: 'pt-pt',
    name: 'Português (Portugal)',
    name_int: 'Portuguese (Portugal)',
  },
  {
    id: 'pa',
    name: 'Punjabi',
    name_int: 'Punjabi',
  },
  {
    id: 'pa-in',
    name: 'Punjabi (Índia)',
    name_int: 'Punjabi (India)',
  },
  {
    id: 'pa-pk',
    name: 'Punjabi (Paquistão)',
    name_int: 'Punjabi (Pakistan)',
  },
  {
    id: 'qu',
    name: 'Quechua',
    name_int: 'Quechua',
  },
  {
    id: 'rm',
    name: 'Reto-romano',
    name_int: 'Romansh',
  },
  {
    id: 'ro',
    name: 'Romeno',
    name_int: 'Romanian',
  },
  {
    id: 'ro-md',
    name: 'Romeno (Moldávia)',
    name_int: 'Romanian (Moldova)',
  },
  {
    id: 'ro-ro',
    name: 'Romeno (Romênia)',
    name_int: 'Romanian (Romania)',
  },
  {
    id: 'rn',
    name: 'Rundi',
    name_int: 'Rundi',
  },
  {
    id: 'ru',
    name: 'Russo',
    name_int: 'Russian',
  },
  {
    id: 'ru-mo',
    name: 'Russo (Moldávia)',
    name_int: 'Russian (Moldova)',
  },
  {
    id: 'ru-ru',
    name: 'Russo (Rússia)',
    name_int: 'Russian (Russia)',
  },
  {
    id: 'ru-ua',
    name: 'Russo (Ucrânia)',
    name_int: 'Russian (Ukraine)',
  },
  {
    id: 'sg',
    name: 'Sango',
    name_int: 'Sango',
  },
  {
    id: 'sa',
    name: 'Sânscrito',
    name_int: 'Sanskrit',
  },
  {
    id: 'sc',
    name: 'Sardo',
    name_int: 'Sardinian',
  },
  {
    id: 'gd',
    name: 'Gaélico Escocês',
    name_int: 'Scottish Gaelic',
  },
  {
    id: 'sr',
    name: 'Sérvio',
    name_int: 'Serbian',
  },
  {
    id: 'sh',
    name: 'Sérvio (latino)',
    name_int: 'Serbian (Latin)',
  },
  {
    id: 'shs',
    name: 'Shuswap',
    name_int: 'Shuswap',
  },
  {
    id: 'sn',
    name: 'Shona',
    name_int: 'Shona',
  },
  {
    id: 'sd',
    name: 'Sindi',
    name_int: 'Sindhi',
  },
  {
    id: 'si',
    name: 'Cingalês',
    name_int: 'Sinhala',
  },
  {
    id: 'sk',
    name: 'Eslovaco',
    name_int: 'Slovak',
  },
  {
    id: 'sl',
    name: 'Esloveno',
    name_int: 'Slovenian',
  },
  {
    id: 'so',
    name: 'Somali',
    name_int: 'Somali',
  },
  {
    id: 'st',
    name: 'Soto do Sul',
    name_int: 'Southern Sotho',
  },
  {
    id: 'es',
    name: 'Espanhol',
    name_int: 'Spanish',
  },
  {
    id: 'es-ar',
    name: 'Espanhol (Argentina)',
    name_int: 'Spanish (Argentina)',
  },
  {
    id: 'es-bo',
    name: 'Espanhol (Bolívia)',
    name_int: 'Spanish (Bolivia)',
  },
  {
    id: 'es-cl',
    name: 'Espanhol (Chile)',
    name_int: 'Spanish (Chile)',
  },
  {
    id: 'es-co',
    name: 'Espanhol (Colômbia)',
    name_int: 'Spanish (Colombia)',
  },
  {
    id: 'es-cr',
    name: 'Espanhol (Costa Rica)',
    name_int: 'Spanish (Costa Rica)',
  },
  {
    id: 'es-do',
    name: 'Espanhol (República Dominicana)',
    name_int: 'Spanish (Dominican Republic)',
  },
  {
    id: 'es-ec',
    name: 'Espanhol (Equador)',
    name_int: 'Spanish (Ecuador)',
  },
  {
    id: 'es-sv',
    name: 'Espanhol (El Salvador)',
    name_int: 'Spanish (El Salvador)',
  },
  {
    id: 'es-gt',
    name: 'Espanhol (Guatemala)',
    name_int: 'Spanish (Guatemala)',
  },
  {
    id: 'es-hn',
    name: 'Espanhol (Honduras)',
    name_int: 'Spanish (Honduras)',
  },
  {
    id: 'es-mx',
    name: 'Espanhol (México)',
    name_int: 'Spanish (Mexico)',
  },
  {
    id: 'es-ni',
    name: 'Espanhol (Nicarágua)',
    name_int: 'Spanish (Nicaragua)',
  },
  {
    id: 'es-pa',
    name: 'Espanhol (Panamá)',
    name_int: 'Spanish (Panama)',
  },
  {
    id: 'es-py',
    name: 'Espanhol (Paraguai)',
    name_int: 'Spanish (Paraguay)',
  },
  {
    id: 'es-pe',
    name: 'Espanhol (Peru)',
    name_int: 'Spanish (Peru)',
  },
  {
    id: 'es-pr',
    name: 'Espanhol (Porto Rico)',
    name_int: 'Spanish (Puerto Rico)',
  },
  {
    id: 'es-es',
    name: 'Espanhol (Espanha)',
    name_int: 'Spanish (Spain)',
  },
  {
    id: 'es-uy',
    name: 'Espanhol (Uruguai)',
    name_int: 'Spanish (Uruguay)',
  },
  {
    id: 'es-ve',
    name: 'Espanhol (Venezuela)',
    name_int: 'Spanish (Venezuela)',
  },
  {
    id: 'sx',
    name: 'Sutu',
    name_int: 'Sotho',
  },
  {
    id: 'sw',
    name: 'Suaíli',
    name_int: 'Swahili',
  },
  {
    id: 'sv',
    name: 'Sueco',
    name_int: 'Swedish',
  },
  {
    id: 'sv-fi',
    name: 'Sueco (Finlândia)',
    name_int: 'Swedish (Finland)',
  },
  {
    id: 'sv-se',
    name: 'Sueco (Suécia)',
    name_int: 'Swedish (Sweden)',
  },
  {
    id: 'tl',
    name: 'Tagalo',
    name_int: 'Tagalog',
  },
  {
    id: 'tg',
    name: 'Tadjique',
    name_int: 'Tajik',
  },
  {
    id: 'ta',
    name: 'Tâmil',
    name_int: 'Tamil',
  },
  {
    id: 'tt',
    name: 'Tatar',
    name_int: 'Tatar',
  },
  {
    id: 'te',
    name: 'Telugu',
    name_int: 'Telugu',
  },
  {
    id: 'th',
    name: 'Tailandês',
    name_int: 'Thai',
  },
  {
    id: 'bo',
    name: 'Tibetano',
    name_int: 'Tibetan',
  },
  {
    id: 'ti',
    name: 'Tigrínia',
    name_int: 'Tigrinya',
  },
  {
    id: 'to',
    name: 'Tonganês',
    name_int: 'Tongan',
  },
  {
    id: 'ts',
    name: 'Tsonga',
    name_int: 'Tsonga',
  },
  {
    id: 'tn',
    name: 'Tswana',
    name_int: 'Tswana',
  },
  {
    id: 'tr',
    name: 'Turco',
    name_int: 'Turkish',
  },
  {
    id: 'tk',
    name: 'Turcomano',
    name_int: 'Turkmen',
  },
  {
    id: 'tw',
    name: 'Twi',
    name_int: 'Twi',
  },
  {
    id: 'ug',
    name: 'Uigur',
    name_int: 'Uyghur',
  },
  {
    id: 'uk',
    name: 'Ucraniano',
    name_int: 'Ukrainian',
  },
  {
    id: 'hsb',
    name: 'Alto Sorábio',
    name_int: 'Upper Sorbian',
  },
  {
    id: 'ur',
    name: 'Urdu',
    name_int: 'Urdu',
  },
  {
    id: 'uz',
    name: 'Uzbeque',
    name_int: 'Uzbek',
  },
  {
    id: 'vi',
    name: 'Vietnamita',
    name_int: 'Vietnamese',
  },
  {
    id: 'cy',
    name: 'Galês',
    name_int: 'Welsh',
  },
  {
    id: 'wo',
    name: 'Wolof',
    name_int: 'Wolof',
  },
  {
    id: 'xh',
    name: 'Xosa',
    name_int: 'Xhosa',
  },
  {
    id: 'yi',
    name: 'Iídiche',
    name_int: 'Yiddish',
  },
  {
    id: 'yo',
    name: 'Ioruba',
    name_int: 'Yoruba',
  },
  {
    id: 'zu',
    name: 'Zulu',
    name_int: 'Zulu',
  },
]
