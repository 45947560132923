<script setup>
import { computed } from 'vue'
import { useI18n } from 'vue-i18n'
import Badge from '@/components/ui/badge/Badge.vue'
import BaseSpinner from '@/components/base/BaseSpinner.vue'

const props = defineProps({
  status: {
    type: String,
    default: 'pending',
    required: true,
  },
  ip: {
    type: String,
    default: null
  }
})

const { t } = useI18n()

const isCreating = computed(() => {
  return props.status === 'pending' && !props.ip
})
const isRebooting = computed(() => {
  return props.status === 'pending' && props.ip
})

function getText() {
  if (isCreating.value) {
    return t('components.server_status_badge.creating')
  }

  if (props.status === 'setup') {
    return t('components.server_status_badge.setup')
  }

  if (isRebooting.value) {
    return t('components.server_status_badge.rebooting')
  }

  if (props.status === 'active') {
    return t('components.server_status_badge.active')
  }

  if (props.status === 'destroyed') {
    return t('components.server_status_badge.destroyed')
  }

  if (props.status === 'running') {
    return t('components.server_status_badge.running')
  }

  if (props.status === 'failed') {
    return t('components.server_status_badge.failed')
  }
}

function getColor() {
  const color = {
    active: 'success',
    pending: 'warning',
    running: 'success',
    failed: 'danger',
    destroyed: 'danger'
  }

  return color[props.status]
}
</script>

<template>
  <Badge
    :variant="getColor()"
    size="xs"
    :dot="!!(props.status === 'running' && props.ip)"
    animated
  >
    <BaseSpinner
      v-if="isCreating || isRebooting"
      class="mr-2"
      size="md"
    />
    {{ getText() }}
  </Badge>
</template>
