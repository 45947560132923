<script setup>
import { CheckCircleIcon, ExclamationTriangleIcon, InformationCircleIcon, XCircleIcon } from '@heroicons/vue/24/solid'

defineProps({
  variant: {
    type: String,
    required: false,
    default: () => 'warning'
  },
  message: {
    type: String,
    required: false
  },
  title: {
    type: String,
    required: false,
    default: () => 'Atenção'
  }
})

const types = {
  info: {
    icon: InformationCircleIcon,
    iconColor: `text-blue-500 dark:text-blue-400`,
    bgColor: `bg-blue-50 dark:bg-blue-400/10`,
    textColor: `text-blue-800 dark:text-blue-400`,
    messageColor: `text-blue-700 dark:text-blue-400`
  },
  success: {
    icon: CheckCircleIcon,
    iconColor: `text-green-500 dark:text-green-400`,
    bgColor: `bg-green-50 dark:bg-green-400/10`,
    textColor: `text-green-800 dark:text-green-400`,
    messageColor: `text-green-700 dark:text-green-400`
  },
  warning: {
    icon: ExclamationTriangleIcon,
    iconColor: `text-yellow-400 dark:text-yellow-400`,
    bgColor: `bg-yellow-50 dark:bg-yellow-400/10`,
    textColor: `text-yellow-800 dark:text-yellow-400`,
    messageColor: `text-yellow-700 dark:text-yellow-400`
  },
  error: {
    icon: XCircleIcon,
    iconColor: `text-red-500 dark:text-red-400`,
    bgColor: `bg-red-50 dark:bg-red-400/10`,
    textColor: `text-red-800 dark:text-red-400`,
    messageColor: `text-red-700 dark:text-red-400`
  }
}
</script>

<template>
  <div
    class="rounded-md p-4"
    :class="types[variant].bgColor"
  >
    <div class="flex">
      <div class="shrink-0">
        <component
          :is="types[variant].icon"
          class="size-5"
          :class="types[variant].iconColor"
          aria-hidden="true"
        />
      </div>
      <div class="ml-3">
        <h3
          class="text-sm font-medium"
          :class="types[variant].textColor"
        >
          {{ title }}
        </h3>
        <div
          class="mt-2 text-sm"
          :class="types[variant].messageColor"
        >
          <p v-if="message">
            {{ message }}
          </p>
          <slot
            v-if="$slots.message"
            name="message"
            :class="types[variant].messageColor"
          />
        </div>
        <div class="mt-2">
          <slot />
        </div>
      </div>
    </div>
  </div>
</template>
