import client from '../plugins/axios'

export const subscriptionService = {
  resource: '/subscriptions',
  create(data) {
    return client.post(this.resource, data)
  },

  show() {
    return client.get(this.resource)
  },

  getUsage(subscriptionId) {
    return client.get(`${this.resource}/${subscriptionId}/usage`)
  },
  upgradeSubscription(subscriptionId, data) {
    return client.post(`${this.resource}/${subscriptionId}/upgrade`, data)
  },

  getUpgradeAmount(subscriptionId, planId) {
    return client.get(
      `${this.resource}/${subscriptionId}/proportional-amount/${planId}`
    )
  }
}
