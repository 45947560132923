<script setup>
import {
  RadioGroup,
  RadioGroupDescription,
  RadioGroupLabel,
  RadioGroupOption
} from '@headlessui/vue'
import { computed } from 'vue'
import { formatCurrency } from '@/helpers/currency.js'
import { formatNumber } from '@/helpers/format.js'

const props = defineProps({
  options: {
    type: Array,
    required: true
  },
  modelValue: {
    type: Object,
    required: true
  }
})
const emits = defineEmits(['update:modelValue', 'change'])
const model = computed({
  get: () => props.modelValue,
  set: (value) => {
    emits('update:modelValue', value)
    emits('change', value)
  }
})
</script>

<template>
  <RadioGroup v-model="model">
    <RadioGroupLabel class="sr-only">
      Pricing plans
    </RadioGroupLabel>
    <div
      class="relative -space-y-px rounded-md bg-background disabled:cursor-not-allowed"
    >
      <RadioGroupOption
        v-for="(plan, planIdx) in options"
        :key="plan.name"
        v-slot="{ checked, active }"
        as="template"
        :value="plan"
      >
        <div
          class="relative flex cursor-pointer flex-col border p-4 focus:outline-none md:grid md:grid-cols-3 md:pl-4 md:pr-6"
          :class="[
            planIdx === 0 ? 'rounded-t-md' : '',
            planIdx === options.length - 1 ? 'rounded-b-md' : '',
            checked ? 'z-10 bg-foreground' : 'border-border',
          ]"
        >
          <span class="flex items-center text-sm">
            <span
              class="flex size-4 items-center justify-center rounded-full border"
              :class="[
                checked
                  ? 'border-transparent bg-foreground'
                  : 'border-border ',
                active ? 'bg-foreground ring-2 ring-offset-2' : '',
              ]"
              aria-hidden="true"
            >
              <span class="size-1.5 rounded-full bg-white" />
            </span>
            <RadioGroupLabel
              as="span"
              class="ml-3 font-medium"
              :class="[
                checked ? 'text-background' : 'text-foreground',
              ]"
            >
              {{ plan.name }}
            </RadioGroupLabel>
          </span>
          <RadioGroupDescription
            as="span"
            class="ml-6 pl-1 text-sm md:ml-0 md:pl-0 md:text-center"
          >
            <span
              class="font-medium"
              :class="[
                checked ? 'text-background' : 'text-foreground',
              ]"
            >{{ formatCurrency(plan.price, plan.currency) }} / {{ $t(`common.plan_cycle.${plan.cycle}`) }}</span>
          </RadioGroupDescription>
          <RadioGroupDescription
            as="span"
            class="ml-6 pl-1 text-sm md:ml-0 md:pl-0 md:text-right"
            :class="[
              checked ? 'text-background/75' : 'text-muted-foreground',
            ]"
          >
            {{ formatNumber(plan.limit) }} {{ $t('common.requests') }}
          </RadioGroupDescription>
        </div>
      </RadioGroupOption>
    </div>
  </RadioGroup>
</template>
