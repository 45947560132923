<script setup>
import Badge from '@/components/ui/badge/Badge.vue'

defineProps({
  status: {
    type: Boolean,
    default: false
  }
})

function getStatusText(status) {
  if (status === 'active') {
    return 'Ativo'
  } else if (status === 'disabled') {
    return 'Inativo'
  } else if (status === 'created') {
    return 'Criado'
  }
}

function getStatusColor(status) {
  if (status === 'active') {
    return 'success'
  } else if (status === 'inactive') {
    return 'danger'
  }

  return 'secondary'
}
</script>

<template>
  <Badge
    :variant="getStatusColor(status)"
    size="xs"
  >
    {{ getStatusText(status) }}
  </Badge>
</template>
