<script setup>
import { computed, ref } from 'vue'
import { toast } from 'vue-sonner'
import { useI18n } from 'vue-i18n'
import CancelSubscriptionModal from '@/components/partials/settings/subscriptions/CancelSubscriptionModal.vue'
import { useUserStore } from '@/stores/user'
import { formatCurrency } from '@/helpers/currency'
import { formatDate, timeToRenew } from '@/helpers/date'
import Badge from '@/components/ui/badge/Badge.vue'
import Button from '@/components/ui/button/Button.vue'
import {
  Card,
  CardContent,
  CardFooter
} from '@/components/ui/card/'
import { Progress } from '@/components/ui/progress'
import { domainService } from '@/services/domain'
import SubscriptionAdditionalCostAlert from '@/components/partials/settings/subscriptions/SubscriptionAdditionalCostAlert.vue'
import SubscriptionDomainLimitAlert from '@/components/partials/settings/subscriptions/SubscriptionDomainLimitAlert.vue'
import Skeleton from '@/components/ui/skeleton/Skeleton.vue'

const props = defineProps({
  subscription: {
    type: Object,
    required: true
  }
})

const { locale } = useI18n()

const store = useUserStore()

const diffBetweenRequests = computed(
  () => Number(store.usage?.usage) - Number(store.usage?.limit)
)
const percentageDiff = computed(() => {
  return ((store.usage?.usage / Number(store.usage?.limit)) * 100) || 0
})

function formatNumber(number) {
  return new Intl.NumberFormat('pt-BR').format(number)
}

const domains = ref(0)
const fetchingDomains = ref(false)

const domainPercentage = computed(() => {
  if (!props.subscription?.plan?.domains) {
    return 0
  }

  return ((domains.value / Number(props.subscription?.plan?.domains ?? 0)) * 100) || 0
})

async function countDomains() {
  if (!store.user.custom_domain_enabled) {
    fetchingDomains.value = false
    return
  }

  try {
    const { data } = await domainService.count()
    domains.value = data.count
  } catch (e) {
    toast.error(e.response?.data?.message, {
      description: e.response?.data?.action || e.message,
    })
  } finally {
    fetchingDomains.value = false
  }
}

countDomains()
</script>

<template>
  <div>
    <Card>
      <CardContent>
        <div>
          <div
            class="mx-auto grid grid-cols-1 gap-4 sm:grid-cols-2 lg:grid-cols-3"
          >
            <div>
              <dt
                class="text-sm font-medium leading-6 text-muted-foreground first-letter:uppercase"
              >
                {{ subscription.plan.product }}
              </dt>
              <dd
                class="w-full flex-none text-xl font-medium leading-10 tracking-tight"
              >
                {{ subscription.plan?.name }}
                <Badge
                  v-if="subscription.status === 'active'"
                  variant="success"
                  class="ml-2"
                >
                  {{ $t('components.subscription_card.status.active') }}
                </Badge>
              </dd>
            </div>
            <div>
              <dt class="text-sm font-medium leading-6 text-muted-foreground">
                {{ $t('components.subscription_card.payment') }}
              </dt>
              <dd
                class="w-full flex-none text-xl font-medium leading-10 tracking-tight"
              >
                {{ formatCurrency(subscription.plan.price, subscription.plan.currency) }}
                <span class="text-sm text-muted-foreground">/ {{ $t(`common.plan_cycle.${subscription.plan.cycle}`) }}
                </span>
              </dd>
            </div>
            <div>
              <dt class="text-sm font-medium leading-6 text-muted-foreground">
                {{ $t('components.subscription_card.renew') }}
              </dt>
              <dd
                class="w-full flex-none text-xl font-medium leading-10 tracking-tight"
              >
                {{ formatDate(subscription.current_period_end, {
                  locale,
                }) }}
                <span
                  v-if="subscription.status === 'active'"
                  class="text-sm text-muted-foreground"
                >{{ $t('common.in') }} {{ timeToRenew(subscription.current_period_end) }} {{ $t('common.days') }}</span>
                <Badge
                  v-else
                  variant="destructive"
                  class="ml-2"
                >
                  {{ $t('components.subscription_card.status.cancel_at_period_end') }}
                </Badge>
              </dd>
            </div>
            <div
              v-if="subscription.plan.limit"
              class="col-span-full"
            >
              <dt class="text-sm font-medium leading-6 text-muted-foreground">
                {{ $t('components.subscription_card.request_limit') }}
              </dt>
              <dd
                class="w-full flex-none pt-2 text-xl font-medium leading-10 tracking-tight"
              >
                <div class="flex flex-1 flex-col gap-6">
                  <div v-if="store.isFetchingUsage">
                    <Skeleton
                      class="h-4 w-full "
                    />
                  </div>
                  <div v-else>
                    <Progress
                      :model-value="percentageDiff"
                    />
                    <span class="mt-2 text-sm ">{{ formatNumber(store.usage?.usage) }} /
                      {{ formatNumber(store.usage?.limit) }}</span>
                  </div>

                  <SubscriptionAdditionalCostAlert
                    v-if="Number(store.usage?.usage) > Number(store.usage?.limit)"
                    :additional-cost="Number(subscription.plan?.additional_cost)"
                    :usage="diffBetweenRequests"
                  />
                </div>
              </dd>
            </div>
            <div
              v-if="subscription.plan.product === 'cloakup' "
              class="col-span-full"
            >
              <dt class="text-sm font-medium leading-6 text-muted-foreground">
                {{ $t('components.subscription_card.domain_limit') }}
              </dt>
              <dd
                class="w-full flex-none pt-2 text-xl font-medium leading-10 tracking-tight"
              >
                <div class="flex flex-1 flex-col gap-6">
                  <div v-if="fetchingDomains">
                    <Skeleton
                      class="h-4 w-full"
                    />
                  </div>
                  <div v-else>
                    <Progress
                      :model-value="domainPercentage ?? 0"
                    />

                    <span class="mt-2 text-sm ">{{ domains }} /
                      {{ formatNumber(subscription.plan.domains) }}</span>
                  </div>

                  <SubscriptionDomainLimitAlert
                    v-if="domainPercentage >= 100"
                  />
                </div>
              </dd>
            </div>
          </div>
        </div>
      </CardContent>

      <CardFooter>
        <div class="flex w-full items-center justify-between gap-2">
          <Button
            v-if="subscription?.id && subscription.status === 'active'"
            as-child
          >
            <router-link
              :to="{
                name: 'upgrade',
                params: {
                  id: subscription?.id,
                },
              }"
            >
              {{ $t('components.subscription_card.upgrade') }}
            </router-link>
          </Button>
          <Button
            v-else-if="subscription.status !== 'pending-cancel'"
            as-child
          >
            <router-link
              :to="{
                name: 'CheckoutPlans',
                query: {
                  product: subscription?.plan?.product,
                },
              }"
            >
              {{ $t('components.subscription_card.subscribe_again') }}
            </router-link>
          </Button>

          <CancelSubscriptionModal
            v-if="subscription?.id && subscription.status === 'active'"
            :product="subscription?.plan?.product"
          />
        </div>
      </CardFooter>
    </Card>
  </div>
</template>
