<script setup>
import { reactive, ref } from 'vue'
import { toast } from 'vue-sonner'
import { useI18n } from 'vue-i18n'
import { userService } from '@/services/user'
import { useUserStore } from '@/stores/user'
import Button from '@/components/ui/button/Button.vue'
import Label from '@/components/ui/label/Label.vue'
import ErrorMessage from '@/components/ui/error-message/ErrorMessage.vue'
import Field from '@/components/ui/field/Field.vue'
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@/components/ui/select'
import BrasilFlag from '@/components/common/BrasilFlag.vue'
import UnitedStatesFlag from '@/components/common/UnitedStatesFlag.vue'
import SpanishFlag from '@/components/common/SpanishFlag.vue'

const { t } = useI18n()
const store = useUserStore()

const langs = [
  {
    name: 'Português',
    code: 'pt-BR'
  },
  {
    name: 'English',
    code: 'en'
  },
  {
    name: 'Español',
    code: 'es'
  }
]

const isLoading = ref(false)
const errors = ref({})
const form = reactive({
  language: store.user?.language ?? window.navigator.language
})

function submit() {
  const payload = {
    language: form.language
  }

  updateLanguage(payload)
}

async function updateLanguage(form) {
  isLoading.value = true
  errors.value = {}
  try {
    await userService.updateLanguage(form)

    store.updateLanguage(form.language)

    toast.success(t('components.update_language.toast.success.title'), {
      description: t('components.update_language.toast.success.description'),
    })
  } catch (e) {
    if (e.response?.status === 422) {
      errors.value = e.response.data?.data
    }

    toast.error(e.response?.data?.message, {
      description: e.response?.data?.action || e.message,
    })
  } finally {
    isLoading.value = false
  }
}
</script>

<template>
  <form @submit.prevent="submit">
    <fieldset :disabled="isLoading">
      <div class="grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
        <div class="col-span-6">
          <Field>
            <Label for="language">
              {{ $t("components.update_language.title") }}
            </Label>
            <Select
              v-model="form.language"
            >
              <SelectTrigger
                id="language"
                name="language"
              >
                <SelectValue :placeholder="$t('common.select_a_option')" />
              </SelectTrigger>
              <SelectContent position="popper">
                <SelectItem
                  v-for="(lang, i) in langs"
                  :key="`Lang${i}`"
                  :value="lang.code"
                >
                  <div class="flex flex-row  items-center gap-2">
                    <BrasilFlag
                      v-if="lang.code === 'pt-BR'"
                      class="size-5"
                    />
                    <UnitedStatesFlag
                      v-if="lang.code === 'en'"
                      class="size-5"
                    />
                    <SpanishFlag
                      v-if="lang.code === 'es'"
                      class="size-5"
                    />
                    {{ lang.name }}
                  </div>
                </SelectItem>
              </SelectContent>
            </Select>
            <ErrorMessage :message="errors?.language" />
          </Field>
        </div>
      </div>
      <div class="mt-6 flex items-center justify-end">
        <Button
          type="submit"
          :loading="isLoading"
        >
          {{ $t('common.save') }}
        </Button>
      </div>
    </fieldset>
  </form>
</template>
