<script setup>
import { CheckIcon, ChevronDownIcon } from '@heroicons/vue/24/solid'
import { onMounted, ref, watch } from 'vue'
import { useRoute } from 'vue-router'
import { useI18n } from 'vue-i18n'
import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  BreadcrumbList,
  BreadcrumbPage,
  BreadcrumbSeparator,
} from '@/components/ui/breadcrumb'
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu'
import { useCampaignStore } from '@/stores/campaign.js'

const { t } = useI18n()

const campaignStore = useCampaignStore()
const route = useRoute()

const options = [{
  alias: 'basic',
  name: t('components.campaign_create_breadcrumb.create_in_basic'),
  href: '/campaigns/create?mode=basic',
}, {
  alias: 'advanced',
  name: t('components.campaign_create_breadcrumb.create_in_advanced'),
  href: '/campaigns/create?mode=advanced',
}]
const option = ref()

onMounted(() => {
  if (!campaignStore.campaign?.length) {
    campaignStore.fetchCampaigns()
  }
})

watch(() => route.query.mode, () => {
  option.value = options.find(option => option.alias === route.query.mode)?.name || 'advanced'
}, {
  immediate: true
})
</script>

<template>
  <Breadcrumb>
    <BreadcrumbList>
      <BreadcrumbItem>
        <BreadcrumbLink as-child>
          <RouterLink to="/">
            {{ $t('common.dashboard') }}
          </RouterLink>
        </BreadcrumbLink>
      </BreadcrumbItem>

      <BreadcrumbSeparator />

      <BreadcrumbItem>
        <BreadcrumbLink as-child>
          <RouterLink to="/campaigns">
            {{ $t('common.campaigns') }}
          </RouterLink>
        </BreadcrumbLink>
      </BreadcrumbItem>

      <BreadcrumbSeparator />

      <BreadcrumbItem>
        <DropdownMenu>
          <DropdownMenuTrigger class="flex items-center gap-1 ">
            <BreadcrumbPage>
              {{ option }}
            </BreadcrumbPage>
            <ChevronDownIcon class="ml-2 size-4" />
          </DropdownMenuTrigger>

          <DropdownMenuContent>
            <DropdownMenuItem
              v-for="(campaign, index) in options"
              :key="campaign.href"
              :value="campaign.href"
              as-child
            >
              <RouterLink
                :to="campaign.href"
                class="w-[220px]"
              >
                <p>
                  {{ campaign.name }}
                </p>

                <CheckIcon
                  v-if="campaign.href === $route.fullPath"
                  class="ml-auto size-4"
                />
              </RouterLink>
            </DropdownMenuItem>
          </DropdownMenuContent>
        </DropdownMenu>
      </BreadcrumbItem>
    </BreadcrumbList>
  </Breadcrumb>
</template>
