<script setup>
import CopyButton from './CopyButton.vue'
import Code from '@/components/common/Code.vue'

const props = defineProps({
  code: String,
  lang: String,
  contentEditable: Boolean
})
</script>

<template>
  <div class="relative mt-5 overflow-hidden rounded ring-1 ring-border">
    <Code
      :code="props.code"
      :lang="props.lang"
      contend-editable
    />
    <div class="absolute right-2 top-2">
      <CopyButton :content="props.code" />
    </div>
  </div>
</template>
