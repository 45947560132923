<script setup>
import { ref } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { toast } from 'vue-sonner'
import { useClipboard } from '@vueuse/core'
import { useI18n } from 'vue-i18n'
import { useHead } from '@unhead/vue'
import PageHeading from '@/components/common/PageHeading.vue'
import SectionHeading from '@/components/common/SectionHeading.vue'
import ServerBadgeStatus from '@/components/partials/server/ServerBadgeStatus.vue'
import ServerConnectForm from '@/components/partials/server/ServerConnectForm.vue'
import ServerDeleteModal from '@/components/partials/server/ServerDeleteModal.vue'
import { serverService } from '@/services/server'
import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  BreadcrumbList,
  BreadcrumbPage,
  BreadcrumbSeparator,
} from '@/components/ui/breadcrumb'
import Button from '@/components/ui/button/Button.vue'

const { t } = useI18n()
useHead({
  title: t('pages.servers.server.title')
})
const route = useRoute()
const router = useRouter()
const { copy, copied } = useClipboard()

const server = ref(null)
const hasError = ref(false)
const showPassword = ref(false)
const showUserName = ref(false)
const isDeletingServer = ref(false)
const checkStatusInterval = ref(null)

async function fetchServer(id) {
  isDeletingServer.value = false
  try {
    const { data } = await serverService.show(id)
    server.value = data

    if (server.value.status !== 'running' && server.value.status !== 'failed') {
      checkStatusInterval.value = setInterval(() => {
        checkStatus(id)
      }, 10000)
    }
  } catch (error) {
    hasError.value = true
  } finally {
    isDeletingServer.value = false
  }
}

async function deleteServer(id) {
  try {
    await serverService.delete(id)

    toast.success(t('components.delete_server_modal.toast.success.title'), {
      description: t('components.delete_server_modal.toast.success.description'),
    })

    await router.push({
      name: 'Servers'
    })
  } catch (error) {
    hasError.value = true

    toast.error(t('components.delete_server_modal.toast.error.title'), {
      description: t('components.delete_server_modal.toast.error.description'),
    })
  }
}

async function checkStatus(id) {
  try {
    const { data } = await serverService.checkStatus(id)
    server.value.status = data.status
    server.value.ip = data.ip
    server.value.password = data.password
    server.value.username = data.username

    if ((data.status !== 'running' || data.status !== 'failed') && !data.ip) {
      server.value.progress = 'setup'
    }

    if (data.status === 'running' || data.status === 'failed') {
      if (data.status === 'running') {
        toast.success(t('pages.servers.check_status.toast.success.title'), {
          description: t('pages.servers.check_status.toast.success.description'),
        })
      } else {
        toast.error(t('pages.servers.check_status.toast.error.title'), {
          description: t('pages.servers.check_status.toast.error.description'),
        })
      }

      clearInterval(checkStatusInterval.value)
    }
  } catch (error) {
    console.log(error)
  }
}

await fetchServer(route.params.id)
</script>

<template>
  <div class="space-y-5">
    <div class="space-y-2">
      <Breadcrumb>
        <BreadcrumbList>
          <BreadcrumbItem>
            <BreadcrumbPage>
              <RouterLink to="/">
                {{ $t('common.dashboard') }}
              </RouterLink>
            </BreadcrumbPage>
          </BreadcrumbItem>

          <BreadcrumbSeparator />

          <BreadcrumbItem>
            <BreadcrumbLink as-child>
              <RouterLink to="/servers">
                {{ $t("pages.servers.breadcrumb.servers") }}
              </RouterLink>
            </BreadcrumbLink>
          </BreadcrumbItem>

          <BreadcrumbSeparator />

          <BreadcrumbItem>
            <BreadcrumbPage>
              {{ $t("pages.servers.breadcrumb.server") }} {{ $route.params.id }}
            </BreadcrumbPage>
          </BreadcrumbItem>
        </BreadcrumbList>
      </Breadcrumb>

      <PageHeading
        :title="$t('pages.servers.server.title')"
      >
        <template #actions>
          <div class="flex items-center space-x-2">
            <ServerConnectForm
              v-if="server.ip"
              :server="server"
            >
              <Button
                type="submit"
              >
                {{ $t("pages.servers.connect") }}
              </Button>
            </ServerConnectForm>

            <ServerDeleteModal
              v-if="server.ip"
              :id="server?.id"
              @deleted="deleteServer"
            />
          </div>
        </template>
      </PageHeading>
    </div>

    <div class="space-y-5">
      <div>
        <SectionHeading
          :title="$t('pages.servers.server.title')"
          :description="$t('pages.servers.server.description')"
        />
      </div>

      <div class="mt-6 border-t border-border">
        <dl class="divide-y divide-border">
          <div class="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
            <dt class="text-sm font-medium leading-6 ">
              {{ $t("common.name") }}
            </dt>
            <dd
              class="mt-1 text-sm leading-6 text-muted-foreground sm:col-span-2 sm:mt-0"
            >
              {{ server.name }}
            </dd>
          </div>
          <div class="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
            <dt class="text-sm font-medium leading-6 ">
              IP
            </dt>
            <dd
              class="mt-1 text-sm leading-6 text-muted-foreground sm:col-span-2 sm:mt-0"
            >
              {{ server.ip }}
            </dd>
          </div>
          <div class="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
            <dt class="text-sm font-medium leading-6 ">
              {{ $t("common.status") }}
            </dt>

            <dd
              class="mt-1 flex text-sm leading-6 text-muted-foreground sm:col-span-2 sm:mt-0"
            >
              <span class="grow">
                <ServerBadgeStatus
                  :status="server.status"
                  :ip="server.ip"
                />
              </span>
            </dd>
          </div>
        </dl>
      </div>

      <div>
        <SectionHeading
          :title="$t('pages.servers.server.credentials.title')"
          :description="$t('pages.servers.server.credentials.description')"
        />

        <div class="mt-6 border-t border-border">
          <dl class="divide-y divide-border">
            <div class="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
              <dt class="text-sm font-medium leading-6 ">
                {{ $t("pages.servers.server.credentials.username") }}
              </dt>
              <dd
                class="mt-1 flex text-sm leading-6 text-muted-foreground sm:col-span-2 sm:mt-0"
              >
                <span class="grow">
                  {{ showUserName ? server.username : "********" }}</span>

                <span class="ml-4 shrink-0 space-x-2">
                  <Button
                    variant="secondary"
                    @click="showUserName = !showUserName"
                  >
                    {{ $t('pages.servers.server.credentials.show_user') }}
                  </Button>
                  <Button
                    variant="secondary"
                    @click="copy(server.username)"
                  >

                    {{ copied ? 'Copiado' : 'Copiar' }}
                  </Button>
                </span>
              </dd>
            </div>
            <div class="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
              <dt class="text-sm font-medium leading-6 ">
                {{ $t("pages.servers.server.credentials.password") }}
              </dt>
              <dd
                class="mt-1 flex text-sm leading-6 text-muted-foreground sm:col-span-2 sm:mt-0"
              >
                <span class="grow">
                  {{ showPassword ? server.password : "********" }}</span>

                <span class="ml-4 shrink-0 space-x-2">
                  <Button
                    variant="secondary"
                    icon=""
                    @click="showPassword = !showPassword"
                  >
                    {{ $t('pages.servers.server.credentials.show_password') }}
                  </Button>
                  <Button
                    variant="secondary"
                    @click="copy(server.password)"
                  >
                    {{ $t('common.copy') }}</Button>
                </span>
              </dd>
            </div>
          </dl>
        </div>
      </div>
    </div>
  </div>
</template>
