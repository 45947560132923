import { createRouter, createWebHistory } from 'vue-router'
import routes from './routes'
import { useAuthStore } from '@/stores/auth'
import { useUserStore } from '@/stores/user'

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {
  const { token } = useAuthStore()
  const store = useUserStore()
  const { user, isTrial } = useUserStore()

  if (!token && to.meta.requiresAuth) {
    return next({
      name: 'SignIn',
      query: {
        redirect_to: to.fullPath
      }
    })
  }

  if (token && !to.meta.requiresAuth) {
    const path = to.query.redirect_to || {
      name: 'Dashboard'
    }

    return next(path)
  }

  //
  const hasFeature = user?.subscriptions?.some((s) => {
    return s.plan.features?.some(f => f === to.meta.feature)
  })
  if (token && to.meta.feature && !hasFeature) {
    return next({
      name: 'Plugins'
    })
  }

  if (token && to.meta.feature && isTrial && !user.plugin_active) {
    return next({
      name: 'Plugins'
    })
  }

  if (
    token
    && to.name !== 'CheckoutPlans'
    && to.meta.requiredHostSubscription
    && !store.user.host_active
  ) {
    return next({
      name: 'CheckoutPlans',
      query: {
        product: 'host'
      }
    })
  }

  if (
    token
    && to.name !== 'CheckoutPlans'
    && to.meta.requiredCloakingSubscription
    && !store.user?.cloakup_active
  ) {
    return next({
      name: 'CheckoutPlans',
      query: {
        product: 'cloakup'
      }
    })
  }

  return next()
})

export default router
