<script setup>
import { ComputerDesktopIcon, DevicePhoneMobileIcon, DeviceTabletIcon } from '@heroicons/vue/24/solid'
import { useI18n } from 'vue-i18n'

import CheckboxGroup from '@/components/CheckboxGroup.vue'

const model = defineModel({
  required: true,
})
const { t } = useI18n()

const devices = [
  {
    id: 'smartphone',
    value: 'smartphone',
    label: t('common.devices.smartphone'),
    icon: DevicePhoneMobileIcon,
  },
  {
    id: 'tablet',
    value: 'tablet',
    label: t('common.devices.tablet'),
    icon: DeviceTabletIcon,
  },
  {
    id: 'desktop',
    value: 'desktop',
    label: t('common.devices.desktop'),
    icon: ComputerDesktopIcon,
  },
]
</script>

<template>
  <CheckboxGroup
    v-model="model"
    :options="devices"
  />
</template>
