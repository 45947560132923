<script setup>
import { useI18n } from 'vue-i18n'
import { useHead } from '@unhead/vue'

const { t } = useI18n()

useHead({
  title: t('pages.page_not_found.title')
})
</script>

<template>
  <div class="shrink-0 pt-10 sm:pt-16">
    <a
      href="/"
      class="inline-flex"
    >
      <span class="sr-only">Cloakup</span>
      <img
        class="h-12 w-auto dark:invert"
        src="../assets/img/logo-black.svg"
        alt="Cloakup logo "
      >
    </a>
  </div>
  <div class="my-auto shrink-0 py-16 sm:py-32">
    <p class="text-sm font-semibold uppercase tracking-wide ">
      404 error
    </p>
    <h1
      class="mt-2 text-4xl font-extrabold tracking-tight  sm:text-5xl"
    >
      {{ $t('page_not_found') }}
    </h1>
    <p class="mt-2 text-base text-muted-foreground">
      {{ $t('page_not_found_description') }}
    </p>
  </div>
</template>
