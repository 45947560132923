<script setup>
import { computed } from 'vue'
import { ChevronRightIcon, CloudIcon, Cog6ToothIcon, FunnelIcon, HomeIcon, StarIcon } from '@heroicons/vue/24/solid'
import { useI18n } from 'vue-i18n'
import {
  Collapsible,
  CollapsibleContent,
  CollapsibleTrigger,
} from '@/components/ui/collapsible'
import { useUserStore } from '@/stores/user'

const userStore = useUserStore()
const { t } = useI18n()

const sidebarNavigation = computed(() => {
  const cloakup = [
    {
      name: t('common.campaigns'),
      href: 'Campaigns',
    },
    {
      name: t('common.requests'),
      href: 'Requests',
    },
    {
      name: t('common.reports'),
      href: 'Reports',
    },
  ]

  if (userStore.user?.custom_domain_enabled) {
    cloakup.push({
      name: t('common.domains'),
      href: 'Domains',
    })
  }

  return [
    {
      name: t('common.dashboard'),
      href: 'Dashboard',
      icon: HomeIcon
    },
    {
      name: t('common.traffic_filter'),
      icon: FunnelIcon,
      children: cloakup
    },
    {
      name: t('common.servers'),
      icon: CloudIcon,
      children: [
        {
          name: t('common.cloud_providers'),
          href: 'CloudProviders'
        },
        {
          name: t('pages.servers.title'),
          href: 'Servers'
        }
      ]
    },
    {
      name: t('common.plugins'),
      href: 'Plugins',
      icon: StarIcon
    },
    {
      name: t('common.settings'),
      href: 'Settings',
      icon: Cog6ToothIcon
    }
  ]
})
</script>

<template>
  <ul
    role="list"
    class="mx-2 space-y-1"
  >
    <li
      v-for="(item) in sidebarNavigation"
      :key="item.name"
    >
      <router-link
        v-if="!item.children"
        v-slot="{ isActive }"
        :to="{ name: item.href }"
        class="flex w-full items-center gap-x-3 rounded-md p-2 text-left text-sm font-semibold leading-6  hover:bg-muted"
        :class="[
          item.href?.includes($route.matched[0]?.name)
            ? 'bg-muted text-foreground'
            : 'text-muted-foreground',
        ]"
      >
        <component
          :is="item.icon"
          class="size-6 shrink-0"
          :class="[
            item.href?.includes($route.matched[0]?.name)
              ? 'bg-muted text-foreground'
              : 'text-muted-foreground',
          ]"
          aria-hidden="true"
        />
        {{ item.name }}
      </router-link>

      <Collapsible
        v-else
        v-slot="{ open }"
      >
        <CollapsibleTrigger
          class="flex w-full items-center gap-x-3 rounded-md p-2 text-left text-sm font-semibold leading-6 hover:bg-muted"
          :class="[
            item.children.some(child => child.href === $route.matched[0]?.name)
              ? 'bg-muted text-foreground'
              : 'text-muted-foreground',
          ]"
        >
          <component
            :is="item.icon"
            class="size-6 shrink-0"
            :class="[
              item.children.some(
                child => child.href === $route.matched[0]?.name,
              )
                ? 'bg-muted text-foreground'
                : 'text-muted-foreground',
            ]"
            aria-hidden="true"
          />
          {{ item.name }}
          <ChevronRightIcon
            class="ml-auto size-5 shrink-0 duration-100"
            :class="[
              open ? 'rotate-90' : 'text-muted-foreground',
            ]"
            aria-hidden="true"
          />
        </CollapsibleTrigger>
        <CollapsibleContent>
          <ul class="mt-1 px-2">
            <li
              v-for="subItem in item.children"
              :key="subItem.name"
              class="hover:rounded-md hover:bg-muted"
            >
              <router-link
                :to="{ name: subItem.href }"
                active-class="text-primary font-semibold"
                class="block rounded-md py-2 pl-9 pr-2 text-sm leading-6 text-muted-foreground"
              >
                {{ subItem.name }}
              </router-link>
            </li>
          </ul>
        </CollapsibleContent>
      </Collapsible>
    </li>
  </ul>
</template>
