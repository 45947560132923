<script setup>
import {
  Switch,
  SwitchGroup,
  SwitchLabel
} from '@headlessui/vue'
import { useI18n } from 'vue-i18n'

const { t } = useI18n()

const enabled = defineModel({
  required: true
})

function getTextType(type) {
  return type ? t('common.allow') : t('common.block')
}
</script>

<template>
  <SwitchGroup
    as="div"
    class="flex items-center"
  >
    <Switch
      v-model="enabled"
      class="relative inline-flex h-6 w-11 shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2  focus:ring-offset-2"
      :class="[
        enabled
          ? 'bg-success focus:ring-success'
          : 'bg-destructive focus:ring-destructive',
      ]"
    >
      <span class="sr-only">Use setting</span>
      <span
        class="pointer-events-none relative inline-block size-5 rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out"
        :class="[
          enabled ? 'translate-x-5' : 'translate-x-0',
        ]"
      >
        <span
          class="absolute inset-0 flex size-full items-center justify-center transition-opacity"
          :class="[
            enabled
              ? 'opacity-0 duration-100 ease-out'
              : 'opacity-100 duration-200 ease-in',
          ]"
          aria-hidden="true"
        >
          <svg
            class="size-3 text-destructive"
            fill="none"
            viewBox="0 0 12 12"
          >
            <path
              d="M4 8l2-2m0 0l2-2M6 6L4 4m2 2l2 2"
              stroke="currentColor"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </span>
        <span
          class="absolute inset-0 flex size-full items-center justify-center transition-opacity"
          :class="[
            enabled
              ? 'opacity-100 duration-200 ease-in'
              : 'opacity-0 duration-100 ease-out',
          ]"
          aria-hidden="true"
        >
          <svg
            class="size-3 text-success"
            fill="currentColor"
            viewBox="0 0 12 12"
          >
            <path
              d="M3.707 5.293a1 1 0 00-1.414 1.414l1.414-1.414zM5 8l-.707.707a1 1 0 001.414 0L5 8zm4.707-3.293a1 1 0 00-1.414-1.414l1.414 1.414zm-7.414 2l2 2 1.414-1.414-2-2-1.414 1.414zm3.414 2l4-4-1.414-1.414-4 4 1.414 1.414z"
            />
          </svg>
        </span>
      </span>
    </Switch>
    <div class="ml-3">
      <SwitchLabel
        as="span"
        class="text-sm font-medium"
      >
        {{ getTextType(enabled) }}
      </SwitchLabel>
    </div>
  </SwitchGroup>
</template>
