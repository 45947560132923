<script setup>
import { reactive, ref, watch } from 'vue'
import { toast } from 'vue-sonner'
import { PlusIcon } from '@heroicons/vue/24/solid'
import { useI18n } from 'vue-i18n'
import { cloudProviderService } from '@/services/cloud-provider'
import Button from '@/components/ui/button/Button.vue'
import Input from '@/components/ui/input/Input.vue'
import Label from '@/components/ui/label/Label.vue'
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger
} from '@/components/ui/dialog'
import Field from '@/components/ui/field/Field.vue'
import Description from '@/components/ui/description/Description.vue'

const props = defineProps({
  cloudProviders: {
    type: Object,
    required: false,
    default: () => ({
      name: '',
      provider: 'digitalocean',
      api_key: ''
    })
  },
})
const emit = defineEmits(['created'])
const { t } = useI18n()

const isOpen = ref(false)
const isLoading = ref(false)
const errors = ref({})
const form = reactive({
  name: 'digitalocean',
  provider: props.cloudProviders?.provider,
  apiKey: props.cloudProviders?.api_key
})

function onSubmit() {
  const payload = {
    name: form.name,
    provider: 'digitalocean',
    api_key: form.apiKey
  }

  createCloudProviderModal(payload)
}

async function createCloudProviderModal(data) {
  isLoading.value = true
  errors.value = {}

  try {
    await cloudProviderService.create(data)

    toast.success(t('components.create_cloud_provider_modal.toast.success.title'), {
      description: t('components.create_cloud_provider_modal.toast.success.description'),
    })

    emit('created')
    isOpen.value = false
  } catch (e) {
    if (e.response?.status === 422) {
      errors.value = e.response.data.data
    }

    toast.error(e.response?.data?.message, {
      description: e.response?.data?.action || e.message,
    })
  } finally {
    isLoading.value = false
  }
}

watch(isOpen, (v) => {
  if (!v) {
    form.name = ''
    form.provider = ''
    form.apiKey = ''
  }
}, {
  immediate: true
})
</script>

<template>
  <div>
    <Dialog v-model:open="isOpen">
      <DialogTrigger>
        <Button>
          <PlusIcon class="mr-2 size-5" />
          {{ $t('components.create_cloud_provider_modal.button') }}
        </Button>
      </DialogTrigger>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>
            {{ $t('components.create_cloud_provider_modal.title') }}
          </DialogTitle>
          <DialogDescription>
            {{ $t('components.create_cloud_provider_modal.description') }}
          </DialogDescription>
        </DialogHeader>

        <div class="mt-4">
          <form
            id="create-provider-form"
            @submit.prevent="onSubmit"
          >
            <fieldset :disabled="isLoading">
              <div class="space-y-4">
                <div>
                  <Field>
                    <Label
                      required
                      for="name"
                    >
                      {{ $t('common.name') }}
                    </Label>
                    <Input
                      id="name"
                      v-model="form.name"
                      name="name"
                      type="text"
                      required
                    />
                  </Field>
                </div>
                <div>
                  <Field>
                    <Label
                      required
                      for="provider_api_key"
                    >
                      {{ $t('components.create_cloud_provider_modal.api_key') }}
                    </Label>
                    <Input
                      id="provider_api_key"
                      v-model="form.apiKey"
                      name="provider_api_key"
                      type="text"
                      required
                    />
                    <Description>
                      {{ $t('components.create_cloud_provider_modal.api_key_description') }}
                      <a
                        href="https://cloud.digitalocean.com/account/api/tokens"
                        target="_blank"
                        rel="noopener noreferrer"
                        class="font-semibold text-blue-600 underline"
                      >
                        {{ $t('components.create_cloud_provider_modal.api_key_link') }}
                      </a>
                    </Description>
                  </Field>
                </div>
              </div>
            </fieldset>
          </form>
        </div>

        <DialogFooter class="mt-4">
          <DialogClose as-child>
            <Button
              type="button"
              variant="secondary"
              :disabled="isLoading"
            >
              {{ $t('common.cancel') }}
            </Button>
          </DialogClose>
          <Button
            :loading="isLoading"
            type="submit"
            form="create-provider-form"
            :disabled="isLoading"
          >
            {{ $t('common.save') }}
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  </div>
</template>
