<script setup>
import { computed } from 'vue'
import { Label } from 'radix-vue'
import { cn } from '@/lib/utils'

const props = defineProps({
  for: {
    type: String,
    required: false
  },
  asChild: {
    type: Boolean,
    required: false
  },
  as: {
    type: null,
    required: false
  },
  class: {
    type: null,
    required: false
  },
  required: {
    type: Boolean,
    default: false
  },
})

const delegatedProps = computed(() => {
  const { required:__ ,class: _, ...delegated } = props

  return delegated
})
</script>

<template>
  <Label
    v-bind="delegatedProps"
    :class="
      cn(
        'block text-sm font-medium leading-6 peer-disabled:cursor-not-allowed peer-disabled:opacity-70 text-label-foreground',
        props.class,
      )
    "
  >
    <slot />
    <span
      v-if="required"
      class="after:ml-0.5 after:text-red-500 after:content-['*']"
    />

  </Label>
</template>
