export function dirto() {
  const campaign = import.meta.env.VITE_DIRTO_CAMPAIGN

  function setup() {
    if (window.location.hostname === 'localhost') {
      return
    }

    const scriptContent = `!function(j,u,s,t,r,a,c,k,i,n,g){
        if(j.jt)return;a=j.jt=function(){a.dispatch?
        a.dispatch.apply(a,arguments):a.q.push(arguments)};
        a.c=r;a.q=[];c=u.createElement(s);c.async=!0;
        c.src=t;k=u.getElementsByTagName(s)[0];
        k.parentNode.insertBefore(c,k);}(window,document,'script',
        'https://static.justtracking.io/js/events.min.js', '${campaign}');
        `

    const script = document.createElement('script')
    script.innerHTML = scriptContent
    document.head.appendChild(script)
  }

  function track(event, data) {
    if (window.location.hostname === 'localhost') {
      return
    }

    jt('track', event, data)
  }

  return {
    setup,
    track
  }
}
