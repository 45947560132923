<script setup>
import { NumberFieldDecrement, useForwardProps } from 'radix-vue'
import { computed } from 'vue'
import { MinusIcon } from '@heroicons/vue/24/solid'
import { cn } from '@/lib/utils'

const props = defineProps({
  class: {
    type: null,
    required: false
  },
})

const delegatedProps = computed(() => {
  const { class: _, ...delegated } = props

  return delegated
})

const forwarded = useForwardProps(delegatedProps)
</script>

<template>
  <NumberFieldDecrement
    v-bind="forwarded"
    :class="cn('absolute top-1/2 -translate-y-1/2 left-0 p-3 disabled:cursor-not-allowed disabled:opacity-20', props.class)"
  >
    <slot>
      <MinusIcon class="size-4" />
    </slot>
  </NumberFieldDecrement>
</template>
