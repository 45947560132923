<script setup>
import { reactive, ref } from 'vue'
import { toast } from 'vue-sonner'
import { useI18n } from 'vue-i18n'
import { userService } from '@/services/user'
import { useUserStore } from '@/stores/user'
import Button from '@/components/ui/button/Button.vue'
import Label from '@/components/ui/label/Label.vue'
import Input from '@/components/ui/input/Input.vue'
import ErrorMessage from '@/components/ui/error-message/ErrorMessage.vue'
import Field from '@/components/ui/field/Field.vue'
import PhoneInput from '@/components/common/PhoneInput.vue'

const { t } = useI18n()
const store = useUserStore()

const isLoading = ref(false)
const errors = ref({})
const form = reactive({
  name: store.user.name,
  email: store.user.email,
  phone: store.user.phone,
})

function submit() {
  const payload = {
    name: form.name,
    phone: form.phone?.replace(/\D/g, ''),
  }

  updateAccount(payload)
}

async function updateAccount(user) {
  isLoading.value = true
  errors.value = {}
  try {
    await userService.updateProfile(user)

    toast.success(t('components.update_profile.toast.success.title'), {
      description: t('components.update_profile.toast.success.description'),
    })
  } catch (e) {
    if (e.response?.status === 422) {
      errors.value = e.response.data?.data
    }

    toast.error(e.response?.data?.message, {
      description: e.response?.data?.action || e.message,
    })
  } finally {
    isLoading.value = false
  }
}
</script>

<template>
  <form @submit.prevent="submit">
    <fieldset :disabled="isLoading">
      <div class="grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
        <div class="col-span-6 sm:col-span-3">
          <Field>
            <Label
              required
              for="name"
            >
              {{ $t("common.name") }}
            </Label>
            <Input
              id="name"
              v-model="form.name"
              type="text"
              name="name"
              required
            />
            <ErrorMessage :message="errors?.name" />
          </Field>
        </div>

        <div class="col-span-6 sm:col-span-3">
          <Field>
            <Label for="phone">
              {{ $t("common.phone") }}
            </Label>
            <PhoneInput
              id="phone"
              v-model="form.phone"
              type="tel"
              name="phone"
              autocomplete="tel"
              placeholder="(00) 00000-0000"
            />
          </Field>
        </div>

        <div class="col-span-6 sm:col-span-3">
          <Field>
            <Label
              required
              for="email"
            >
              {{ $t("common.email") }}
            </Label>
            <Input
              id="email"
              v-model="form.email"
              disabled
              type="email"
              name="email"
              autocomplete="email"
              required
            />
          </Field>
        </div>
      </div>
      <div class="mt-6 flex items-center justify-end">
        <Button
          type="submit"
          :loading="isLoading"
        >
          {{ $t('common.save') }}
        </Button>
      </div>
    </fieldset>
  </form>
</template>
