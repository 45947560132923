<script setup>
import { ref, watch } from 'vue'
import BaseSpinner from '@/components/base/BaseSpinner.vue'
import FormStepCount from '@/components/common/FormStepCount.vue'
import { formatCurrency } from '@/helpers/currency'
import { subscriptionService } from '@/services/subscription'

const props = defineProps({
  currentPlan: {
    type: Object,
    required: false
  },
  newPlan: {
    type: Object,
    required: true
  },
  currentSubscription: {
    type: Object,
    required: false
  }
})

const upgradeAmount = ref(null)
const isLoading = ref(false)
const error = ref(false)

async function fetchUpgradeAmount(subscriptionId, planId) {
  error.value = false
  try {
    isLoading.value = true
    const { data } = await subscriptionService.getUpgradeAmount(
      subscriptionId,
      planId
    )
    upgradeAmount.value = data.amount
    isLoading.value = false
  } catch (e) {
    error.value = true
  } finally {
    isLoading.value = false
  }
}

watch(
  () => props.newPlan,
  () => {
    fetchUpgradeAmount(props.currentSubscription.id, props.newPlan.id)
  },
  {
    deep: true,
    immediate: true
  }
)
</script>

<template>
  <div class="flex gap-2">
    <FormStepCount step="2" />
    <div>
      <label class="text-base font-medium ">
        {{ $t("components.upgrade_resume.title") }}
      </label>
      <p class="text-sm leading-5 text-muted-foreground">
        {{ $t("components.upgrade_resume.description") }}
      </p>
    </div>
  </div>
  <div class="mt-5 space-y-4">
    <div v-if="isLoading">
      <BaseSpinner />
    </div>
    <div v-else-if="upgradeAmount && !error">
      <span class="text-sm text-muted-foreground">
        {{ $t("components.upgrade_resume.total") }}
      </span>
      <h3 class="text-lg font-semibold">
        {{ formatCurrency(upgradeAmount, newPlan.currency) }}
      </h3>
    </div>
    <div v-else>
      <h3>
        {{ $t("components.upgrade_resume.total_fallback") }}
      </h3>
    </div>
  </div>
</template>
