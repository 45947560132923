import client from '../plugins/axios'

export const walletService = {
  resource: '/wallet',
  balance(params = {}) {
    return client.get(`${this.resource}/balance`, {
      params
    })
  },
  withdraw(data) {
    return client.post(`${this.resource}/withdraw`, data)
  },
  addPixKey(data) {
    return client.post(`${this.resource}/pix`, data)
  }
}
