<script setup>
import { reactive, ref, watch } from 'vue'
import { toast } from 'vue-sonner'
import { useI18n } from 'vue-i18n'
import { useHead } from '@unhead/vue'
import { useRoute, useRouter } from 'vue-router'
import CampaignAdvancedForm from '@/components/partials/campaign/CampaignAdvancedForm.vue'
import PageHeading from '@/components/common/PageHeading.vue'
import CampaignBasicForm from '@/components/partials/campaign/CampaignBasicForm.vue'
import { useCampaignStore } from '@/stores/campaign'
import CampaignCreateBreadcrumb from '@/components/partials/campaign/CampaignCreateBreadcrumb.vue'

const { t } = useI18n()
useHead({
  title: t('pages.campaign.create.title')
})
const router = useRouter()
const route = useRoute()
const campaignStore = useCampaignStore()

const isSaving = ref(false)
const errors = ref({})
const form = reactive({
  name: '',
  active: true,
  pages: {
    white: {
      type: 'content',
      content: ''
    },
    offer: {
      type: 'content',
      content: ''
    },
    offers: [
      {
        content: '',
        share: 100,
        type: 'content'
      }
    ]
  },
  filters: {
    deny_always: false,
    facebook: false,
    google: false,
    bots: false,
    tiktok: false,
    proxy: false,
    kwai: false,
    adspy: false,
    cloakup_ai: false,
    geolocation: {
      allow: true,
      countries: []
    },
    device: {
      allow: true,
      devices: []
    },
    referer: {
      block_null: false,
      allow: true,
      domains: []
    },
    query: {
      allow: true,
      params: {},
      condition: 'some',
      remove_params: false
    },
    domain: {
      allow: true,
      domains: []
    },
    browser_language: {
      allow: true,
      languages: []
    },
    isp: {
      allow: true,
      isps: []
    },
    os: {
      allow: true,
      os: []
    },
    user_agent: {
      allow: true,
      user_agents: []
    },
    blacklist: [],
    whitelist: []
  },
  alias: null,
  domain: null
})
const mode = ref(route.query.mode || 'basic')

async function createCampaign(campaign) {
  errors.value = {}
  isSaving.value = true
  try {
    const data = await campaignStore.create(campaign)

    toast.success(t('pages.campaign.create.toast.success.title'), {
      description: t('pages.campaign.create.toast.success.description'),
    })

    campaignStore.toggleParamsConfig()

    await router.push({
      name: 'Campaign',
      params: {
        id: data.id
      },
    })
  } catch (e) {
    if (e.response?.status === 422) {
      errors.value = e.response.data.data
    }

    toast.error(e.response?.data?.message, {
      description: e.response?.data?.action || e.message,
    })
  } finally {
    isSaving.value = false
  }
}

watch(() => route.query.mode, () => {
  mode.value = route.query.mode || 'advanced'
})
</script>

<template>
  <div class="space-y-5">
    <div class="space-y-2">
      <CampaignCreateBreadcrumb />

      <PageHeading :title="$t('pages.campaign.create.title')" />
    </div>

    <CampaignBasicForm
      v-if="mode === 'basic'"
      :is-loading="isSaving"
      :errors="errors"
      :campaign="form"
      @submit="createCampaign"
    />

    <CampaignAdvancedForm
      v-else
      :is-loading="isSaving"
      :errors="errors"
      :campaign="form"
      @submit="createCampaign"
    />
  </div>
</template>
