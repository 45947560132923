<script setup>
import { computed, ref, watch } from 'vue'
import { toast } from 'vue-sonner'
import { useRoute, useRouter } from 'vue-router'
import { useI18n } from 'vue-i18n'
import { useHead } from '@unhead/vue'
import PageHeading from '@/components/common/PageHeading.vue'
import DomainCreateModal from '@/components/partials/domains/DomainCreateModal.vue'
import { domainService } from '@/services/domain'
import EmptyState from '@/components/common/EmptyState.vue'
import { Tabs, TabsList, TabsTrigger } from '@/components/ui/tabs'
import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  BreadcrumbList,
  BreadcrumbPage,
  BreadcrumbSeparator,
} from '@/components/ui/breadcrumb'
import { useUserStore } from '@/stores/user'
import DomainCard from '@/components/partials/domains/DomainCard.vue'
import { Card, CardContent } from '@/components/ui/card'
import { Skeleton } from '@/components/ui/skeleton'

const { t } = useI18n()
useHead({
  title: t('pages.domains.title')
})

const userStore = useUserStore()
const route = useRoute()
const router = useRouter()

const domains = ref([])
const isLoading = ref(false)
const hasError = ref(false)

const count = computed(() => domains.value.length)

async function fetchDomains(params) {
  isLoading.value = true
  try {
    const { data } = await domainService.findAll(params)
    domains.value = data.data
  } catch (e) {
    hasError.value = true

    toast.error(e.response?.data?.message, {
      description: e.response?.data?.action || e.message,
    })
  } finally {
    isLoading.value = false
  }
}

function onStatusChanged(status) {
  router.push({
    query: {
      ...route.query,
      verified: status === 'all' ? undefined : status
    }
  })
}

function refresh() {
  fetchDomains({
    page: 1,
    limit: 100
  })
}

watch(
  () => [route.query],
  () => {
    fetchDomains({
      page: route.query.page,
      limit: route.query.limit,
      verified: route.query.verified
    })
  },
  {
    immediate: true
  }
)
</script>

<template>
  <div class="space-y-5">
    <div class="space-y-2">
      <Breadcrumb>
        <BreadcrumbList>
          <BreadcrumbItem>
            <BreadcrumbLink as-child>
              <RouterLink to="/">
                {{ $t("common.dashboard") }}
              </RouterLink>
            </BreadcrumbLink>
          </BreadcrumbItem>

          <BreadcrumbSeparator />

          <BreadcrumbItem>
            <BreadcrumbPage>
              {{ $t("pages.domains.title") }}
            </BreadcrumbPage>
          </BreadcrumbItem>
        </BreadcrumbList>
      </Breadcrumb>

      <PageHeading
        :title="`${$t('pages.domains.title')} (${count}/${userStore?.cloakupSubscription?.plan?.domains ?? 0})`"
      >
        <template #actions>
          <DomainCreateModal @created="refresh" />
        </template>
      </PageHeading>
    </div>

    <Tabs
      :default-value="$route.query.verified || 'all'"
      class="w-[400px]"
      @update:model-value="onStatusChanged"
    >
      <TabsList>
        <TabsTrigger value="all">
          {{ $t('common.all') }}
        </TabsTrigger>
        <TabsTrigger value="true">
          {{ $t('pages.domains.verified') }}
        </TabsTrigger>
        <TabsTrigger value="false">
          {{ $t('pages.domains.unverified') }}
        </TabsTrigger>
      </TabsList>
    </Tabs>

    <div
      v-if="isLoading"
      class="space-y-2"
    >
      <div
        v-for="i in 5"
        :key="i"
        class="flex flex-col space-y-3"
      >
        <Skeleton class="h-[70px] w-full rounded-xl" />
      </div>
    </div>
    <Card v-else-if="!domains.length">
      <CardContent>
        <EmptyState
          :title="$t('pages.domains.empty_state.title')"
          :description="$t('pages.domains.empty_state.description')"
        />
      </CardContent>
    </Card>
    <ul
      v-else
      class="space-y-2"
    >
      <li
        v-for="domain in domains"
        :key="domain.name"
      >
        <DomainCard
          :domain="domain"
          @deleted="refresh"
          @verified="refresh"
        />
      </li>
    </ul>
  </div>
</template>
