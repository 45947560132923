import Dashboard from '@/views/Dashboard.vue'

export default [
  {
    path: '/',
    name: 'Dashboard',
    component: Dashboard,
    meta: {
      layout: 'default',
      requiresAuth: true
    }
  }
]
