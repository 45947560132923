<script setup>
import { ref } from 'vue'
import { toast } from 'vue-sonner'
import { ExternalLinkIcon } from '@radix-icons/vue'
import Button from '@/components/ui/button/Button.vue'
import { campaignService } from '@/services/campaign.js'
import { useCampaignStore } from '@/stores/campaign.js'
import CodeBlock from '@/components/common/CodeBlock.vue'

const campaignStore = useCampaignStore()

const isLoading = ref(false)
const script = ref(undefined)

async function downloadJavascriptIntegration() {
  isLoading.value = true
  try {
    const { data } = await campaignService.downloadFile(
      campaignStore.campaign.id,
      'javascript'
    )
    script.value = data.file
  } catch (e) {
    toast.error(e.response?.data?.message, {
      description: e.response?.data?.action || e.message,
    })
  } finally {
    isLoading.value = false
  }
}
</script>

<template>
  <div class="py-5">
    <ul class="list-inside list-disc text-muted-foreground">
      <li>
        {{ $t('components.campaign_integration_javascript.copy_script') }}
      </li>

      <li>
        {{ $t('components.campaign_integration_javascript.add_script') }}
      </li>

      <li>
        {{ $t('components.campaign_integration_javascript.use_link') }}
      </li>
    </ul>

    <div class="mt-2 flex flex-col">
      <div class="mt-5 flex flex-col gap-2">
        <CodeBlock
          v-if="script"
          :code="script"
          lang="html"
          class="max-w-md"
        />

        <div>
          <Button
            v-if="!script"
            :loading="isLoading"
            @click="downloadJavascriptIntegration"
          >
            {{
              $t('components.campaign_integration_javascript.show_script')
            }}
          </Button>
        </div>
      </div>
    </div>
    <div class="mt-2 flex shrink-0">
      <a
        href="https://help.cloakup.me/pt-br/article/como-integrar-via-javascript-13t251i"
        class="flex items-center gap-2 text-sm text-blue-500 underline"
        target="_blank"
      >
        <ExternalLinkIcon class="size-4" />
        {{ $t('components.campaign_integration_javascript.step_by_step') }}
      </a>
    </div>
  </div>
</template>
