import client from '../plugins/axios'

export const authService = {
  resource: '/auth',
  signIn(data) {
    return client.post(`${this.resource}/sign-in`, data)
  },
  me() {
    return client.get(`${this.resource}/me`)
  },
  signOut() {
    return client.post(`${this.resource}/sign-out`)
  },
  signUp(data) {
    return client.post(`${this.resource}/sign-up`, {
      ...data
    })
  },
  sendEmailForgotPassword(email) {
    return client.post(`${this.resource}/forgot-password`, {
      email
    })
  },
  resetPassword(data) {
    return client.post(`${this.resource}/reset-password`, data)
  },
  google(credential) {
    return client.post(`${this.resource}/google`, credential)
  }
}
