<script setup>
import { BanknotesIcon, CreditCardIcon, StarIcon, UserCircleIcon } from '@heroicons/vue/24/solid'
import { useI18n } from 'vue-i18n'
import { computed } from 'vue'
import { useHead } from '@unhead/vue'
import BaseSpinner from '@/components/base/BaseSpinner.vue'
import BaseTab from '@/components/base/BaseTab.vue'
import PageHeading from '@/components/common/PageHeading.vue'
import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  BreadcrumbList,
  BreadcrumbPage,
  BreadcrumbSeparator,
} from '@/components/ui/breadcrumb'

const { t } = useI18n()
useHead({
  title: t('pages.settings.title')
})

const tabs = computed(() => [
  {
    name: t('pages.settings.tabs.profile'),
    href: {
      name: 'Profile'
    },
    icon: UserCircleIcon
  },
  {
    name: t('pages.settings.tabs.subscriptions'),
    href: {
      name: 'Subscription'
    },
    icon: StarIcon
  },
  {
    name: t('pages.settings.tabs.invoices'),
    href: {
      name: 'Invoices'
    },
    icon: BanknotesIcon
  },
  {
    name: t('pages.settings.tabs.payment_methods'),
    href: {
      name: 'PaymentMethod'
    },
    icon: CreditCardIcon
  }
])
</script>

<template>
  <div class="space-y-5">
    <div class="space-y-2">
      <Breadcrumb>
        <BreadcrumbList>
          <BreadcrumbItem>
            <BreadcrumbLink as-child>
              <RouterLink to="/">
                {{ $t('common.dashboard') }}
              </RouterLink>
            </BreadcrumbLink>
          </BreadcrumbItem>

          <BreadcrumbSeparator />

          <BreadcrumbItem>
            <BreadcrumbPage>
              {{ $t("pages.settings.breadcrumb.settings") }}
            </BreadcrumbPage>
          </BreadcrumbItem>
        </BreadcrumbList>
      </Breadcrumb>

      <PageHeading
        :title="t('pages.settings.title')"
      />

      <BaseTab :tabs="tabs" />
    </div>
    <router-view v-slot="{ Component }">
      <Suspense :timeout="0">
        <component :is="Component" />
        <template #fallback>
          <div class="flex min-h-screen items-center justify-center">
            <BaseSpinner />
          </div>
        </template>
      </Suspense>
    </router-view>
  </div>
</template>
