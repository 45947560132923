<script setup>
import { computed } from 'vue'
import { ArrowUpRightIcon, CloudArrowDownIcon, PhotoIcon, RectangleGroupIcon } from '@heroicons/vue/24/solid'
import { LockClosedIcon } from '@radix-icons/vue'
import { useI18n } from 'vue-i18n'
import { useHead } from '@unhead/vue'
import Badge from '@/components/ui/badge/Badge.vue'
import PageHeading from '@/components/common/PageHeading.vue'
import { useUserStore } from '@/stores/user'
import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  BreadcrumbList,
  BreadcrumbPage,
  BreadcrumbSeparator,
} from '@/components/ui/breadcrumb'

const store = useUserStore()
const { t } = useI18n()
useHead({
  title: t('pages.plugins.title')
})

const user = computed(() => store.user)
const isCreated = computed(() => user.value?.status === 'created')

const openIn = computed(() => {
  const timestampAtual = Date.now()
  const timestampCriacao = new Date(
    store.cloakupSubscription?.created_at
  ).getTime()
  const milissegundosRestantes
    = 7 * 24 * 60 * 60 * 1000 - (timestampAtual - timestampCriacao)
  const diasRestantes = Math.ceil(
    milissegundosRestantes / (24 * 60 * 60 * 1000)
  )
  return diasRestantes
})

function hasPermission(permission) {
  return user.value?.subscriptions?.some((s) => {
    return s.plan.features?.some(f => f === permission)
  })
}

const actions = [
  {
    title: t('pages.plugins.safe_page_generator.title'),
    description: t('pages.plugins.safe_page_generator.description'),
    href: {
      name: 'SafePageGeneratorv1'
    },
    icon: RectangleGroupIcon,
    iconForeground: 'text-teal-700 dark:text-teal-400',
    iconBackground: 'bg-teal-50 dark:bg-teal-500/10',
    permission: 'safe_page_generator',

    soon: false
  },

  {
    title: t('pages.plugins.page_downloader.title'),
    description: t('pages.plugins.page_downloader.description'),
    href: {
      name: 'PageDownloader'
    },
    icon: CloudArrowDownIcon,
    iconForeground: 'text-sky-700 dark:text-sky-400',
    iconBackground: 'bg-sky-50 dark:bg-sky-500/10',
    permission: 'page_downloader',
    soon: false
  },
  {
    title: t('pages.plugins.crypto_text.title'),
    description: t('pages.plugins.crypto_text.description'),
    href: {
      name: 'EncryptText'
    },
    icon: LockClosedIcon,
    iconForeground: 'text-purple-700 dark:text-purple-400',
    iconBackground: 'bg-purple-50 dark:bg-purple-500/10',
    permission: 'crypto_text',
    soon: false
  },
  {
    title: t('pages.plugins.meta_remover.title'),
    description: t('pages.plugins.meta_remover.description'),
    href: {
      name: 'MetaRemover'
    },
    icon: PhotoIcon,
    iconForeground: 'text-sky-700 dark:text-sky-400',
    iconBackground: 'bg-sky-50 dark:bg-sky-500/10',
    permission: 'meta_remover',
  }
]
</script>

<template>
  <div class="space-y-5">
    <div class="space-y-2">
      <Breadcrumb>
        <BreadcrumbList>
          <BreadcrumbItem>
            <BreadcrumbLink as-child>
              <RouterLink to="/">
                {{ $t('common.dashboard') }}
              </RouterLink>
            </BreadcrumbLink>
          </BreadcrumbItem>

          <BreadcrumbSeparator />

          <BreadcrumbItem>
            <BreadcrumbPage>
              {{
                $t('pages.plugins.title')
              }}
            </BreadcrumbPage>
          </BreadcrumbItem>
        </BreadcrumbList>
      </Breadcrumb>

      <PageHeading
        :title="$t('pages.plugins.title')"
      />
    </div>

    <div>
      <div
        class="divide-y divide-border overflow-hidden rounded-lg border sm:grid sm:grid-cols-2 sm:gap-px sm:divide-y-0"
      >
        <div
          v-for="(action, actionIdx) in actions"
          :key="action.title"
          class="group relative bg-background p-6 focus-within:ring-2 focus-within:ring-inset focus-within:ring-primary"
          :class="[
            actionIdx === 0
              ? 'rounded-t-lg sm:rounded-tr-none'
              : '',
            actionIdx === 1 ? 'sm:rounded-tr-lg' : '',
            actionIdx === actions.length - 2 ? 'sm:rounded-bl-lg' : '',
            actionIdx === actions.length - 1
              ? 'rounded-b-lg sm:rounded-bl-none'
              : '',
          ]"
        >
          <div>
            <span
              class="inline-flex rounded-lg p-3 ring-4 ring-background"
              :class="[
                action.iconBackground,
                action.iconForeground,
              ]"
            >
              <component
                :is="action.icon"
                class="size-6"
                aria-hidden="true"
              />
            </span>
          </div>
          <div class="mt-8">
            <h3 class="text-lg font-medium">
              <div v-if="action.href && !action.soon">
                <router-link
                  :to="
                    hasPermission(action.permission)
                      ? action.href
                      : isCreated
                        ? {
                          name: 'CheckoutPlans',
                          params: {
                            id: store.cloakupSubscription?.id,
                          },
                        }
                        : {
                          name: 'upgrade',
                          params: {
                            id: store.cloakupSubscription?.id,
                          },
                        }
                  "
                >
                  <span
                    class="absolute inset-0"
                    aria-hidden="true"
                  />
                  {{ action.title }}
                </router-link>
              </div>
              <div v-else>
                <span
                  class="absolute inset-0"
                  aria-hidden="true"
                />
                {{ action.title }}
              </div>
            </h3>
            <p class="mt-2 text-sm text-muted-foreground">
              {{ action.description }}
            </p>
          </div>
          <span
            class="pointer-events-none absolute right-6 top-6 "
            aria-hidden="true"
          >
            <div v-if="isCreated">
              <Badge variant="success">
                {{ $t('common.subscribe') }}
              </Badge>
            </div>
            <div v-else-if="!hasPermission(action.permission)">
              <Badge variant="success">
                {{ $t('common.upgrade') }}
              </Badge>
            </div>
            <div
              v-else-if="
                hasPermission(action.permission)
                  && store.isTrial
                  && !store.user.plugin_active
              "
            >
              <Badge variant="info">
                {{ $t('pages.plugins.open_in') }} {{ openIn }} {{ $t('pages.plugins.days') }}
              </Badge>
            </div>
            <div v-else-if="hasPermission(action.permission) && !store.isTrial">
              <ArrowUpRightIcon class="size-5" />
            </div>
          </span>
        </div>
      </div>
    </div>
  </div>
</template>
