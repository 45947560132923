<script setup>
import { ExclamationTriangleIcon } from '@heroicons/vue/24/solid'
import { computed } from 'vue'
import { Alert, AlertDescription, AlertTitle } from '@/components/ui/alert'
import { formatCurrency } from '@/helpers/currency'

const props = defineProps({
  additionalCost: {
    type: Number,
    required: true
  },
  usage: {
    type: Number,
    required: true
  }
})

const totalAdditionalCost = computed(() => {
  return (
    props.usage
    * (Number(props.additionalCost) / 1000)
  )
})
</script>

<template>
  <Alert
    variant="destructive"
  >
    <ExclamationTriangleIcon class="size-4" />
    <AlertTitle>
      {{ $t('components.subscription_additional_cost_alert.title') }}
    </AlertTitle>
    <AlertDescription>
      <p>
        {{ $t('components.subscription_additional_cost_alert.description') }}
        <strong>{{ formatCurrency(totalAdditionalCost) }}</strong>.
      </p>
      <p>
        {{ $t('components.subscription_additional_cost_alert.button') }}
      </p>
    </AlertDescription>
  </Alert>
</template>
