<script setup>
import { BuildingLibraryIcon, CursorArrowRaysIcon } from '@heroicons/vue/24/solid'
import { useI18n } from 'vue-i18n'
import { useHead } from '@unhead/vue'
import BaseTab from '@/components/base/BaseTab.vue'
import PageHeading from '@/components/common/PageHeading.vue'
import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  BreadcrumbList,
  BreadcrumbPage,
  BreadcrumbSeparator,
} from '@/components/ui/breadcrumb'

const { t } = useI18n()
useHead({
  title: t('pages.referral.title')
})

const tabs = [
  {
    name: 'Indicações',
    href: {
      name: 'dashboard.referral.index'
    },
    icon: CursorArrowRaysIcon
  },
  {
    name: 'Ganhos',
    href: {
      name: 'Transactions'
    },
    icon: BuildingLibraryIcon
  },
]
</script>

<template>
  <div class="space-y-5">
    <div class="space-y-2">
      <Breadcrumb>
        <BreadcrumbList>
          <BreadcrumbItem>
            <BreadcrumbLink as-child>
              <RouterLink to="/">
                {{ $t('common.dashboard') }}
              </RouterLink>
            </BreadcrumbLink>
          </BreadcrumbItem>

          <BreadcrumbSeparator />

          <BreadcrumbItem>
            <BreadcrumbPage>
              Indique e ganhe
            </BreadcrumbPage>
          </BreadcrumbItem>
        </BreadcrumbList>
      </Breadcrumb>
      <PageHeading
        :title="$t('pages.referral.title')"
      />
      <BaseTab :tabs="tabs" />
    </div>

    <router-view v-slot="{ Component }">
      <keep-alive>
        <component :is="Component" />
      </keep-alive>
    </router-view>
  </div>
</template>
