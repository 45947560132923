<script setup>
import { ref } from 'vue'
import { toast } from 'vue-sonner'
import { ExternalLinkIcon } from '@radix-icons/vue'
import { campaignService } from '@/services/campaign.js'
import { useCampaignStore } from '@/stores/campaign.js'
import Button from '@/components/ui/button/Button.vue'

const campaignStore = useCampaignStore()

const isLoading = ref(false)

async function downloadPHPIntegration() {
  isLoading.value = true
  try {
    const { data } = await campaignService.downloadFile(campaignStore.campaign.id, 'php')
    const file = `data:application/${data.type};base64,${data.file}`

    const link = document.createElement('a')
    link.download = 'index.php'
    link.href = file
    link.click()
  } catch (e) {
    toast.error(e.response?.data?.message, {
      description: e.response?.data?.action || e.message,
    })
  } finally {
    isLoading.value = false
  }
}
</script>

<template>
  <div class="py-5">
    <div class="mt-2 flex flex-col">
      <div class="mt-5">
        <Button
          :loading="isLoading"
          @click="downloadPHPIntegration"
        >
          {{ $t('components.campaign_integration_php.download_file') }}
        </Button>
      </div>
    </div>
    <div class="mt-2 flex shrink-0">
      <a
        href="https://help.cloakup.me/pt-br/article/como-integrar-via-php-1bjljck/"
        class="flex items-center gap-2 text-sm text-blue-500 underline"
        target="_blank"
      >
        <ExternalLinkIcon class="size-4" />
        {{ $t('components.campaign_integration_php.step_by_step') }}
      </a>
    </div>
  </div>
</template>
