<script setup>
import { computed, reactive, ref, watch } from 'vue'
import { toast } from 'vue-sonner'
import { useI18n } from 'vue-i18n'
import ServerRegionRadioGroup from './ServerRegionRadioGroup.vue'
import ErrorMessage from '@/components/ui/error-message/ErrorMessage.vue'
import BaseAlert from '@/components/base/BaseAlert.vue'
import BaseRadio from '@/components/base/BaseRadio.vue'
import BaseSpinner from '@/components/base/BaseSpinner.vue'
import ServerSizeRadioGroup from '@/components/partials/server/ServerSizeRadioGroup.vue'
import { cloudProviderService } from '@/services/cloud-provider'
import { serverService } from '@/services/server'
import { serverRegions, serverSizes } from '@/static/servers'
import Button from '@/components/ui/button/Button.vue'
import Input from '@/components/ui/input/Input.vue'
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from '@/components/ui/dialog'
import Field from '@/components/ui/field/Field.vue'
import Label from '@/components/ui/label/Label.vue'

const emit = defineEmits(['created'])
const { t } = useI18n()
const model = defineModel({
  required: true
})
const isLoading = ref(false)
const errors = ref({})
const form = reactive({
  name: '',
  provider: '',
  size: '',
  region: '',
  image: ''
})
const sizeCategoryId = ref(1)

const availableRegions = computed(() => {
  return serverRegions.sort((a, b) => a.name.localeCompare(b.name))
})

const availableSizes = computed(() => {
  return serverSizes
    .filter(size => size.region_ids.includes(form.region?.id))
    .filter(size => sizeCategoryId.value == size.size_category.id)
})

function closeModal() {
  form.name = ''
  form.provider = ''
  form.size = ''
  form.region = ''
  form.image = ''

  model.value = false
}

function onSubmit() {
  const payload = {
    name: form.name?.replace(/[^a-zA-Z0-9]/g, ''),
    provider: {
      id: form.provider?.id,
      alias: form.provider?.provider
    },
    size: form.size?.name,
    region: form.region?.slug,
    image: 'ubuntu-22-04-x64'
  }

  createServer(payload)
}

async function createServer(data) {
  isLoading.value = true
  errors.value = {}

  try {
    await serverService.create(data)

    toast.success(t('components.server_create_modal.toast.success.title'), {
      description: t('components.server_create_modal.toast.success.description'),
    })

    emit('created')
    closeModal()
  } catch (e) {
    if (e.response?.status === 422) {
      errors.value = e.response.data.data
    }

    toast.error(e.response?.data?.message, {
      description: e.response?.data?.action || e.message,
    })
  } finally {
    isLoading.value = false
  }
}

// use cloud provider
const cloudProviders = ref([])
const failedToFetchCloudProviders = ref(false)
const isLoadingCloudProviders = ref(false)

async function fetchCloudProviders(page, limit) {
  failedToFetchCloudProviders.value = false
  isLoadingCloudProviders.value = true
  try {
    const { data } = await cloudProviderService.findAll({
      page,
      limit
    })

    cloudProviders.value = data.data
  } catch (error) {
    failedToFetchCloudProviders.value = true
  } finally {
    isLoadingCloudProviders.value = false
  }
}

fetchCloudProviders(1, 100)

// use regions
const regions = ref([])
const isLoadingRegions = ref(false)
const failedToFetchRegions = ref(false)

async function fetchRegions() {
  isLoadingRegions.value = true
  if (!form.provider?.id) {
    return
  }

  try {
    const data = await cloudProviderService.getRegions(form.provider.id)
    regions.value = data.data
  } catch (error) {
    failedToFetchRegions.value = true
  } finally {
    isLoadingRegions.value = false
  }
}

watch(
  () => form.provider.id,
  (value) => {
    fetchRegions()
  },
  {
    deep: true
  }
)

watch(model, (v) => {
  if (!v) {
    form.name = ''
    form.provider = ''
    form.size = ''
    form.region = ''
    form.image = ''
  }
}, {
  immediate: true
})

const machineCategories = [
  {
    id: 1,
    title: 'Básica',
  },
  {
    id: 13,
    title: 'Premium Intel',
  },
  {
    id: 14,
    title: 'Premium AMD',
  }
]
</script>

<template>
  <div>
    <Dialog v-model:open="model">
      <DialogContent class="max-h-[90dvh] grid-rows-[auto_minmax(0,1fr)_auto] p-0 ">
        <DialogHeader class="px-6 pt-4">
          <DialogTitle>
            {{ $t('components.server_create_modal.title') }}
          </DialogTitle>
          <DialogDescription>
            {{ $t('components.server_create_modal.description') }}
          </DialogDescription>
        </DialogHeader>

        <div class="grid gap-4 overflow-y-auto px-6 py-4">
          <div class="flex  flex-col justify-between">
            <form
              id="server-form"
              @submit.prevent="onSubmit"
            >
              <fieldset :disabled="isLoading || isLoadingRegions">
                <div class="space-y-4">
                  <Field>
                    <Label
                      for="server_name"
                      required
                    >
                      {{ $t('components.server_create_modal.name') }}
                    </Label>
                    <Input
                      id="server_name"
                      v-model="form.name"
                      type="text"
                      :errors="errors.name"
                      required
                    />
                  </Field>

                  <Field>
                    <Label
                      for="server_provider"
                      required
                    >
                      {{ $t('components.server_create_modal.provider') }}
                    </Label>
                    <div class="relative">
                      <select
                        id="server_provider"
                        v-model="form.provider"
                        :disabled="isLoadingCloudProviders"
                        name="server_provider"
                        class="focus:border-ring-primary border-neutral w-full rounded-md border text-sm shadow-sm transition duration-300 focus:border-primary focus:ring-primary disabled:cursor-not-allowed disabled:bg-neutral-100"
                        :class="[
                          errors.provider
                            ? 'border-red-500 text-red-500'
                            : 'border-neutral-300',
                        ]"
                        required
                      >
                        <option
                          v-for="provider in cloudProviders"
                          :key="provider.id"
                          :value="provider"
                        >
                          {{ provider.name }}
                        </option>
                      </select>
                      <BaseSpinner
                        v-if="isLoadingCloudProviders"
                        class="absolute right-10 top-2"
                      />
                    </div>
                    <ErrorMessage
                      :message="errors?.provider"
                    />
                  </Field>

                  <Field>
                    <Label required>
                      {{ $t('components.server_create_modal.region') }}
                    </Label>
                    <ServerRegionRadioGroup
                      v-model="form.region"
                      :regions="availableRegions"
                    />
                    <ErrorMessage
                      :message="errors?.region"
                    />
                  </Field>

                  <div
                    v-if="form.region?.slug"
                  >
                    <h2 class="text-base font-semibold leading-7">
                      {{ $t('components.server_create_modal.machine_size') }}
                    </h2>

                    <div
                      class="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6"
                    >
                      <div class="col-span-6">
                        <fieldset>
                          <div
                            class="space-y-4 sm:flex sm:items-center sm:space-x-4 sm:space-y-0"
                          >
                            <div
                              v-for="machineCategory in machineCategories"
                              :key="machineCategory.id"
                              class="flex items-center"
                            >
                              <BaseRadio
                                :id="machineCategory.id"
                                v-model="sizeCategoryId"
                                name="machine_category"
                                :value="machineCategory.id"
                                :label="machineCategory.title"
                              />
                            </div>
                          </div>
                        </fieldset>
                      </div>

                      <div class="col-span-6">
                        <div>
                          <div v-if="availableSizes.length">
                            <ServerSizeRadioGroup
                              v-model="form.size"
                              :sizes="availableSizes"
                            />
                          </div>
                          <BaseAlert
                            v-else
                            :title="$t('components.server_create_modal.machine_not_found.title')"
                            :message="$t('components.server_create_modal.machine_not_found.description')"
                          />
                          <ErrorMessage
                            :message="errors?.size"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </fieldset>
            </form>
          </div>
        </div>

        <DialogFooter class="px-6 py-4">
          <DialogClose as-child>
            <Button
              type="button"
              variant="secondary"
              :disabled="isLoading"
            >
              {{ $t('common.cancel') }}
            </Button>
          </DialogClose>
          <Button
            :loading="isLoading"
            type="submit"
            form="server-form"
            :disabled="isLoading"
          >
            {{ $t('common.save') }}
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  </div>
</template>
