<script setup>
import {
  Dialog,
  DialogDescription,
  DialogPanel,
  DialogTitle,
  TransitionChild,
  TransitionRoot
} from '@headlessui/vue'
import { XMarkIcon } from '@heroicons/vue/24/solid'
import { ref } from 'vue'

defineProps({
  title: {
    type: String,
    default: ''
  },
  description: {
    type: String,
    default: ''
  },
  open: {
    type: Boolean,
    default: false
  },
  icon: {
    type: String,
    default: ''
  },
  size: {
    type: String,
    default: 'lg'
  },
  clickOutside: {
    type: Boolean,
    default: false
  }
})
const emit = defineEmits(['close'])
const sizes = {
  sm: 'sm:max-w-lg',
  md: 'sm:max-w-xl',
  lg: 'sm:max-w-2xl',
  xl: 'sm:max-w-3xl',
  full: 'sm:max-w-full'
}

const completeButtonRef = ref(null)

function closeModal() {
  emit('close')
}
</script>

<template>
  <Teleport to="body">
    <TransitionRoot
      as="template"
      :show="open"
    >
      <Dialog
        as="div"
        class="relative z-10"
        :initial-focus="completeButtonRef"
        @close="closeModal"
      >
        <TransitionChild
          as="template"
          enter="ease-out duration-300"
          enter-from="opacity-0"
          enter-to="opacity-100"
          leave="ease-in duration-200"
          leave-from="opacity-100"
          leave-to="opacity-0"
        >
          <div
            class="fixed inset-0 bg-neutral-900/20 bg-opacity-75 backdrop-blur-md transition-opacity"
            aria-hidden="true"
          />
        </TransitionChild>

        <div class="fixed inset-0 z-10 overflow-hidden">
          <div
            class="flex min-h-full items-end justify-center text-center sm:items-center"
          >
            <TransitionChild
              as="template"
              enter="ease-out duration-300"
              enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enter-to="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leave-from="opacity-100 translate-y-0 sm:scale-100"
              leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <DialogPanel
                class="relative flex max-h-[calc(100vh-40px*2)] w-full flex-col overflow-hidden rounded-lg bg-white text-left shadow-xl ring-1 ring-white/10 transition-all sm:my-8"
                :class="[sizes[size], !$slots.footer ? 'pb-2' : '']"
              >
                <div
                  v-if="title"
                  class="rounded-t-lg px-2 py-5"
                >
                  <div
                    class="max-w-lg text-center sm:ml-4 sm:mt-0 sm:text-left"
                  >
                    <DialogTitle
                      as="h3"
                      class="text-base font-semibold leading-6 "
                    >
                      {{ title }}
                    </DialogTitle>
                    <div class="mt-2">
                      <DialogDescription
                        class="text-sm leading-6 text-muted-foreground"
                      >
                        {{ description }}
                      </DialogDescription>
                    </div>
                  </div>
                </div>
                <div class="absolute right-0 top-0 hidden pr-4 pt-4 sm:block">
                  <button
                    ref="completeButtonRef"
                    type="button"
                    class="rounded-md text-neutral-400 hover:text-muted-foreground focus:outline-none focus:ring-2 focus:ring-primary focus:ring-offset-2"
                    @click="closeModal"
                  >
                    <span class="sr-only">Close</span>
                    <XMarkIcon
                      class="size-6"
                      aria-hidden="true"
                    />
                  </button>
                </div>

                <div
                  class="relative h-screen flex-1 overflow-y-auto bg-white px-4 pb-4 sm:px-6 sm:pb-4"
                  :class="{
                    'rounded-t-lg py-4': !title,
                  }"
                >
                  <slot />
                </div>

                <div
                  v-if="$slots.footer"
                  class="rounded-lg rounded-t-none bg-neutral-50 p-4 sm:px-6"
                >
                  <slot name="footer" />
                </div>
              </DialogPanel>
            </TransitionChild>
          </div>
        </div>
      </Dialog>
    </TransitionRoot>
  </Teleport>
</template>
