<script setup>
import { ref, watch } from 'vue'
import { toast } from 'vue-sonner'
import dayjs from 'dayjs'
import PageChart from '../dashboard/PageChart.vue'
import ProgressIndicator from '@/components/common/ProgressIndicator.vue'
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle
} from '@/components/ui/card'
import { reportService } from '@/services/report-service'
import { ScrollArea } from '@/components/ui/scroll-area'
import EmptyCard from '@/components/EmptyCard.vue'

const props = defineProps({
  campaignId: {
    type: String,
    required: false
  },
  startDate: {
    type: Date,
    required: false,
  },
  endDate: {
    type: Date,
    required: false,
  }
})

const controller = new AbortController()

const isLoading = ref(false)
const pages = ref([])

async function fetchByPage(params) {
  isLoading.value = true
  try {
    const { data } = await reportService.groupByPages({
      params,
      signal: controller.signal
    })
    pages.value = data
  } catch (e) {
    if (e.message === 'canceled') {
      return
    }

    toast.error(e.response?.data?.message, {
      description: e.response?.data?.action || e.message,
    })
  } finally {
    isLoading.value = false
  }
}

watch(() => [props.startDate, props.endDate, props.campaignId], () => {
  if (!props.campaignId) {
    return
  }

  fetchByPage({
    start_date: dayjs(props.startDate).unix() * 1000,
    end_date: dayjs(props.endDate).unix() * 1000,
    campaign: props.campaignId
  })
}, {
  immediate: true
})
</script>

<template>
  <Card>
    <CardHeader class="flex flex-col items-stretch space-y-0 border-b p-0 sm:flex-row">
      <div class="flex flex-1 flex-col justify-center gap-1 px-6 py-5 sm:py-6">
        <CardTitle>
          {{ $t('components.requests_by_page.title') }}
        </CardTitle>
        <CardDescription>
          {{ $t('components.requests_by_page.description') }}
        </CardDescription>
      </div>
    </CardHeader>
    <div class="h-2">
      <ProgressIndicator v-if="isLoading" />
    </div>

    <CardContent>
      <EmptyCard
        v-if="!pages?.length"
        :message="!campaignId ? $t('pages.reports.missing_selected_campaign') : undefined"
      />
      <ScrollArea
        v-else
        class="max-h-[300px] pr-4"
      >
        <PageChart
          :data="pages"
        />
      </ScrollArea>
    </CardContent>
  </Card>
</template>
