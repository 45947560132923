import CloudProvidersView from '@/views/cloud-provider/CloudProvidersView.vue'

export default [
  {
    path: '/cloud-providers',
    name: 'CloudProviders',
    component: CloudProvidersView,
    meta: {
      layout: 'default',
      requiresAuth: true,
      requiredHostSubscription: true
    }
  }
]
