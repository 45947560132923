<script setup>
import { ref } from 'vue'
import { PaperClipIcon } from '@heroicons/vue/24/solid'
import { toast } from 'vue-sonner'
import { useI18n } from 'vue-i18n'
import { useHead } from '@unhead/vue'
import InvoiceBadgeStatus from '@/components/partials/InvoiceStatusBadge.vue'
import BaseTable from '@/components/base/BaseTable.vue'
import SectionHeading from '@/components/common/SectionHeading.vue'
import { formatCurrency } from '@/helpers/currency'
import { formatDate } from '@/helpers/date'
import { billingService } from '@/services/billing'
import PayInvoiceModal from '@/components/partials/payments/PayInvoiceModal.vue'
import Button from '@/components/ui/button/Button.vue'
import EmptyState from '@/components/common/EmptyState.vue'

const { t, locale } = useI18n()
useHead({
  title: t('pages.settings.invoices.title')
})

const subscriptions = ref([])
const isLoading = ref(false)
const hasError = ref(false)
const selectedSecret = ref(null)
const showModal = ref(false)

async function fetchPayments() {
  isLoading.value = true
  try {
    const { data } = await billingService.getPaymentHistory()
    subscriptions.value = data
  } catch (e) {
    hasError.value = true

    toast.error(e.response?.data?.message, {
      description: e.response?.data?.action || e.message,
    })
  } finally {
    isLoading.value = false
  }
}
fetchPayments()

const header = [
  {
    label: t('common.created_at'),
    value: 'created_at',
    format: (value) => {
      return formatDate(value, {
        locale: locale.value,
      })
    }
  },
  {
    label: t('common.amount'),
    value: 'amount',
  },
  {
    label: t('common.status'),
    value: 'status'
  }
]
</script>

<template>
  <div>
    <div>
      <SectionHeading
        :title="$t('pages.settings.invoices.title')"
        :description="$t('pages.settings.invoices.description')"
      />
      <div class="mt-8">
        <BaseTable
          :headers="header"
          :columns="subscriptions"
          :loading="isLoading"
          :error="hasError"
        >
          <template #col-amount="{ row, column }">
            {{ formatCurrency(row, column.currency) }}
          </template>
          <template #col-status="{ row }">
            <InvoiceBadgeStatus :status="row" />
          </template>
          <template #actions="{ column }">
            <Button
              v-if="column.invoice_url && column.status !== 'open'"
              as-child
              variant="secondary"
            >
              <a
                target="_blank"
                :href="column.invoice_url"
              >
                <PaperClipIcon
                  class="size-5"
                  aria-hidden="true"
                />
              </a>
            </Button>
          </template>
          <template #empty>
            <EmptyState
              :title="$t('pages.settings.invoices.empty_state.title')"
            />
          </template>
        </BaseTable>

        <PayInvoiceModal
          :open="showModal"
          :client-secret="selectedSecret"
          @close="showModal = false"
          @paid="fetchPayments"
        />
      </div>
    </div>
  </div>
</template>
