<script setup>
import {
  Switch,
  SwitchDescription,
  SwitchGroup,
  SwitchLabel
} from '@headlessui/vue'
import { computed } from 'vue'

const props = defineProps({
  label: {
    type: String,
    default: ''
  },
  modelValue: {
    type: Boolean,
    default: false
  },
  description: {
    type: String,
    default: ''
  },
  checked: {
    type: Boolean,
    default: false
  },
  disabled: {
    type: Boolean,
    default: false
  }
})
const emit = defineEmits(['update:modelValue', 'change'])
const enabled = computed({
  get: () => props.modelValue || props.checked,
  set: (value) => {
    emit('update:modelValue', value)
    emit('change', value)
  }
})
</script>

<template>
  <SwitchGroup
    as="div"
    class="flex flex-row items-center gap-4 py-2"
  >
    <Switch
      v-model="enabled"
      :aria-checked="enabled"
      :disabled="disabled"
      class=" relative inline-flex h-6 w-11 shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-primary disabled:pointer-events-none disabled:cursor-not-allowed disabled:opacity-50  "
      :class="[
        enabled ? 'bg-primary' : 'bg-primary/15',
      ]"
    >
      <span
        aria-hidden="true"
        class="pointer-events-none inline-block size-5 rounded-full bg-background shadow ring-0 transition duration-200 ease-in-out"
        :class="[
          enabled ? 'translate-x-5' : 'translate-x-0',
        ]"
      />
    </Switch>
    <div>
      <SwitchLabel
        as="span"
        class="cursor-pointer text-sm font-medium "
      >
        {{ label }}
      </SwitchLabel>
      <SwitchDescription
        v-if="description"
        :id="`${label}-description`"
        class="text-sm text-muted-foreground"
      >
        {{ description }}
      </SwitchDescription>
    </div>
  </SwitchGroup>
</template>
