<script setup>
import { computed, ref, watch } from 'vue'
import { onBeforeRouteLeave, useRoute } from 'vue-router'
import { toast } from 'vue-sonner'
import { EyeIcon, PlusIcon, ServerIcon } from '@heroicons/vue/24/solid'
import { useI18n } from 'vue-i18n'
import { useHead } from '@unhead/vue'
import BaseTable from '@/components/base/BaseTable.vue'
import PageHeading from '@/components/common/PageHeading.vue'
import Pagination from '@/components/common/Pagination.vue'
import ServerBadgeStatus from '@/components/partials/server/ServerBadgeStatus.vue'
import ServerConnectForm from '@/components/partials/server/ServerConnectForm.vue'
import ServerCreateModal from '@/components/partials/server/ServerCreateModal.vue'
import ServerDeleteModal from '@/components/partials/server/ServerDeleteModal.vue'
import { formatDate } from '@/helpers/date'
import { serverService } from '@/services/server'
import Button from '@/components/ui/button/Button.vue'
import EmptyState from '@/components/common/EmptyState.vue'
import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  BreadcrumbList,
  BreadcrumbPage,
  BreadcrumbSeparator,
} from '@/components/ui/breadcrumb'

const route = useRoute()
const { t, locale } = useI18n()
useHead({
  title: t('pages.servers.title')
})

// table
const servers = ref([])
const isLoading = ref(false)
const hasError = ref(false)
const showCreateServerModal = ref(false)

const count = ref(0)

const intervals = new Map()

async function fetchServers({
  page,
  limit
}) {
  isLoading.value = true
  try {
    const { data } = await serverService.findAll({
      page,
      limit
    })
    servers.value = data.data
    count.value = data.count

    // for each server, if server is not running, check status every 10 seconds, then if status is running, update server status and stop checking
    servers.value.forEach((server) => {
      if (server.status !== 'running' && server.status !== 'failed') {
        intervals.set(
          server.id,
          setInterval(() => {
            checkStatus(server.id)
          }, 10000)
        )
      }
    })
  } catch (error) {
    hasError.value = true
  } finally {
    isLoading.value = false
  }
}

async function checkStatus(id) {
  try {
    const { data } = await serverService.checkStatus(id)
    const server = servers.value.find(server => server.id === id)
    server.status = data.status
    server.ip = data.ip
    server.password = data.password
    server.username = data.username

    if ((data.status !== 'running' || data.status !== 'failed') && !data.ip) {
      server.progress = 'setup'
    }

    if (data.status === 'running' || data.status === 'failed') {
      if (data.status === 'running') {
        toast.success(t('pages.servers.check_status.toast.success.title'), {
          description: t('pages.servers.check_status.toast.success.description'),
        })
      } else {
        toast.error(t('pages.servers.check_status.toast.error.title'), {
          description: t('pages.servers.check_status.toast.error.description'),
        })
      }
      clearInterval(intervals.get(id))
    }
  } catch (error) {
    console.log(error)
  }
}

function onDeleteCloudProvider() {
  fetchServers({
    page: route.query.page,
    limit: route.query.limit
  })
}

watch(
  () => [route.query],
  () => {
    fetchServers({
      page: route.query.page,
      limit: route.query.limit
    })
  },
  {
    immediate: true
  }
)

const serverTableHeader = computed(() => [
  {
    label: t('common.created_at'),
    value: 'created_at',
    format: value => formatDate(value.replace('.000Z', ''), {
      locale: locale.value
    })
  },
  {
    label: t('common.name'),
    value: 'name'
  },
  {
    label: t('common.status'),
    value: 'status'
  },
  {
    label: 'IP',
    value: 'ip'
  }
])

onBeforeRouteLeave((to, from) => {
  intervals.forEach((interval) => {
    clearInterval(interval)
  })
})
</script>

<template>
  <div class="space-y-5">
    <div class="space-y-2">
      <Breadcrumb>
        <BreadcrumbList>
          <BreadcrumbItem>
            <BreadcrumbLink as-child>
              <RouterLink to="/">
                {{ $t('common.dashboard') }}
              </RouterLink>
            </BreadcrumbLink>
          </BreadcrumbItem>

          <BreadcrumbSeparator />

          <BreadcrumbItem>
            <BreadcrumbPage>
              {{ $t("pages.servers.breadcrumb.servers") }}
            </BreadcrumbPage>
          </BreadcrumbItem>
        </BreadcrumbList>
      </Breadcrumb>
      <PageHeading
        :title="$t('pages.servers.title')"
        :description="$t('pages.servers.description')"
      >
        <template #actions>
          <Button
            @click="showCreateServerModal = true"
          >
            <PlusIcon
              class="mr-2 size-5"
            />
            {{ $t("pages.servers.create_server") }}
          </Button>
        </template>
      </PageHeading>
    </div>

    <div>
      <BaseTable
        :headers="serverTableHeader"
        :columns="servers"
        :loading="isLoading"
        :error="hasError"
      >
        <template #col-status="{ column }">
          <ServerBadgeStatus
            :status="column.status"
            :ip="column.ip"
          />
        </template>
        <template #actions="{ column }">
          <div class="flex items-center justify-end gap-4">
            <div v-if="column.ip && column.status === 'running'">
              <ServerConnectForm :server="column">
                <button
                  type="submit"
                >
                  <ServerIcon
                    class="size-5 text-muted-foreground"
                  />
                </button>
              </ServerConnectForm>
            </div>
            <router-link
              v-if="column.ip && column.status === 'running'"
              class="cursor-pointer"
              :to="{
                name: 'Server',
                params: {
                  id: column.id,
                },
              }"
            >
              <EyeIcon
                name="EyeIcon"
                class="size-5 text-muted-foreground"
              />
            </router-link>
            <ServerDeleteModal
              :id="column?.id"
              @deleted="onDeleteCloudProvider"
            />
          </div>
        </template>
        <template #pagination>
          <Pagination
            :total="count"
          />
        </template>
        <template #empty>
          <EmptyState
            :title="$t('pages.servers.empty_state.title')"
            :description="$t('pages.servers.empty_state.description')"
          />
        </template>
      </BaseTable>
    </div>

    <ServerCreateModal
      v-model="showCreateServerModal"
      @created="fetchServers({
        page: route.query.page,
        limit: route.query.limit,
      })"
    />
  </div>
</template>
