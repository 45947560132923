import client from '../plugins/axios'

export const transactionService = {
  resource: '/transactions',
  findAll(params = {}) {
    return client.get(this.resource, {
      params
    })
  }
}
