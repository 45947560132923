<script setup>
import { reactive, ref } from 'vue'
import { toast } from 'vue-sonner'
import { useI18n } from 'vue-i18n'
import { userService } from '@/services/user'
import Button from '@/components/ui/button/Button.vue'
import Label from '@/components/ui/label/Label.vue'
import ErrorMessage from '@/components/ui/error-message/ErrorMessage.vue'
import Field from '@/components/ui/field/Field.vue'
import PasswordInput from '@/components/common/PasswordInput.vue'

const { t } = useI18n()

const form = reactive({
  password: '',
  passwordConfirmation: ''
})
const errors = ref({})
const isLoading = ref(false)

function onSubmit() {
  const payload = {
    password: form.password,
    password_confirmation: form.passwordConfirmation
  }

  updatePassword(payload)
}

async function updatePassword(payload) {
  isLoading.value = true
  errors.value = {}

  try {
    await userService.updatePassword(payload)

    toast.success(t('components.update_password.toast.success.title'), {
      description: t('components.update_password.toast.success.description'),
    })
  } catch (e) {
    if (e.response?.status === 422) {
      errors.value = e.response.data.data
    }

    toast.error(e.response?.data?.message, {
      description: e.response?.data?.action || e.message,
    })
  } finally {
    isLoading.value = false
  }
}
</script>

<template>
  <form @submit.prevent="onSubmit">
    <fieldset :disabled="isLoading">
      <div class="grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
        <div class="col-span-6 sm:col-span-3">
          <Field>
            <Label for="password">
              {{ $t("components.update_password.password") }}
            </Label>
            <PasswordInput
              id="password"
              v-model="form.password"
              name="password"
              autocomplete="new-password"
              :invalid="errors?.password"
            />
            <ErrorMessage :message="errors?.password" />
          </Field>
        </div>
        <div class="col-span-6 sm:col-span-3">
          <Field>
            <Label for="new-password">
              {{ $t("components.update_password.confirm_password") }}
            </Label>
            <PasswordInput
              id="new-password"
              v-model="form.passwordConfirmation"
              name="new-password"
              :invalid="errors?.password_confirmation"
            />
            <ErrorMessage :message="errors?.password_confirmation" />
          </Field>
        </div>
      </div>
      <div class="mt-6 flex items-center justify-end">
        <Button
          type="submit"
          :loading="isLoading"
        >
          {{ $t('common.save') }}
        </Button>
      </div>
    </fieldset>
  </form>
</template>
