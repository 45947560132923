<script setup>
import { computed, ref, watch } from 'vue'
import { useClipboard } from '@vueuse/core'
import { useRoute } from 'vue-router'
import ReferralTable from '@/components/partials/referral/ReferralTable.vue'
import SectionHeading from '@/components/common/SectionHeading.vue'
import { userService } from '@/services/user'
import { useUserStore } from '@/stores/user'
import Button from '@/components/ui/button/Button.vue'
import Input from '@/components/ui/input/Input.vue'

const { copy, copied } = useClipboard()
const route = useRoute()
const store = useUserStore()

// search
const count = ref(0)

const hasError = ref(false)
const isLoading = ref(false)
const users = ref([])

const user = computed(() => store.user)
const inviteURL = computed(
  () => `https://ref.cloakup.me/${user.value?.invite_slug}`
)

async function fetchUsers(params) {
  isLoading.value = true
  try {
    const { data } = await userService.getInvited(params)
    users.value = data.data
    count.value = data.count
  } catch (e) {
    hasError.value = true
  } finally {
    isLoading.value = false
  }
}

watch(
  () => [route.query],
  () => {
    fetchUsers({
      page: route.query.page,
      limit: route.query.limit
    })
  },
  {
    immediate: true
  }
)
</script>

<template>
  <div class="space-y-5">
    <SectionHeading
      title="Indicações"
      :description="`Indique e ganha ${user?.invite_commission}% sobre cada pagamento feito na plataforma.`"
    >
      <template #actions>
        <div class="flex items-center gap-2">
          <Input
            :model-value="inviteURL"
            type="text"
            readonly
            class="w-full"
          />

          <Button
            type="button"
            @click="copy(inviteURL)"
          >
            {{ copied ? 'Copiado' : 'Copiar' }}
          </Button>
        </div>
      </template>
    </SectionHeading>
    <div class="mt-5">
      <ReferralTable
        :users="users"
        :count="count"
        :is-loading="isLoading"
        :has-error="hasError"
      />
    </div>
  </div>
</template>
