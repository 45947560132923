<script setup>
import { ref } from 'vue'
import { EyeIcon, EyeSlashIcon, LockClosedIcon } from '@heroicons/vue/24/solid'
import Input from '@/components/ui/input/Input.vue'

const model = defineModel()

const showPassword = ref(false)
</script>

<template>
  <Input
    v-model="model"
    :type="showPassword ? 'text' : 'password'"
    placeholder="*******"
  >
    <template #leading>
      <LockClosedIcon class="size-5 text-muted-foreground" />
    </template>
    <template #trailing>
      <EyeIcon
        v-if="showPassword"
        class="size-5 text-muted-foreground"
        @click="showPassword = !showPassword"
      />
      <EyeSlashIcon
        v-else
        class="size-5 text-muted-foreground"
        @click="showPassword = !showPassword"
      />
    </template>
  </Input>
</template>
