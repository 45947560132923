<script setup>
import { computed } from 'vue'
import { InformationCircleIcon } from '@heroicons/vue/24/outline'
import { Button } from '@/components/ui/button'
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle
} from '@/components/ui/dialog'
import { Card } from '@/components/ui/card'
import CopyButton from '@/components/common/CopyButton.vue'

const props = defineProps({
  params: {
    type: Object,
    required: true
  },
  url: {
    type: String,
    required: false,
    default: null
  },
  network: {
    type: String,
    required: false,
    default: null
  },
  isSimpleMode: {
    type: Boolean,
    required: false,
    default: false
  }
})
const model = defineModel({
  type: Boolean,
  required: true,
})

function parseObjectToQueryString(queryString) {
  const queryStringParts = []

  for (const key in queryString) {
    if (queryString.hasOwnProperty(key)) {
      const values = queryString[key]
      for (const value of values) {
        queryStringParts.push(`${encodeURIComponent(key)}=${encodeURIComponent(value)}`)
      }
    }
  }

  return queryStringParts.join('&')
}

const queryString = computed(() => parseObjectToQueryString(props.params))

const videos = {
  facebook: 'https://www.youtube.com/embed/OqnlvQl9avE?si=YY7AidAhrnNpQLRp',
  google: 'https://www.youtube.com/embed/ivthWYT1onc?si=aXPBKrG6rVr9HSA1'
}
</script>

<template>
  <Dialog v-model:open="model">
    <DialogContent>
      <DialogHeader>
        <DialogTitle>
          {{ $t('setup_params_alert_title') }}
        </DialogTitle>
        <DialogDescription>
          {{ $t('setup_params_alert_description') }}
        </DialogDescription>
      </DialogHeader>

      <Card
        v-if="url"
      >
        <div class="flex items-center gap-4 p-4 ">
          <p class="word-break flex flex-1 flex-col break-all text-sm">
            <strong>URL:</strong>
            {{ url }}
          </p>
          <div class="flex shrink-0 items-center gap-2">
            <CopyButton
              :content="url"
            />
          </div>
        </div>
      </Card>

      <Card>
        <div class="flex items-center gap-4 p-4 ">
          <p class="word-break flex flex-1 flex-col break-all text-sm">
            <strong>Parâmetros:</strong>
            {{ queryString }}
          </p>
          <div class="flex shrink-0 items-center gap-2">
            <CopyButton
              :content="queryString"
            />
          </div>
        </div>
      </Card>

      <iframe
        v-if="videos[network] && isSimpleMode"
        width="460"
        height="315"
        :src="videos[network]"
        title="YouTube video player"
        frameborder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        referrerpolicy="strict-origin-when-cross-origin"
        allowfullscreen
        class="rounded-md"
      />

      <DialogFooter>
        <Button
          variant="link"
          class="mr-auto p-0 text-blue-500 underline"
          as-child
        >
          <a
            href="https://help.cloakup.me/pt-br/article/como-utilizar-os-parametros-do-cloakup-nas-plataformas-de-ads-1li38ha/"
            target="_blank"
          >
            <InformationCircleIcon class="mr-2 size-5" />
            {{ $t('setup_params_alert_help') }}
          </a>
        </Button>
        <DialogClose>
          <Button>
            {{ $t('setup_params_alert_button') }}
          </Button>
        </DialogClose>
      </DialogFooter>
    </DialogContent>
  </Dialog>
</template>
