export default [
  {
    countryName: 'Afghanistan',
    countryShortCode: 'AF',
    regions: [
      {
        name: 'Badakhshan',
        id: 'BDS'
      },
      {
        name: 'Badghis',
        id: 'BDG'
      },
      {
        name: 'Baghlan',
        id: 'BGL'
      },
      {
        name: 'Balkh',
        id: 'BAL'
      },
      {
        name: 'Bamyan',
        id: 'BAM'
      },
      {
        name: 'Daykundi',
        id: 'DAY'
      },
      {
        name: 'Farah',
        id: 'FRA'
      },
      {
        name: 'Faryab',
        id: 'FYB'
      },
      {
        name: 'Ghazni',
        id: 'GHA'
      },
      {
        name: 'Ghor',
        id: 'GHO'
      },
      {
        name: 'Helmand',
        id: 'HEL'
      },
      {
        name: 'Herat',
        id: 'HER'
      },
      {
        name: 'Jowzjan',
        id: 'JOW'
      },
      {
        name: 'Kabul',
        id: 'KAB'
      },
      {
        name: 'Kandahar',
        id: 'KAN'
      },
      {
        name: 'Kapisa',
        id: 'KAP'
      },
      {
        name: 'Khost',
        id: 'KHO'
      },
      {
        name: 'Kunar',
        id: 'KNR'
      },
      {
        name: 'Kunduz',
        id: 'KDZ'
      },
      {
        name: 'Laghman',
        id: 'LAG'
      },
      {
        name: 'Logar',
        id: 'LOW'
      },
      {
        name: 'Maidan Wardak',
        id: 'WAR'
      },
      {
        name: 'Nangarhar',
        id: 'NAN'
      },
      {
        name: 'Nimruz',
        id: 'NIM'
      },
      {
        name: 'Nuristan',
        id: 'NUR'
      },
      {
        name: 'Paktia',
        id: 'PIA'
      },
      {
        name: 'Paktika',
        id: 'PKA'
      },
      {
        name: 'Panjshir',
        id: 'PAN'
      },
      {
        name: 'Parwan',
        id: 'PAR'
      },
      {
        name: 'Samangan',
        id: 'SAM'
      },
      {
        name: 'Sar-e Pol',
        id: 'SAR'
      },
      {
        name: 'Takhar',
        id: 'TAK'
      },
      {
        name: 'Urozgan',
        id: 'ORU'
      },
      {
        name: 'Zabul',
        id: 'ZAB'
      }
    ]
  },
  {
    countryName: 'Åland Islands',
    countryShortCode: 'AX',
    regions: [
      {
        name: 'Brändö',
        id: 'BR'
      },
      {
        name: 'Eckerö',
        id: 'EC'
      },
      {
        name: 'Finström',
        id: 'FN'
      },
      {
        name: 'Föglö',
        id: 'FG'
      },
      {
        name: 'Geta',
        id: 'GT'
      },
      {
        name: 'Hammarland',
        id: 'HM'
      },
      {
        name: 'Jomala',
        id: 'JM'
      },
      {
        name: 'Kumlinge',
        id: 'KM'
      },
      {
        name: 'Kökar',
        id: 'KK'
      },
      {
        name: 'Lemland',
        id: 'LE'
      },
      {
        name: 'Lumparland',
        id: 'LU'
      },
      {
        name: 'Mariehamn',
        id: 'MH'
      },
      {
        name: 'Saltvik',
        id: 'SV'
      },
      {
        name: 'Sottunga',
        id: 'ST'
      },
      {
        name: 'Sund',
        id: 'SD'
      },
      {
        name: 'Vårdö',
        id: 'VR'
      }
    ]
  },
  {
    countryName: 'Albania',
    countryShortCode: 'AL',
    regions: [
      {
        name: 'Berat',
        id: '01'
      },
      {
        name: 'Dibër',
        id: '09'
      },
      {
        name: 'Durrës',
        id: '02'
      },
      {
        name: 'Elbasan',
        id: '03'
      },
      {
        name: 'Fier',
        id: '04'
      },
      {
        name: 'Gjirokastër',
        id: '05'
      },
      {
        name: 'Korçë',
        id: '06'
      },
      {
        name: 'Kukës',
        id: '07'
      },
      {
        name: 'Lezhë',
        id: '08'
      },
      {
        name: 'Shkodër',
        id: '10'
      },
      {
        name: 'Tirana',
        id: '11'
      },
      {
        name: 'Vlorë',
        id: '12'
      }
    ]
  },
  {
    countryName: 'Algeria',
    countryShortCode: 'DZ',
    regions: [
      {
        name: 'Adrar',
        id: '01'
      },
      {
        name: 'Aïn Defla',
        id: '44'
      },
      {
        name: 'Aïn Témouchent',
        id: '46'
      },
      {
        name: 'Algiers',
        id: '16'
      },
      {
        name: 'Annaba',
        id: '23'
      },
      {
        name: 'Batna',
        id: '05'
      },
      {
        name: 'Béchar',
        id: '08'
      },
      {
        name: 'Béjaïa',
        id: '06'
      },
      {
        name: 'Biskra',
        id: '07'
      },
      {
        name: 'Blida',
        id: '09'
      },
      {
        name: 'Bordj Bou Arréridj',
        id: '34'
      },
      {
        name: 'Bouïra',
        id: '10'
      },
      {
        name: 'Boumerdès',
        id: '35'
      },
      {
        name: 'Chlef',
        id: '02'
      },
      {
        name: 'Constantine',
        id: '25'
      },
      {
        name: 'Djelfa',
        id: '17'
      },
      {
        name: 'El Bayadh',
        id: '32'
      },
      {
        name: 'El Oued',
        id: '39'
      },
      {
        name: 'El Tarf',
        id: '36'
      },
      {
        name: 'Ghardaïa',
        id: '47'
      },
      {
        name: 'Guelma',
        id: '24'
      },
      {
        name: 'Illizi',
        id: '33'
      },
      {
        name: 'Jijel',
        id: '18'
      },
      {
        name: 'Khenchela',
        id: '40'
      },
      {
        name: 'Laghouat',
        id: '03'
      },
      {
        name: 'Mascara',
        id: '29'
      },
      {
        name: 'Médéa',
        id: '26'
      },
      {
        name: 'Mila',
        id: '43'
      },
      {
        name: 'Mostaganem',
        id: '27'
      },
      {
        name: 'Msila',
        id: '28'
      },
      {
        name: 'Naâma',
        id: '45'
      },
      {
        name: 'Oran',
        id: '31'
      },
      {
        name: 'Ouargla',
        id: '30'
      },
      {
        name: 'Oum el Bouaghi',
        id: '04'
      },
      {
        name: 'Relizane',
        id: '48'
      },
      {
        name: 'Saïda',
        id: '20'
      },
      {
        name: 'Sétif',
        id: '19'
      },
      {
        name: 'Sidi Bel Abbès',
        id: '22'
      },
      {
        name: 'Skikda',
        id: '21'
      },
      {
        name: 'Souk Ahras',
        id: '41'
      },
      {
        name: 'Tamanghasset',
        id: '11'
      },
      {
        name: 'Tébessa',
        id: '12'
      },
      {
        name: 'Tiaret',
        id: '14'
      },
      {
        name: 'Tindouf',
        id: '37'
      },
      {
        name: 'Tipaza',
        id: '42'
      },
      {
        name: 'Tissemsilt',
        id: '38'
      },
      {
        name: 'Tizi Ouzou',
        id: '15'
      },
      {
        name: 'Tlemcen',
        id: '13'
      }
    ]
  },
  {
    countryName: 'American Samoa',
    countryShortCode: 'AS',
    regions: [
      {
        name: 'Tutuila',
        id: '01'
      },
      {
        name: 'Aunu\'u',
        id: '02'
      },
      {
        name: 'Ta\'ū',
        id: '03'
      },
      {
        name: 'Ofu‑Olosega',
        id: '04'
      },
      {
        name: 'Rose Atoll',
        id: '21'
      },
      {
        name: 'Swains Island',
        id: '22'
      }
    ]
  },
  {
    countryName: 'Andorra',
    countryShortCode: 'AD',
    regions: [
      {
        name: 'Andorra la Vella',
        id: '07'
      },
      {
        name: 'Canillo',
        id: '02'
      },
      {
        name: 'Encamp',
        id: '03'
      },
      {
        name: 'Escaldes-Engordany',
        id: '08'
      },
      {
        name: 'La Massana',
        id: '04'
      },
      {
        name: 'Ordino',
        id: '05'
      },
      {
        name: 'Sant Julià de Lòria',
        id: '06'
      }
    ]
  },
  {
    countryName: 'Angola',
    countryShortCode: 'AO',
    regions: [
      {
        name: 'Bengo',
        id: 'BGO'
      },
      {
        name: 'Benguela',
        id: 'BGU'
      },
      {
        name: 'Bié',
        id: 'BIE'
      },
      {
        name: 'Cabinda',
        id: 'CAB'
      },
      {
        name: 'Cuando Cubango',
        id: 'CCU'
      },
      {
        name: 'Cuanza Norte',
        id: 'CNO'
      },
      {
        name: 'Cuanza Sul',
        id: 'CUS'
      },
      {
        name: 'Cunene',
        id: 'CNN'
      },
      {
        name: 'Huambo',
        id: 'HUA'
      },
      {
        name: 'Huíla',
        id: 'HUI'
      },
      {
        name: 'Luanda',
        id: 'LUA'
      },
      {
        name: 'Lunda Norte',
        id: 'LNO'
      },
      {
        name: 'Lunda Sul',
        id: 'LSU'
      },
      {
        name: 'Malanje',
        id: 'MAL'
      },
      {
        name: 'Moxico',
        id: 'MOX'
      },
      {
        name: 'Namibe',
        id: 'NAM'
      },
      {
        name: 'Uíge',
        id: 'UIG'
      },
      {
        name: 'Zaire',
        id: 'ZAI'
      }
    ]
  },
  {
    countryName: 'Anguilla',
    countryShortCode: 'AI',
    regions: [
      {
        name: 'Anguilla',
        id: '01'
      },
      {
        name: 'Anguillita Island',
        id: '02'
      },
      {
        name: 'Blowing Rock',
        id: '03'
      },
      {
        name: 'Cove Cay',
        id: '04'
      },
      {
        name: 'Crocus Cay',
        id: '05'
      },
      {
        name: 'Deadman\'s Cay',
        id: '06'
      },
      {
        name: 'Dog Island',
        id: '07'
      },
      {
        name: 'East Cay',
        id: '08'
      },
      {
        name: 'Little Island',
        id: '09'
      },
      {
        name: 'Little Scrub Island',
        id: '10'
      },
      {
        name: 'Mid Cay',
        id: '11'
      },
      {
        name: 'North Cay',
        id: '12'
      },
      {
        name: 'Prickly Pear Cays',
        id: '13'
      },
      {
        name: 'Rabbit Island',
        id: '14'
      },
      {
        name: 'Sandy Island/Sand Island',
        id: '15'
      },
      {
        name: 'Scilly Cay',
        id: '16'
      },
      {
        name: 'Scrub Island',
        id: '17'
      },
      {
        name: 'Seal Island',
        id: '18'
      },
      {
        name: 'Sombrero/Hat Island',
        id: '19'
      },
      {
        name: 'South Cay',
        id: '20'
      },
      {
        name: 'South Wager Island',
        id: '21'
      },
      {
        name: 'West Cay',
        id: '22'
      }
    ]
  },
  {
    countryName: 'Antarctica',
    countryShortCode: 'AQ',
    regions: [
      {
        name: 'Antarctica',
        id: 'AQ'
      }
    ]
  },
  {
    countryName: 'Antigua and Barbuda',
    countryShortCode: 'AG',
    regions: [
      {
        name: 'Antigua Island',
        id: '01'
      },
      {
        name: 'Barbuda Island',
        id: '02'
      },
      {
        name: 'Bird Island',
        id: '04'
      },
      {
        name: 'Bishop Island',
        id: '05'
      },
      {
        name: 'Blake Island',
        id: '06'
      },
      {
        name: 'Crump Island',
        id: '09'
      },
      {
        name: 'Dulcina Island',
        id: '10'
      },
      {
        name: 'Exchange Island',
        id: '11'
      },
      {
        name: 'Five Islands',
        id: '12'
      },
      {
        name: 'Great Bird Island',
        id: '13'
      },
      {
        name: 'Green Island',
        id: '14'
      },
      {
        name: 'Guiana Island',
        id: '15'
      },
      {
        name: 'Hawes Island',
        id: '17'
      },
      {
        name: 'Hells Gate Island',
        id: '16'
      },
      {
        name: 'Henry Island',
        id: '18'
      },
      {
        name: 'Johnson Island',
        id: '19'
      },
      {
        name: 'Kid Island',
        id: '20'
      },
      {
        name: 'Lobster Island',
        id: '22'
      },
      {
        name: 'Maiden Island',
        id: '24'
      },
      {
        name: 'Moor Island',
        id: '25'
      },
      {
        name: 'Nanny Island',
        id: '26'
      },
      {
        name: 'Pelican Island',
        id: '27'
      },
      {
        name: 'Prickly Pear Island',
        id: '28'
      },
      {
        name: 'Rabbit Island',
        id: '29'
      },
      {
        name: 'Red Head Island',
        id: '31'
      },
      {
        name: 'Redonda Island',
        id: '03'
      },
      {
        name: 'Sandy Island',
        id: '32'
      },
      {
        name: 'Smith Island',
        id: '33'
      },
      {
        name: 'The Sisters',
        id: '34'
      },
      {
        name: 'Vernon Island',
        id: '35'
      },
      {
        name: 'Wicked Will Island',
        id: '36'
      },
      {
        name: 'York Island',
        id: '37'
      }
    ]
  },
  {
    countryName: 'Argentina',
    countryShortCode: 'AR',
    regions: [
      {
        name: 'Buenos Aires',
        id: 'B'
      },
      {
        name: 'Capital Federal',
        id: 'C'
      },
      {
        name: 'Catamarca',
        id: 'K'
      },
      {
        name: 'Chaco',
        id: 'H'
      },
      {
        name: 'Chubut',
        id: 'U'
      },
      {
        name: 'Córdoba',
        id: 'X'
      },
      {
        name: 'Corrientes',
        id: 'W'
      },
      {
        name: 'Entre Ríos',
        id: 'E'
      },
      {
        name: 'Formosa',
        id: 'P'
      },
      {
        name: 'Jujuy',
        id: 'Y'
      },
      {
        name: 'La Pampa',
        id: 'L'
      },
      {
        name: 'La Rioja',
        id: 'F'
      },
      {
        name: 'Mendoza',
        id: 'M'
      },
      {
        name: 'Misiones',
        id: 'N'
      },
      {
        name: 'Neuquén',
        id: 'Q'
      },
      {
        name: 'Río Negro',
        id: 'R'
      },
      {
        name: 'Salta',
        id: 'A'
      },
      {
        name: 'San Juan',
        id: 'J'
      },
      {
        name: 'San Luis',
        id: 'D'
      },
      {
        name: 'Santa Cruz',
        id: 'Z'
      },
      {
        name: 'Santa Fe',
        id: 'S'
      },
      {
        name: 'Santiago del Estero',
        id: 'G'
      },
      {
        name: 'Tierra del Fuego',
        id: 'V'
      },
      {
        name: 'Tucumán',
        id: 'T'
      }
    ]
  },
  {
    countryName: 'Armenia',
    countryShortCode: 'AM',
    regions: [
      {
        name: 'Aragatsotn',
        id: 'AG'
      },
      {
        name: 'Ararat',
        id: 'AR'
      },
      {
        name: 'Armavir',
        id: 'AV'
      },
      {
        name: 'Gegharkunik',
        id: 'GR'
      },
      {
        name: 'Kotayk',
        id: 'KT'
      },
      {
        name: 'Lori',
        id: 'LO'
      },
      {
        name: 'Shirak',
        id: 'SH'
      },
      {
        name: 'Syunik',
        id: 'SU'
      },
      {
        name: 'Tavush',
        id: 'TV'
      },
      {
        name: 'Vayots Dzor',
        id: 'VD'
      },
      {
        name: 'Yerevan',
        id: 'ER'
      }
    ]
  },
  {
    countryName: 'Aruba',
    countryShortCode: 'AW',
    regions: [
      {
        name: 'Aruba',
        id: 'AW'
      }
    ]
  },
  {
    countryName: 'Australia',
    countryShortCode: 'AU',
    regions: [
      {
        name: 'Australian Capital Territory',
        id: 'ACT'
      },
      {
        name: 'New South Wales',
        id: 'NSW'
      },
      {
        name: 'Northern Territory',
        id: 'NT'
      },
      {
        name: 'Queensland',
        id: 'QLD'
      },
      {
        name: 'South Australia',
        id: 'SA'
      },
      {
        name: 'Tasmania',
        id: 'TAS'
      },
      {
        name: 'Victoria',
        id: 'VIC'
      },
      {
        name: 'Western Australia',
        id: 'WA'
      }
    ]
  },
  {
    countryName: 'Austria',
    countryShortCode: 'AT',
    regions: [
      {
        name: 'Burgenland',
        id: '1'
      },
      {
        name: 'Kärnten',
        id: '2'
      },
      {
        name: 'Niederösterreich',
        id: '3'
      },
      {
        name: 'Oberösterreich',
        id: '4'
      },
      {
        name: 'Salzburg',
        id: '5'
      },
      {
        name: 'Steiermark',
        id: '6'
      },
      {
        name: 'Tirol',
        id: '7'
      },
      {
        name: 'Vorarlberg',
        id: '8'
      },
      {
        name: 'Wien',
        id: '9'
      }
    ]
  },
  {
    countryName: 'Azerbaijan',
    countryShortCode: 'AZ',
    regions: [
      {
        name: 'Abşeron',
        id: 'ABS'
      },
      {
        name: 'Ağcabədi',
        id: 'AGC'
      },
      {
        name: 'Ağdam',
        id: 'AGM'
      },
      {
        name: 'Ağdaş',
        id: 'AGS'
      },
      {
        name: 'Ağstafa',
        id: 'AGA'
      },
      {
        name: 'Ağsu',
        id: 'AGU'
      },
      {
        name: 'Astara',
        id: 'AST'
      },
      {
        name: 'Bakı',
        id: 'BAK'
      },
      {
        name: 'Babək',
        id: 'BAB'
      },
      {
        name: 'Balakən',
        id: 'BAL'
      },
      {
        name: 'Bərdə',
        id: 'BAR'
      },
      {
        name: 'Beyləqan',
        id: 'BEY'
      },
      {
        name: 'Biləsuvar',
        id: 'BIL'
      },
      {
        name: 'Cəbrayıl',
        id: 'CAB'
      },
      {
        name: 'Cəlilabad',
        id: 'CAL'
      },
      {
        name: 'Culfa',
        id: 'CUL'
      },
      {
        name: 'Daşkəsən',
        id: 'DAS'
      },
      {
        name: 'Füzuli',
        id: 'FUZ'
      },
      {
        name: 'Gədəbəy',
        id: 'GAD'
      },
      {
        name: 'Goranboy',
        id: 'GOR'
      },
      {
        name: 'Göyçay',
        id: 'GOY'
      },
      {
        name: 'Göygöl',
        id: 'GYG'
      },
      {
        name: 'Hacıqabul',
        id: 'HAC'
      },
      {
        name: 'İmişli',
        id: 'IMI'
      },
      {
        name: 'İsmayıllı',
        id: 'ISM'
      },
      {
        name: 'Kəlbəcər',
        id: 'KAL'
      },
      {
        name: 'Kǝngǝrli',
        id: 'KAN'
      },
      {
        name: 'Kürdəmir',
        id: 'KUR'
      },
      {
        name: 'Laçın',
        id: 'LAC'
      },
      {
        name: 'Lənkəran',
        id: 'LAN'
      },
      {
        name: 'Lerik',
        id: 'LER'
      },
      {
        name: 'Masallı',
        id: 'MAS'
      },
      {
        name: 'Neftçala',
        id: 'NEF'
      },
      {
        name: 'Oğuz',
        id: 'OGU'
      },
      {
        name: 'Ordubad',
        id: 'ORD'
      },
      {
        name: 'Qəbələ',
        id: 'QAB'
      },
      {
        name: 'Qax',
        id: 'QAX'
      },
      {
        name: 'Qazax',
        id: 'QAZ'
      },
      {
        name: 'Qobustan',
        id: 'QOB'
      },
      {
        name: 'Quba',
        id: 'QBA'
      },
      {
        name: 'Qubadli',
        id: 'QBI'
      },
      {
        name: 'Qusar',
        id: 'QUS'
      },
      {
        name: 'Saatlı',
        id: 'SAT'
      },
      {
        name: 'Sabirabad',
        id: 'SAB'
      },
      {
        name: 'Şabran',
        id: 'SBN'
      },
      {
        name: 'Sədərək',
        id: 'SAD'
      },
      {
        name: 'Şahbuz',
        id: 'SAH'
      },
      {
        name: 'Şəki',
        id: 'SAK'
      },
      {
        name: 'Salyan',
        id: 'SAL'
      },
      {
        name: 'Şamaxı',
        id: 'SMI'
      },
      {
        name: 'Şəmkir',
        id: 'SKR'
      },
      {
        name: 'Samux',
        id: 'SMX'
      },
      {
        name: 'Şərur',
        id: 'SAR'
      },
      {
        name: 'Siyəzən',
        id: 'SIY'
      },
      {
        name: 'Şuşa',
        id: 'SUS'
      },
      {
        name: 'Tərtər',
        id: 'TAR'
      },
      {
        name: 'Tovuz',
        id: 'TOV'
      },
      {
        name: 'Ucar',
        id: 'UCA'
      },
      {
        name: 'Xaçmaz',
        id: 'XAC'
      },
      {
        name: 'Xızı',
        id: 'XIZ'
      },
      {
        name: 'Xocalı',
        id: 'XCI'
      },
      {
        name: 'Xocavənd',
        id: 'XVD'
      },
      {
        name: 'Yardımlı',
        id: 'YAR'
      },
      {
        name: 'Yevlax',
        id: 'YEV'
      },
      {
        name: 'Zəngilan',
        id: 'ZAN'
      },
      {
        name: 'Zaqatala',
        id: 'ZAQ'
      },
      {
        name: 'Zərdab',
        id: 'ZAR'
      }
    ]
  },
  {
    countryName: 'Bahamas',
    countryShortCode: 'BS',
    regions: [
      {
        name: 'Acklins Island',
        id: '01'
      },
      {
        name: 'Berry Islands',
        id: '22'
      },
      {
        name: 'Bimini',
        id: '02'
      },
      {
        name: 'Black Point',
        id: '23'
      },
      {
        name: 'Cat Island',
        id: '03'
      },
      {
        name: 'Central Abaco',
        id: '24'
      },
      {
        name: 'Crooked Island and Long Cay',
        id: '28'
      },
      {
        name: 'East Grand Bahama',
        id: '29'
      },
      {
        name: 'Exuma',
        id: '04'
      },
      {
        name: 'Freeport',
        id: '05'
      },
      {
        name: 'Fresh Creek',
        id: '06'
      },
      {
        name: 'Governor\'s Harbour',
        id: '07'
      },
      {
        name: 'Green Turtle Cay',
        id: '08'
      },
      {
        name: 'Harbour Island',
        id: '09'
      },
      {
        name: 'High Rock',
        id: '10'
      },
      {
        name: 'Inagua',
        id: '11'
      },
      {
        name: 'Kemps Bay',
        id: '12'
      },
      {
        name: 'Long Island',
        id: '13'
      },
      {
        name: 'Marsh Harbour',
        id: '14'
      },
      {
        name: 'Mayaguana',
        id: '15'
      },
      {
        name: 'Moore’s Island',
        id: '40'
      },
      {
        name: 'New Providence',
        id: '16'
      },
      {
        name: 'Nichollstown and Berry Islands',
        id: '17'
      },
      {
        name: 'North Abaco',
        id: '42'
      },
      {
        name: 'North Andros',
        id: '41'
      },
      {
        name: 'North Eleuthera',
        id: '33'
      },
      {
        name: 'Ragged Island',
        id: '18'
      },
      {
        name: 'Rock Sound',
        id: '19'
      },
      {
        name: 'San Salvador and Rum Cay',
        id: '20'
      },
      {
        name: 'Sandy Point',
        id: '21'
      },
      {
        name: 'South Abaco',
        id: '35'
      },
      {
        name: 'South Andros',
        id: '36'
      },
      {
        name: 'South Eleuthera',
        id: '37'
      },
      {
        name: 'West Grand Bahama',
        id: '39'
      }
    ]
  },
  {
    countryName: 'Bahrain',
    countryShortCode: 'BH',
    regions: [
      {
        name: 'Al Janūbīyah',
        id: '14'
      },
      {
        name: 'Al Manāmah',
        id: '13'
      },
      {
        name: 'Al Muḩarraq',
        id: '15'
      },
      {
        name: 'Al Wusţá',
        id: '16'
      },
      {
        name: 'Ash Shamālīyah',
        id: '17'
      }
    ]
  },
  {
    countryName: 'Bangladesh',
    countryShortCode: 'BD',
    regions: [
      {
        name: 'Barisal',
        id: 'A'
      },
      {
        name: 'Chittagong',
        id: 'B'
      },
      {
        name: 'Dhaka',
        id: 'C'
      },
      {
        name: 'Khulna',
        id: 'D'
      },
      {
        name: 'Mymensingh',
        id: 'M'
      },
      {
        name: 'Rajshahi',
        id: 'E'
      },
      {
        name: 'Rangpur',
        id: 'F'
      },
      {
        name: 'Sylhet',
        id: 'G'
      }
    ]
  },
  {
    countryName: 'Barbados',
    countryShortCode: 'BB',
    regions: [
      {
        name: 'Christ Church',
        id: '01'
      },
      {
        name: 'Saint Andrew',
        id: '02'
      },
      {
        name: 'Saint George',
        id: '03'
      },
      {
        name: 'Saint James',
        id: '04'
      },
      {
        name: 'Saint John',
        id: '05'
      },
      {
        name: 'Saint Joseph',
        id: '06'
      },
      {
        name: 'Saint Lucy',
        id: '07'
      },
      {
        name: 'Saint Michael',
        id: '08'
      },
      {
        name: 'Saint Peter',
        id: '09'
      },
      {
        name: 'Saint Philip',
        id: '10'
      },
      {
        name: 'Saint Thomas',
        id: '11'
      }
    ]
  },
  {
    countryName: 'Belarus',
    countryShortCode: 'BY',
    regions: [
      {
        name: 'Brest voblast',
        id: 'BR'
      },
      {
        name: 'Gorod Minsk',
        id: 'HM'
      },
      {
        name: 'Homiel voblast',
        id: 'HO'
      },
      {
        name: 'Hrodna voblast',
        id: 'HR'
      },
      {
        name: 'Mahilyow voblast',
        id: 'MA'
      },
      {
        name: 'Minsk voblast',
        id: 'MI'
      },
      {
        name: 'Vitsebsk voblast',
        id: 'VI'
      }
    ]
  },
  {
    countryName: 'Belgium',
    countryShortCode: 'BE',
    regions: [
      {
        name: 'Brussels',
        id: 'BRU'
      },
      {
        name: 'Flanders',
        id: 'VLG'
      },
      {
        name: 'Wallonia',
        id: 'WAL'
      }
    ]
  },
  {
    countryName: 'Belize',
    countryShortCode: 'BZ',
    regions: [
      {
        name: 'Belize District',
        id: 'BZ'
      },
      {
        name: 'Cayo District',
        id: 'CY'
      },
      {
        name: 'Corozal District',
        id: 'CZL'
      },
      {
        name: 'Orange Walk District',
        id: 'OW'
      },
      {
        name: 'Stann Creek District',
        id: 'SC'
      },
      {
        name: 'Toledo District',
        id: 'TOL'
      }
    ]
  },
  {
    countryName: 'Benin',
    countryShortCode: 'BJ',
    regions: [
      {
        name: 'Alibori',
        id: 'AL'
      },
      {
        name: 'Atakora',
        id: 'AK'
      },
      {
        name: 'Atlantique',
        id: 'AQ'
      },
      {
        name: 'Borgou',
        id: 'BO'
      },
      {
        name: 'Collines Department',
        id: 'CO'
      },
      {
        name: 'Donga',
        id: 'DO'
      },
      {
        name: 'Kouffo',
        id: 'KO'
      },
      {
        name: 'Littoral Department',
        id: 'LI'
      },
      {
        name: 'Mono Department',
        id: 'MO'
      },
      {
        name: 'Ouémé',
        id: 'OU'
      },
      {
        name: 'Plateau',
        id: 'PL'
      },
      {
        name: 'Zou',
        id: 'ZO'
      }
    ]
  },
  {
    countryName: 'Bermuda',
    countryShortCode: 'BM',
    regions: [
      {
        name: 'City of Hamilton',
        id: '03'
      },
      {
        name: 'Devonshire Parish',
        id: '01'
      },
      {
        name: 'Hamilton Parish',
        id: '02'
      },
      {
        name: 'Paget Parish',
        id: '04'
      },
      {
        name: 'Pembroke Parish',
        id: '05'
      },
      {
        name: 'Sandys Parish',
        id: '08'
      },
      {
        name: 'Smith\'s Parish',
        id: '09'
      },
      {
        name: 'Southampton Parish',
        id: '10'
      },
      {
        name: 'St. George\'s Parish',
        id: '07'
      },
      {
        name: 'Town of St. George',
        id: '06'
      },
      {
        name: 'Warwick Parish',
        id: '11'
      }
    ]
  },
  {
    countryName: 'Bhutan',
    countryShortCode: 'BT',
    regions: [
      {
        name: 'Bumthang',
        id: '33'
      },
      {
        name: 'Chhukha',
        id: '12'
      },
      {
        name: 'Dagana',
        id: '22'
      },
      {
        name: 'Gasa',
        id: 'GA'
      },
      {
        name: 'Haa',
        id: '13'
      },
      {
        name: 'Lhuntse',
        id: '44'
      },
      {
        name: 'Mongar',
        id: '42'
      },
      {
        name: 'Paro',
        id: '11'
      },
      {
        name: 'Pemagatshel',
        id: '43'
      },
      {
        name: 'Punakha',
        id: '23'
      },
      {
        name: 'Samdrup Jongkhar',
        id: '45'
      },
      {
        name: 'Samtse',
        id: '14'
      },
      {
        name: 'Sarpang',
        id: '31'
      },
      {
        name: 'Thimphu',
        id: '15'
      },
      {
        name: 'Trashigang',
        id: '41'
      },
      {
        name: 'Trashiyangtse',
        id: 'TY'
      },
      {
        name: 'Trongsa',
        id: '32'
      },
      {
        name: 'Tsirang',
        id: '21'
      },
      {
        name: 'Wangdue Phodrang',
        id: '24'
      },
      {
        name: 'Zhemgang',
        id: '34'
      }
    ]
  },
  {
    countryName: 'Bolivia',
    countryShortCode: 'BO',
    regions: [
      {
        name: 'Beni',
        id: 'BE'
      },
      {
        name: 'Chuquisaca',
        id: 'CH'
      },
      {
        name: 'Cochabamba',
        id: 'CB'
      },
      {
        name: 'La Paz',
        id: 'LP'
      },
      {
        name: 'Oruro',
        id: 'OR'
      },
      {
        name: 'Pando',
        id: 'PD'
      },
      {
        name: 'Potosí',
        id: 'PT'
      },
      {
        name: 'Santa Cruz',
        id: 'SC'
      },
      {
        name: 'Tarija',
        id: 'TJ'
      }
    ]
  },
  {
    countryName: 'Bonaire, Sint Eustatius and Saba',
    countryShortCode: 'BQ',
    regions: [
      {
        name: 'Bonaire',
        id: 'BO'
      },
      {
        name: 'Saba Isand',
        id: 'SA'
      },
      {
        name: 'Sint Eustatius',
        id: 'SE'
      }
    ]
  },
  {
    countryName: 'Bosnia and Herzegovina',
    countryShortCode: 'BA',
    regions: [
      {
        name: 'Brčko Distrikt',
        id: 'BRC'
      },
      {
        name: 'Federacija Bosne i Hercegovine',
        id: 'BIH'
      },
      {
        name: 'Republika Srpska',
        id: 'SRP'
      }
    ]
  },
  {
    countryName: 'Botswana',
    countryShortCode: 'BW',
    regions: [
      {
        name: 'Central',
        id: 'CE'
      },
      {
        name: 'Ghanzi',
        id: 'GH'
      },
      {
        name: 'Kgalagadi',
        id: 'KG'
      },
      {
        name: 'Kgatleng',
        id: 'KL'
      },
      {
        name: 'Kweneng',
        id: 'KW'
      },
      {
        name: 'North West',
        id: 'NW'
      },
      {
        name: 'North-East',
        id: 'NE'
      },
      {
        name: 'South East',
        id: 'SE'
      },
      {
        name: 'Southern',
        id: 'SO'
      }
    ]
  },
  {
    countryName: 'Bouvet Island',
    countryShortCode: 'BV',
    regions: [
      {
        name: 'Bouvet Island',
        id: 'BV'
      }
    ]
  },
  {
    countryName: 'Brazil',
    countryShortCode: 'BR',
    regions: [
      {
        name: 'Acre',
        id: 'AC'
      },
      {
        name: 'Alagoas',
        id: 'AL'
      },
      {
        name: 'Amapá',
        id: 'AP'
      },
      {
        name: 'Amazonas',
        id: 'AM'
      },
      {
        name: 'Bahia',
        id: 'BA'
      },
      {
        name: 'Ceará',
        id: 'CE'
      },
      {
        name: 'Distrito Federal',
        id: 'DF'
      },
      {
        name: 'Espírito Santo',
        id: 'ES'
      },
      {
        name: 'Goiás',
        id: 'GO'
      },
      {
        name: 'Maranhão',
        id: 'MA'
      },
      {
        name: 'Mato Grosso',
        id: 'MT'
      },
      {
        name: 'Mato Grosso do Sul',
        id: 'MS'
      },
      {
        name: 'Minas Gerais',
        id: 'MG'
      },
      {
        name: 'Pará',
        id: 'PA'
      },
      {
        name: 'Paraíba',
        id: 'PB'
      },
      {
        name: 'Paraná',
        id: 'PR'
      },
      {
        name: 'Pernambuco',
        id: 'PE'
      },
      {
        name: 'Piauí',
        id: 'PI'
      },
      {
        name: 'Rio de Janeiro',
        id: 'RJ'
      },
      {
        name: 'Rio Grande do Norte',
        id: 'RN'
      },
      {
        name: 'Rio Grande do Sul',
        id: 'RS'
      },
      {
        name: 'Rondônia',
        id: 'RO'
      },
      {
        name: 'Roraima',
        id: 'RR'
      },
      {
        name: 'Santa Catarina',
        id: 'SC'
      },
      {
        name: 'São Paulo',
        id: 'SP'
      },
      {
        name: 'Sergipe',
        id: 'SE'
      },
      {
        name: 'Tocantins',
        id: 'TO'
      }
    ]
  },
  {
    countryName: 'British Indian Ocean Territory',
    countryShortCode: 'IO',
    regions: [
      {
        name: 'British Indian Ocean Territory',
        id: 'IO'
      }
    ]
  },
  {
    countryName: 'Brunei Darussalam',
    countryShortCode: 'BN',
    regions: [
      {
        name: 'Belait',
        id: 'BE'
      },
      {
        name: 'Brunei Muara',
        id: 'BM'
      },
      {
        name: 'Temburong',
        id: 'TE'
      },
      {
        name: 'Tutong',
        id: 'TU'
      }
    ]
  },
  {
    countryName: 'Bulgaria',
    countryShortCode: 'BG',
    regions: [
      {
        name: 'Blagoevgrad',
        id: '01'
      },
      {
        name: 'Burgas',
        id: '02'
      },
      {
        name: 'Dobrich',
        id: '08'
      },
      {
        name: 'Gabrovo',
        id: '07'
      },
      {
        name: 'Jambol',
        id: '28'
      },
      {
        name: 'Khaskovo',
        id: '26'
      },
      {
        name: 'Kjustendil',
        id: '10'
      },
      {
        name: 'Kurdzhali',
        id: '09'
      },
      {
        name: 'Lovech',
        id: '11'
      },
      {
        name: 'Montana',
        id: '12'
      },
      {
        name: 'Pazardzhik',
        id: '13'
      },
      {
        name: 'Pernik',
        id: '14'
      },
      {
        name: 'Pleven',
        id: '15'
      },
      {
        name: 'Plovdiv',
        id: '16'
      },
      {
        name: 'Razgrad',
        id: '17'
      },
      {
        name: 'Ruse',
        id: '18'
      },
      {
        name: 'Shumen',
        id: '27'
      },
      {
        name: 'Silistra',
        id: '19'
      },
      {
        name: 'Sliven',
        id: '20'
      },
      {
        name: 'Smoljan',
        id: '21'
      },
      {
        name: 'Sofija',
        id: '23'
      },
      {
        name: 'Sofija-Grad',
        id: '22'
      },
      {
        name: 'Stara Zagora',
        id: '24'
      },
      {
        name: 'Turgovishhe',
        id: '25'
      },
      {
        name: 'Varna',
        id: '03'
      },
      {
        name: 'Veliko Turnovo',
        id: '04'
      },
      {
        name: 'Vidin',
        id: '05'
      },
      {
        name: 'Vraca',
        id: '06'
      }
    ]
  },
  {
    countryName: 'Burkina Faso',
    countryShortCode: 'BF',
    regions: [
      {
        name: 'Balé',
        id: 'BAL'
      },
      {
        name: 'Bam/Lake Bam',
        id: 'BAM'
      },
      {
        name: 'Banwa Province',
        id: 'BAN'
      },
      {
        name: 'Bazèga',
        id: 'BAZ'
      },
      {
        name: 'Bougouriba',
        id: 'BGR'
      },
      {
        name: 'Boulgou Province',
        id: 'BLG'
      },
      {
        name: 'Boulkiemdé',
        id: 'BLK'
      },
      {
        name: 'Comoé/Komoe',
        id: 'COM'
      },
      {
        name: 'Ganzourgou Province',
        id: 'GAN'
      },
      {
        name: 'Gnagna',
        id: 'GNA'
      },
      {
        name: 'Gourma Province',
        id: 'GOU'
      },
      {
        name: 'Houet',
        id: 'HOU'
      },
      {
        name: 'Ioba',
        id: 'IOB'
      },
      {
        name: 'Kadiogo',
        id: 'KAD'
      },
      {
        name: 'Kénédougou',
        id: 'KEN'
      },
      {
        name: 'Komondjari',
        id: 'KMD'
      },
      {
        name: 'Kompienga',
        id: 'KMP'
      },
      {
        name: 'Kossi Province',
        id: 'KOS'
      },
      {
        name: 'Koulpélogo',
        id: 'KOP'
      },
      {
        name: 'Kouritenga',
        id: 'KOT'
      },
      {
        name: 'Kourwéogo',
        id: 'KOW'
      },
      {
        name: 'Léraba',
        id: 'LER'
      },
      {
        name: 'Loroum',
        id: 'LOR'
      },
      {
        name: 'Mouhoun',
        id: 'MOU'
      },
      {
        name: 'Namentenga',
        id: 'NAM'
      },
      {
        name: 'Naouri/Nahouri',
        id: 'NAO'
      },
      {
        name: 'Nayala',
        id: 'NAY'
      },
      {
        name: 'Noumbiel',
        id: 'NOU'
      },
      {
        name: 'Oubritenga',
        id: 'OUB'
      },
      {
        name: 'Oudalan',
        id: 'OUD'
      },
      {
        name: 'Passoré',
        id: 'PAS'
      },
      {
        name: 'Poni',
        id: 'PON'
      },
      {
        name: 'Sanguié',
        id: 'SNG'
      },
      {
        name: 'Sanmatenga',
        id: 'SMT'
      },
      {
        name: 'Séno',
        id: 'SEN'
      },
      {
        name: 'Sissili',
        id: 'SIS'
      },
      {
        name: 'Soum',
        id: 'SOM'
      },
      {
        name: 'Sourou',
        id: 'SOR'
      },
      {
        name: 'Tapoa',
        id: 'TAP'
      },
      {
        name: 'Tui/Tuy',
        id: 'TUI'
      },
      {
        name: 'Yagha',
        id: 'YAG'
      },
      {
        name: 'Yatenga',
        id: 'YAT'
      },
      {
        name: 'Ziro',
        id: 'ZIR'
      },
      {
        name: 'Zondoma',
        id: 'ZON'
      },
      {
        name: 'Zoundwéogo',
        id: 'ZOU'
      }
    ]
  },
  {
    countryName: 'Burundi',
    countryShortCode: 'BI',
    regions: [
      {
        name: 'Bubanza',
        id: 'BB'
      },
      {
        name: 'Bujumbura Mairie',
        id: 'BM'
      },
      {
        name: 'Bujumbura Rural',
        id: 'BL'
      },
      {
        name: 'Bururi',
        id: 'BR'
      },
      {
        name: 'Cankuzo',
        id: 'CA'
      },
      {
        name: 'Cibitoke',
        id: 'CI'
      },
      {
        name: 'Gitega',
        id: 'GI'
      },
      {
        name: 'Karuzi',
        id: 'KR'
      },
      {
        name: 'Kayanza',
        id: 'KY'
      },
      {
        name: 'Kirundo',
        id: 'KI'
      },
      {
        name: 'Makamba',
        id: 'MA'
      },
      {
        name: 'Muramvya',
        id: 'MU'
      },
      {
        name: 'Muyinga',
        id: 'MY'
      },
      {
        name: 'Mwaro',
        id: 'MW'
      },
      {
        name: 'Ngozi',
        id: 'NG'
      },
      {
        name: 'Rutana',
        id: 'RT'
      },
      {
        name: 'Ruyigi',
        id: 'RY'
      }
    ]
  },
  {
    countryName: 'Cambodia',
    countryShortCode: 'KH',
    regions: [
      {
        name: 'Baat Dambang',
        id: '2'
      },
      {
        name: 'Banteay Mean Chey',
        id: '1'
      },
      {
        name: 'Kampong Chaam',
        id: '3'
      },
      {
        name: 'Kampong Chhnang',
        id: '4'
      },
      {
        name: 'Kampong Spueu',
        id: '5'
      },
      {
        name: 'Kampong Thum',
        id: '6'
      },
      {
        name: 'Kampot',
        id: '7'
      },
      {
        name: 'Kandaal',
        id: '8'
      },
      {
        name: 'Kaoh Kong',
        id: '9'
      },
      {
        name: 'Kracheh',
        id: '10'
      },
      {
        name: 'Krong Kaeb',
        id: '23'
      },
      {
        name: 'Krong Pailin',
        id: '24'
      },
      {
        name: 'Krong Preah Sihanouk',
        id: '18'
      },
      {
        name: 'Mondol Kiri',
        id: '11'
      },
      {
        name: 'Otdar Mean Chey',
        id: '22'
      },
      {
        name: 'Phnom Penh',
        id: '12'
      },
      {
        name: 'Pousaat',
        id: '15'
      },
      {
        name: 'Preah Vihear',
        id: '13'
      },
      {
        name: 'Prey Veaeng',
        id: '14'
      },
      {
        name: 'Rotanah Kiri',
        id: '16'
      },
      {
        name: 'Siem Reab',
        id: '17'
      },
      {
        name: 'Stueng Treng',
        id: '19'
      },
      {
        name: 'Svaay Rieng',
        id: '20'
      },
      {
        name: 'Taakaev',
        id: '21'
      },
      {
        name: 'Tbong Khmum',
        id: '25'
      }
    ]
  },
  {
    countryName: 'Cameroon',
    countryShortCode: 'CM',
    regions: [
      {
        name: 'Adamaoua',
        id: 'AD'
      },
      {
        name: 'Centre',
        id: 'CE'
      },
      {
        name: 'Est',
        id: 'ES'
      },
      {
        name: 'Extrême-Nord',
        id: 'EN'
      },
      {
        name: 'Littoral',
        id: 'LT'
      },
      {
        name: 'Nord',
        id: 'NO'
      },
      {
        name: 'Nord-Ouest',
        id: 'NW'
      },
      {
        name: 'Ouest',
        id: 'OU'
      },
      {
        name: 'Sud',
        id: 'SU'
      },
      {
        name: 'Sud-Ouest',
        id: 'SW'
      }
    ]
  },
  {
    countryName: 'Canada',
    countryShortCode: 'CA',
    regions: [
      {
        name: 'Alberta',
        id: 'AB'
      },
      {
        name: 'British Columbia',
        id: 'BC'
      },
      {
        name: 'Manitoba',
        id: 'MB'
      },
      {
        name: 'New Brunswick',
        id: 'NB'
      },
      {
        name: 'Newfoundland and Labrador',
        id: 'NL'
      },
      {
        name: 'Northwest Territories',
        id: 'NT'
      },
      {
        name: 'Nova Scotia',
        id: 'NS'
      },
      {
        name: 'Nunavut',
        id: 'NU'
      },
      {
        name: 'Ontario',
        id: 'ON'
      },
      {
        name: 'Prince Edward Island',
        id: 'PE'
      },
      {
        name: 'Quebec',
        id: 'QC'
      },
      {
        name: 'Saskatchewan',
        id: 'SK'
      },
      {
        name: 'Yukon',
        id: 'YT'
      }
    ]
  },
  {
    countryName: 'Cape Verde',
    countryShortCode: 'CV',
    regions: [
      {
        name: 'Boa Vista',
        id: 'BV'
      },
      {
        name: 'Brava',
        id: 'BR'
      },
      {
        name: 'Calheta de São Miguel',
        id: 'CS'
      },
      {
        name: 'Maio',
        id: 'MA'
      },
      {
        name: 'Mosteiros',
        id: 'MO'
      },
      {
        name: 'Paúl',
        id: 'PA'
      },
      {
        name: 'Porto Novo',
        id: 'PN'
      },
      {
        name: 'Praia',
        id: 'PR'
      },
      {
        name: 'Ribeira Brava',
        id: 'RB'
      },
      {
        name: 'Ribeira Grande',
        id: 'RG'
      },
      {
        name: 'Sal',
        id: 'SL'
      },
      {
        name: 'Santa Catarina',
        id: 'CA'
      },
      {
        name: 'Santa Cruz',
        id: 'CR'
      },
      {
        name: 'São Domingos',
        id: 'SD'
      },
      {
        name: 'São Filipe',
        id: 'SF'
      },
      {
        name: 'São Nicolau',
        id: 'SN'
      },
      {
        name: 'São Vicente',
        id: 'SV'
      },
      {
        name: 'Tarrafal',
        id: 'TA'
      },
      {
        name: 'Tarrafal de São Nicolau',
        id: 'TS'
      }
    ]
  },
  {
    countryName: 'Cayman Islands',
    countryShortCode: 'KY',
    regions: [
      {
        name: 'Creek'
      },
      {
        name: 'Eastern'
      },
      {
        name: 'Midland'
      },
      {
        name: 'South Town'
      },
      {
        name: 'Spot Bay'
      },
      {
        name: 'Stake Bay'
      },
      {
        name: 'West End'
      },
      {
        name: 'Western'
      }
    ]
  },
  {
    countryName: 'Central African Republic',
    countryShortCode: 'CF',
    regions: [
      {
        name: 'Bamingui-Bangoran',
        id: 'BB'
      },
      {
        name: 'Bangui',
        id: 'BGF'
      },
      {
        name: 'Basse-Kotto',
        id: 'BK'
      },
      {
        name: 'Haute-Kotto',
        id: 'HK'
      },
      {
        name: 'Haut-Mbomou',
        id: 'HM'
      },
      {
        name: 'Kémo',
        id: 'KG'
      },
      {
        name: 'Lobaye',
        id: 'LB'
      },
      {
        name: 'Mambéré-Kadéï',
        id: 'HS'
      },
      {
        name: 'Mbomou',
        id: 'MB'
      },
      {
        name: 'Nana-Grebizi',
        id: '10'
      },
      {
        name: 'Nana-Mambéré',
        id: 'NM'
      },
      {
        name: 'Ombella-M\'Poko',
        id: 'MP'
      },
      {
        name: 'Ouaka',
        id: 'UK'
      },
      {
        name: 'Ouham',
        id: 'AC'
      },
      {
        name: 'Ouham Péndé',
        id: 'OP'
      },
      {
        name: 'Sangha-Mbaéré',
        id: 'SE'
      },
      {
        name: 'Vakaga',
        id: 'VK'
      }
    ]
  },
  {
    countryName: 'Chad',
    countryShortCode: 'TD',
    regions: [
      {
        name: 'Bahr el Ghazal',
        id: 'BG'
      },
      {
        name: 'Batha',
        id: 'BA'
      },
      {
        name: 'Borkou',
        id: 'BO'
      },
      {
        name: 'Chari-Baguirmi',
        id: 'CB'
      },
      {
        name: 'Ennedi-Est',
        id: 'EE'
      },
      {
        name: 'Ennedi-Ouest',
        id: 'EO'
      },
      {
        name: 'Guéra',
        id: 'GR'
      },
      {
        name: 'Hadjer Lamis',
        id: 'HL'
      },
      {
        name: 'Kanem',
        id: 'KA'
      },
      {
        name: 'Lac',
        id: 'LC'
      },
      {
        name: 'Logone Occidental',
        id: 'LO'
      },
      {
        name: 'Logone Oriental',
        id: 'LR'
      },
      {
        name: 'Mondoul',
        id: 'MA'
      },
      {
        name: 'Mayo-Kébbi-Est',
        id: 'ME'
      },
      {
        name: 'Moyen-Chari',
        id: 'MC'
      },
      {
        name: 'Ouaddai',
        id: 'OD'
      },
      {
        name: 'Salamat',
        id: 'SA'
      },
      {
        name: 'Sila',
        id: 'SI'
      },
      {
        name: 'Tandjilé',
        id: 'TA'
      },
      {
        name: 'Tibesti',
        id: 'TI'
      },
      {
        name: 'Ville de Ndjamena',
        id: 'ND'
      },
      {
        name: 'Wadi Fira',
        id: 'WF'
      }
    ]
  },
  {
    countryName: 'Chile',
    countryShortCode: 'CL',
    regions: [
      {
        name: 'Aisén del General Carlos Ibáñez del Campo',
        id: 'AI'
      },
      {
        name: 'Antofagasta',
        id: 'AN'
      },
      {
        name: 'Araucanía',
        id: 'AR'
      },
      {
        name: 'Arica y Parinacota',
        id: 'AP'
      },
      {
        name: 'Atacama',
        id: 'AT'
      },
      {
        name: 'Bío-Bío',
        id: 'BI'
      },
      {
        name: 'Coquimbo',
        id: 'CO'
      },
      {
        name: 'Libertador General Bernardo O\'Higgins',
        id: 'LI'
      },
      {
        name: 'Los Lagos',
        id: 'LL'
      },
      {
        name: 'Los Ríos',
        id: 'LR'
      },
      {
        name: 'Magallanes y Antartica Chilena',
        id: 'MA'
      },
      {
        name: 'Marga-Marga',
        id: ''
      },
      {
        name: 'Maule',
        id: 'ML'
      },
      {
        name: 'Ñuble',
        id: 'NB'
      },
      {
        name: 'Región Metropolitana de Santiago',
        id: 'RM'
      },
      {
        name: 'Tarapacá',
        id: 'TA'
      },
      {
        name: 'Valparaíso',
        id: 'VS'
      }
    ]
  },
  {
    countryName: 'China',
    countryShortCode: 'CN',
    regions: [
      {
        name: 'Anhui',
        id: 'AH'
      },
      {
        name: 'Beijing',
        id: 'BJ'
      },
      {
        name: 'Chongqing',
        id: 'CQ'
      },
      {
        name: 'Fujian',
        id: 'FJ'
      },
      {
        name: 'Gansu',
        id: 'GS'
      },
      {
        name: 'Guangdong',
        id: 'GD'
      },
      {
        name: 'Guangxi',
        id: 'GX'
      },
      {
        name: 'Guizhou',
        id: 'GZ'
      },
      {
        name: 'Hainan',
        id: 'HI'
      },
      {
        name: 'Hebei',
        id: 'HE'
      },
      {
        name: 'Heilongjiang',
        id: 'HL'
      },
      {
        name: 'Henan',
        id: 'HA'
      },
      {
        name: 'Hong Kong',
        id: 'HK'
      },
      {
        name: 'Hubei',
        id: 'HB'
      },
      {
        name: 'Hunan',
        id: 'HN'
      },
      {
        name: 'Inner Mongolia',
        id: 'NM'
      },
      {
        name: 'Jiangsu',
        id: 'JS'
      },
      {
        name: 'Jiangxi',
        id: 'JX'
      },
      {
        name: 'Jilin',
        id: 'JL'
      },
      {
        name: 'Liaoning',
        id: 'LN'
      },
      {
        name: 'Macau',
        id: 'MO'
      },
      {
        name: 'Ningxia',
        id: 'NX'
      },
      {
        name: 'Qinghai',
        id: 'QH'
      },
      {
        name: 'Shaanxi',
        id: 'SN'
      },
      {
        name: 'Shandong',
        id: 'SD'
      },
      {
        name: 'Shanghai',
        id: 'SH'
      },
      {
        name: 'Shanxi',
        id: 'SX'
      },
      {
        name: 'Sichuan',
        id: 'SC'
      },
      {
        name: 'Tianjin',
        id: 'TJ'
      },
      {
        name: 'Tibet',
        id: 'XZ'
      },
      {
        name: 'Xinjiang',
        id: 'XJ'
      },
      {
        name: 'Yunnan',
        id: 'YN'
      },
      {
        name: 'Zhejiang',
        id: 'ZJ'
      }
    ]
  },
  {
    countryName: 'Christmas Island',
    countryShortCode: 'CX',
    regions: [
      {
        name: 'Christmas Island',
        id: 'CX'
      }
    ]
  },
  {
    countryName: 'Cocos (Keeling) Islands',
    countryShortCode: 'CC',
    regions: [
      {
        name: 'Direction Island',
        id: 'DI'
      },
      {
        name: 'Home Island',
        id: 'HM'
      },
      {
        name: 'Horsburgh Island',
        id: 'HR'
      },
      {
        name: 'North Keeling Island',
        id: 'NK'
      },
      {
        name: 'South Island',
        id: 'SI'
      },
      {
        name: 'West Island',
        id: 'WI'
      }
    ]
  },
  {
    countryName: 'Colombia',
    countryShortCode: 'CO',
    regions: [
      {
        name: 'Amazonas',
        id: 'AMA'
      },
      {
        name: 'Antioquia',
        id: 'ANT'
      },
      {
        name: 'Arauca',
        id: 'ARA'
      },
      {
        name: 'Archipiélago de San Andrés',
        id: 'SAP'
      },
      {
        name: 'Atlántico',
        id: 'ATL'
      },
      {
        name: 'Bogotá D.C.',
        id: 'DC'
      },
      {
        name: 'Bolívar',
        id: 'BOL'
      },
      {
        name: 'Boyacá',
        id: 'BOY'
      },
      {
        name: 'Caldas',
        id: 'CAL'
      },
      {
        name: 'Caquetá',
        id: 'CAQ'
      },
      {
        name: 'Casanare',
        id: 'CAS'
      },
      {
        name: 'Cauca',
        id: 'CAU'
      },
      {
        name: 'Cesar',
        id: 'CES'
      },
      {
        name: 'Chocó',
        id: 'CHO'
      },
      {
        name: 'Córdoba',
        id: 'COR'
      },
      {
        name: 'Cundinamarca',
        id: 'CUN'
      },
      {
        name: 'Guainía',
        id: 'GUA'
      },
      {
        name: 'Guaviare',
        id: 'GUV'
      },
      {
        name: 'Huila',
        id: 'HUI'
      },
      {
        name: 'La Guajira',
        id: 'LAG'
      },
      {
        name: 'Magdalena',
        id: 'MAG'
      },
      {
        name: 'Meta',
        id: 'MET'
      },
      {
        name: 'Nariño',
        id: 'NAR'
      },
      {
        name: 'Norte de Santander',
        id: 'NSA'
      },
      {
        name: 'Putumayo',
        id: 'PUT'
      },
      {
        name: 'Quindío',
        id: 'QUI'
      },
      {
        name: 'Risaralda',
        id: 'RIS'
      },
      {
        name: 'Santander',
        id: 'SAN'
      },
      {
        name: 'Sucre',
        id: 'SUC'
      },
      {
        name: 'Tolima',
        id: 'TOL'
      },
      {
        name: 'Valle del Cauca',
        id: 'VAC'
      },
      {
        name: 'Vaupés',
        id: 'VAU'
      },
      {
        name: 'Vichada',
        id: 'VID'
      }
    ]
  },
  {
    countryName: 'Comoros',
    countryShortCode: 'KM',
    regions: [
      {
        name: 'Andjazîdja',
        id: 'G'
      },
      {
        name: 'Andjouân',
        id: 'A'
      },
      {
        name: 'Moûhîlî',
        id: 'M'
      }
    ]
  },
  {
    countryName: 'Congo, Republic of the (Brazzaville)',
    countryShortCode: 'CG',
    regions: [
      {
        name: 'Bouenza',
        id: '11'
      },
      {
        name: 'Brazzaville',
        id: 'BZV'
      },
      {
        name: 'Cuvette',
        id: '8'
      },
      {
        name: 'Cuvette-Ouest',
        id: '15'
      },
      {
        name: 'Kouilou',
        id: '5'
      },
      {
        name: 'Lékoumou',
        id: '2'
      },
      {
        name: 'Likouala',
        id: '7'
      },
      {
        name: 'Niari',
        id: '9'
      },
      {
        name: 'Plateaux',
        id: '14'
      },
      {
        name: 'Pointe-Noire',
        id: '16'
      },
      {
        name: 'Pool',
        id: '12'
      },
      {
        name: 'Sangha',
        id: '13'
      }
    ]
  },
  {
    countryName: 'Congo, the Democratic Republic of the (Kinshasa)',
    countryShortCode: 'CD',
    regions: [
      {
        name: 'Bandundu',
        id: 'BN'
      },
      {
        name: 'Bas-Congo',
        id: 'BC'
      },
      {
        name: 'Équateur',
        id: 'EQ'
      },
      {
        name: 'Kasaï-Occidental',
        id: 'KE'
      },
      {
        name: 'Kasaï-Oriental',
        id: 'KW'
      },
      {
        name: 'Katanga',
        id: 'KA'
      },
      {
        name: 'Kinshasa',
        id: 'KN'
      },
      {
        name: 'Maniema',
        id: 'MA'
      },
      {
        name: 'Nord-Kivu',
        id: 'NK'
      },
      {
        name: 'Orientale',
        id: 'OR'
      },
      {
        name: 'Sud-Kivu',
        id: 'SK'
      }
    ]
  },
  {
    countryName: 'Cook Islands',
    countryShortCode: 'CK',
    regions: [
      {
        name: 'Aitutaki'
      },
      {
        name: 'Atiu'
      },
      {
        name: 'Avarua'
      },
      {
        name: 'Mangaia'
      },
      {
        name: 'Manihiki'
      },
      {
        name: 'Ma\'uke'
      },
      {
        name: 'Mitiaro'
      },
      {
        name: 'Nassau'
      },
      {
        name: 'Palmerston'
      },
      {
        name: 'Penrhyn'
      },
      {
        name: 'Pukapuka'
      },
      {
        name: 'Rakahanga'
      }
    ]
  },
  {
    countryName: 'Costa Rica',
    countryShortCode: 'CR',
    regions: [
      {
        name: 'Alajuela',
        id: '2'
      },
      {
        name: 'Cartago',
        id: '3'
      },
      {
        name: 'Guanacaste',
        id: '5'
      },
      {
        name: 'Heredia',
        id: '4'
      },
      {
        name: 'Limón',
        id: '7'
      },
      {
        name: 'Puntarenas',
        id: '6'
      },
      {
        name: 'San José',
        id: '1'
      }
    ]
  },
  {
    countryName: 'Côte d\'Ivoire, Republic of',
    countryShortCode: 'CI',
    regions: [
      {
        name: 'Agnéby',
        id: '16'
      },
      {
        name: 'Bafing',
        id: '17'
      },
      {
        name: 'Bas-Sassandra',
        id: '09'
      },
      {
        name: 'Denguélé',
        id: '10'
      },
      {
        name: 'Dix-Huit Montagnes',
        id: '06'
      },
      {
        name: 'Fromager',
        id: '18'
      },
      {
        name: 'Haut-Sassandra',
        id: '02'
      },
      {
        name: 'Lacs',
        id: '07'
      },
      {
        name: 'Lagunes',
        id: '01'
      },
      {
        name: 'Marahoué',
        id: '12'
      },
      {
        name: 'Moyen-Cavally',
        id: '19'
      },
      {
        name: 'Moyen-Comoé',
        id: '05'
      },
      {
        name: 'N\'zi-Comoé',
        id: '11'
      },
      {
        name: 'Savanes',
        id: '03'
      },
      {
        name: 'Sud-Bandama',
        id: '15'
      },
      {
        name: 'Sud-Comoé',
        id: '13'
      },
      {
        name: 'Vallée du Bandama',
        id: '04'
      },
      {
        name: 'Worodougou',
        id: '14'
      },
      {
        name: 'Zanzan',
        id: '08'
      }
    ]
  },
  {
    countryName: 'Croatia',
    countryShortCode: 'HR',
    regions: [
      {
        name: 'Bjelovarsko-bilogorska županija',
        id: '07'
      },
      {
        name: 'Brodsko-posavska županija',
        id: '12'
      },
      {
        name: 'Dubrovačko-neretvanska županija',
        id: '19'
      },
      {
        name: 'Grad Zagreb',
        id: '21'
      },
      {
        name: 'Istarska županija',
        id: '18'
      },
      {
        name: 'Karlovačka županija',
        id: '04'
      },
      {
        name: 'Koprivničko-križevačka županija',
        id: '06'
      },
      {
        name: 'Krapinsko-zagorska županija',
        id: '02'
      },
      {
        name: 'Ličko-senjska županija',
        id: '09'
      },
      {
        name: 'Međimurska županija',
        id: '20'
      },
      {
        name: 'Osječko-baranjska županija',
        id: '14'
      },
      {
        name: 'Požeško-slavonska županija',
        id: '11'
      },
      {
        name: 'Primorsko-goranska županija',
        id: '08'
      },
      {
        name: 'Sisačko-moslavačka županija',
        id: '03'
      },
      {
        name: 'Splitsko-dalmatinska županija',
        id: '17'
      },
      {
        name: 'Šibensko-kninska županija',
        id: '15'
      },
      {
        name: 'Varaždinska županija',
        id: '05'
      },
      {
        name: 'Virovitičko-podravska županija',
        id: '10'
      },
      {
        name: 'Vukovarsko-srijemska županija',
        id: '16'
      },
      {
        name: 'Zadarska županija',
        id: '13'
      },
      {
        name: 'Zagrebačka županija',
        id: '01'
      }
    ]
  },
  {
    countryName: 'Cuba',
    countryShortCode: 'CU',
    regions: [
      {
        name: 'Artemisa',
        id: '15'
      },
      {
        name: 'Camagüey',
        id: '09'
      },
      {
        name: 'Ciego de Ávila',
        id: '08'
      },
      {
        name: 'Cienfuegos',
        id: '06'
      },
      {
        name: 'Granma',
        id: '12'
      },
      {
        name: 'Guantánamo',
        id: '14'
      },
      {
        name: 'Holguín',
        id: '11'
      },
      {
        name: 'Isla de la Juventud',
        id: '99'
      },
      {
        name: 'La Habana',
        id: '03'
      },
      {
        name: 'Las Tunas',
        id: '10'
      },
      {
        name: 'Matanzas',
        id: '04'
      },
      {
        name: 'Mayabeque',
        id: '16'
      },
      {
        name: 'Pinar del Río',
        id: '01'
      },
      {
        name: 'Sancti Spíritus',
        id: '07'
      },
      {
        name: 'Santiago de Cuba',
        id: '13'
      },
      {
        name: 'Villa Clara',
        id: '05'
      }
    ]
  },
  {
    countryName: 'Curaçao',
    countryShortCode: 'CW',
    regions: [
      {
        name: 'Curaçao',
        id: 'CW'
      }
    ]
  },
  {
    countryName: 'Cyprus',
    countryShortCode: 'CY',
    regions: [
      {
        name: 'Ammochostos',
        id: '04'
      },
      {
        name: 'Keryneia',
        id: '06'
      },
      {
        name: 'Larnaka',
        id: '03'
      },
      {
        name: 'Lefkosia',
        id: '01'
      },
      {
        name: 'Lemesos',
        id: '02'
      },
      {
        name: 'Pafos',
        id: '05'
      }
    ]
  },
  {
    countryName: 'Czech Republic',
    countryShortCode: 'CZ',
    regions: [
      {
        name: 'Hlavní město Praha',
        id: '10'
      },
      {
        name: 'Jihočeský kraj',
        id: '31'
      },
      {
        name: 'Jihomoravský kraj',
        id: '64'
      },
      {
        name: 'Karlovarský kraj',
        id: '41'
      },
      {
        name: 'Královéhradecký kraj',
        id: '52'
      },
      {
        name: 'Liberecký kraj',
        id: '51'
      },
      {
        name: 'Moravskoslezský kraj',
        id: '80'
      },
      {
        name: 'Olomoucký kraj',
        id: '71'
      },
      {
        name: 'Pardubický kraj',
        id: '53'
      },
      {
        name: 'Plzeňský kraj',
        id: '32'
      },
      {
        name: 'Středočeský kraj',
        id: '20'
      },
      {
        name: 'Ústecký kraj',
        id: '42'
      },
      {
        name: 'Vysočina',
        id: '63'
      },
      {
        name: 'Zlínský kraj',
        id: '72'
      }
    ]
  },
  {
    countryName: 'Denmark',
    countryShortCode: 'DK',
    regions: [
      {
        name: 'Hovedstaden',
        id: 'DK-84'
      },
      {
        name: 'Midtjylland',
        id: 'DK-82'
      },
      {
        name: 'Nordjylland',
        id: 'DK-81'
      },
      {
        name: 'Sjælland',
        id: 'DK-85'
      },
      {
        name: 'Syddanmark',
        id: 'DK-83'
      }
    ]
  },
  {
    countryName: 'Djibouti',
    countryShortCode: 'DJ',
    regions: [
      {
        name: 'Ali Sabieh',
        id: 'AS'
      },
      {
        name: 'Arta',
        id: 'AR'
      },
      {
        name: 'Dikhil',
        id: 'DI'
      },
      {
        name: 'Obock',
        id: 'OB'
      },
      {
        name: 'Tadjourah',
        id: 'TA'
      }
    ]
  },
  {
    countryName: 'Dominica',
    countryShortCode: 'DM',
    regions: [
      {
        name: 'Saint Andrew Parish',
        id: '02'
      },
      {
        name: 'Saint David Parish',
        id: '03'
      },
      {
        name: 'Saint George Parish',
        id: '04'
      },
      {
        name: 'Saint John Parish',
        id: '05'
      },
      {
        name: 'Saint Joseph Parish',
        id: '06'
      },
      {
        name: 'Saint Luke Parish',
        id: '07'
      },
      {
        name: 'Saint Mark Parish',
        id: '08'
      },
      {
        name: 'Saint Patrick Parish',
        id: '09'
      },
      {
        name: 'Saint Paul Parish',
        id: '10'
      },
      {
        name: 'Saint Peter Parish',
        id: '11'
      }
    ]
  },
  {
    countryName: 'Dominican Republic',
    countryShortCode: 'DO',
    regions: [
      {
        name: 'Cibao Central',
        id: '02'
      },
      {
        name: 'Del Valle',
        id: '37'
      },
      {
        name: 'Distrito Nacional',
        id: '01'
      },
      {
        name: 'Enriquillo',
        id: '38'
      },
      {
        name: 'Norcentral',
        id: '04'
      },
      {
        name: 'Nordeste',
        id: '33'
      },
      {
        name: 'Noroeste',
        id: '34'
      },
      {
        name: 'Norte',
        id: '35'
      },
      {
        name: 'Valdesia',
        id: '42'
      }
    ]
  },
  {
    countryName: 'Ecuador',
    countryShortCode: 'EC',
    regions: [
      {
        name: 'Azuay',
        id: 'A'
      },
      {
        name: 'Bolívar',
        id: 'B'
      },
      {
        name: 'Cañar',
        id: 'F'
      },
      {
        name: 'Carchi',
        id: 'C'
      },
      {
        name: 'Chimborazo',
        id: 'H'
      },
      {
        name: 'Cotopaxi',
        id: 'X'
      },
      {
        name: 'El Oro',
        id: 'O'
      },
      {
        name: 'Esmeraldas',
        id: 'E'
      },
      {
        name: 'Galápagos',
        id: 'W'
      },
      {
        name: 'Guayas',
        id: 'G'
      },
      {
        name: 'Imbabura',
        id: 'I'
      },
      {
        name: 'Loja',
        id: 'L'
      },
      {
        name: 'Los Ríos',
        id: 'R'
      },
      {
        name: 'Manabí',
        id: 'M'
      },
      {
        name: 'Morona-Santiago',
        id: 'S'
      },
      {
        name: 'Napo',
        id: 'N'
      },
      {
        name: 'Orellana',
        id: 'D'
      },
      {
        name: 'Pastaza',
        id: 'Y'
      },
      {
        name: 'Pichincha',
        id: 'P'
      },
      {
        name: 'Santa Elena',
        id: 'SE'
      },
      {
        name: 'Santo Domingo de los Tsáchilas',
        id: 'SD'
      },
      {
        name: 'Sucumbíos',
        id: 'U'
      },
      {
        name: 'Tungurahua',
        id: 'T'
      },
      {
        name: 'Zamora-Chinchipe',
        id: 'Z'
      }
    ]
  },
  {
    countryName: 'Egypt',
    countryShortCode: 'EG',
    regions: [
      {
        name: 'Alexandria',
        id: 'ALX'
      },
      {
        name: 'Aswan',
        id: 'ASN'
      },
      {
        name: 'Asyout',
        id: 'AST'
      },
      {
        name: 'Bani Sueif',
        id: 'BNS'
      },
      {
        name: 'Beheira',
        id: 'BH'
      },
      {
        name: 'Cairo',
        id: 'C'
      },
      {
        name: 'Daqahlia',
        id: 'DK'
      },
      {
        name: 'Dumiat',
        id: 'DT'
      },
      {
        name: 'El Bahr El Ahmar',
        id: 'BA'
      },
      {
        name: 'El Ismailia',
        id: 'IS'
      },
      {
        name: 'El Suez',
        id: 'SUZ'
      },
      {
        name: 'El Wadi El Gedeed',
        id: 'WAD'
      },
      {
        name: 'Fayoum',
        id: 'FYM'
      },
      {
        name: 'Gharbia',
        id: 'GH'
      },
      {
        name: 'Giza',
        id: 'GZ'
      },
      {
        name: 'Helwan',
        id: 'HU'
      },
      {
        name: 'Kafr El Sheikh',
        id: 'KFS'
      },
      {
        name: 'Luxor',
        id: 'LX'
      },
      {
        name: 'Matrouh',
        id: 'MT'
      },
      {
        name: 'Menia',
        id: 'MN'
      },
      {
        name: 'Menofia',
        id: 'MNF'
      },
      {
        name: 'North Sinai',
        id: 'SIN'
      },
      {
        name: 'Port Said',
        id: 'PTS'
      },
      {
        name: 'Qalubia',
        id: 'KB'
      },
      {
        name: 'Qena',
        id: 'KN'
      },
      {
        name: 'Sharqia',
        id: 'SHR'
      },
      {
        name: 'Sixth of October',
        id: 'SU'
      },
      {
        name: 'Sohag',
        id: 'SHG'
      },
      {
        name: 'South Sinai',
        id: 'JS'
      }
    ]
  },
  {
    countryName: 'El Salvador',
    countryShortCode: 'SV',
    regions: [
      {
        name: 'Ahuachapán',
        id: 'AH'
      },
      {
        name: 'Cabañas',
        id: 'CA'
      },
      {
        name: 'Cuscatlán',
        id: 'CU'
      },
      {
        name: 'Chalatenango',
        id: 'CH'
      },
      {
        name: 'La Libertad',
        id: 'LI'
      },
      {
        name: 'La Paz',
        id: 'PA'
      },
      {
        name: 'La Unión',
        id: 'UN'
      },
      {
        name: 'Morazán',
        id: 'MO'
      },
      {
        name: 'San Miguel',
        id: 'SM'
      },
      {
        name: 'San Salvador',
        id: 'SS'
      },
      {
        name: 'Santa Ana',
        id: 'SA'
      },
      {
        name: 'San Vicente',
        id: 'SV'
      },
      {
        name: 'Sonsonate',
        id: 'SO'
      },
      {
        name: 'Usulután',
        id: 'US'
      }
    ]
  },
  {
    countryName: 'Equatorial Guinea',
    countryShortCode: 'GQ',
    regions: [
      {
        name: 'Annobón',
        id: 'AN'
      },
      {
        name: 'Bioko Norte',
        id: 'BN'
      },
      {
        name: 'Bioko Sur',
        id: 'BS'
      },
      {
        name: 'Centro Sur',
        id: 'CS'
      },
      {
        name: 'Kié-Ntem',
        id: 'KN'
      },
      {
        name: 'Litoral',
        id: 'LI'
      },
      {
        name: 'Wele-Nzas',
        id: 'WN'
      }
    ]
  },
  {
    countryName: 'Eritrea',
    countryShortCode: 'ER',
    regions: [
      {
        name: 'Anseba',
        id: 'AN'
      },
      {
        name: 'Debub',
        id: 'DU'
      },
      {
        name: 'Debub-Keih-Bahri',
        id: 'DK'
      },
      {
        name: 'Gash-Barka',
        id: 'GB'
      },
      {
        name: 'Maekel',
        id: 'MA'
      },
      {
        name: 'Semien-Keih-Bahri',
        id: 'SK'
      }
    ]
  },
  {
    countryName: 'Estonia',
    countryShortCode: 'EE',
    regions: [
      {
        name: 'Harjumaa (Tallinn)',
        id: '37'
      },
      {
        name: 'Hiiumaa (Kardla)',
        id: '39'
      },
      {
        name: 'Ida-Virumaa (Johvi)',
        id: '44'
      },
      {
        name: 'Järvamaa (Paide)',
        id: '41'
      },
      {
        name: 'Jõgevamaa (Jogeva)',
        id: '49'
      },
      {
        name: 'Läänemaa',
        id: '57'
      },
      {
        name: 'Lääne-Virumaa (Rakvere)',
        id: '59'
      },
      {
        name: 'Pärnumaa (Parnu)',
        id: '67'
      },
      {
        name: 'Põlvamaa (Polva)',
        id: '65'
      },
      {
        name: 'Raplamaa (Rapla)',
        id: '70'
      },
      {
        name: 'Saaremaa (Kuessaare)',
        id: '74'
      },
      {
        name: 'Tartumaa (Tartu)',
        id: '78'
      },
      {
        name: 'Valgamaa (Valga)',
        id: '82'
      },
      {
        name: 'Viljandimaa (Viljandi)',
        id: '84'
      },
      {
        name: 'Võrumaa (Voru)',
        id: '86'
      }
    ]
  },
  {
    countryName: 'Ethiopia',
    countryShortCode: 'ET',
    regions: [
      {
        name: 'Addis Ababa',
        id: 'AA'
      },
      {
        name: 'Afar',
        id: 'AF'
      },
      {
        name: 'Amhara',
        id: 'AM'
      },
      {
        name: 'Benshangul-Gumaz',
        id: 'BE'
      },
      {
        name: 'Dire Dawa',
        id: 'DD'
      },
      {
        name: 'Gambela',
        id: 'GA'
      },
      {
        name: 'Harari',
        id: 'HA'
      },
      {
        name: 'Oromia',
        id: 'OR'
      },
      {
        name: 'Somali',
        id: 'SO'
      },
      {
        name: 'Southern Nations Nationalities and People\'s Region',
        id: 'SN'
      },
      {
        name: 'Tigray',
        id: 'TI'
      }
    ]
  },
  {
    countryName: 'Falkland Islands (Islas Malvinas)',
    countryShortCode: 'FK',
    regions: [
      {
        name: 'Falkland Islands (Islas Malvinas)'
      }
    ]
  },
  {
    countryName: 'Faroe Islands',
    countryShortCode: 'FO',
    regions: [
      {
        name: 'Bordoy'
      },
      {
        name: 'Eysturoy'
      },
      {
        name: 'Mykines'
      },
      {
        name: 'Sandoy'
      },
      {
        name: 'Skuvoy'
      },
      {
        name: 'Streymoy'
      },
      {
        name: 'Suduroy'
      },
      {
        name: 'Tvoroyri'
      },
      {
        name: 'Vagar'
      }
    ]
  },
  {
    countryName: 'Fiji',
    countryShortCode: 'FJ',
    regions: [
      {
        name: 'Ba',
        id: '01'
      },
      {
        name: 'Bua',
        id: '02'
      },
      {
        name: 'Cakaudrove',
        id: '03'
      },
      {
        name: 'Kadavu',
        id: '04'
      },
      {
        name: 'Lau',
        id: '05'
      },
      {
        name: 'Lomaiviti',
        id: '06'
      },
      {
        name: 'Macuata',
        id: '07'
      },
      {
        name: 'Nadroga and Navosa',
        id: '08'
      },
      {
        name: 'Naitasiri',
        id: '09'
      },
      {
        name: 'Namosi',
        id: '10'
      },
      {
        name: 'Ra',
        id: '11'
      },
      {
        name: 'Rewa',
        id: '12'
      },
      {
        name: 'Rotuma',
        id: 'R'
      },
      {
        name: 'Serua',
        id: '13'
      },
      {
        name: 'Tailevu',
        id: '14'
      }
    ]
  },
  {
    countryName: 'Finland',
    countryShortCode: 'FI',
    regions: [
      {
        name: 'Ahvenanmaan maakunta',
        id: 'FI-01'
      },
      {
        name: 'Etelä-Karjala',
        id: 'FI-02'
      },
      {
        name: 'Etelä-Pohjanmaa',
        id: 'FI-03'
      },
      {
        name: 'Etelä-Savo',
        id: 'FI-04'
      },
      {
        name: 'Kainuu',
        id: 'FI-05'
      },
      {
        name: 'Kanta-Häme',
        id: 'FI-06'
      },
      {
        name: 'Keski-Pohjanmaa',
        id: 'FI-07'
      },
      {
        name: 'Keski-Suomi',
        id: 'FI-08'
      },
      {
        name: 'Kymenlaakso',
        id: 'FI-09'
      },
      {
        name: 'Lappi',
        id: 'FI-10'
      },
      {
        name: 'Pirkanmaa',
        id: 'FI-11'
      },
      {
        name: 'Pohjanmaa',
        id: 'FI-12'
      },
      {
        name: 'Pohjois-Karjala',
        id: 'FI-13'
      },
      {
        name: 'Pohjois-Pohjanmaa',
        id: 'FI-14'
      },
      {
        name: 'Pohjois-Savo',
        id: 'FI-15'
      },
      {
        name: 'Päijät-Häme',
        id: 'FI-16'
      },
      {
        name: 'Satakunta',
        id: 'FI-17'
      },
      {
        name: 'Uusimaa',
        id: 'FI-18'
      },
      {
        name: 'Varsinais-Suomi',
        id: 'FI-19'
      }
    ]
  },
  {
    countryName: 'France',
    countryShortCode: 'FR',
    regions: [
      {
        name: 'Auvergne-Rhône-Alpes',
        id: 'ARA'
      },
      {
        name: 'Bourgogne-Franche-Comté',
        id: 'BFC'
      },
      {
        name: 'Bretagne',
        id: 'BRE'
      },
      {
        name: 'Centre-Val de Loire',
        id: 'CVL'
      },
      {
        name: 'Corse',
        id: 'COR'
      },
      {
        name: 'Grand Est',
        id: 'GES'
      },
      {
        name: 'Hauts-de-France',
        id: 'HDF'
      },
      {
        name: 'Île-de-France',
        id: 'IDF'
      },
      {
        name: 'Normandie',
        id: 'NOR'
      },
      {
        name: 'Nouvelle-Aquitaine',
        id: 'NAQ'
      },
      {
        name: 'Occitanie',
        id: 'OCC'
      },
      {
        name: 'Pays de la Loire',
        id: 'PDL'
      },
      {
        name: 'Provence-Alpes-Cote d\'Azur',
        id: 'PAC'
      },
      {
        name: 'Clipperton',
        id: 'CP'
      },
      {
        name: 'Guadeloupe',
        id: 'GP'
      },
      {
        name: 'Guyane',
        id: 'GF'
      },
      {
        name: 'Martinique',
        id: 'MQ'
      },
      {
        name: 'Mayotte',
        id: 'YT'
      },
      {
        name: 'Nouvelle-Calédonie',
        id: 'NC'
      },
      {
        name: 'Polynésie',
        id: 'PF'
      },
      {
        name: 'Saint-Pierre-et-Miquelon',
        id: 'PM'
      },
      {
        name: 'Saint Barthélemy',
        id: 'BL'
      },
      {
        name: 'Saint Martin',
        id: 'MF'
      },
      {
        name: 'Réunion',
        id: 'RE'
      },
      {
        name: 'Terres Australes Françaises',
        id: 'TF'
      },
      {
        name: 'Wallis-et-Futuna',
        id: 'WF'
      }
    ]
  },
  {
    countryName: 'French Guiana',
    countryShortCode: 'GF',
    regions: [
      {
        name: 'French Guiana'
      }
    ]
  },
  {
    countryName: 'French Polynesia',
    countryShortCode: 'PF',
    regions: [
      {
        name: 'Archipel des Marquises'
      },
      {
        name: 'Archipel des Tuamotu'
      },
      {
        name: 'Archipel des Tubuai'
      },
      {
        name: 'Iles du Vent'
      },
      {
        name: 'Iles Sous-le-Vent'
      }
    ]
  },
  {
    countryName: 'French Southern and Antarctic Lands',
    countryShortCode: 'TF',
    regions: [
      {
        name: 'Adelie Land'
      },
      {
        name: 'Ile Crozet'
      },
      {
        name: 'Iles Kerguelen'
      },
      {
        name: 'Iles Saint-Paul et Amsterdam'
      }
    ]
  },
  {
    countryName: 'Gabon',
    countryShortCode: 'GA',
    regions: [
      {
        name: 'Estuaire',
        id: '1'
      },
      {
        name: 'Haut-Ogooué',
        id: '2'
      },
      {
        name: 'Moyen-Ogooué',
        id: '3'
      },
      {
        name: 'Ngounié',
        id: '4'
      },
      {
        name: 'Nyanga',
        id: '5'
      },
      {
        name: 'Ogooué-Ivindo',
        id: '6'
      },
      {
        name: 'Ogooué-Lolo',
        id: '7'
      },
      {
        name: 'Ogooué-Maritime',
        id: '8'
      },
      {
        name: 'Woleu-Ntem',
        id: '9'
      }
    ]
  },
  {
    countryName: 'Gambia, The',
    countryShortCode: 'GM',
    regions: [
      {
        name: 'Banjul',
        id: 'B'
      },
      {
        name: 'Central River',
        id: 'M'
      },
      {
        name: 'Lower River',
        id: 'L'
      },
      {
        name: 'North Bank',
        id: 'N'
      },
      {
        name: 'Upper River',
        id: 'U'
      },
      {
        name: 'Western',
        id: 'W'
      }
    ]
  },
  {
    countryName: 'Georgia',
    countryShortCode: 'GE',
    regions: [
      {
        name: 'Abkhazia (Sokhumi)',
        id: 'AB'
      },
      {
        name: 'Ajaria (Bat\'umi)',
        id: 'AJ'
      },
      {
        name: 'Guria',
        id: 'GU'
      },
      {
        name: 'Imereti',
        id: 'IM'
      },
      {
        name: 'K\'akheti',
        id: 'KA'
      },
      {
        name: 'Kvemo Kartli',
        id: 'KK'
      },
      {
        name: 'Mtshkheta-Mtianeti',
        id: 'MM'
      },
      {
        name: 'Rach\'a-Lexhkumi-KvemoSvaneti',
        id: 'RL'
      },
      {
        name: 'Samegrelo-Zemo Svaneti',
        id: 'SZ'
      },
      {
        name: 'Samtskhe-Javakheti',
        id: 'SJ'
      },
      {
        name: 'Shida Kartli',
        id: 'SK'
      },
      {
        name: 'Tbilisi',
        id: 'TB'
      }
    ]
  },
  {
    countryName: 'Germany',
    countryShortCode: 'DE',
    regions: [
      {
        name: 'Baden-Württemberg',
        id: 'BW'
      },
      {
        name: 'Bayern',
        id: 'BY'
      },
      {
        name: 'Berlin',
        id: 'BE'
      },
      {
        name: 'Brandenburg',
        id: 'BB'
      },
      {
        name: 'Bremen',
        id: 'HB'
      },
      {
        name: 'Hamburg',
        id: 'HH'
      },
      {
        name: 'Hessen',
        id: 'HE'
      },
      {
        name: 'Mecklenburg-Vorpommern',
        id: 'MV'
      },
      {
        name: 'Niedersachsen',
        id: 'NI'
      },
      {
        name: 'Nordrhein-Westfalen',
        id: 'NW'
      },
      {
        name: 'Rheinland-Pfalz',
        id: 'RP'
      },
      {
        name: 'Saarland',
        id: 'SL'
      },
      {
        name: 'Sachsen',
        id: 'SN'
      },
      {
        name: 'Sachsen-Anhalt',
        id: 'ST'
      },
      {
        name: 'Schleswig-Holstein',
        id: 'SH'
      },
      {
        name: 'Thüringen',
        id: 'TH'
      }
    ]
  },
  {
    countryName: 'Ghana',
    countryShortCode: 'GH',
    regions: [
      {
        name: 'Ahafo'
      },
      {
        name: 'Ashanti',
        id: 'AH'
      },
      {
        name: 'Bono'
      },
      {
        name: 'Bono East'
      },
      {
        name: 'Central',
        id: 'CP'
      },
      {
        name: 'Eastern',
        id: 'EP'
      },
      {
        name: 'Greater Accra',
        id: 'AA'
      },
      {
        name: 'Northern',
        id: 'NP'
      },
      {
        name: 'North East'
      },
      {
        name: 'Oti'
      },
      {
        name: 'Savannah'
      },
      {
        name: 'Upper East',
        id: 'UE'
      },
      {
        name: 'Upper West',
        id: 'UW'
      },
      {
        name: 'Volta',
        id: 'TV'
      },
      {
        name: 'Western',
        id: 'WP'
      },
      {
        name: 'Western North'
      }
    ]
  },
  {
    countryName: 'Gibraltar',
    countryShortCode: 'GI',
    regions: [
      {
        name: 'Gibraltar'
      }
    ]
  },
  {
    countryName: 'Greece',
    countryShortCode: 'GR',
    regions: [
      {
        name: 'Anatolikí Makedonía kai Thráki',
        id: 'A'
      },
      {
        name: 'Attikḯ',
        id: 'I'
      },
      {
        name: 'Dytikí Elláda',
        id: 'G'
      },
      {
        name: 'Dytikí Makedonía',
        id: 'C'
      },
      {
        name: 'Ionía Nísia',
        id: 'F'
      },
      {
        name: 'Kentrikí Makedonía',
        id: 'B'
      },
      {
        name: 'Krítí',
        id: 'M'
      },
      {
        name: 'Notío Aigaío',
        id: 'L'
      },
      {
        name: 'Peloponnísos',
        id: 'J'
      },
      {
        name: 'Stereá Elláda',
        id: 'H'
      },
      {
        name: 'Thessalía',
        id: 'E'
      },
      {
        name: 'Voreío Aigaío',
        id: 'K'
      },
      {
        name: 'Ípeiros',
        id: 'D'
      },
      {
        name: 'Ágion Óros',
        id: '69'
      }
    ]
  },
  {
    countryName: 'Greenland',
    countryShortCode: 'GL',
    regions: [
      {
        name: 'Kommune Kujalleq',
        id: 'KU'
      },
      {
        name: 'Kommuneqarfik Sermersooq',
        id: 'SM'
      },
      {
        name: 'Qaasuitsup Kommunia',
        id: 'QA'
      },
      {
        name: 'Qeqqata Kommunia',
        id: 'QE'
      }
    ]
  },
  {
    countryName: 'Grenada',
    countryShortCode: 'GD',
    regions: [
      {
        name: 'Saint Andrew',
        id: '01'
      },
      {
        name: 'Saint David',
        id: '02'
      },
      {
        name: 'Saint George',
        id: '03'
      },
      {
        name: 'Saint John',
        id: '04'
      },
      {
        name: 'Saint Mark',
        id: '05'
      },
      {
        name: 'Saint Patrick',
        id: '06'
      },
      {
        name: 'Southern Grenadine Islands',
        id: '10'
      }
    ]
  },
  {
    countryName: 'Guadeloupe',
    countryShortCode: 'GP',
    regions: [
      {
        name: 'Guadeloupe'
      }
    ]
  },
  {
    countryName: 'Guam',
    countryShortCode: 'GU',
    regions: [
      {
        name: 'Guam'
      }
    ]
  },
  {
    countryName: 'Guatemala',
    countryShortCode: 'GT',
    regions: [
      {
        name: 'Alta Verapaz',
        id: 'AV'
      },
      {
        name: 'Baja Verapaz',
        id: 'BV'
      },
      {
        name: 'Chimaltenango',
        id: 'CM'
      },
      {
        name: 'Chiquimula',
        id: 'CQ'
      },
      {
        name: 'El Progreso',
        id: 'PR'
      },
      {
        name: 'Escuintla',
        id: 'ES'
      },
      {
        name: 'Guatemala',
        id: 'GU'
      },
      {
        name: 'Huehuetenango',
        id: 'HU'
      },
      {
        name: 'Izabal',
        id: 'IZ'
      },
      {
        name: 'Jalapa',
        id: 'JA'
      },
      {
        name: 'Jutiapa',
        id: 'JU'
      },
      {
        name: 'Petén',
        id: 'PE'
      },
      {
        name: 'Quetzaltenango',
        id: 'QZ'
      },
      {
        name: 'Quiché',
        id: 'QC'
      },
      {
        name: 'Retalhuleu',
        id: 'Re'
      },
      {
        name: 'Sacatepéquez',
        id: 'SA'
      },
      {
        name: 'San Marcos',
        id: 'SM'
      },
      {
        name: 'Santa Rosa',
        id: 'SR'
      },
      {
        name: 'Sololá',
        id: 'SO'
      },
      {
        name: 'Suchitepéquez',
        id: 'SU'
      },
      {
        name: 'Totonicapán',
        id: 'TO'
      },
      {
        name: 'Zacapa',
        id: 'ZA'
      }
    ]
  },
  {
    countryName: 'Guernsey',
    countryShortCode: 'GG',
    regions: [
      {
        name: 'Castel'
      },
      {
        name: 'Forest'
      },
      {
        name: 'St. Andrew'
      },
      {
        name: 'St. Martin'
      },
      {
        name: 'St. Peter Port'
      },
      {
        name: 'St. Pierre du Bois'
      },
      {
        name: 'St. Sampson'
      },
      {
        name: 'St. Saviour'
      },
      {
        name: 'Torteval'
      },
      {
        name: 'Vale'
      }
    ]
  },
  {
    countryName: 'Guinea',
    countryShortCode: 'GN',
    regions: [
      {
        name: 'Boké',
        id: 'B'
      },
      {
        name: 'Conakry',
        id: 'C'
      },
      {
        name: 'Faranah',
        id: 'F'
      },
      {
        name: 'Kankan',
        id: 'K'
      },
      {
        name: 'Kindia',
        id: 'D'
      },
      {
        name: 'Labé',
        id: 'L'
      },
      {
        name: 'Mamou',
        id: 'M'
      },
      {
        name: 'Nzérékoré',
        id: 'N'
      }
    ]
  },
  {
    countryName: 'Guinea-Bissau',
    countryShortCode: 'GW',
    regions: [
      {
        name: 'Bafatá',
        id: 'BA'
      },
      {
        name: 'Biombo',
        id: 'BM'
      },
      {
        name: 'Bissau',
        id: 'BS'
      },
      {
        name: 'Bolama-Bijagos',
        id: 'BL'
      },
      {
        name: 'Cacheu',
        id: 'CA'
      },
      {
        name: 'Gabú',
        id: 'GA'
      },
      {
        name: 'Oio',
        id: 'OI'
      },
      {
        name: 'Quinara',
        id: 'QU'
      },
      {
        name: 'Tombali',
        id: 'TO'
      }
    ]
  },
  {
    countryName: 'Guyana',
    countryShortCode: 'GY',
    regions: [
      {
        name: 'Barima-Waini',
        id: 'BA'
      },
      {
        name: 'Cuyuni-Mazaruni',
        id: 'CU'
      },
      {
        name: 'Demerara-Mahaica',
        id: 'DE'
      },
      {
        name: 'East Berbice-Corentyne',
        id: 'EB'
      },
      {
        name: 'Essequibo Islands-West Demerara',
        id: 'ES'
      },
      {
        name: 'Mahaica-Berbice',
        id: 'MA'
      },
      {
        name: 'Pomeroon-Supenaam',
        id: 'PM'
      },
      {
        name: 'Potaro-Siparuni',
        id: 'PT'
      },
      {
        name: 'Upper Demerara-Berbice',
        id: 'UD'
      },
      {
        name: 'Upper Takutu-Upper Essequibo',
        id: 'UT'
      }
    ]
  },
  {
    countryName: 'Haiti',
    countryShortCode: 'HT',
    regions: [
      {
        name: 'Artibonite',
        id: 'AR'
      },
      {
        name: 'Centre',
        id: 'CE'
      },
      {
        name: 'Grand\'Anse',
        id: 'GA'
      },
      {
        name: 'Nippes',
        id: 'NI'
      },
      {
        name: 'Nord',
        id: 'ND'
      },
      {
        name: 'Nord-Est',
        id: 'NE'
      },
      {
        name: 'Nord-Ouest',
        id: 'NO'
      },
      {
        name: 'Ouest',
        id: 'OU'
      },
      {
        name: 'Sud',
        id: 'SD'
      },
      {
        name: 'Sud-Est',
        id: 'SE'
      }
    ]
  },
  {
    countryName: 'Heard Island and McDonald Islands',
    countryShortCode: 'HM',
    regions: [
      {
        name: 'Heard Island and McDonald Islands'
      }
    ]
  },
  {
    countryName: 'Holy See (Vatican City)',
    countryShortCode: 'VA',
    regions: [
      {
        name: 'Holy See (Vatican City)',
        id: '01'
      }
    ]
  },
  {
    countryName: 'Honduras',
    countryShortCode: 'HN',
    regions: [
      {
        name: 'Atlántida',
        id: 'AT'
      },
      {
        name: 'Choluteca',
        id: 'CH'
      },
      {
        name: 'Colón',
        id: 'CL'
      },
      {
        name: 'Comayagua',
        id: 'CM'
      },
      {
        name: 'Copán',
        id: 'CP'
      },
      {
        name: 'Cortés',
        id: 'CR'
      },
      {
        name: 'El Paraíso',
        id: 'EP'
      },
      {
        name: 'Francisco Morazán',
        id: 'FM'
      },
      {
        name: 'Gracias a Dios',
        id: 'GD'
      },
      {
        name: 'Intibucá',
        id: 'IN'
      },
      {
        name: 'Islas de la Bahía',
        id: 'IB'
      },
      {
        name: 'La Paz',
        id: 'LP'
      },
      {
        name: 'Lempira',
        id: 'LE'
      },
      {
        name: 'Ocotepeque',
        id: 'OC'
      },
      {
        name: 'Olancho',
        id: 'OL'
      },
      {
        name: 'Santa Bárbara',
        id: 'SB'
      },
      {
        name: 'Valle',
        id: 'VA'
      },
      {
        name: 'Yoro',
        id: 'YO'
      }
    ]
  },
  {
    countryName: 'Hong Kong',
    countryShortCode: 'HK',
    regions: [
      {
        name: 'Hong Kong',
        id: 'HK'
      }
    ]
  },
  {
    countryName: 'Hungary',
    countryShortCode: 'HU',
    regions: [
      {
        name: 'Bács-Kiskun',
        id: 'BK'
      },
      {
        name: 'Baranya',
        id: 'BA'
      },
      {
        name: 'Békés',
        id: 'BE'
      },
      {
        name: 'Békéscsaba',
        id: 'BC'
      },
      {
        name: 'Borsod-Abauj-Zemplen',
        id: 'BZ'
      },
      {
        name: 'Budapest',
        id: 'BU'
      },
      {
        name: 'Csongrád',
        id: 'CS'
      },
      {
        name: 'Debrecen',
        id: 'DE'
      },
      {
        name: 'Dunaújváros',
        id: 'DU'
      },
      {
        name: 'Eger',
        id: 'EG'
      },
      {
        name: 'Érd',
        id: 'ER'
      },
      {
        name: 'Fejér',
        id: 'FE'
      },
      {
        name: 'Győr',
        id: 'GY'
      },
      {
        name: 'Győr-Moson-Sopron',
        id: 'GS'
      },
      {
        name: 'Hajdú-Bihar',
        id: 'HB'
      },
      {
        name: 'Heves',
        id: 'HE'
      },
      {
        name: 'Hódmezővásárhely',
        id: 'HV'
      },
      {
        name: 'Jász-Nagykun-Szolnok',
        id: 'N'
      },
      {
        name: 'Kaposvár',
        id: 'KV'
      },
      {
        name: 'Kecskemét',
        id: 'KM'
      },
      {
        name: 'Komárom-Esztergom',
        id: 'KE'
      },
      {
        name: 'Miskolc',
        id: 'MI'
      },
      {
        name: 'Nagykanizsa',
        id: 'NK'
      },
      {
        name: 'Nógrád',
        id: 'NO'
      },
      {
        name: 'Nyíregyháza',
        id: 'NY'
      },
      {
        name: 'Pécs',
        id: 'PS'
      },
      {
        name: 'Pest',
        id: 'PE'
      },
      {
        name: 'Salgótarján',
        id: 'ST'
      },
      {
        name: 'Somogy',
        id: 'SO'
      },
      {
        name: 'Sopron',
        id: 'SN'
      },
      {
        name: 'Szabolcs-á-Bereg',
        id: 'SZ'
      },
      {
        name: 'Szeged',
        id: 'SD'
      },
      {
        name: 'Székesfehérvár',
        id: 'SF'
      },
      {
        name: 'Szekszárd',
        id: 'SS'
      },
      {
        name: 'Szolnok',
        id: 'SK'
      },
      {
        name: 'Szombathely',
        id: 'SH'
      },
      {
        name: 'Tatabánya',
        id: 'TB'
      },
      {
        name: 'Tolna',
        id: 'TO'
      },
      {
        name: 'Vas',
        id: 'VA'
      },
      {
        name: 'Veszprém',
        id: 'VE'
      },
      {
        name: 'Veszprém (City)',
        id: 'VM'
      },
      {
        name: 'Zala',
        id: 'ZA'
      },
      {
        name: 'Zalaegerszeg',
        id: 'ZE'
      }
    ]
  },
  {
    countryName: 'Iceland',
    countryShortCode: 'IS',
    regions: [
      {
        name: 'Austurland',
        id: '7'
      },
      {
        name: 'Höfuðborgarsvæði',
        id: '1'
      },
      {
        name: 'Norðurland eystra',
        id: '6'
      },
      {
        name: 'Norðurland vestra',
        id: '5'
      },
      {
        name: 'Suðurland',
        id: '8'
      },
      {
        name: 'Suðurnes',
        id: '2'
      },
      {
        name: 'Vestfirðir',
        id: '4'
      },
      {
        name: 'Vesturland',
        id: '3'
      }
    ]
  },
  {
    countryName: 'India',
    countryShortCode: 'IN',
    regions: [
      {
        name: 'Andaman and Nicobar Islands',
        id: 'AN'
      },
      {
        name: 'Andhra Pradesh',
        id: 'AP'
      },
      {
        name: 'Arunachal Pradesh',
        id: 'AR'
      },
      {
        name: 'Assam',
        id: 'AS'
      },
      {
        name: 'Bihar',
        id: 'BR'
      },
      {
        name: 'Chandigarh',
        id: 'CH'
      },
      {
        name: 'Chhattisgarh',
        id: 'CT'
      },
      {
        name: 'Dadra and Nagar Haveli and Daman and Diu',
        id: 'DH'
      },
      {
        name: 'Delhi',
        id: 'DL'
      },
      {
        name: 'Goa',
        id: 'GA'
      },
      {
        name: 'Gujarat',
        id: 'GJ'
      },
      {
        name: 'Haryana',
        id: 'HR'
      },
      {
        name: 'Himachal Pradesh',
        id: 'HP'
      },
      {
        name: 'Jammu and Kashmir',
        id: 'JK'
      },
      {
        name: 'Jharkhand',
        id: 'JH'
      },
      {
        name: 'Karnataka',
        id: 'KA'
      },
      {
        name: 'Kerala',
        id: 'KL'
      },
      {
        name: 'Ladakh',
        id: 'LA'
      },
      {
        name: 'Lakshadweep',
        id: 'LD'
      },
      {
        name: 'Madhya Pradesh',
        id: 'MP'
      },
      {
        name: 'Maharashtra',
        id: 'MH'
      },
      {
        name: 'Manipur',
        id: 'MN'
      },
      {
        name: 'Meghalaya',
        id: 'ML'
      },
      {
        name: 'Mizoram',
        id: 'MZ'
      },
      {
        name: 'Nagaland',
        id: 'NL'
      },
      {
        name: 'Odisha',
        id: 'OR'
      },
      {
        name: 'Puducherry',
        id: 'PY'
      },
      {
        name: 'Punjab',
        id: 'PB'
      },
      {
        name: 'Rajasthan',
        id: 'RJ'
      },
      {
        name: 'Sikkim',
        id: 'SK'
      },
      {
        name: 'Tamil Nadu',
        id: 'TN'
      },
      {
        name: 'Telangana',
        id: 'TG'
      },
      {
        name: 'Tripura',
        id: 'TR'
      },
      {
        name: 'Uttarakhand',
        id: 'UT'
      },
      {
        name: 'Uttar Pradesh',
        id: 'UP'
      },
      {
        name: 'West Bengal',
        id: 'WB'
      }
    ]
  },
  {
    countryName: 'Indonesia',
    countryShortCode: 'ID',
    regions: [
      {
        name: 'Aceh',
        id: 'AC'
      },
      {
        name: 'Bali',
        id: 'BA'
      },
      {
        name: 'Bangka Belitung',
        id: 'BB'
      },
      {
        name: 'Banten',
        id: 'BT'
      },
      {
        name: 'Bengkulu',
        id: 'BE'
      },
      {
        name: 'Gorontalo',
        id: 'GO'
      },
      {
        name: 'Jakarta Raya',
        id: 'JK'
      },
      {
        name: 'Jambi',
        id: 'JA'
      },
      {
        name: 'Jawa Barat',
        id: 'JB'
      },
      {
        name: 'Jawa Tengah',
        id: 'JT'
      },
      {
        name: 'Jawa Timur',
        id: 'JI'
      },
      {
        name: 'Kalimantan Barat',
        id: 'KB'
      },
      {
        name: 'Kalimantan Selatan',
        id: 'KS'
      },
      {
        name: 'Kalimantan Tengah',
        id: 'KT'
      },
      {
        name: 'Kalimantan Timur',
        id: 'KI'
      },
      {
        name: 'Kalimantan Utara',
        id: 'KU'
      },
      {
        name: 'Kepulauan Riau',
        id: 'KR'
      },
      {
        name: 'Lampung',
        id: 'LA'
      },
      {
        name: 'Maluku',
        id: 'MA'
      },
      {
        name: 'Maluku Utara',
        id: 'MU'
      },
      {
        name: 'Nusa Tenggara Barat',
        id: 'NB'
      },
      {
        name: 'Nusa Tenggara Timur',
        id: 'NT'
      },
      {
        name: 'Papua',
        id: 'PA'
      },
      {
        name: 'Papua Barat',
        id: 'PB'
      },
      {
        name: 'Riau',
        id: 'RI'
      },
      {
        name: 'Sulawesi Selatan',
        id: 'SR'
      },
      {
        name: 'Sulawesi Tengah',
        id: 'ST'
      },
      {
        name: 'Sulawesi Tenggara',
        id: 'SG'
      },
      {
        name: 'Sulawesi Utara',
        id: 'SA'
      },
      {
        name: 'Sumatera Barat',
        id: 'SB'
      },
      {
        name: 'Sumatera Selatan',
        id: 'SS'
      },
      {
        name: 'Sumatera Utara',
        id: 'SU'
      },
      {
        name: 'Yogyakarta',
        id: 'YO'
      }
    ]
  },
  {
    countryName: 'Iran, Islamic Republic of',
    countryShortCode: 'IR',
    regions: [
      {
        name: 'Alborz',
        id: '32'
      },
      {
        name: 'Ardabīl',
        id: '03'
      },
      {
        name: 'Āz̄arbāyjān-e Gharbī',
        id: '02'
      },
      {
        name: 'Āz̄arbāyjān-e Sharqī',
        id: '01'
      },
      {
        name: 'Būshehr',
        id: '06'
      },
      {
        name: 'Chahār Maḩāl va Bakhtīārī',
        id: '08'
      },
      {
        name: 'Eşfahān',
        id: '04'
      },
      {
        name: 'Fārs',
        id: '14'
      },
      {
        name: 'Gīlān',
        id: '19'
      },
      {
        name: 'Golestān',
        id: '27'
      },
      {
        name: 'Hamadān',
        id: '24'
      },
      {
        name: 'Hormozgān',
        id: '23'
      },
      {
        name: 'Īlām',
        id: '05'
      },
      {
        name: 'Kermān',
        id: '15'
      },
      {
        name: 'Kermānshāh',
        id: '17'
      },
      {
        name: 'Khorāsān-e Jonūbī',
        id: '29'
      },
      {
        name: 'Khorāsān-e Raẕavī',
        id: '30'
      },
      {
        name: 'Khorāsān-e Shomālī',
        id: '61'
      },
      {
        name: 'Khūzestān',
        id: '10'
      },
      {
        name: 'Kohgīlūyeh va Bowyer Aḩmad',
        id: '18'
      },
      {
        name: 'Kordestān',
        id: '16'
      },
      {
        name: 'Lorestān',
        id: '20'
      },
      {
        name: 'Markazi',
        id: '22'
      },
      {
        name: 'Māzandarān',
        id: '21'
      },
      {
        name: 'Qazvīn',
        id: '28'
      },
      {
        name: 'Qom',
        id: '26'
      },
      {
        name: 'Semnān',
        id: '12'
      },
      {
        name: 'Sīstān va Balūchestān',
        id: '13'
      },
      {
        name: 'Tehrān',
        id: '07'
      },
      {
        name: 'Yazd',
        id: '25'
      },
      {
        name: 'Zanjān',
        id: '11'
      }
    ]
  },
  {
    countryName: 'Iraq',
    countryShortCode: 'IQ',
    regions: [
      {
        name: 'Al Anbār',
        id: 'AN'
      },
      {
        name: 'Al Başrah',
        id: 'BA'
      },
      {
        name: 'Al Muthanná',
        id: 'MU'
      },
      {
        name: 'Al Qādisīyah',
        id: 'QA'
      },
      {
        name: 'An Najaf',
        id: 'NA'
      },
      {
        name: 'Arbīl',
        id: 'AR'
      },
      {
        name: 'As Sulaymānīyah',
        id: 'SU'
      },
      {
        name: 'Bābil',
        id: 'BB'
      },
      {
        name: 'Baghdād',
        id: 'BG'
      },
      {
        name: 'Dohuk',
        id: 'DA'
      },
      {
        name: 'Dhī Qār',
        id: 'DQ'
      },
      {
        name: 'Diyālá',
        id: 'DI'
      },
      {
        name: 'Karbalā\'',
        id: 'KA'
      },
      {
        name: 'Kirkuk',
        id: 'KI'
      },
      {
        name: 'Maysān',
        id: 'MA'
      },
      {
        name: 'Nīnawá',
        id: 'NI'
      },
      {
        name: 'Şalāḩ ad Dīn',
        id: 'SD'
      },
      {
        name: 'Wāsiţ',
        id: 'WA'
      }
    ]
  },
  {
    countryName: 'Ireland',
    countryShortCode: 'IE',
    regions: [
      {
        name: 'Carlow',
        id: 'CW'
      },
      {
        name: 'Cavan',
        id: 'CN'
      },
      {
        name: 'Clare',
        id: 'CE'
      },
      {
        name: 'Cork',
        id: 'CO'
      },
      {
        name: 'Donegal',
        id: 'DL'
      },
      {
        name: 'Dublin',
        id: 'D'
      },
      {
        name: 'Galway',
        id: 'G'
      },
      {
        name: 'Kerry',
        id: 'KY'
      },
      {
        name: 'Kildare',
        id: 'KE'
      },
      {
        name: 'Kilkenny',
        id: 'KK'
      },
      {
        name: 'Laois',
        id: 'LS'
      },
      {
        name: 'Leitrim',
        id: 'LM'
      },
      {
        name: 'Limerick',
        id: 'LK'
      },
      {
        name: 'Longford',
        id: 'LD'
      },
      {
        name: 'Louth',
        id: 'LH'
      },
      {
        name: 'Mayo',
        id: 'MO'
      },
      {
        name: 'Meath',
        id: 'MH'
      },
      {
        name: 'Monaghan',
        id: 'MN'
      },
      {
        name: 'Offaly',
        id: 'OY'
      },
      {
        name: 'Roscommon',
        id: 'RN'
      },
      {
        name: 'Sligo',
        id: 'SO'
      },
      {
        name: 'Tipperary',
        id: 'TA'
      },
      {
        name: 'Waterford',
        id: 'WD'
      },
      {
        name: 'Westmeath',
        id: 'WH'
      },
      {
        name: 'Wexford',
        id: 'WX'
      },
      {
        name: 'Wicklow',
        id: 'WW'
      }
    ]
  },
  {
    countryName: 'Isle of Man',
    countryShortCode: 'IM',
    regions: [
      {
        name: 'Isle of Man'
      }
    ]
  },
  {
    countryName: 'Israel',
    countryShortCode: 'IL',
    regions: [
      {
        name: 'HaDarom',
        id: 'D'
      },
      {
        name: 'HaMerkaz',
        id: 'M'
      },
      {
        name: 'HaTsafon',
        id: 'Z'
      },
      {
        name: 'H̱efa',
        id: 'HA'
      },
      {
        name: 'Tel-Aviv',
        id: 'TA'
      },
      {
        name: 'Yerushalayim',
        id: 'JM'
      }
    ]
  },
  {
    countryName: 'Italy',
    countryShortCode: 'IT',
    regions: [
      {
        name: 'Abruzzo',
        id: '65'
      },
      {
        name: 'Basilicata',
        id: '77'
      },
      {
        name: 'Calabria',
        id: '78'
      },
      {
        name: 'Campania',
        id: '72'
      },
      {
        name: 'Emilia-Romagna',
        id: '45'
      },
      {
        name: 'Friuli-Venezia Giulia',
        id: '36'
      },
      {
        name: 'Lazio',
        id: '62'
      },
      {
        name: 'Liguria',
        id: '42'
      },
      {
        name: 'Lombardia',
        id: '25'
      },
      {
        name: 'Marche',
        id: '57'
      },
      {
        name: 'Molise',
        id: '67'
      },
      {
        name: 'Piemonte',
        id: '21'
      },
      {
        name: 'Puglia',
        id: '75'
      },
      {
        name: 'Sardegna',
        id: '88'
      },
      {
        name: 'Sicilia',
        id: '82'
      },
      {
        name: 'Toscana',
        id: '52'
      },
      {
        name: 'Trentino-Alto Adige',
        id: '32'
      },
      {
        name: 'Umbria',
        id: '55'
      },
      {
        name: 'Valle d\'Aosta',
        id: '23'
      },
      {
        name: 'Veneto',
        id: '34'
      }
    ]
  },
  {
    countryName: 'Jamaica',
    countryShortCode: 'JM',
    regions: [
      {
        name: 'Clarendon',
        id: '13'
      },
      {
        name: 'Hanover',
        id: '09'
      },
      {
        name: 'Kingston',
        id: '01'
      },
      {
        name: 'Manchester',
        id: '12'
      },
      {
        name: 'Portland',
        id: '04'
      },
      {
        name: 'Saint Andrew',
        id: '02'
      },
      {
        name: 'Saint Ann',
        id: '06'
      },
      {
        name: 'Saint Catherine',
        id: '14'
      },
      {
        name: 'Saint Elizabeth',
        id: '11'
      },
      {
        name: 'Saint James',
        id: '08'
      },
      {
        name: 'Saint Mary',
        id: '05'
      },
      {
        name: 'Saint Thomas',
        id: '03'
      },
      {
        name: 'Trelawny',
        id: '07'
      },
      {
        name: 'Westmoreland',
        id: '10'
      }
    ]
  },
  {
    countryName: 'Japan',
    countryShortCode: 'JP',
    regions: [
      {
        name: 'Aichi',
        id: '23'
      },
      {
        name: 'Akita',
        id: '05'
      },
      {
        name: 'Aomori',
        id: '02'
      },
      {
        name: 'Chiba',
        id: '12'
      },
      {
        name: 'Ehime',
        id: '38'
      },
      {
        name: 'Fukui',
        id: '18'
      },
      {
        name: 'Fukuoka',
        id: '40'
      },
      {
        name: 'Fukushima',
        id: '07'
      },
      {
        name: 'Gifu',
        id: '21'
      },
      {
        name: 'Gunma',
        id: '10'
      },
      {
        name: 'Hiroshima',
        id: '34'
      },
      {
        name: 'Hokkaido',
        id: '01'
      },
      {
        name: 'Hyogo',
        id: '28'
      },
      {
        name: 'Ibaraki',
        id: '08'
      },
      {
        name: 'Ishikawa',
        id: '17'
      },
      {
        name: 'Iwate',
        id: '03'
      },
      {
        name: 'Kagawa',
        id: '37'
      },
      {
        name: 'Kagoshima',
        id: '46'
      },
      {
        name: 'Kanagawa',
        id: '14'
      },
      {
        name: 'Kochi',
        id: '39'
      },
      {
        name: 'Kumamoto',
        id: '43'
      },
      {
        name: 'Kyoto',
        id: '26'
      },
      {
        name: 'Mie',
        id: '24'
      },
      {
        name: 'Miyagi',
        id: '04'
      },
      {
        name: 'Miyazaki',
        id: '45'
      },
      {
        name: 'Nagano',
        id: '20'
      },
      {
        name: 'Nagasaki',
        id: '42'
      },
      {
        name: 'Nara',
        id: '29'
      },
      {
        name: 'Niigata',
        id: '15'
      },
      {
        name: 'Oita',
        id: '44'
      },
      {
        name: 'Okayama',
        id: '33'
      },
      {
        name: 'Okinawa',
        id: '47'
      },
      {
        name: 'Osaka',
        id: '27'
      },
      {
        name: 'Saga',
        id: '41'
      },
      {
        name: 'Saitama',
        id: '11'
      },
      {
        name: 'Shiga',
        id: '25'
      },
      {
        name: 'Shimane',
        id: '32'
      },
      {
        name: 'Shizuoka',
        id: '22'
      },
      {
        name: 'Tochigi',
        id: '09'
      },
      {
        name: 'Tokushima',
        id: '36'
      },
      {
        name: 'Tokyo',
        id: '13'
      },
      {
        name: 'Tottori',
        id: '31'
      },
      {
        name: 'Toyama',
        id: '16'
      },
      {
        name: 'Wakayama',
        id: '30'
      },
      {
        name: 'Yamagata',
        id: '06'
      },
      {
        name: 'Yamaguchi',
        id: '35'
      },
      {
        name: 'Yamanashi',
        id: '19'
      }
    ]
  },
  {
    countryName: 'Jersey',
    countryShortCode: 'JE',
    regions: [
      {
        name: 'Jersey'
      }
    ]
  },
  {
    countryName: 'Jordan',
    countryShortCode: 'JO',
    regions: [
      {
        name: '‘Ajlūn',
        id: 'AJ'
      },
      {
        name: 'Al \'Aqabah',
        id: 'AQ'
      },
      {
        name: 'Al Balqā’',
        id: 'BA'
      },
      {
        name: 'Al Karak',
        id: 'KA'
      },
      {
        name: 'Al Mafraq',
        id: 'MA'
      },
      {
        name: 'Al ‘A̅şimah',
        id: 'AM'
      },
      {
        name: 'Aţ Ţafīlah',
        id: 'AT'
      },
      {
        name: 'Az Zarqā’',
        id: 'AZ'
      },
      {
        name: 'Irbid',
        id: 'IR'
      },
      {
        name: 'Jarash',
        id: 'JA'
      },
      {
        name: 'Ma‘ān',
        id: 'MN'
      },
      {
        name: 'Mādabā',
        id: 'MD'
      }
    ]
  },
  {
    countryName: 'Kazakhstan',
    countryShortCode: 'KZ',
    regions: [
      {
        name: 'Almaty',
        id: 'ALA'
      },
      {
        name: 'Aqmola',
        id: 'AKM'
      },
      {
        name: 'Aqtobe',
        id: 'AKT'
      },
      {
        name: 'Astana',
        id: 'AST'
      },
      {
        name: 'Atyrau',
        id: 'ATY'
      },
      {
        name: 'Batys Qazaqstan',
        id: 'ZAP'
      },
      {
        name: 'Bayqongyr'
      },
      {
        name: 'Mangghystau',
        id: 'MAN'
      },
      {
        name: 'Ongtustik Qazaqstan',
        id: 'YUZ'
      },
      {
        name: 'Pavlodar',
        id: 'PAV'
      },
      {
        name: 'Qaraghandy',
        id: 'KAR'
      },
      {
        name: 'Qostanay',
        id: 'KUS'
      },
      {
        name: 'Qyzylorda',
        id: 'KZY'
      },
      {
        name: 'Shyghys Qazaqstan',
        id: 'VOS'
      },
      {
        name: 'Soltustik Qazaqstan',
        id: 'SEV'
      },
      {
        name: 'Zhambyl',
        id: 'ZHA'
      }
    ]
  },
  {
    countryName: 'Kenya',
    countryShortCode: 'KE',
    regions: [
      {
        name: 'Baringo',
        id: '01'
      },
      {
        name: 'Bomet',
        id: '02'
      },
      {
        name: 'Bungoma',
        id: '03'
      },
      {
        name: 'Busia',
        id: '04'
      },
      {
        name: 'Eleyo/Marakwet',
        id: '05'
      },
      {
        name: 'Embu',
        id: '06'
      },
      {
        name: 'Garissa',
        id: '07'
      },
      {
        name: 'Homa Bay',
        id: '08'
      },
      {
        name: 'Isiolo',
        id: '09'
      },
      {
        name: 'Kajiado',
        id: '10'
      },
      {
        name: 'Kakamega',
        id: '11'
      },
      {
        name: 'Kericho',
        id: '12'
      },
      {
        name: 'Kiambu',
        id: '13'
      },
      {
        name: 'Kilifi',
        id: '14'
      },
      {
        name: 'Kirinyaga',
        id: '15'
      },
      {
        name: 'Kisii',
        id: '16'
      },
      {
        name: 'Kisumu',
        id: '17'
      },
      {
        name: 'Kitui',
        id: '18'
      },
      {
        name: 'Kwale',
        id: '19'
      },
      {
        name: 'Laikipia',
        id: '20'
      },
      {
        name: 'Lamu',
        id: '21'
      },
      {
        name: 'Machakos',
        id: '22'
      },
      {
        name: 'Makueni',
        id: '23'
      },
      {
        name: 'Mandera',
        id: '24'
      },
      {
        name: 'Marsabit',
        id: '25'
      },
      {
        name: 'Meru',
        id: '26'
      },
      {
        name: 'Migori',
        id: '27'
      },
      {
        name: 'Mombasa',
        id: '28'
      },
      {
        name: 'Murang\'a',
        id: '29'
      },
      {
        name: 'Nairobi City',
        id: '30'
      },
      {
        name: 'Nakuru',
        id: '31'
      },
      {
        name: 'Nandi',
        id: '32'
      },
      {
        name: 'Narok',
        id: '33'
      },
      {
        name: 'Nyamira',
        id: '34'
      },
      {
        name: 'Nyandarua',
        id: '35'
      },
      {
        name: 'Nyeri',
        id: '36'
      },
      {
        name: 'Samburu',
        id: '37'
      },
      {
        name: 'Siaya',
        id: '38'
      },
      {
        name: 'Taita/Taveta',
        id: '39'
      },
      {
        name: 'Tana River',
        id: '40'
      },
      {
        name: 'Tharaka-Nithi',
        id: '41'
      },
      {
        name: 'Trans Nzoia',
        id: '42'
      },
      {
        name: 'Turkana',
        id: '43'
      },
      {
        name: 'Uasin Gishu',
        id: '44'
      },
      {
        name: 'Vihiga',
        id: '45'
      },
      {
        name: 'Wajir',
        id: '46'
      },
      {
        name: 'West Pokot',
        id: '47'
      }
    ]
  },
  {
    countryName: 'Kiribati',
    countryShortCode: 'KI',
    regions: [
      {
        name: 'Abaiang'
      },
      {
        name: 'Abemama'
      },
      {
        name: 'Aranuka'
      },
      {
        name: 'Arorae'
      },
      {
        name: 'Banaba'
      },
      {
        name: 'Beru'
      },
      {
        name: 'Butaritari'
      },
      {
        name: 'Central Gilberts'
      },
      {
        name: 'Gilbert Islands',
        id: 'G'
      },
      {
        name: 'Kanton'
      },
      {
        name: 'Kiritimati'
      },
      {
        name: 'Kuria'
      },
      {
        name: 'Line Islands',
        id: 'L'
      },
      {
        name: 'Maiana'
      },
      {
        name: 'Makin'
      },
      {
        name: 'Marakei'
      },
      {
        name: 'Nikunau'
      },
      {
        name: 'Nonouti'
      },
      {
        name: 'Northern Gilberts'
      },
      {
        name: 'Onotoa'
      },
      {
        name: 'Phoenix Islands',
        id: 'P'
      },
      {
        name: 'Southern Gilberts'
      },
      {
        name: 'Tabiteuea'
      },
      {
        name: 'Tabuaeran'
      },
      {
        name: 'Tamana'
      },
      {
        name: 'Tarawa'
      },
      {
        name: 'Teraina'
      }
    ]
  },
  {
    countryName: 'Korea, Democratic People\'s Republic of',
    countryShortCode: 'KP',
    regions: [
      {
        name: 'Chagang-do (Chagang Province)',
        id: '04'
      },
      {
        name: 'Hamgyong-bukto (North Hamgyong Province)',
        id: '09'
      },
      {
        name: 'Hamgyong-namdo (South Hamgyong Province)',
        id: '08'
      },
      {
        name: 'Hwanghae-bukto (North Hwanghae Province)',
        id: '06'
      },
      {
        name: 'Hwanghae-namdo (South Hwanghae Province)',
        id: '05'
      },
      {
        name: 'Kangwon-do (Kangwon Province)',
        id: '07'
      },
      {
        name: 'Nasŏn (Najin-Sŏnbong)',
        id: '13'
      },
      {
        name: 'P\'yongan-bukto (North P\'yongan Province)',
        id: '03'
      },
      {
        name: 'P\'yongan-namdo (South P\'yongan Province)',
        id: '02'
      },
      {
        name: 'P\'yongyang-si (P\'yongyang City)',
        id: '01'
      },
      {
        name: 'Yanggang-do (Yanggang Province)',
        id: '10'
      }
    ]
  },
  {
    countryName: 'Korea, Republic of',
    countryShortCode: 'KR',
    regions: [
      {
        name: 'Chungcheongbuk-do',
        id: '43'
      },
      {
        name: 'Chungcheongnam-do',
        id: '44'
      },
      {
        name: 'Jeju-teukbyeoljachido',
        id: '49'
      },
      {
        name: 'Jeollabuk-do',
        id: '45'
      },
      {
        name: 'Jeollanam-do',
        id: '46'
      },
      {
        name: 'Incheon-gwangyeoksi',
        id: '28'
      },
      {
        name: 'Gangwon-do',
        id: '42'
      },
      {
        name: 'Gwangju-gwangyeoksi',
        id: '29'
      },
      {
        name: 'Gyeonggi-do',
        id: '41'
      },
      {
        name: 'Gyeongsangbuk-do',
        id: '47'
      },
      {
        name: 'Gyeongsangnam-do',
        id: '48'
      },
      {
        name: 'Busan-gwangyeoksi',
        id: '26'
      },
      {
        name: 'Seoul-teukbyeolsi',
        id: '11'
      },
      {
        name: 'Sejong-teukbyeoljachisi',
        id: '50'
      },
      {
        name: 'Daegu-gwangyeoksi',
        id: '27'
      },
      {
        name: 'Daejeon-gwangyeoksi',
        id: '30'
      },
      {
        name: 'Ulsan-gwangyeoksi',
        id: '31'
      }
    ]
  },
  {
    countryName: 'Kosovo',
    countryShortCode: 'XK',
    regions: [
      {
        name: 'Farizaj',
        id: 'FZ'
      },
      {
        name: 'Gjakova',
        id: 'GK'
      },
      {
        name: 'Gjilan',
        id: 'GL'
      },
      {
        name: 'Mitrovica',
        id: 'MI'
      },
      {
        name: 'Peja/Peć',
        id: 'PE'
      },
      {
        name: 'Pristina',
        id: 'PR'
      },
      {
        name: 'Prizren',
        id: 'PZ'
      }
    ]
  },
  {
    countryName: 'Kuwait',
    countryShortCode: 'KW',
    regions: [
      {
        name: 'Al Aḩmadi',
        id: 'AH'
      },
      {
        name: 'Al Farwānīyah',
        id: 'FA'
      },
      {
        name: 'Al Jahrā’',
        id: 'JA'
      },
      {
        name: 'Al ‘Āşimah',
        id: 'KU'
      },
      {
        name: 'Ḩawallī',
        id: 'HA'
      },
      {
        name: 'Mubārak al Kabir',
        id: 'MU'
      }
    ]
  },
  {
    countryName: 'Kyrgyzstan',
    countryShortCode: 'KG',
    regions: [
      {
        name: 'Batken Oblasty',
        id: 'B'
      },
      {
        name: 'Bishkek Shaary',
        id: 'GB'
      },
      {
        name: 'Chuy Oblasty (Bishkek)',
        id: 'C'
      },
      {
        name: 'Jalal-Abad Oblasty',
        id: 'J'
      },
      {
        name: 'Naryn Oblasty',
        id: 'N'
      },
      {
        name: 'Osh Oblasty',
        id: 'O'
      },
      {
        name: 'Talas Oblasty',
        id: 'T'
      },
      {
        name: 'Ysyk-Kol Oblasty (Karakol)',
        id: 'Y'
      }
    ]
  },
  {
    countryName: 'Laos',
    countryShortCode: 'LA',
    regions: [
      {
        name: 'Attapu',
        id: 'AT'
      },
      {
        name: 'Bokèo',
        id: 'BK'
      },
      {
        name: 'Bolikhamxai',
        id: 'BL'
      },
      {
        name: 'Champasak',
        id: 'CH'
      },
      {
        name: 'Houaphan',
        id: 'HO'
      },
      {
        name: 'Khammouan',
        id: 'KH'
      },
      {
        name: 'Louang Namtha',
        id: 'LM'
      },
      {
        name: 'Louangphabang',
        id: 'LP'
      },
      {
        name: 'Oudômxai',
        id: 'OU'
      },
      {
        name: 'Phôngsali',
        id: 'PH'
      },
      {
        name: 'Salavan',
        id: 'SL'
      },
      {
        name: 'Savannakhét',
        id: 'SV'
      },
      {
        name: 'Vientiane',
        id: 'VI'
      },
      {
        name: 'Xaignabouli',
        id: 'XA'
      },
      {
        name: 'Xékong',
        id: 'XE'
      },
      {
        name: 'Xaisomboun',
        id: 'XS'
      },
      {
        name: 'Xiangkhouang',
        id: 'XI'
      }
    ]
  },
  {
    countryName: 'Latvia',
    countryShortCode: 'LV',
    regions: [
      {
        name: 'Aglona',
        id: '001'
      },
      {
        name: 'Aizkraukle',
        id: '002'
      },
      {
        name: 'Aizpute',
        id: '003'
      },
      {
        name: 'Aknīste',
        id: '004'
      },
      {
        name: 'Aloja',
        id: '005'
      },
      {
        name: 'Alsunga',
        id: '06'
      },
      {
        name: 'Alūksne',
        id: '007'
      },
      {
        name: 'Amata',
        id: '008'
      },
      {
        name: 'Ape',
        id: '009'
      },
      {
        name: 'Auce',
        id: '010'
      },
      {
        name: 'Ādaži',
        id: '011'
      },
      {
        name: 'Babīte',
        id: '012'
      },
      {
        name: 'Baldone',
        id: '013'
      },
      {
        name: 'Baltinava',
        id: '014'
      },
      {
        name: 'Balvi',
        id: '015'
      },
      {
        name: 'Bauska',
        id: '016'
      },
      {
        name: 'Beverīna',
        id: '017'
      },
      {
        name: 'Brocēni',
        id: '018'
      },
      {
        name: 'Burtnieki',
        id: '019'
      },
      {
        name: 'Carnikava',
        id: '020'
      },
      {
        name: 'Cesvaine',
        id: '021'
      },
      {
        name: 'Cēsis',
        id: '022'
      },
      {
        name: 'Cibla',
        id: '023'
      },
      {
        name: 'Dagda',
        id: '024'
      },
      {
        name: 'Daugavpils',
        id: '025'
      },
      {
        name: 'Daugavpils (City)',
        id: 'DGV'
      },
      {
        name: 'Dobele',
        id: '026'
      },
      {
        name: 'Dundaga',
        id: '027'
      },
      {
        name: 'Durbe',
        id: '028'
      },
      {
        name: 'Engure',
        id: '029'
      },
      {
        name: 'Ērgļi',
        id: '030'
      },
      {
        name: 'Garkalne',
        id: '031'
      },
      {
        name: 'Grobiņa',
        id: '032'
      },
      {
        name: 'Gulbene',
        id: '033'
      },
      {
        name: 'Iecava',
        id: '034'
      },
      {
        name: 'Ikšķile',
        id: '035'
      },
      {
        name: 'Ilūkste',
        id: '036'
      },
      {
        name: 'Inčukalns',
        id: '037'
      },
      {
        name: 'Jaunjelgava',
        id: '038'
      },
      {
        name: 'Jaunpiebalga',
        id: '039'
      },
      {
        name: 'Jaunpils',
        id: '040'
      },
      {
        name: 'Jelgava',
        id: '041'
      },
      {
        name: 'Jelgava (City)',
        id: 'JEL'
      },
      {
        name: 'Jēkabpils',
        id: '042'
      },
      {
        name: 'Jēkabpils (City)',
        id: 'JKB'
      },
      {
        name: 'Jūrmala (City)',
        id: 'JUR'
      },
      {
        name: 'Kandava',
        id: '043'
      },
      {
        name: 'Kārsava',
        id: '044'
      },
      {
        name: 'Kocēni',
        id: '045'
      },
      {
        name: 'Koknese',
        id: '046'
      },
      {
        name: 'Krāslava',
        id: '047'
      },
      {
        name: 'Krimulda',
        id: '048'
      },
      {
        name: 'Krustpils',
        id: '049'
      },
      {
        name: 'Kuldīga',
        id: '050'
      },
      {
        name: 'Ķegums',
        id: '051'
      },
      {
        name: 'Ķekava',
        id: '052'
      },
      {
        name: 'Lielvārde',
        id: '053'
      },
      {
        name: 'Liepāja',
        id: 'LPX'
      },
      {
        name: 'Limbaži',
        id: '054'
      },
      {
        name: 'Līgatne',
        id: '055'
      },
      {
        name: 'Līvāni',
        id: '056'
      },
      {
        name: 'Lubāna',
        id: '057'
      },
      {
        name: 'Ludza',
        id: '058'
      },
      {
        name: 'Madona',
        id: '059'
      },
      {
        name: 'Mazsalaca',
        id: '060'
      },
      {
        name: 'Mālpils',
        id: '061'
      },
      {
        name: 'Mārupe',
        id: '062'
      },
      {
        name: 'Mērsrags',
        id: '063'
      },
      {
        name: 'Naukšēni',
        id: '064'
      },
      {
        name: 'Nereta',
        id: '065'
      },
      {
        name: 'Nīca',
        id: '066'
      },
      {
        name: 'Ogre',
        id: '067'
      },
      {
        name: 'Olaine',
        id: '068'
      },
      {
        name: 'Ozolnieki',
        id: '069'
      },
      {
        name: 'Pārgauja',
        id: '070'
      },
      {
        name: 'Pāvilosta',
        id: '071'
      },
      {
        name: 'Pļaviņas',
        id: '072'
      },
      {
        name: 'Preiļi',
        id: '073'
      },
      {
        name: 'Priekule',
        id: '074'
      },
      {
        name: 'Priekuļi',
        id: '075'
      },
      {
        name: 'Rauna',
        id: '076'
      },
      {
        name: 'Rēzekne',
        id: '077'
      },
      {
        name: 'Rēzekne (City)',
        id: 'REZ'
      },
      {
        name: 'Riebiņi',
        id: '078'
      },
      {
        name: 'Rīga',
        id: 'RIX'
      },
      {
        name: 'Roja',
        id: '079'
      },
      {
        name: 'Ropaži',
        id: '080'
      },
      {
        name: 'Rucava',
        id: '081'
      },
      {
        name: 'Rugāji',
        id: '082'
      },
      {
        name: 'Rundāle',
        id: '083'
      },
      {
        name: 'Rūjiena',
        id: '084'
      },
      {
        name: 'Sala',
        id: '085'
      },
      {
        name: 'Salacgrīva',
        id: '086'
      },
      {
        name: 'Salaspils',
        id: '087'
      },
      {
        name: 'Saldus',
        id: '088'
      },
      {
        name: 'Saulkrasti',
        id: '089'
      },
      {
        name: 'Sēja',
        id: '090'
      },
      {
        name: 'Sigulda',
        id: '091'
      },
      {
        name: 'Skrīveri',
        id: '092'
      },
      {
        name: 'Skrunda',
        id: '093'
      },
      {
        name: 'Smiltene',
        id: '094'
      },
      {
        name: 'Stopiņi',
        id: '095'
      },
      {
        name: 'Strenči',
        id: '096'
      },
      {
        name: 'Talsi',
        id: '097'
      },
      {
        name: 'Tērvete',
        id: '098'
      },
      {
        name: 'Tukums',
        id: '099'
      },
      {
        name: 'Vaiņode',
        id: '100'
      },
      {
        name: 'Valka',
        id: '101'
      },
      {
        name: 'Valmiera',
        id: 'VMR'
      },
      {
        name: 'Varakļāni',
        id: '102'
      },
      {
        name: 'Vārkava',
        id: '103'
      },
      {
        name: 'Vecpiebalga',
        id: '104'
      },
      {
        name: 'Vecumnieki',
        id: '105'
      },
      {
        name: 'Ventspils',
        id: '106'
      },
      {
        name: 'Ventspils (City)',
        id: 'VEN'
      },
      {
        name: 'Viesīte',
        id: '107'
      },
      {
        name: 'Viļaka',
        id: '108'
      },
      {
        name: 'Viļāni',
        id: '109'
      },
      {
        name: 'Zilupe',
        id: '110'
      }
    ]
  },
  {
    countryName: 'Lebanon',
    countryShortCode: 'LB',
    regions: [
      {
        name: 'Aakkâr',
        id: 'AK'
      },
      {
        name: 'Baalbelk-Hermel',
        id: 'BH'
      },
      {
        name: 'Béqaa',
        id: 'BI'
      },
      {
        name: 'Beyrouth',
        id: 'BA'
      },
      {
        name: 'Liban-Nord',
        id: 'AS'
      },
      {
        name: 'Liban-Sud',
        id: 'JA'
      },
      {
        name: 'Mont-Liban',
        id: 'JL'
      },
      {
        name: 'Nabatîyé',
        id: 'NA'
      }
    ]
  },
  {
    countryName: 'Lesotho',
    countryShortCode: 'LS',
    regions: [
      {
        name: 'Berea',
        id: 'D'
      },
      {
        name: 'Butha-Buthe',
        id: 'B'
      },
      {
        name: 'Leribe',
        id: 'C'
      },
      {
        name: 'Mafeteng',
        id: 'E'
      },
      {
        name: 'Maseru',
        id: 'A'
      },
      {
        name: 'Mohales Hoek',
        id: 'F'
      },
      {
        name: 'Mokhotlong',
        id: 'J'
      },
      {
        name: 'Qacha\'s Nek',
        id: 'H'
      },
      {
        name: 'Quthing',
        id: 'G'
      },
      {
        name: 'Thaba-Tseka',
        id: 'K'
      }
    ]
  },
  {
    countryName: 'Liberia',
    countryShortCode: 'LR',
    regions: [
      {
        name: 'Bomi',
        id: 'BM'
      },
      {
        name: 'Bong',
        id: 'BG'
      },
      {
        name: 'Gbarpolu',
        id: 'GP'
      },
      {
        name: 'Grand Bassa',
        id: 'GB'
      },
      {
        name: 'Grand Cape Mount',
        id: 'CM'
      },
      {
        name: 'Grand Gedeh',
        id: 'GG'
      },
      {
        name: 'Grand Kru',
        id: 'GK'
      },
      {
        name: 'Lofa',
        id: 'LO'
      },
      {
        name: 'Margibi',
        id: 'MG'
      },
      {
        name: 'Maryland',
        id: 'MY'
      },
      {
        name: 'Montserrado',
        id: 'MO'
      },
      {
        name: 'Nimba',
        id: 'NI'
      },
      {
        name: 'River Cess',
        id: 'RI'
      },
      {
        name: 'River Geee',
        id: 'RG'
      },
      {
        name: 'Sinoe',
        id: 'SI'
      }
    ]
  },
  {
    countryName: 'Libya',
    countryShortCode: 'LY',
    regions: [
      {
        name: 'Al Buţnān',
        id: 'BU'
      },
      {
        name: 'Al Jabal al Akhḑar',
        id: 'JA'
      },
      {
        name: 'Al Jabal al Gharbī',
        id: 'JG'
      },
      {
        name: 'Al Jafārah',
        id: 'JI'
      },
      {
        name: 'Al Jufrah',
        id: 'JU'
      },
      {
        name: 'Al Kufrah',
        id: 'FK'
      },
      {
        name: 'Al Marj',
        id: 'MJ'
      },
      {
        name: 'Al Marquab',
        id: 'MB'
      },
      {
        name: 'Al Wāḩāt',
        id: 'WA'
      },
      {
        name: 'An Nuqaţ al Khams',
        id: 'NQ'
      },
      {
        name: 'Az Zāwiyah',
        id: 'ZA'
      },
      {
        name: 'Banghāzī',
        id: 'BA'
      },
      {
        name: 'Darnah',
        id: 'DR'
      },
      {
        name: 'Ghāt',
        id: 'GH'
      },
      {
        name: 'Mişrātah',
        id: 'MI'
      },
      {
        name: 'Murzuq',
        id: 'MQ'
      },
      {
        name: 'Nālūt',
        id: 'NL'
      },
      {
        name: 'Sabhā',
        id: 'SB'
      },
      {
        name: 'Surt',
        id: 'SR'
      },
      {
        name: 'Ţarābulus',
        id: 'TB'
      },
      {
        name: 'Yafran',
        id: 'WD'
      },
      {
        name: 'Wādī ash Shāţiʾ',
        id: 'WS'
      }
    ]
  },
  {
    countryName: 'Liechtenstein',
    countryShortCode: 'LI',
    regions: [
      {
        name: 'Balzers',
        id: '01'
      },
      {
        name: 'Eschen',
        id: '02'
      },
      {
        name: 'Gamprin',
        id: '03'
      },
      {
        name: 'Mauren',
        id: '04'
      },
      {
        name: 'Planken',
        id: '05'
      },
      {
        name: 'Ruggell',
        id: '06'
      },
      {
        name: 'Schaan',
        id: '07'
      },
      {
        name: 'Schellenberg',
        id: '08'
      },
      {
        name: 'Triesen',
        id: '09'
      },
      {
        name: 'Triesenberg',
        id: '10'
      },
      {
        name: 'Vaduz',
        id: '11'
      }
    ]
  },
  {
    countryName: 'Lithuania',
    countryShortCode: 'LT',
    regions: [
      {
        name: 'Alytaus',
        id: 'AL'
      },
      {
        name: 'Kauno',
        id: 'KU'
      },
      {
        name: 'Klaipėdos',
        id: 'KL'
      },
      {
        name: 'Marijampolės',
        id: 'MR'
      },
      {
        name: 'Panevėžio',
        id: 'PN'
      },
      {
        name: 'Šiaulių',
        id: 'SA'
      },
      {
        name: 'Tauragės',
        id: 'TA'
      },
      {
        name: 'Telšių',
        id: 'TE'
      },
      {
        name: 'Utenos',
        id: 'UT'
      },
      {
        name: 'Vilniaus',
        id: 'VL'
      }
    ]
  },
  {
    countryName: 'Luxembourg',
    countryShortCode: 'LU',
    regions: [
      {
        name: 'Capellen',
        id: 'CA'
      },
      {
        name: 'Clevaux',
        id: 'CL'
      },
      {
        name: 'Diekirch',
        id: 'DI'
      },
      {
        name: 'Echternach',
        id: 'EC'
      },
      {
        name: 'Esch-sur-Alzette',
        id: 'ES'
      },
      {
        name: 'Grevenmacher',
        id: 'GR'
      },
      {
        name: 'Luxembourg',
        id: 'LU'
      },
      {
        name: 'Mersch',
        id: 'ME'
      },
      {
        name: 'Redange',
        id: 'RD'
      },
      {
        name: 'Remich',
        id: 'RM'
      },
      {
        name: 'Vianden',
        id: 'VD'
      },
      {
        name: 'Wiltz',
        id: 'WI'
      }
    ]
  },
  {
    countryName: 'Macao',
    countryShortCode: 'MO',
    regions: [
      {
        name: 'Macao'
      }
    ]
  },
  {
    countryName: 'Macedonia, Republic of',
    countryShortCode: 'MK',
    regions: [
      {
        name: 'Aračinovo',
        id: '02'
      },
      {
        name: 'Berovo',
        id: '03'
      },
      {
        name: 'Bitola',
        id: '04'
      },
      {
        name: 'Bogdanci',
        id: '05'
      },
      {
        name: 'Bogovinje',
        id: '06'
      },
      {
        name: 'Bosilovo',
        id: '07'
      },
      {
        name: 'Brvenica',
        id: '08'
      },
      {
        name: 'Centar Župa',
        id: '78'
      },
      {
        name: 'Čaška',
        id: '80'
      },
      {
        name: 'Češinovo-Obleševo',
        id: '81'
      },
      {
        name: 'Čučer Sandevo',
        id: '82'
      },
      {
        name: 'Debar',
        id: '21'
      },
      {
        name: 'Debarca',
        id: '22'
      },
      {
        name: 'Delčevo',
        id: '23'
      },
      {
        name: 'Demir Hisar',
        id: '25'
      },
      {
        name: 'Demir Kapija',
        id: '24'
      },
      {
        name: 'Doran',
        id: '26'
      },
      {
        name: 'Dolneni',
        id: '27'
      },
      {
        name: 'Gevgelija',
        id: '18'
      },
      {
        name: 'Gostivar',
        id: '19'
      },
      {
        name: 'Gradsko',
        id: '20'
      },
      {
        name: 'Ilinden',
        id: '34'
      },
      {
        name: 'Jegunovce',
        id: '35'
      },
      {
        name: 'Karbinci',
        id: '37'
      },
      {
        name: 'Kavadarci',
        id: '36'
      },
      {
        name: 'Kičevo',
        id: '40'
      },
      {
        name: 'Kočani',
        id: '42'
      },
      {
        name: 'Konče',
        id: '41'
      },
      {
        name: 'Kratovo',
        id: '43'
      },
      {
        name: 'Kriva Palanka',
        id: '44'
      },
      {
        name: 'Krivogaštani',
        id: '45'
      },
      {
        name: 'Kruševo',
        id: '46'
      },
      {
        name: 'Kumanovo',
        id: '47'
      },
      {
        name: 'Lipkovo',
        id: '48'
      },
      {
        name: 'Lozovo',
        id: '49'
      },
      {
        name: 'Makedonska Kamenica',
        id: '51'
      },
      {
        name: 'Makedonski Brod',
        id: '52'
      },
      {
        name: 'Mavrovo i Rostuša',
        id: '50'
      },
      {
        name: 'Mogila',
        id: '53'
      },
      {
        name: 'Negotino',
        id: '54'
      },
      {
        name: 'Novaci',
        id: '55'
      },
      {
        name: 'Novo Selo',
        id: '56'
      },
      {
        name: 'Ohrid',
        id: '58'
      },
      {
        name: 'Pehčevo',
        id: '60'
      },
      {
        name: 'Petrovec',
        id: '59'
      },
      {
        name: 'Plasnica',
        id: '61'
      },
      {
        name: 'Prilep',
        id: '62'
      },
      {
        name: 'Probištip',
        id: '63'
      },
      {
        name: 'Radoviš',
        id: ''
      },
      {
        name: 'Rankovce',
        id: '65'
      },
      {
        name: 'Resen',
        id: '66'
      },
      {
        name: 'Rosoman',
        id: '67'
      },
      {
        name: 'Skopje',
        id: '85'
      },
      {
        name: 'Sopište',
        id: '70'
      },
      {
        name: 'Staro Nagoričane',
        id: '71'
      },
      {
        name: 'Struga',
        id: '72'
      },
      {
        name: 'Strumica',
        id: '73'
      },
      {
        name: 'Studeničani',
        id: '74'
      },
      {
        name: 'Sveti Nikole',
        id: '69'
      },
      {
        name: 'Štip',
        id: '83'
      },
      {
        name: 'Tearce',
        id: '75'
      },
      {
        name: 'Tetovo',
        id: '76'
      },
      {
        name: 'Valandovo',
        id: '10'
      },
      {
        name: 'Vasilevo',
        id: '11'
      },
      {
        name: 'Veles',
        id: '13'
      },
      {
        name: 'Vevčani',
        id: '12'
      },
      {
        name: 'Vinica',
        id: '14'
      },
      {
        name: 'Vrapčište',
        id: '16'
      },
      {
        name: 'Zelenikovo',
        id: '32'
      },
      {
        name: 'Zrnovci',
        id: '33'
      },
      {
        name: 'Želino',
        id: '30'
      }
    ]
  },
  {
    countryName: 'Madagascar',
    countryShortCode: 'MG',
    regions: [
      {
        name: 'Antananarivo',
        id: 'T'
      },
      {
        name: 'Antsiranana',
        id: 'D'
      },
      {
        name: 'Fianarantsoa',
        id: 'F'
      },
      {
        name: 'Mahajanga',
        id: 'M'
      },
      {
        name: 'Toamasina',
        id: 'A'
      },
      {
        name: 'Toliara',
        id: 'U'
      }
    ]
  },
  {
    countryName: 'Malawi',
    countryShortCode: 'MW',
    regions: [
      {
        name: 'Balaka',
        id: 'BA'
      },
      {
        name: 'Blantyre',
        id: 'BL'
      },
      {
        name: 'Chikwawa',
        id: 'CK'
      },
      {
        name: 'Chiradzulu',
        id: 'CR'
      },
      {
        name: 'Chitipa',
        id: 'CT'
      },
      {
        name: 'Dedza',
        id: 'DE'
      },
      {
        name: 'Dowa',
        id: 'DO'
      },
      {
        name: 'Karonga',
        id: 'KR'
      },
      {
        name: 'Kasungu',
        id: 'KS'
      },
      {
        name: 'Likoma',
        id: 'LK'
      },
      {
        name: 'Lilongwe',
        id: 'LI'
      },
      {
        name: 'Machinga',
        id: 'MH'
      },
      {
        name: 'Mangochi',
        id: 'MG'
      },
      {
        name: 'Mchinji',
        id: 'MC'
      },
      {
        name: 'Mulanje',
        id: 'MU'
      },
      {
        name: 'Mwanza',
        id: 'MW'
      },
      {
        name: 'Mzimba',
        id: 'MZ'
      },
      {
        name: 'Nkhata Bay',
        id: 'NE'
      },
      {
        name: 'Nkhotakota',
        id: 'NB'
      },
      {
        name: 'Nsanje',
        id: 'NS'
      },
      {
        name: 'Ntcheu',
        id: 'NU'
      },
      {
        name: 'Ntchisi',
        id: 'NI'
      },
      {
        name: 'Phalombe',
        id: 'PH'
      },
      {
        name: 'Rumphi',
        id: 'RU'
      },
      {
        name: 'Salima',
        id: 'SA'
      },
      {
        name: 'Thyolo',
        id: 'TH'
      },
      {
        name: 'Zomba',
        id: 'ZO'
      }
    ]
  },
  {
    countryName: 'Malaysia',
    countryShortCode: 'MY',
    regions: [
      {
        name: 'Johor',
        id: '01'
      },
      {
        name: 'Kedah',
        id: '02'
      },
      {
        name: 'Kelantan',
        id: '03'
      },
      {
        name: 'Melaka',
        id: '04'
      },
      {
        name: 'Negeri Sembilan',
        id: '05'
      },
      {
        name: 'Pahang',
        id: '06'
      },
      {
        name: 'Perak',
        id: '08'
      },
      {
        name: 'Perlis',
        id: '09'
      },
      {
        name: 'Pulau Pinang',
        id: '07'
      },
      {
        name: 'Sabah',
        id: '12'
      },
      {
        name: 'Sarawak',
        id: '13'
      },
      {
        name: 'Selangor',
        id: '10'
      },
      {
        name: 'Terengganu',
        id: '11'
      },
      {
        name: 'Wilayah Persekutuan (Kuala Lumpur)',
        id: '14'
      },
      {
        name: 'Wilayah Persekutuan (Labuan)',
        id: '15'
      },
      {
        name: 'Wilayah Persekutuan (Putrajaya)',
        id: '16'
      }
    ]
  },
  {
    countryName: 'Maldives',
    countryShortCode: 'MV',
    regions: [
      {
        name: 'Alifu Alifu',
        id: '02'
      },
      {
        name: 'Alifu Dhaalu',
        id: '00'
      },
      {
        name: 'Baa',
        id: '20'
      },
      {
        name: 'Dhaalu',
        id: '17'
      },
      {
        name: 'Faafu',
        id: '14'
      },
      {
        name: 'Gaafu Alifu',
        id: '27'
      },
      {
        name: 'Gaafu Dhaalu',
        id: '28'
      },
      {
        name: 'Gnaviyani',
        id: '29'
      },
      {
        name: 'Haa Alifu',
        id: '07'
      },
      {
        name: 'Haa Dhaalu',
        id: '23'
      },
      {
        name: 'Kaafu',
        id: '26'
      },
      {
        name: 'Laamu',
        id: '05'
      },
      {
        name: 'Lhaviyani',
        id: '03'
      },
      {
        name: 'Malé',
        id: 'MLE'
      },
      {
        name: 'Meemu',
        id: '12'
      },
      {
        name: 'Noonu',
        id: '25'
      },
      {
        name: 'Raa',
        id: '13'
      },
      {
        name: 'Seenu',
        id: '01'
      },
      {
        name: 'Shaviyani',
        id: '24'
      },
      {
        name: 'Thaa',
        id: '08'
      },
      {
        name: 'Vaavu',
        id: '04'
      }
    ]
  },
  {
    countryName: 'Mali',
    countryShortCode: 'ML',
    regions: [
      {
        name: 'Bamako',
        id: 'BKO'
      },
      {
        name: 'Gao',
        id: '7'
      },
      {
        name: 'Kayes',
        id: '1'
      },
      {
        name: 'Kidal',
        id: '8'
      },
      {
        name: 'Koulikoro',
        id: '2'
      },
      {
        name: 'Mopti',
        id: '5'
      },
      {
        name: 'Segou',
        id: '4'
      },
      {
        name: 'Sikasso',
        id: '3'
      },
      {
        name: 'Tombouctou',
        id: '6'
      },
      {
        name: 'Taoudénit',
        id: '9'
      },
      {
        name: 'Ménaka',
        id: '10'
      }
    ]
  },
  {
    countryName: 'Malta',
    countryShortCode: 'MT',
    regions: [
      {
        name: 'Attard',
        id: '01'
      },
      {
        name: 'Balzan',
        id: '02'
      },
      {
        name: 'Birgu',
        id: '03'
      },
      {
        name: 'Birkirkara',
        id: '04'
      },
      {
        name: 'Birżebbuġa',
        id: '05'
      },
      {
        name: 'Bormla',
        id: '06'
      },
      {
        name: 'Dingli',
        id: '07'
      },
      {
        name: 'Fgura',
        id: '08'
      },
      {
        name: 'Floriana',
        id: '09'
      },
      {
        name: 'Fontana',
        id: '10'
      },
      {
        name: 'Guda',
        id: '11'
      },
      {
        name: 'Gżira',
        id: '12'
      },
      {
        name: 'Għajnsielem',
        id: '13'
      },
      {
        name: 'Għarb',
        id: '14'
      },
      {
        name: 'Għargħur',
        id: '15'
      },
      {
        name: 'Għasri',
        id: '16'
      },
      {
        name: 'Għaxaq',
        id: '17'
      },
      {
        name: 'Ħamrun',
        id: '18'
      },
      {
        name: 'Iklin',
        id: '19'
      },
      {
        name: 'Isla',
        id: '20'
      },
      {
        name: 'Kalkara',
        id: '21'
      },
      {
        name: 'Kerċem',
        id: '22'
      },
      {
        name: 'Kirkop',
        id: '23'
      },
      {
        name: 'Lija',
        id: '24'
      },
      {
        name: 'Luqa',
        id: '25'
      },
      {
        name: 'Marsa',
        id: '26'
      },
      {
        name: 'Marsaskala',
        id: '27'
      },
      {
        name: 'Marsaxlokk',
        id: '28'
      },
      {
        name: 'Mdina',
        id: '29'
      },
      {
        name: 'Mellieħa',
        id: '30'
      },
      {
        name: 'Mġarr',
        id: '31'
      },
      {
        name: 'Mosta',
        id: '32'
      },
      {
        name: 'Mqabba',
        id: '33'
      },
      {
        name: 'Msida',
        id: '34'
      },
      {
        name: 'Mtarfa',
        id: '35'
      },
      {
        name: 'Munxar',
        id: '36'
      },
      {
        name: 'Nadur',
        id: '37'
      },
      {
        name: 'Naxxar',
        id: '38'
      },
      {
        name: 'Paola',
        id: '39'
      },
      {
        name: 'Pembroke',
        id: '40'
      },
      {
        name: 'Pietà',
        id: '41'
      },
      {
        name: 'Qala',
        id: '42'
      },
      {
        name: 'Qormi',
        id: '43'
      },
      {
        name: 'Qrendi',
        id: '44'
      },
      {
        name: 'Rabat Għawdex',
        id: '45'
      },
      {
        name: 'Rabat Malta',
        id: '46'
      },
      {
        name: 'Safi',
        id: '47'
      },
      {
        name: 'San Ġiljan',
        id: '48'
      },
      {
        name: 'San Ġwann',
        id: '49'
      },
      {
        name: 'San Lawrenz',
        id: '50'
      },
      {
        name: 'San Pawl il-Baħar',
        id: '51'
      },
      {
        name: 'Sannat',
        id: '52'
      },
      {
        name: 'Santa Luċija',
        id: '53'
      },
      {
        name: 'Santa Venera',
        id: '54'
      },
      {
        name: 'Siġġiewi',
        id: '55'
      },
      {
        name: 'Sliema',
        id: '56'
      },
      {
        name: 'Swieqi',
        id: '57'
      },
      {
        name: 'Tai Xbiex',
        id: '58'
      },
      {
        name: 'Tarzien',
        id: '59'
      },
      {
        name: 'Valletta',
        id: '60'
      },
      {
        name: 'Xagħra',
        id: '61'
      },
      {
        name: 'Xewkija',
        id: '62'
      },
      {
        name: 'Xgħajra',
        id: '63'
      },
      {
        name: 'Żabbar',
        id: '64'
      },
      {
        name: 'Żebbuġ Għawde',
        id: '65'
      },
      {
        name: 'Żebbuġ Malta',
        id: '66'
      },
      {
        name: 'Żejtun',
        id: '67'
      },
      {
        name: 'Żurrieq',
        id: '68'
      }
    ]
  },
  {
    countryName: 'Marshall Islands',
    countryShortCode: 'MH',
    regions: [
      {
        name: 'Ailinglaplap',
        id: 'ALL'
      },
      {
        name: 'Ailuk',
        id: 'ALK'
      },
      {
        name: 'Arno',
        id: 'ARN'
      },
      {
        name: 'Aur',
        id: 'AUR'
      },
      {
        name: 'Bikini and Kili',
        id: 'KIL'
      },
      {
        name: 'Ebon',
        id: 'EBO'
      },
      {
        name: 'Jabat',
        id: 'JAB'
      },
      {
        name: 'Jaluit',
        id: 'JAL'
      },
      {
        name: 'Kwajalein',
        id: 'KWA'
      },
      {
        name: 'Lae',
        id: 'LAE'
      },
      {
        name: 'Lib',
        id: 'LIB'
      },
      {
        name: 'Likiep',
        id: 'LIK'
      },
      {
        name: 'Majuro',
        id: 'MAJ'
      },
      {
        name: 'Maloelap',
        id: 'MAL'
      },
      {
        name: 'Mejit',
        id: 'MEJ'
      },
      {
        name: 'Namdrik',
        id: 'NMK'
      },
      {
        name: 'Namu',
        id: 'NMU'
      },
      {
        name: 'Rongelap',
        id: 'RON'
      },
      {
        name: 'Ujae',
        id: 'UJA'
      },
      {
        name: 'Utrik',
        id: 'UTI'
      },
      {
        name: 'Wotho',
        id: 'WTH'
      },
      {
        name: 'Wotje',
        id: 'WTJ'
      }
    ]
  },
  {
    countryName: 'Martinique',
    countryShortCode: 'MQ',
    regions: [
      {
        name: 'Martinique'
      }
    ]
  },
  {
    countryName: 'Mauritania',
    countryShortCode: 'MR',
    regions: [
      {
        name: 'Adrar',
        id: '07'
      },
      {
        name: 'Assaba',
        id: '03'
      },
      {
        name: 'Brakna',
        id: '05'
      },
      {
        name: 'Dakhlet Nouadhibou',
        id: '08'
      },
      {
        name: 'Gorgol',
        id: '04'
      },
      {
        name: 'Guidimaka',
        id: '10'
      },
      {
        name: 'Hodh Ech Chargui',
        id: '01'
      },
      {
        name: 'Hodh El Gharbi',
        id: '02'
      },
      {
        name: 'Inchiri',
        id: '12'
      },
      {
        name: 'Nouakchott Nord',
        id: '14'
      },
      {
        name: 'Nouakchott Ouest',
        id: '13'
      },
      {
        name: 'Nouakchott Sud',
        id: '15'
      },
      {
        name: 'Tagant',
        id: '09'
      },
      {
        name: 'Tiris Zemmour',
        id: '11'
      },
      {
        name: 'Trarza',
        id: '06'
      }
    ]
  },
  {
    countryName: 'Mauritius',
    countryShortCode: 'MU',
    regions: [
      {
        name: 'Agalega Islands',
        id: 'AG'
      },
      {
        name: 'Beau Bassin-Rose Hill',
        id: 'BR'
      },
      {
        name: 'Black River',
        id: 'BL'
      },
      {
        name: 'Cargados Carajos Shoals',
        id: 'CC'
      },
      {
        name: 'Curepipe',
        id: 'CU'
      },
      {
        name: 'Flacq',
        id: 'FL'
      },
      {
        name: 'Grand Port',
        id: 'GP'
      },
      {
        name: 'Moka',
        id: 'MO'
      },
      {
        name: 'Pamplemousses',
        id: 'PA'
      },
      {
        name: 'Plaines Wilhems',
        id: 'PW'
      },
      {
        name: 'Port Louis (City)',
        id: 'PU'
      },
      {
        name: 'Port Louis',
        id: 'PL'
      },
      {
        name: 'Riviere du Rempart',
        id: 'RR'
      },
      {
        name: 'Rodrigues Island',
        id: 'RO'
      },
      {
        name: 'Savanne',
        id: 'SA'
      },
      {
        name: 'Vacoas-Phoenix',
        id: 'CP'
      }
    ]
  },
  {
    countryName: 'Mayotte',
    countryShortCode: 'YT',
    regions: [
      {
        name: 'Dzaoudzi',
        id: '01'
      },
      {
        name: 'Pamandzi',
        id: '02'
      },
      {
        name: 'Mamoudzou',
        id: '03'
      },
      {
        name: 'Dembeni',
        id: '04'
      },
      {
        name: 'Bandrélé',
        id: '05'
      },
      {
        name: 'Kani-Kéli',
        id: '06'
      },
      {
        name: 'Bouéni',
        id: '07'
      },
      {
        name: 'Chirongui',
        id: '08'
      },
      {
        name: 'Sada',
        id: '09'
      },
      {
        name: 'Ouangani',
        id: '10'
      },
      {
        name: 'Chiconi',
        id: '11'
      },
      {
        name: 'Tsingoni',
        id: '12'
      },
      {
        name: 'M\'Tsangamouji',
        id: '13'
      },
      {
        name: 'Acoua',
        id: '14'
      },
      {
        name: 'Mtsamboro',
        id: '15'
      },
      {
        name: 'Bandraboua',
        id: '16'
      },
      {
        name: 'Koungou',
        id: '17'
      }
    ]
  },
  {
    countryName: 'Mexico',
    countryShortCode: 'MX',
    regions: [
      {
        name: 'Aguascalientes',
        id: 'AGU'
      },
      {
        name: 'Baja California',
        id: 'BCN'
      },
      {
        name: 'Baja California Sur',
        id: 'BCS'
      },
      {
        name: 'Campeche',
        id: 'CAM'
      },
      {
        name: 'Ciudad de México',
        id: 'CMX'
      },
      {
        name: 'Chiapas',
        id: 'CHP'
      },
      {
        name: 'Chihuahua',
        id: 'CHH'
      },
      {
        name: 'Coahuila de Zaragoza',
        id: 'COA'
      },
      {
        name: 'Colima',
        id: 'COL'
      },
      {
        name: 'Durango',
        id: 'DUR'
      },
      {
        name: 'Estado de México',
        id: 'MEX'
      },
      {
        name: 'Guanajuato',
        id: 'GUA'
      },
      {
        name: 'Guerrero',
        id: 'GRO'
      },
      {
        name: 'Hidalgo',
        id: 'HID'
      },
      {
        name: 'Jalisco',
        id: 'JAL'
      },
      {
        name: 'Michoacán de Ocampo',
        id: 'MIC'
      },
      {
        name: 'Morelos',
        id: 'MOR'
      },
      {
        name: 'Nayarit',
        id: 'NAY'
      },
      {
        name: 'Nuevo León',
        id: 'NLE'
      },
      {
        name: 'Oaxaca',
        id: 'OAX'
      },
      {
        name: 'Puebla',
        id: 'PUE'
      },
      {
        name: 'Querétaro de Arteaga',
        id: 'QUE'
      },
      {
        name: 'Quintana Roo',
        id: 'ROO'
      },
      {
        name: 'San Luis Potosí',
        id: 'SLP'
      },
      {
        name: 'Sinaloa',
        id: 'SIN'
      },
      {
        name: 'Sonora',
        id: 'SON'
      },
      {
        name: 'Tabasco',
        id: 'TAB'
      },
      {
        name: 'Tamaulipas',
        id: 'TAM'
      },
      {
        name: 'Tlaxcala',
        id: 'TLA'
      },
      {
        name: 'Veracruz',
        id: 'VER'
      },
      {
        name: 'Yucatán',
        id: 'YUC'
      },
      {
        name: 'Zacatecas',
        id: 'ZAC'
      }
    ]
  },
  {
    countryName: 'Micronesia, Federated States of',
    countryShortCode: 'FM',
    regions: [
      {
        name: 'Chuuk (Truk)',
        id: 'TRK'
      },
      {
        name: 'Kosrae',
        id: 'KSA'
      },
      {
        name: 'Pohnpei',
        id: 'PNI'
      },
      {
        name: 'Yap',
        id: 'YAP'
      }
    ]
  },
  {
    countryName: 'Moldova',
    countryShortCode: 'MD',
    regions: [
      {
        name: 'Aenii Noi',
        id: 'AN'
      },
      {
        name: 'Basarabeasca',
        id: 'BS'
      },
      {
        name: 'Bălți',
        id: 'BA'
      },
      {
        name: 'Bender',
        id: 'BD'
      },
      {
        name: 'Briceni',
        id: 'BR'
      },
      {
        name: 'Cahul',
        id: 'CA'
      },
      {
        name: 'Cantemir',
        id: 'CT'
      },
      {
        name: 'Călărași',
        id: 'CL'
      },
      {
        name: 'Căușeni',
        id: 'CS'
      },
      {
        name: 'Chișinău',
        id: 'CU'
      },
      {
        name: 'Cimișlia',
        id: 'CM'
      },
      {
        name: 'Criuleni',
        id: 'CR'
      },
      {
        name: 'Dondușeni',
        id: 'DO'
      },
      {
        name: 'Drochia',
        id: 'DR'
      },
      {
        name: 'Dubăsari',
        id: 'DU'
      },
      {
        name: 'Edineț',
        id: 'ED'
      },
      {
        name: 'Fălești',
        id: 'FA'
      },
      {
        name: 'Florești',
        id: 'FL'
      },
      {
        name: 'Găgăuzia',
        id: 'GA'
      },
      {
        name: 'Glodeni',
        id: 'GL'
      },
      {
        name: 'Hîncești',
        id: 'HI'
      },
      {
        name: 'Ialoveni',
        id: 'IA'
      },
      {
        name: 'Leova',
        id: 'LE'
      },
      {
        name: 'Nisporeni',
        id: 'NI'
      },
      {
        name: 'Ocnița',
        id: 'OC'
      },
      {
        name: 'Orhei',
        id: 'OR'
      },
      {
        name: 'Rezina',
        id: 'RE'
      },
      {
        name: 'Rîșcani',
        id: 'RI'
      },
      {
        name: 'Sîngerei',
        id: 'SI'
      },
      {
        name: 'Soroca',
        id: 'SO'
      },
      {
        name: 'Stânga Nistrului',
        id: 'SN'
      },
      {
        name: 'Strășeni',
        id: 'ST'
      },
      {
        name: 'Șoldănești',
        id: 'SD'
      },
      {
        name: 'Ștefan Vodă',
        id: 'SV'
      },
      {
        name: 'Taraclia',
        id: 'TA'
      },
      {
        name: 'Telenești',
        id: 'TE'
      },
      {
        name: 'Ungheni',
        id: 'UN'
      }
    ]
  },
  {
    countryName: 'Monaco',
    countryShortCode: 'MC',
    regions: [
      {
        name: 'Colle',
        id: 'CL'
      },
      {
        name: 'Condamine',
        id: 'CO'
      },
      {
        name: 'Fontvieille',
        id: 'FO'
      },
      {
        name: 'Gare',
        id: 'GA'
      },
      {
        name: 'Jardin Exotique',
        id: 'JE'
      },
      {
        name: 'Larvotto',
        id: 'LA'
      },
      {
        name: 'Malbousquet',
        id: 'MA'
      },
      {
        name: 'Monaco-Ville',
        id: 'MO'
      },
      {
        name: 'Moneghetti',
        id: 'MG'
      },
      {
        name: 'Monte-Carlo',
        id: 'MC'
      },
      {
        name: 'Moulins',
        id: 'MU'
      },
      {
        name: 'Port-Hercule',
        id: 'PH'
      },
      {
        name: 'Saint-Roman',
        id: 'SR'
      },
      {
        name: 'Sainte-Dévote',
        id: 'SD'
      },
      {
        name: 'Source',
        id: 'SO'
      },
      {
        name: 'Spélugues',
        id: 'SP'
      },
      {
        name: 'Vallon de la Rousse',
        id: 'VR'
      }
    ]
  },
  {
    countryName: 'Mongolia',
    countryShortCode: 'MN',
    regions: [
      {
        name: 'Arhangay',
        id: '073'
      },
      {
        name: 'Bayan-Olgiy',
        id: '071'
      },
      {
        name: 'Bayanhongor',
        id: '069'
      },
      {
        name: 'Bulgan',
        id: '067'
      },
      {
        name: 'Darhan',
        id: '037'
      },
      {
        name: 'Dornod',
        id: '061'
      },
      {
        name: 'Dornogovi',
        id: '063'
      },
      {
        name: 'Dundgovi',
        id: '059'
      },
      {
        name: 'Dzavhan',
        id: '057'
      },
      {
        name: 'Govi-Altay',
        id: '065'
      },
      {
        name: 'Govi-Sumber',
        id: '064'
      },
      {
        name: 'Hovd',
        id: '043'
      },
      {
        name: 'Hovsgol',
        id: '041'
      },
      {
        name: 'Omnogovi',
        id: '053'
      },
      {
        name: 'Ovorhangay',
        id: '055'
      },
      {
        name: 'Selenge',
        id: '049'
      },
      {
        name: 'Suhbaatar',
        id: '051'
      },
      {
        name: 'Tov',
        id: '047'
      },
      {
        name: 'Ulaanbaatar',
        id: '1'
      },
      {
        name: 'Uvs',
        id: '046'
      },
      {
        name: 'Erdenet',
        id: '074'
      }
    ]
  },
  {
    countryName: 'Montenegro',
    countryShortCode: 'ME',
    regions: [
      {
        name: 'Andrijevica',
        id: '01'
      },
      {
        name: 'Bar',
        id: '02'
      },
      {
        name: 'Berane',
        id: '03'
      },
      {
        name: 'Bijelo Polje',
        id: '04'
      },
      {
        name: 'Budva',
        id: '05'
      },
      {
        name: 'Cetinje',
        id: '06'
      },
      {
        name: 'Danilovgrad',
        id: '07'
      },
      {
        name: 'Gusinje',
        id: '22'
      },
      {
        name: 'Herceg Novi',
        id: '08'
      },
      {
        name: 'Kolašin',
        id: '09'
      },
      {
        name: 'Kotor',
        id: '10'
      },
      {
        name: 'Mojkovac',
        id: '11'
      },
      {
        name: 'Nikšić',
        id: '12'
      },
      {
        name: 'Petnica',
        id: '23'
      },
      {
        name: 'Plav',
        id: '13'
      },
      {
        name: 'Plužine',
        id: '14'
      },
      {
        name: 'Pljevlja',
        id: '15'
      },
      {
        name: 'Podgorica',
        id: '16'
      },
      {
        name: 'Rožaje',
        id: '17'
      },
      {
        name: 'Šavnik',
        id: '18'
      },
      {
        name: 'Tivat',
        id: '19'
      },
      {
        name: 'Ulcinj',
        id: '20'
      },
      {
        name: 'Žabljak',
        id: '21'
      }
    ]
  },
  {
    countryName: 'Montserrat',
    countryShortCode: 'MS',
    regions: [
      {
        name: 'Saint Anthony'
      },
      {
        name: 'Saint Georges'
      },
      {
        name: 'Saint Peter\'s'
      }
    ]
  },
  {
    countryName: 'Morocco',
    countryShortCode: 'MA',
    regions: [
      {
        name: 'Tanger-Tétouan-Al Hoceïma',
        id: 'MA-01'
      },
      {
        name: 'L\'Oriental',
        id: 'MA-02'
      },
      {
        name: 'Fès-Meknès',
        id: 'MA-03'
      },
      {
        name: 'Rabat-Salé-Kénitra',
        id: 'MA-04'
      },
      {
        name: 'Béni Mellal-Khénifra',
        id: 'MA-05'
      },
      {
        name: 'Casablanca-Settat',
        id: 'MA-06'
      },
      {
        name: 'Marrakech-Safi',
        id: 'MA-07'
      },
      {
        name: 'Drâa-Tafilalet',
        id: 'MA-08'
      },
      {
        name: 'Souss-Massa',
        id: 'MA-09'
      },
      {
        name: 'Guelmim-Oued Noun',
        id: 'MA-10'
      },
      {
        name: 'Laâyoune-Sakia El Hamra',
        id: 'MA-11'
      },
      {
        name: 'Dakhla-Oued Ed-Dahab',
        id: 'MA-12'
      }
    ]
  },
  {
    countryName: 'Mozambique',
    countryShortCode: 'MZ',
    regions: [
      {
        name: 'Cabo Delgado',
        id: 'P'
      },
      {
        name: 'Gaza',
        id: 'G'
      },
      {
        name: 'Inhambane',
        id: 'I'
      },
      {
        name: 'Manica',
        id: 'B'
      },
      {
        name: 'Maputo',
        id: 'L'
      },
      {
        name: 'Maputo (City)',
        id: 'MPM'
      },
      {
        name: 'Nampula',
        id: 'N'
      },
      {
        name: 'Niassa',
        id: 'A'
      },
      {
        name: 'Sofala',
        id: 'S'
      },
      {
        name: 'Tete',
        id: 'T'
      },
      {
        name: 'Zambezia',
        id: 'Q'
      }
    ]
  },
  {
    countryName: 'Myanmar',
    countryShortCode: 'MM',
    regions: [
      {
        name: 'Ayeyarwady',
        id: '07'
      },
      {
        name: 'Bago',
        id: '02'
      },
      {
        name: 'Chin',
        id: '14'
      },
      {
        name: 'Kachin',
        id: '11'
      },
      {
        name: 'Kayah',
        id: '12'
      },
      {
        name: 'Kayin',
        id: '13'
      },
      {
        name: 'Magway',
        id: '03'
      },
      {
        name: 'Mandalay',
        id: '04'
      },
      {
        name: 'Mon',
        id: '15'
      },
      {
        name: 'Nay Pyi Taw',
        id: '18'
      },
      {
        name: 'Rakhine',
        id: '16'
      },
      {
        name: 'Sagaing',
        id: '01'
      },
      {
        name: 'Shan',
        id: '17'
      },
      {
        name: 'Tanintharyi',
        id: '05'
      },
      {
        name: 'Yangon',
        id: '06'
      }
    ]
  },
  {
    countryName: 'Namibia',
    countryShortCode: 'NA',
    regions: [
      {
        name: 'Erongo',
        id: 'ER'
      },
      {
        name: 'Hardap',
        id: 'HA'
      },
      {
        name: 'Kavango East',
        id: 'KE'
      },
      {
        name: 'Kavango West',
        id: 'KW'
      },
      {
        name: 'Karas',
        id: 'KA'
      },
      {
        name: 'Khomas',
        id: 'KH'
      },
      {
        name: 'Kunene',
        id: 'KU'
      },
      {
        name: 'Ohangwena',
        id: 'OW'
      },
      {
        name: 'Omaheke',
        id: 'OH'
      },
      {
        name: 'Omusati',
        id: 'OS'
      },
      {
        name: 'Oshana',
        id: 'ON'
      },
      {
        name: 'Oshikoto',
        id: 'OT'
      },
      {
        name: 'Otjozondjupa',
        id: 'OD'
      },
      {
        name: 'Zambezi',
        id: 'CA'
      }
    ]
  },
  {
    countryName: 'Nauru',
    countryShortCode: 'NR',
    regions: [
      {
        name: 'Aiwo',
        id: '01'
      },
      {
        name: 'Anabar',
        id: '02'
      },
      {
        name: 'Anetan',
        id: '03'
      },
      {
        name: 'Anibare',
        id: '04'
      },
      {
        name: 'Baiti',
        id: '05'
      },
      {
        name: 'Boe',
        id: '06'
      },
      {
        name: 'Buada',
        id: '07'
      },
      {
        name: 'Denigomodu',
        id: '08'
      },
      {
        name: 'Ewa',
        id: '09'
      },
      {
        name: 'Ijuw',
        id: '10'
      },
      {
        name: 'Meneng',
        id: '11'
      },
      {
        name: 'Nibok',
        id: '12'
      },
      {
        name: 'Uaboe',
        id: '13'
      },
      {
        name: 'Yaren',
        id: '14'
      }
    ]
  },
  {
    countryName: 'Nepal',
    countryShortCode: 'NP',
    regions: [
      {
        name: 'Province No. 1',
        id: '1'
      },
      {
        name: 'Madhesh Province',
        id: '2'
      },
      {
        name: 'Bagmati Province',
        id: '3'
      },
      {
        name: 'Gandaki Province',
        id: '4'
      },
      {
        name: 'Lumbini Province',
        id: '5'
      },
      {
        name: 'Karnali Province',
        id: '6'
      },
      {
        name: 'Sudurpashchim Province',
        id: '7'
      }
    ]
  },
  {
    countryName: 'Netherlands',
    countryShortCode: 'NL',
    regions: [
      {
        name: 'Drenthe',
        id: 'DR'
      },
      {
        name: 'Flevoland',
        id: 'FL'
      },
      {
        name: 'Friesland',
        id: 'FR'
      },
      {
        name: 'Gelderland',
        id: 'GE'
      },
      {
        name: 'Groningen',
        id: 'GR'
      },
      {
        name: 'Limburg',
        id: 'LI'
      },
      {
        name: 'Noord-Brabant',
        id: 'NB'
      },
      {
        name: 'Noord-Holland',
        id: 'NH'
      },
      {
        name: 'Overijssel',
        id: 'OV'
      },
      {
        name: 'Utrecht',
        id: 'UT'
      },
      {
        name: 'Zeeland',
        id: 'ZE'
      },
      {
        name: 'Zuid-Holland',
        id: 'ZH'
      }
    ]
  },
  {
    countryName: 'New Caledonia',
    countryShortCode: 'NC',
    regions: [
      {
        name: 'Iles Loyaute'
      },
      {
        name: 'Nord'
      },
      {
        name: 'Sud'
      }
    ]
  },
  {
    countryName: 'New Zealand',
    countryShortCode: 'NZ',
    regions: [
      {
        name: 'Auckland',
        id: 'AUK'
      },
      {
        name: 'Bay of Plenty',
        id: 'BOP'
      },
      {
        name: 'Canterbury',
        id: 'CAN'
      },
      {
        name: 'Gisborne',
        id: 'GIS'
      },
      {
        name: 'Hawke\'s Bay',
        id: 'HKB'
      },
      {
        name: 'Marlborough',
        id: 'MBH'
      },
      {
        name: 'Manawatu-Wanganui',
        id: 'MWT'
      },
      {
        name: 'Northland',
        id: 'NTL'
      },
      {
        name: 'Nelson',
        id: 'NSN'
      },
      {
        name: 'Otago',
        id: 'OTA'
      },
      {
        name: 'Southland',
        id: 'STL'
      },
      {
        name: 'Taranaki',
        id: 'TKI'
      },
      {
        name: 'Tasman',
        id: 'TAS'
      },
      {
        name: 'Waikato',
        id: 'WKO'
      },
      {
        name: 'Wellington',
        id: 'WGN'
      },
      {
        name: 'West Coast',
        id: 'WTC'
      },
      {
        name: 'Chatham Islands Territory',
        id: 'CIT'
      }
    ]
  },
  {
    countryName: 'Nicaragua',
    countryShortCode: 'NI',
    regions: [
      {
        name: 'Boaco',
        id: 'BO'
      },
      {
        name: 'Carazo',
        id: 'CA'
      },
      {
        name: 'Chinandega',
        id: 'CI'
      },
      {
        name: 'Chontales',
        id: 'CO'
      },
      {
        name: 'Estelí',
        id: 'ES'
      },
      {
        name: 'Granada',
        id: 'GR'
      },
      {
        name: 'Jinotega',
        id: 'JI'
      },
      {
        name: 'León',
        id: 'LE'
      },
      {
        name: 'Madriz',
        id: 'MD'
      },
      {
        name: 'Managua',
        id: 'MN'
      },
      {
        name: 'Masaya',
        id: 'MS'
      },
      {
        name: 'Matagalpa',
        id: 'MT'
      },
      {
        name: 'Nueva Segovia',
        id: 'NS'
      },
      {
        name: 'Río San Juan',
        id: 'SJ'
      },
      {
        name: 'Rivas',
        id: 'RI'
      },
      {
        name: 'Atlántico Norte',
        id: 'AN'
      },
      {
        name: 'Atlántico Sur',
        id: 'AS'
      }
    ]
  },
  {
    countryName: 'Niger',
    countryShortCode: 'NE',
    regions: [
      {
        name: 'Agadez',
        id: '1'
      },
      {
        name: 'Diffa',
        id: '2'
      },
      {
        name: 'Dosso',
        id: '3'
      },
      {
        name: 'Maradi',
        id: '4'
      },
      {
        name: 'Niamey',
        id: '8'
      },
      {
        name: 'Tahoua',
        id: '5'
      },
      {
        name: 'Tillabéri',
        id: '6'
      },
      {
        name: 'Zinder',
        id: '7'
      }
    ]
  },
  {
    countryName: 'Nigeria',
    countryShortCode: 'NG',
    regions: [
      {
        name: 'Abia',
        id: 'AB'
      },
      {
        name: 'Abuja Federal Capital Territory',
        id: 'FC'
      },
      {
        name: 'Adamawa',
        id: 'AD'
      },
      {
        name: 'Akwa Ibom',
        id: 'AK'
      },
      {
        name: 'Anambra',
        id: 'AN'
      },
      {
        name: 'Bauchi',
        id: 'BA'
      },
      {
        name: 'Bayelsa',
        id: 'BY'
      },
      {
        name: 'Benue',
        id: 'BE'
      },
      {
        name: 'Borno',
        id: 'BO'
      },
      {
        name: 'Cross River',
        id: 'CR'
      },
      {
        name: 'Delta',
        id: 'DE'
      },
      {
        name: 'Ebonyi',
        id: 'EB'
      },
      {
        name: 'Edo',
        id: 'ED'
      },
      {
        name: 'Ekiti',
        id: 'EK'
      },
      {
        name: 'Enugu',
        id: 'EN'
      },
      {
        name: 'Gombe',
        id: 'GO'
      },
      {
        name: 'Imo',
        id: 'IM'
      },
      {
        name: 'Jigawa',
        id: 'JI'
      },
      {
        name: 'Kaduna',
        id: 'KD'
      },
      {
        name: 'Kano',
        id: 'KN'
      },
      {
        name: 'Katsina',
        id: 'KT'
      },
      {
        name: 'Kebbi',
        id: 'KE'
      },
      {
        name: 'Kogi',
        id: 'KO'
      },
      {
        name: 'Kwara',
        id: 'KW'
      },
      {
        name: 'Lagos',
        id: 'LA'
      },
      {
        name: 'Nassarawa',
        id: 'NA'
      },
      {
        name: 'Niger',
        id: 'NI'
      },
      {
        name: 'Ogun',
        id: 'OG'
      },
      {
        name: 'Ondo',
        id: 'ON'
      },
      {
        name: 'Osun',
        id: 'OS'
      },
      {
        name: 'Oyo',
        id: 'OY'
      },
      {
        name: 'Plateau',
        id: 'PL'
      },
      {
        name: 'Rivers',
        id: 'RI'
      },
      {
        name: 'Sokoto',
        id: 'SO'
      },
      {
        name: 'Taraba',
        id: 'TA'
      },
      {
        name: 'Yobe',
        id: 'YO'
      },
      {
        name: 'Zamfara',
        id: 'ZA'
      }
    ]
  },
  {
    countryName: 'Niue',
    countryShortCode: 'NU',
    regions: [
      {
        name: 'Niue'
      }
    ]
  },
  {
    countryName: 'Norfolk Island',
    countryShortCode: 'NF',
    regions: [
      {
        name: 'Norfolk Island'
      }
    ]
  },
  {
    countryName: 'Northern Mariana Islands',
    countryShortCode: 'MP',
    regions: [
      {
        name: 'Northern Islands'
      },
      {
        name: 'Rota'
      },
      {
        name: 'Saipan'
      },
      {
        name: 'Tinian'
      }
    ]
  },
  {
    countryName: 'Norway',
    countryShortCode: 'NO',
    regions: [
      {
        name: 'Akershus',
        id: '02'
      },
      {
        name: 'Aust-Agder',
        id: '09'
      },
      {
        name: 'Buskerud',
        id: '06'
      },
      {
        name: 'Finnmark',
        id: '20'
      },
      {
        name: 'Hedmark',
        id: '04'
      },
      {
        name: 'Hordaland',
        id: '12'
      },
      {
        name: 'Møre og Romsdal',
        id: '15'
      },
      {
        name: 'Nordland',
        id: '18'
      },
      {
        name: 'Nord-Trøndelag',
        id: '17'
      },
      {
        name: 'Oppland',
        id: '05'
      },
      {
        name: 'Oslo',
        id: '03'
      },
      {
        name: 'Rogaland',
        id: '11'
      },
      {
        name: 'Sogn og Fjordane',
        id: '14'
      },
      {
        name: 'Sør-Trøndelag',
        id: '16'
      },
      {
        name: 'Telemark',
        id: '08'
      },
      {
        name: 'Troms',
        id: '19'
      },
      {
        name: 'Vest-Agder',
        id: '10'
      },
      {
        name: 'Vestfold',
        id: '07'
      },
      {
        name: 'Østfold',
        id: '01'
      },
      {
        name: 'Jan Mayen',
        id: '22'
      },
      {
        name: 'Svalbard',
        id: '21'
      }
    ]
  },
  {
    countryName: 'Oman',
    countryShortCode: 'OM',
    regions: [
      {
        name: 'Ad Dakhiliyah',
        id: 'DA'
      },
      {
        name: 'Al Buraymi',
        id: 'BU'
      },
      {
        name: 'Al Wusta',
        id: 'WU'
      },
      {
        name: 'Az Zahirah',
        id: 'ZA'
      },
      {
        name: 'Janub al Batinah',
        id: 'BS'
      },
      {
        name: 'Janub ash Sharqiyah',
        id: 'SS'
      },
      {
        name: 'Masqat',
        id: 'MA'
      },
      {
        name: 'Musandam',
        id: 'MU'
      },
      {
        name: 'Shamal al Batinah',
        id: 'BJ'
      },
      {
        name: 'Shamal ash Sharqiyah',
        id: 'SJ'
      },
      {
        name: 'Zufar',
        id: 'ZU'
      }
    ]
  },
  {
    countryName: 'Pakistan',
    countryShortCode: 'PK',
    regions: [
      {
        name: 'Āzād Kashmīr',
        id: 'JK'
      },
      {
        name: 'Balōchistān',
        id: 'BA'
      },
      {
        name: 'Gilgit-Baltistān',
        id: 'GB'
      },
      {
        name: 'Islāmābād',
        id: 'IS'
      },
      {
        name: 'Khaībar Pakhtūnkhwās',
        id: 'KP'
      },
      {
        name: 'Punjāb',
        id: 'PB'
      },
      {
        name: 'Sindh',
        id: 'SD'
      },
      {
        name: 'Federally Administered Tribal Areas',
        id: 'TA'
      }
    ]
  },
  {
    countryName: 'Palau',
    countryShortCode: 'PW',
    regions: [
      {
        name: 'Aimeliik',
        id: '002'
      },
      {
        name: 'Airai',
        id: '004'
      },
      {
        name: 'Angaur',
        id: '010'
      },
      {
        name: 'Hatobohei',
        id: '050'
      },
      {
        name: 'Kayangel',
        id: '100'
      },
      {
        name: 'Koror',
        id: '150'
      },
      {
        name: 'Melekeok',
        id: '212'
      },
      {
        name: 'Ngaraard',
        id: '214'
      },
      {
        name: 'Ngarchelong',
        id: '218'
      },
      {
        name: 'Ngardmau',
        id: '222'
      },
      {
        name: 'Ngatpang',
        id: '224'
      },
      {
        name: 'Ngchesar',
        id: '226'
      },
      {
        name: 'Ngeremlengui',
        id: '227'
      },
      {
        name: 'Ngiwal',
        id: '228'
      },
      {
        name: 'Peleliu',
        id: '350'
      },
      {
        name: 'Sonsoral',
        id: '370'
      }
    ]
  },
  {
    countryName: 'Palestine, State of',
    countryShortCode: 'PS',
    regions: [
      {
        name: 'Ak Khalīl',
        id: 'HBN'
      },
      {
        name: 'Al Quds',
        id: 'JEM'
      },
      {
        name: 'Arīḩā wal Aghwār',
        id: 'JRH'
      },
      {
        name: 'Bayt Laḩm',
        id: 'BTH'
      },
      {
        name: 'Dayr al Balaḩ',
        id: 'DEB'
      },
      {
        name: 'Ghazzah',
        id: 'GZA'
      },
      {
        name: 'Janīn',
        id: 'JEN'
      },
      {
        name: 'Khān Yūnis',
        id: 'KYS'
      },
      {
        name: 'Nāblus',
        id: 'NBS'
      },
      {
        name: 'Qalqīyah',
        id: 'QQA'
      },
      {
        name: 'Rafaḩ',
        id: 'RFH'
      },
      {
        name: 'Rām Allāh wal Bīrah',
        id: 'RBH'
      },
      {
        name: 'Salfīt',
        id: 'SLT'
      },
      {
        name: 'Shamāl Ghazzah',
        id: 'NGZ'
      },
      {
        name: 'Ţūbās',
        id: 'TBS'
      },
      {
        name: 'Ţūlkarm',
        id: 'TKM'
      }
    ]
  },
  {
    countryName: 'Panama',
    countryShortCode: 'PA',
    regions: [
      {
        name: 'Bocas del Toro',
        id: '1'
      },
      {
        name: 'Chiriquí',
        id: '4'
      },
      {
        name: 'Coclé',
        id: '2'
      },
      {
        name: 'Colón',
        id: '3'
      },
      {
        name: 'Darién',
        id: '5'
      },
      {
        name: 'Emberá',
        id: 'EM'
      },
      {
        name: 'Herrera',
        id: '6'
      },
      {
        name: 'Kuna Yala',
        id: 'KY'
      },
      {
        name: 'Los Santos',
        id: '7'
      },
      {
        name: 'Ngäbe-Buglé',
        id: 'NB'
      },
      {
        name: 'Panamá',
        id: '8'
      },
      {
        name: 'Panamá Oeste',
        id: '10'
      },
      {
        name: 'Veraguas',
        id: '9'
      }
    ]
  },
  {
    countryName: 'Papua New Guinea',
    countryShortCode: 'PG',
    regions: [
      {
        name: 'Bougainville',
        id: 'NSB'
      },
      {
        name: 'Central',
        id: 'CPM'
      },
      {
        name: 'Chimbu',
        id: 'CPK'
      },
      {
        name: 'East New Britain',
        id: 'EBR'
      },
      {
        name: 'East Sepik',
        id: 'ESW'
      },
      {
        name: 'Eastern Highlands',
        id: 'EHG'
      },
      {
        name: 'Enga',
        id: 'EPW'
      },
      {
        name: 'Gulf',
        id: 'GPK'
      },
      {
        name: 'Hela',
        id: 'HLA'
      },
      {
        name: 'Jiwaka',
        id: 'JWK'
      },
      {
        name: 'Madang',
        id: 'MOM'
      },
      {
        name: 'Manus',
        id: 'MRL'
      },
      {
        name: 'Milne Bay',
        id: 'MBA'
      },
      {
        name: 'Morobe',
        id: 'MPL'
      },
      {
        name: 'Port Moresby',
        id: 'NCD'
      },
      {
        name: 'New Ireland',
        id: 'NIK'
      },
      {
        name: 'Northern',
        id: 'NPP'
      },
      {
        name: 'Southern Highlands',
        id: 'SHM'
      },
      {
        name: 'West New Britain',
        id: 'WBK'
      },
      {
        name: 'West Sepik',
        id: 'SAN'
      },
      {
        name: 'Western',
        id: 'WPD'
      },
      {
        name: 'Western Highlands',
        id: 'WHM'
      }
    ]
  },
  {
    countryName: 'Paraguay',
    countryShortCode: 'PY',
    regions: [
      {
        name: 'Alto Paraguay',
        id: '16'
      },
      {
        name: 'Alto Parana',
        id: '10'
      },
      {
        name: 'Amambay',
        id: '13'
      },
      {
        name: 'Asuncion',
        id: 'ASU'
      },
      {
        name: 'Caaguazu',
        id: '5'
      },
      {
        name: 'Caazapa',
        id: '6'
      },
      {
        name: 'Canindeyu',
        id: '14'
      },
      {
        name: 'Central',
        id: '11'
      },
      {
        name: 'Concepcion',
        id: '1'
      },
      {
        name: 'Cordillera',
        id: '3'
      },
      {
        name: 'Boqueron',
        id: '17'
      },
      {
        name: 'Guaira',
        id: '4'
      },
      {
        name: 'Itapua',
        id: '7'
      },
      {
        name: 'Misiones',
        id: '8'
      },
      {
        name: 'Neembucu',
        id: '12'
      },
      {
        name: 'Paraguari',
        id: '9'
      },
      {
        name: 'Presidente Hayes',
        id: '15'
      },
      {
        name: 'San Pedro',
        id: '2'
      }
    ]
  },
  {
    countryName: 'Peru',
    countryShortCode: 'PE',
    regions: [
      {
        name: 'Amazonas',
        id: 'AMA'
      },
      {
        name: 'Ancash',
        id: 'ANC'
      },
      {
        name: 'Apurimac',
        id: 'APU'
      },
      {
        name: 'Arequipa',
        id: 'ARE'
      },
      {
        name: 'Ayacucho',
        id: 'AYA'
      },
      {
        name: 'Cajamarca',
        id: 'CAJ'
      },
      {
        name: 'Callao',
        id: 'CAL'
      },
      {
        name: 'Cusco',
        id: 'CUS'
      },
      {
        name: 'Huancavelica',
        id: 'HUV'
      },
      {
        name: 'Huanuco',
        id: 'HUC'
      },
      {
        name: 'Ica',
        id: 'ICA'
      },
      {
        name: 'Junin',
        id: 'JUN'
      },
      {
        name: 'La Libertad',
        id: 'LAL'
      },
      {
        name: 'Lambayeque',
        id: 'LAM'
      },
      {
        name: 'Lima',
        id: 'LIM'
      },
      {
        name: 'Loreto',
        id: 'LOR'
      },
      {
        name: 'Madre de Dios',
        id: 'MDD'
      },
      {
        name: 'Moquegua',
        id: 'MOQ'
      },
      {
        name: 'Municipalidad Metropolitana de Lima',
        id: 'LMA'
      },
      {
        name: 'Pasco',
        id: 'PAS'
      },
      {
        name: 'Piura',
        id: 'PIU'
      },
      {
        name: 'Puno',
        id: 'PUN'
      },
      {
        name: 'San Martin',
        id: 'SAM'
      },
      {
        name: 'Tacna',
        id: 'TAC'
      },
      {
        name: 'Tumbes',
        id: 'TUM'
      },
      {
        name: 'Ucayali',
        id: 'UCA'
      }
    ]
  },
  {
    countryName: 'Philippines',
    countryShortCode: 'PH',
    regions: [
      {
        name: 'Abra',
        id: 'ABR'
      },
      {
        name: 'Agusan del Norte',
        id: 'AGN'
      },
      {
        name: 'Agusan del Sur',
        id: 'AGS'
      },
      {
        name: 'Aklan',
        id: 'AKL'
      },
      {
        name: 'Albay',
        id: 'ALB'
      },
      {
        name: 'Antique',
        id: 'ANT'
      },
      {
        name: 'Apayao',
        id: 'APA'
      },
      {
        name: 'Aurora',
        id: 'AUR'
      },
      {
        name: 'Basilan',
        id: 'BAS'
      },
      {
        name: 'Bataan',
        id: 'BAN'
      },
      {
        name: 'Batanes',
        id: 'BTN'
      },
      {
        name: 'Batangas',
        id: 'BTG'
      },
      {
        name: 'Benguet',
        id: 'BEN'
      },
      {
        name: 'Biliran',
        id: 'BIL'
      },
      {
        name: 'Bohol',
        id: 'BOH'
      },
      {
        name: 'Bukidnon',
        id: 'BUK'
      },
      {
        name: 'Bulacan',
        id: 'BUL'
      },
      {
        name: 'Cagayan',
        id: 'CAG'
      },
      {
        name: 'Camarines Norte',
        id: 'CAN'
      },
      {
        name: 'Camarines Sur',
        id: 'CAS'
      },
      {
        name: 'Camiguin',
        id: 'CAM'
      },
      {
        name: 'Capiz',
        id: 'CAP'
      },
      {
        name: 'Catanduanes',
        id: 'CAT'
      },
      {
        name: 'Cavite',
        id: 'CAV'
      },
      {
        name: 'Cebu',
        id: 'CEB'
      },
      {
        name: 'Cotabato',
        id: 'NCO'
      },
      {
        name: 'Davao del Norte',
        id: 'DAV'
      },
      {
        name: 'Davao del Sur',
        id: 'DAS'
      },
      {
        name: 'Davao Occidental',
        id: 'DVO'
      },
      {
        name: 'Davao Oriental',
        id: 'DAO'
      },
      {
        name: 'Davao de Oro',
        id: 'COM'
      },
      {
        name: 'Dinagat Islands',
        id: 'DIN'
      },
      {
        name: 'Eastern Samar',
        id: 'EAS'
      },
      {
        name: 'Guimaras',
        id: 'GUI'
      },
      {
        name: 'Ifugao',
        id: 'IFU'
      },
      {
        name: 'Ilocos Norte',
        id: 'ILN'
      },
      {
        name: 'Ilocos Sur',
        id: 'ILS'
      },
      {
        name: 'Iloilo',
        id: 'ILI'
      },
      {
        name: 'Isabela',
        id: 'ISA'
      },
      {
        name: 'Kalinga',
        id: 'KAL'
      },
      {
        name: 'La Union',
        id: 'LUN'
      },
      {
        name: 'Laguna',
        id: 'LAG'
      },
      {
        name: 'Lanao del Norte',
        id: 'LAN'
      },
      {
        name: 'Lanao del Sur',
        id: 'LAS'
      },
      {
        name: 'Leyte',
        id: 'LEY'
      },
      {
        name: 'Maguindanao',
        id: 'MAG'
      },
      {
        name: 'Marinduque',
        id: 'MAD'
      },
      {
        name: 'Masbate',
        id: 'MAS'
      },
      {
        name: 'Metro Manila',
        id: '00'
      },
      {
        name: 'Mindoro Occidental',
        id: 'MDC'
      },
      {
        name: 'Mindoro Oriental',
        id: 'MDR'
      },
      {
        name: 'Misamis Occidental',
        id: 'MSC'
      },
      {
        name: 'Misamis Oriental',
        id: 'MSR'
      },
      {
        name: 'Mountain Province',
        id: 'MOU'
      },
      {
        name: 'Negros Occidental',
        id: 'NEC'
      },
      {
        name: 'Negros Oriental',
        id: 'NER'
      },
      {
        name: 'Northern Samar',
        id: 'NSA'
      },
      {
        name: 'Nueva Ecija',
        id: 'NUE'
      },
      {
        name: 'Nueva Vizcaya',
        id: 'NUV'
      },
      {
        name: 'Palawan',
        id: 'PLW'
      },
      {
        name: 'Pampanga',
        id: 'PAM'
      },
      {
        name: 'Pangasinan',
        id: 'PAN'
      },
      {
        name: 'Quezon',
        id: 'QUE'
      },
      {
        name: 'Quirino',
        id: 'QUI'
      },
      {
        name: 'Rizal',
        id: 'RIZ'
      },
      {
        name: 'Romblon',
        id: 'ROM'
      },
      {
        name: 'Samar',
        id: 'WSA'
      },
      {
        name: 'Sarangani',
        id: 'SAR'
      },
      {
        name: 'Siquijor',
        id: 'SIG'
      },
      {
        name: 'Sorsogon',
        id: 'SOR'
      },
      {
        name: 'South Cotabato',
        id: 'SCO'
      },
      {
        name: 'Southern Leyte',
        id: 'SLE'
      },
      {
        name: 'Sultan Kudarat',
        id: 'AUK'
      },
      {
        name: 'Sulu',
        id: 'SLU'
      },
      {
        name: 'Surigao del Norte',
        id: 'SUN'
      },
      {
        name: 'Surigao del Sur',
        id: 'SUR'
      },
      {
        name: 'Tarlac',
        id: 'TAR'
      },
      {
        name: 'Tawi-Tawi',
        id: 'TAW'
      },
      {
        name: 'Zambales',
        id: 'ZMB'
      },
      {
        name: 'Zamboanga del Norte',
        id: 'ZAN'
      },
      {
        name: 'Zamboanga del Sur',
        id: 'ZAS'
      },
      {
        name: 'Zamboanga Sibugay',
        id: 'ZSI'
      }
    ]
  },
  {
    countryName: 'Pitcairn',
    countryShortCode: 'PN',
    regions: [
      {
        name: 'Pitcairn Islands'
      }
    ]
  },
  {
    countryName: 'Poland',
    countryShortCode: 'PL',
    regions: [
      {
        name: 'Dolnośląskie',
        id: '02'
      },
      {
        name: 'Kujawsko-pomorskie',
        id: '04'
      },
      {
        name: 'Łódzkie',
        id: '10'
      },
      {
        name: 'Lubelskie',
        id: '06'
      },
      {
        name: 'Lubuskie',
        id: '08'
      },
      {
        name: 'Małopolskie',
        id: '12'
      },
      {
        name: 'Mazowieckie',
        id: '14'
      },
      {
        name: 'Opolskie',
        id: '16'
      },
      {
        name: 'Podkarpackie',
        id: '18'
      },
      {
        name: 'Podlaskie',
        id: '20'
      },
      {
        name: 'Pomorskie',
        id: '22'
      },
      {
        name: 'Śląskie',
        id: '24'
      },
      {
        name: 'Świętokrzyskie',
        id: '26'
      },
      {
        name: 'Warmińsko-mazurskie',
        id: '28'
      },
      {
        name: 'Wielkopolskie',
        id: '30'
      },
      {
        name: 'Zachodniopomorskie',
        id: '32'
      }
    ]
  },
  {
    countryName: 'Portugal',
    countryShortCode: 'PT',
    regions: [
      {
        name: 'Açores',
        id: '20'
      },
      {
        name: 'Aveiro',
        id: '01'
      },
      {
        name: 'Beja',
        id: '02'
      },
      {
        name: 'Braga',
        id: '03'
      },
      {
        name: 'Bragança',
        id: '04'
      },
      {
        name: 'Castelo Branco',
        id: '05'
      },
      {
        name: 'Coimbra',
        id: '06'
      },
      {
        name: 'Évora',
        id: '07'
      },
      {
        name: 'Faro',
        id: '08'
      },
      {
        name: 'Guarda',
        id: '09'
      },
      {
        name: 'Leiria',
        id: '10'
      },
      {
        name: 'Lisboa',
        id: '11'
      },
      {
        name: 'Madeira',
        id: '30'
      },
      {
        name: 'Portalegre',
        id: '12'
      },
      {
        name: 'Porto',
        id: '13'
      },
      {
        name: 'Santarém',
        id: '14'
      },
      {
        name: 'Setúbal',
        id: '15'
      },
      {
        name: 'Viana do Castelo',
        id: '16'
      },
      {
        name: 'Vila Real',
        id: '17'
      },
      {
        name: 'Viseu',
        id: '18'
      }
    ]
  },
  {
    countryName: 'Puerto Rico',
    countryShortCode: 'PR',
    regions: [
      {
        name: 'Adjuntas'
      },
      {
        name: 'Aguada'
      },
      {
        name: 'Aguadilla'
      },
      {
        name: 'Aguas Buenas'
      },
      {
        name: 'Aibonito'
      },
      {
        name: 'Anasco'
      },
      {
        name: 'Arecibo'
      },
      {
        name: 'Arroyo'
      },
      {
        name: 'Barceloneta'
      },
      {
        name: 'Barranquitas'
      },
      {
        name: 'Bayamon'
      },
      {
        name: 'Cabo Rojo'
      },
      {
        name: 'Caguas'
      },
      {
        name: 'Camuy'
      },
      {
        name: 'Canovanas'
      },
      {
        name: 'Carolina'
      },
      {
        name: 'Cat'
      },
      {
        name: 'Ceiba'
      },
      {
        name: 'Ciales'
      },
      {
        name: 'Cidra'
      },
      {
        name: 'Coamo'
      },
      {
        name: 'Comerio'
      },
      {
        name: 'Corozal'
      },
      {
        name: 'Culebra'
      },
      {
        name: 'Dorado'
      },
      {
        name: 'Fajardo'
      },
      {
        name: 'Florida'
      },
      {
        name: 'Guanica'
      },
      {
        name: 'Guayama'
      },
      {
        name: 'Guayanilla'
      },
      {
        name: 'Guaynabo'
      },
      {
        name: 'Gurabo'
      },
      {
        name: 'Hatillo'
      },
      {
        name: 'Hormigueros'
      },
      {
        name: 'Humacao'
      },
      {
        name: 'Isabe'
      },
      {
        name: 'Juana Diaz'
      },
      {
        name: 'Juncos'
      },
      {
        name: 'Lajas'
      },
      {
        name: 'Lares'
      },
      {
        name: 'Las Marias'
      },
      {
        name: 'Las oiza'
      },
      {
        name: 'Luquillo'
      },
      {
        name: 'Manati'
      },
      {
        name: 'Maricao'
      },
      {
        name: 'Maunabo'
      },
      {
        name: 'Mayaguez'
      },
      {
        name: 'Moca'
      },
      {
        name: 'Morovis'
      },
      {
        name: 'Naguabo'
      },
      {
        name: 'Naranjito'
      },
      {
        name: 'Orocovis'
      },
      {
        name: 'Patillas'
      },
      {
        name: 'Penuelas'
      },
      {
        name: 'Ponce'
      },
      {
        name: 'Quebradillas'
      },
      {
        name: 'Rincon'
      },
      {
        name: 'Rio Grande'
      },
      {
        name: 'Sabana linas'
      },
      {
        name: 'San German'
      },
      {
        name: 'San Juan'
      },
      {
        name: 'San Lorenzo'
      },
      {
        name: 'San Sebastian'
      },
      {
        name: 'Santa Isabel'
      },
      {
        name: 'Toa Alta'
      },
      {
        name: 'Toa Baja'
      },
      {
        name: 'Trujillo Alto'
      },
      {
        name: 'Utuado'
      },
      {
        name: 'Vega Alta'
      },
      {
        name: 'Vega ues'
      },
      {
        name: 'Villalba'
      },
      {
        name: 'Yabucoa'
      },
      {
        name: 'Yauco'
      }
    ]
  },
  {
    countryName: 'Qatar',
    countryShortCode: 'QA',
    regions: [
      {
        name: 'Ad Dawḩah',
        id: 'DA'
      },
      {
        name: 'Al Khawr wa adh Dhakhīrah',
        id: 'KH'
      },
      {
        name: 'Al Wakrah',
        id: 'WA'
      },
      {
        name: 'Ar Rayyān',
        id: 'RA'
      },
      {
        name: 'Ash Shamāl',
        id: 'MS'
      },
      {
        name: 'Az̧ Za̧`āyin',
        id: 'ZA'
      },
      {
        name: 'Umm Şalāl',
        id: 'US'
      }
    ]
  },
  {
    countryName: 'Réunion',
    countryShortCode: 'RE',
    regions: [
      {
        name: 'Réunion'
      }
    ]
  },
  {
    countryName: 'Romania',
    countryShortCode: 'RO',
    regions: [
      {
        name: 'Alba',
        id: 'AB'
      },
      {
        name: 'Arad',
        id: 'AR'
      },
      {
        name: 'Arges',
        id: 'AG'
      },
      {
        name: 'Bacau',
        id: 'BC'
      },
      {
        name: 'Bihor',
        id: 'BH'
      },
      {
        name: 'Bistrita-Nasaud',
        id: 'BN'
      },
      {
        name: 'Botosani',
        id: 'BT'
      },
      {
        name: 'Braila',
        id: 'BR'
      },
      {
        name: 'Brasov',
        id: 'BV'
      },
      {
        name: 'Iifov',
        id: 'B'
      },
      {
        name: 'Buzau',
        id: 'BZ'
      },
      {
        name: 'Calarasi',
        id: 'CL'
      },
      {
        name: 'Caras-Severin',
        id: 'CS'
      },
      {
        name: 'Cluj',
        id: 'CJ'
      },
      {
        name: 'Constanta',
        id: 'CT'
      },
      {
        name: 'Covasna',
        id: 'CV'
      },
      {
        name: 'Dambovita',
        id: 'DB'
      },
      {
        name: 'Dolj',
        id: 'DJ'
      },
      {
        name: 'Galati',
        id: 'GL'
      },
      {
        name: 'Giurgiu',
        id: 'GR'
      },
      {
        name: 'Gorj',
        id: 'GJ'
      },
      {
        name: 'Harghita',
        id: 'HR'
      },
      {
        name: 'Hunedoara',
        id: 'HD'
      },
      {
        name: 'Ialomita',
        id: 'IL'
      },
      {
        name: 'Iasi',
        id: 'IS'
      },
      {
        name: 'Maramures',
        id: 'MM'
      },
      {
        name: 'Mehedinti',
        id: 'MH'
      },
      {
        name: 'Mures',
        id: 'MS'
      },
      {
        name: 'Neamt',
        id: 'NT'
      },
      {
        name: 'Olt',
        id: 'OT'
      },
      {
        name: 'Prahova',
        id: 'PH'
      },
      {
        name: 'Salaj',
        id: 'SJ'
      },
      {
        name: 'Satu Mare',
        id: 'SM'
      },
      {
        name: 'Sibiu',
        id: 'SB'
      },
      {
        name: 'Suceava',
        id: 'SV'
      },
      {
        name: 'Teleorman',
        id: 'TR'
      },
      {
        name: 'Timis',
        id: 'TM'
      },
      {
        name: 'Tulcea',
        id: 'TL'
      },
      {
        name: 'Valcea',
        id: 'VL'
      },
      {
        name: 'Vaslui',
        id: 'VS'
      },
      {
        name: 'Vrancea',
        id: 'VN'
      }
    ]
  },
  {
    countryName: 'Russian Federation',
    countryShortCode: 'RU',
    regions: [
      {
        name: 'Republic of Adygea',
        id: 'AD'
      },
      {
        name: 'Republic of Altai (Gorno-Altaysk)',
        id: 'AL'
      },
      {
        name: 'Altai Krai',
        id: 'ALT'
      },
      {
        name: 'Amur Oblast',
        id: 'AMU'
      },
      {
        name: 'Arkhangelsk Oblast',
        id: 'ARK'
      },
      {
        name: 'Astrakhan Oblast',
        id: 'AST'
      },
      {
        name: 'Republic of Bashkortostan',
        id: 'BA'
      },
      {
        name: 'Belgorod Oblast',
        id: 'BEL'
      },
      {
        name: 'Bryansk Oblast',
        id: 'BRY'
      },
      {
        name: 'Republic of Buryatia',
        id: 'BU'
      },
      {
        name: 'Chechen Republic',
        id: 'CE'
      },
      {
        name: 'Chelyabinsk Oblast',
        id: 'CHE'
      },
      {
        name: 'Chukotka Autonomous Okrug',
        id: 'CHU'
      },
      {
        name: 'Chuvash Republic',
        id: 'CU'
      },
      {
        name: 'Republic of Dagestan',
        id: 'DA'
      },
      {
        name: 'Republic of Ingushetia',
        id: 'IN'
      },
      {
        name: 'Irkutsk Oblast',
        id: 'IRK'
      },
      {
        name: 'Ivanovo Oblast',
        id: 'IVA'
      },
      {
        name: 'Jewish Autonomous Oblast',
        id: 'JEW'
      },
      {
        name: 'Kabardino-Balkar Republic',
        id: 'KB'
      },
      {
        name: 'Kaliningrad Oblast',
        id: 'KLN'
      },
      {
        name: 'Republic of Kalmykia',
        id: 'KL'
      },
      {
        name: 'Kaluga Oblast',
        id: 'KLU'
      },
      {
        name: 'Kamchatka Krai',
        id: 'KAM'
      },
      {
        name: 'Karachay-Cherkess Republic',
        id: 'KC'
      },
      {
        name: 'Republic of Karelia',
        id: 'KR'
      },
      {
        name: 'Khabarovsk Krai',
        id: 'KHA'
      },
      {
        name: 'Republic of Khakassia',
        id: 'KK'
      },
      {
        name: 'Khanty-Mansi Autonomous Okrug - Yugra',
        id: 'KHM'
      },
      {
        name: 'Kemerovo Oblast',
        id: 'KEM'
      },
      {
        name: 'Kirov Oblast',
        id: 'KIR'
      },
      {
        name: 'Komi Republic',
        id: 'KO'
      },
      {
        name: 'Kostroma Oblast',
        id: 'KOS'
      },
      {
        name: 'Krasnodar Krai',
        id: 'KDA'
      },
      {
        name: 'Krasnoyarsk Krai',
        id: 'KYA'
      },
      {
        name: 'Kurgan Oblast',
        id: 'KGN'
      },
      {
        name: 'Kursk Oblast',
        id: 'KRS'
      },
      {
        name: 'Leningrad Oblast',
        id: 'LEN'
      },
      {
        name: 'Lipetsk Oblast',
        id: 'LIP'
      },
      {
        name: 'Magadan Oblast',
        id: 'MAG'
      },
      {
        name: 'Mari El Republic',
        id: 'ME'
      },
      {
        name: 'Republic of Mordovia',
        id: 'MO'
      },
      {
        name: 'Moscow Oblast',
        id: 'MOS'
      },
      {
        name: 'Moscow',
        id: 'MOW'
      },
      {
        name: 'Murmansk Oblast',
        id: 'MU'
      },
      {
        name: 'Nenets Autonomous Okrug',
        id: 'NEN'
      },
      {
        name: 'Nizhny Novgorod Oblast',
        id: 'NIZ'
      },
      {
        name: 'Novgorod Oblast',
        id: 'NGR'
      },
      {
        name: 'Novosibirsk Oblast',
        id: 'NVS'
      },
      {
        name: 'Omsk Oblast',
        id: 'OMS'
      },
      {
        name: 'Orenburg Oblast',
        id: 'ORE'
      },
      {
        name: 'Oryol Oblast',
        id: 'ORL'
      },
      {
        name: 'Penza Oblast',
        id: 'PNZ'
      },
      {
        name: 'Perm Krai',
        id: 'PER'
      },
      {
        name: 'Primorsky Krai',
        id: 'PRI'
      },
      {
        name: 'Pskov Oblast',
        id: 'PSK'
      },
      {
        name: 'Rostov Oblast',
        id: 'ROS'
      },
      {
        name: 'Ryazan Oblast',
        id: 'RYA'
      },
      {
        name: 'Saint Petersburg',
        id: 'SPE'
      },
      {
        name: 'Sakha (Yakutia) Republic',
        id: 'SA'
      },
      {
        name: 'Sakhalin Oblast',
        id: 'SAK'
      },
      {
        name: 'Samara Oblast',
        id: 'SAM'
      },
      {
        name: 'Saratov Oblast',
        id: 'SAR'
      },
      {
        name: 'Republic of North Ossetia-Alania',
        id: 'NOA'
      },
      {
        name: 'Smolensk Oblast',
        id: 'SMO'
      },
      {
        name: 'Stavropol Krai',
        id: 'STA'
      },
      {
        name: 'Sverdlovsk Oblast',
        id: 'SVE'
      },
      {
        name: 'Tambov Oblast',
        id: 'TAM'
      },
      {
        name: 'Republic of Tatarstan',
        id: 'TA'
      },
      {
        name: 'Tomsk Oblast',
        id: 'TOM'
      },
      {
        name: 'Tuva Republic',
        id: 'TU'
      },
      {
        name: 'Tula Oblast',
        id: 'TUL'
      },
      {
        name: 'Tver Oblast',
        id: 'TVE'
      },
      {
        name: 'Tyumen Oblast',
        id: 'TYU'
      },
      {
        name: 'Udmurt Republic',
        id: 'UD'
      },
      {
        name: 'Ulyanovsk Oblast',
        id: 'ULY'
      },
      {
        name: 'Vladimir Oblast',
        id: 'VLA'
      },
      {
        name: 'Volgograd Oblast',
        id: 'VGG'
      },
      {
        name: 'Vologda Oblast',
        id: 'VLG'
      },
      {
        name: 'Voronezh Oblast',
        id: 'VOR'
      },
      {
        name: 'Yamalo-Nenets Autonomous Okrug',
        id: 'YAN'
      },
      {
        name: 'Yaroslavl Oblast',
        id: 'YAR'
      },
      {
        name: 'Zabaykalsky Krai',
        id: 'ZAB'
      }
    ]
  },
  {
    countryName: 'Rwanda',
    countryShortCode: 'RW',
    regions: [
      {
        name: 'Kigali',
        id: '01'
      },
      {
        name: 'Eastern',
        id: '02'
      },
      {
        name: 'Northern',
        id: '03'
      },
      {
        name: 'Western',
        id: '04'
      },
      {
        name: 'Southern',
        id: '05'
      }
    ]
  },
  {
    countryName: 'Saint Barthélemy',
    countryShortCode: 'BL',
    regions: [
      {
        name: 'Au Vent',
        id: '02'
      },
      {
        name: 'Sous le Vent',
        id: '01'
      }
    ]
  },
  {
    countryName: 'Saint Helena, Ascension and Tristan da Cunha',
    countryShortCode: 'SH',
    regions: [
      {
        name: 'Ascension',
        id: 'AC'
      },
      {
        name: 'Saint Helena',
        id: 'HL'
      },
      {
        name: 'Tristan da Cunha',
        id: 'TA'
      }
    ]
  },
  {
    countryName: 'Saint Kitts and Nevis',
    countryShortCode: 'KN',
    regions: [
      {
        name: 'Saint Kitts',
        id: 'K'
      },
      {
        name: 'Nevis',
        id: 'N'
      }
    ]
  },
  {
    countryName: 'Saint Lucia',
    countryShortCode: 'LC',
    regions: [
      {
        name: 'Anse-la-Raye',
        id: '01'
      },
      {
        name: 'Canaries',
        id: '12'
      },
      {
        name: 'Castries',
        id: '02'
      },
      {
        name: 'Choiseul',
        id: '03'
      },
      {
        name: 'Dennery',
        id: '05'
      },
      {
        name: 'Gros Islet',
        id: '06'
      },
      {
        name: 'Laborie',
        id: '07'
      },
      {
        name: 'Micoud',
        id: '08'
      },
      {
        name: 'Soufriere',
        id: '10'
      },
      {
        name: 'Vieux Fort',
        id: '11'
      }
    ]
  },
  {
    countryName: 'Saint Martin',
    countryShortCode: 'MF',
    regions: [
      {
        name: 'Saint Martin'
      }
    ]
  },
  {
    countryName: 'Saint Pierre and Miquelon',
    countryShortCode: 'PM',
    regions: [
      {
        name: 'Miquelon'
      },
      {
        name: 'Saint Pierre'
      }
    ]
  },
  {
    countryName: 'Saint Vincent and the Grenadines',
    countryShortCode: 'VC',
    regions: [
      {
        name: 'Charlotte',
        id: '01'
      },
      {
        name: 'Grenadines',
        id: '06'
      },
      {
        name: 'Saint Andrew',
        id: '02'
      },
      {
        name: 'Saint David',
        id: '03'
      },
      {
        name: 'Saint George',
        id: '04'
      },
      {
        name: 'Saint Patrick',
        id: '05'
      }
    ]
  },
  {
    countryName: 'Samoa',
    countryShortCode: 'WS',
    regions: [
      {
        name: 'A\'ana',
        id: 'AA'
      },
      {
        name: 'Aiga-i-le-Tai',
        id: 'AL'
      },
      {
        name: 'Atua',
        id: 'AT'
      },
      {
        name: 'Fa\'asaleleaga',
        id: 'FA'
      },
      {
        name: 'Gaga\'emauga',
        id: 'GE'
      },
      {
        name: 'Gagaifomauga',
        id: 'GI'
      },
      {
        name: 'Palauli',
        id: 'PA'
      },
      {
        name: 'Satupa\'itea',
        id: 'SA'
      },
      {
        name: 'Tuamasaga',
        id: 'TU'
      },
      {
        name: 'Va\'a-o-Fonoti',
        id: 'VF'
      },
      {
        name: 'Vaisigano',
        id: 'VS'
      }
    ]
  },
  {
    countryName: 'San Marino',
    countryShortCode: 'SM',
    regions: [
      {
        name: 'Acquaviva',
        id: '01'
      },
      {
        name: 'Borgo Maggiore',
        id: '06'
      },
      {
        name: 'Chiesanuova',
        id: '02'
      },
      {
        name: 'Domagnano',
        id: '03'
      },
      {
        name: 'Faetano',
        id: '04'
      },
      {
        name: 'Fiorentino',
        id: '05'
      },
      {
        name: 'Montegiardino',
        id: '08'
      },
      {
        name: 'San Marino',
        id: '07'
      },
      {
        name: 'Serravalle',
        id: '09'
      }
    ]
  },
  {
    countryName: 'Sao Tome and Principe',
    countryShortCode: 'ST',
    regions: [
      {
        name: 'Principe',
        id: 'P'
      },
      {
        name: 'Sao Tome',
        id: 'S'
      }
    ]
  },
  {
    countryName: 'Saudi Arabia',
    countryShortCode: 'SA',
    regions: [
      {
        name: '\'Asir',
        id: '14'
      },
      {
        name: 'Al Bahah',
        id: '11'
      },
      {
        name: 'Al Hudud ash Shamaliyah',
        id: '08'
      },
      {
        name: 'Al Jawf',
        id: '12'
      },
      {
        name: 'Al Madinah al Munawwarah',
        id: '03'
      },
      {
        name: 'Al Qasim',
        id: '05'
      },
      {
        name: 'Ar Riyad',
        id: '01'
      },
      {
        name: 'Ash Sharqiyah',
        id: '04'
      },
      {
        name: 'Ha\'il',
        id: '06'
      },
      {
        name: 'Jazan',
        id: '09'
      },
      {
        name: 'Makkah al Mukarramah',
        id: '02'
      },
      {
        name: 'Najran',
        id: '10'
      },
      {
        name: 'Tabuk',
        id: '07'
      }
    ]
  },
  {
    countryName: 'Senegal',
    countryShortCode: 'SN',
    regions: [
      {
        name: 'Dakar',
        id: 'DK'
      },
      {
        name: 'Diourbel',
        id: 'DB'
      },
      {
        name: 'Fatick',
        id: 'FK'
      },
      {
        name: 'Kaffrine',
        id: 'KA'
      },
      {
        name: 'Kaolack',
        id: 'KL'
      },
      {
        name: 'Kedougou',
        id: 'KE'
      },
      {
        name: 'Kolda',
        id: 'KD'
      },
      {
        name: 'Louga',
        id: 'LG'
      },
      {
        name: 'Matam',
        id: 'MT'
      },
      {
        name: 'Saint-Louis',
        id: 'SL'
      },
      {
        name: 'Sedhiou',
        id: 'SE'
      },
      {
        name: 'Tambacounda',
        id: 'TC'
      },
      {
        name: 'Thies',
        id: 'TH'
      },
      {
        name: 'Ziguinchor',
        id: 'ZG'
      }
    ]
  },
  {
    countryName: 'Serbia',
    countryShortCode: 'RS',
    regions: [
      {
        name: 'Beograd (Belgrade)',
        id: '00'
      },
      {
        name: 'Borski',
        id: '14'
      },
      {
        name: 'Braničevski',
        id: '11'
      },
      {
        name: 'Jablanički',
        id: '23'
      },
      {
        name: 'Južnobački',
        id: '06'
      },
      {
        name: 'Južnobanatski',
        id: '04'
      },
      {
        name: 'Kolubarski',
        id: '09'
      },
      {
        name: 'Kosovski',
        id: '25'
      },
      {
        name: 'Kosovsko-Mitrovački',
        id: '28'
      },
      {
        name: 'Kosovsko-Pomoravski',
        id: '29'
      },
      {
        name: 'Mačvanski',
        id: '08'
      },
      {
        name: 'Moravički',
        id: '17'
      },
      {
        name: 'Nišavski',
        id: '20'
      },
      {
        name: 'Pčinjski',
        id: '24'
      },
      {
        name: 'Pećki',
        id: '26'
      },
      {
        name: 'Pirotski',
        id: '22'
      },
      {
        name: 'Podunavski',
        id: '10'
      },
      {
        name: 'Pomoravski',
        id: '13'
      },
      {
        name: 'Prizrenski',
        id: '27'
      },
      {
        name: 'Rasinski',
        id: '19'
      },
      {
        name: 'Raški',
        id: '18'
      },
      {
        name: 'Severnobački',
        id: '01'
      },
      {
        name: 'Severnobanatski',
        id: '03'
      },
      {
        name: 'Srednjebanatski',
        id: '02'
      },
      {
        name: 'Sremski',
        id: '07'
      },
      {
        name: 'Šumadijski',
        id: '12'
      },
      {
        name: 'Toplički',
        id: '21'
      },
      {
        name: 'Zaječarski',
        id: '15'
      },
      {
        name: 'Zapadnobački',
        id: '05'
      },
      {
        name: 'Zlatiborski',
        id: '16'
      }
    ]
  },
  {
    countryName: 'Seychelles',
    countryShortCode: 'SC',
    regions: [
      {
        name: 'Anse aux Pins',
        id: '01'
      },
      {
        name: 'Anse Boileau',
        id: '02'
      },
      {
        name: 'Anse Etoile',
        id: '03'
      },
      {
        name: 'Anse Royale',
        id: '05'
      },
      {
        name: 'Anu Cap',
        id: '04'
      },
      {
        name: 'Baie Lazare',
        id: '06'
      },
      {
        name: 'Baie Sainte Anne',
        id: '07'
      },
      {
        name: 'Beau Vallon',
        id: '08'
      },
      {
        name: 'Bel Air',
        id: '09'
      },
      {
        name: 'Bel Ombre',
        id: '10'
      },
      {
        name: 'Cascade',
        id: '11'
      },
      {
        name: 'Glacis',
        id: '12'
      },
      {
        name: 'Grand\'Anse Mahe',
        id: '13'
      },
      {
        name: 'Grand\'Anse Praslin',
        id: '14'
      },
      {
        name: 'La Digue',
        id: '15'
      },
      {
        name: 'La Riviere Anglaise',
        id: '16'
      },
      {
        name: 'Les Mamelles',
        id: '24'
      },
      {
        name: 'Mont Buxton',
        id: '17'
      },
      {
        name: 'Mont Fleuri',
        id: '18'
      },
      {
        name: 'Plaisance',
        id: '19'
      },
      {
        name: 'Pointe La Rue',
        id: '20'
      },
      {
        name: 'Port Glaud',
        id: '21'
      },
      {
        name: 'Roche Caiman',
        id: '25'
      },
      {
        name: 'Saint Louis',
        id: '22'
      },
      {
        name: 'Takamaka',
        id: '23'
      }
    ]
  },
  {
    countryName: 'Sierra Leone',
    countryShortCode: 'SL',
    regions: [
      {
        name: 'Eastern',
        id: 'E'
      },
      {
        name: 'Northern',
        id: 'N'
      },
      {
        name: 'Southern',
        id: 'S'
      },
      {
        name: 'Western',
        id: 'W'
      }
    ]
  },
  {
    countryName: 'Singapore',
    countryShortCode: 'SG',
    regions: [
      {
        name: 'Central Singapore',
        id: '01'
      },
      {
        name: 'North East',
        id: '02'
      },
      {
        name: 'North West',
        id: '03'
      },
      {
        name: 'South East',
        id: '04'
      },
      {
        name: 'South West',
        id: '05'
      }
    ]
  },
  {
    countryName: 'Sint Maarten (Dutch part)',
    countryShortCode: 'SX',
    regions: [
      {
        name: 'Sint Maarten'
      }
    ]
  },
  {
    countryName: 'Slovakia',
    countryShortCode: 'SK',
    regions: [
      {
        name: 'Banskobystricky',
        id: 'BC'
      },
      {
        name: 'Bratislavsky',
        id: 'BL'
      },
      {
        name: 'Kosicky',
        id: 'KI'
      },
      {
        name: 'Nitriansky',
        id: 'NI'
      },
      {
        name: 'Presovsky',
        id: 'PV'
      },
      {
        name: 'Trenciansky',
        id: 'TC'
      },
      {
        name: 'Trnavsky',
        id: 'TA'
      },
      {
        name: 'Zilinsky',
        id: 'ZI'
      }
    ]
  },
  {
    countryName: 'Slovenia',
    countryShortCode: 'SI',
    regions: [
      {
        name: 'Ajdovscina',
        id: '001'
      },
      {
        name: 'Apace',
        id: '195'
      },
      {
        name: 'Beltinci',
        id: '002'
      },
      {
        name: 'Benedikt',
        id: '148'
      },
      {
        name: 'Bistrica ob Sotli',
        id: '149'
      },
      {
        name: 'Bled',
        id: '003'
      },
      {
        name: 'Bloke',
        id: '150'
      },
      {
        name: 'Bohinj',
        id: '004'
      },
      {
        name: 'Borovnica',
        id: '005'
      },
      {
        name: 'Bovec',
        id: '006'
      },
      {
        name: 'Braslovce',
        id: '151'
      },
      {
        name: 'Brda',
        id: '007'
      },
      {
        name: 'Brezice',
        id: '009'
      },
      {
        name: 'Brezovica',
        id: '008'
      },
      {
        name: 'Cankova',
        id: '152'
      },
      {
        name: 'Celje',
        id: '011'
      },
      {
        name: 'Cerklje na Gorenjskem',
        id: '012'
      },
      {
        name: 'Cerknica',
        id: '013'
      },
      {
        name: 'Cerkno',
        id: '014'
      },
      {
        name: 'Cerkvenjak',
        id: '153'
      },
      {
        name: 'Cirkulane',
        id: '196'
      },
      {
        name: 'Crensovci',
        id: '015'
      },
      {
        name: 'Crna na Koroskem',
        id: '016'
      },
      {
        name: 'Crnomelj',
        id: '017'
      },
      {
        name: 'Destrnik',
        id: '018'
      },
      {
        name: 'Divaca',
        id: '019'
      },
      {
        name: 'Dobje',
        id: '154'
      },
      {
        name: 'Dobrepolje',
        id: '020'
      },
      {
        name: 'Dobrna',
        id: '155'
      },
      {
        name: 'Dobrova-Polhov Gradec',
        id: '021'
      },
      {
        name: 'Dobrovnik',
        id: '156'
      },
      {
        name: 'Dol pri Ljubljani',
        id: '022'
      },
      {
        name: 'Dolenjske Toplice',
        id: '157'
      },
      {
        name: 'Domzale',
        id: '023'
      },
      {
        name: 'Dornava',
        id: '024'
      },
      {
        name: 'Dravograd',
        id: '025'
      },
      {
        name: 'Duplek',
        id: '026'
      },
      {
        name: 'Gorenja Vas-Poljane',
        id: '027'
      },
      {
        name: 'Gorisnica',
        id: '028'
      },
      {
        name: 'Gorje',
        id: '207'
      },
      {
        name: 'Gornja Radgona',
        id: '029'
      },
      {
        name: 'Gornji Grad',
        id: '030'
      },
      {
        name: 'Gornji Petrovci',
        id: '031'
      },
      {
        name: 'Grad',
        id: '158'
      },
      {
        name: 'Grosuplje',
        id: '032'
      },
      {
        name: 'Hajdina',
        id: '159'
      },
      {
        name: 'Hoce-Slivnica',
        id: '160'
      },
      {
        name: 'Hodos',
        id: '161'
      },
      {
        name: 'Horjul',
        id: '162'
      },
      {
        name: 'Hrastnik',
        id: '034'
      },
      {
        name: 'Hrpelje-Kozina',
        id: '035'
      },
      {
        name: 'Idrija',
        id: '036'
      },
      {
        name: 'Ig',
        id: '037'
      },
      {
        name: 'Ilirska Bistrica',
        id: '038'
      },
      {
        name: 'Ivancna Gorica',
        id: '039'
      },
      {
        name: 'Izola',
        id: '040'
      },
      {
        name: 'Jesenice',
        id: '041'
      },
      {
        name: 'Jursinci',
        id: '042'
      },
      {
        name: 'Kamnik',
        id: '043'
      },
      {
        name: 'Kanal',
        id: '044'
      },
      {
        name: 'Kidricevo',
        id: '045'
      },
      {
        name: 'Kobarid',
        id: '046'
      },
      {
        name: 'Kobilje',
        id: '047'
      },
      {
        name: 'Kocevje',
        id: '048'
      },
      {
        name: 'Komen',
        id: '049'
      },
      {
        name: 'Komenda',
        id: '164'
      },
      {
        name: 'Koper',
        id: '050'
      },
      {
        name: 'Kodanjevica na Krki',
        id: '197'
      },
      {
        name: 'Kostel',
        id: '165'
      },
      {
        name: 'Kozje',
        id: '051'
      },
      {
        name: 'Kranj',
        id: '052'
      },
      {
        name: 'Kranjska Gora',
        id: '053'
      },
      {
        name: 'Krizevci',
        id: '166'
      },
      {
        name: 'Krsko',
        id: '054'
      },
      {
        name: 'Kungota',
        id: '055'
      },
      {
        name: 'Kuzma',
        id: '056'
      },
      {
        name: 'Lasko',
        id: '057'
      },
      {
        name: 'Lenart',
        id: '058'
      },
      {
        name: 'Lendava',
        id: '059'
      },
      {
        name: 'Litija',
        id: '060'
      },
      {
        name: 'Ljubljana',
        id: '061'
      },
      {
        name: 'Ljubno',
        id: '062'
      },
      {
        name: 'Ljutomer',
        id: '063'
      },
      {
        name: 'Log-Dragomer',
        id: '208'
      },
      {
        name: 'Logatec',
        id: '064'
      },
      {
        name: 'Loska Dolina',
        id: '065'
      },
      {
        name: 'Loski Potok',
        id: '066'
      },
      {
        name: 'Lovrenc na Pohorju',
        id: '167'
      },
      {
        name: 'Lukovica',
        id: '068'
      },
      {
        name: 'Luce',
        id: '067'
      },
      {
        name: 'Majsperk',
        id: '069'
      },
      {
        name: 'Makole',
        id: '198'
      },
      {
        name: 'Maribor',
        id: '070'
      },
      {
        name: 'Markovci',
        id: '168'
      },
      {
        name: 'Medvode',
        id: '071'
      },
      {
        name: 'Menges',
        id: '072'
      },
      {
        name: 'Metlika',
        id: '073'
      },
      {
        name: 'Mezica',
        id: '074'
      },
      {
        name: 'Miklavz na Dravskem Polju',
        id: '169'
      },
      {
        name: 'Miren-Kostanjevica',
        id: '075'
      },
      {
        name: 'Mirna',
        id: '212'
      },
      {
        name: 'Mirna Pec',
        id: '170'
      },
      {
        name: 'Mislinja',
        id: '076'
      },
      {
        name: 'Mokronog-Trebelno',
        id: '199'
      },
      {
        name: 'Moravce',
        id: '077'
      },
      {
        name: 'Moravske Toplice',
        id: '078'
      },
      {
        name: 'Mozirje',
        id: '079'
      },
      {
        name: 'Murska Sobota',
        id: '080'
      },
      {
        name: 'Naklo',
        id: '082'
      },
      {
        name: 'Nazarje',
        id: '083'
      },
      {
        name: 'Nova Gorica',
        id: '084'
      },
      {
        name: 'Novo Mesto',
        id: '085'
      },
      {
        name: 'Odranci',
        id: '086'
      },
      {
        name: 'Ormoz',
        id: '087'
      },
      {
        name: 'Osilnica',
        id: '088'
      },
      {
        name: 'Pesnica',
        id: '089'
      },
      {
        name: 'Piran',
        id: '090'
      },
      {
        name: 'Pivka',
        id: '091'
      },
      {
        name: 'Podcetrtek',
        id: '092'
      },
      {
        name: 'Podlehnik',
        id: '172'
      },
      {
        name: 'Podvelka',
        id: '093'
      },
      {
        name: 'Poljcane',
        id: '200'
      },
      {
        name: 'Postojna',
        id: '094'
      },
      {
        name: 'Prebold',
        id: '174'
      },
      {
        name: 'Preddvor',
        id: '095'
      },
      {
        name: 'Prevalje',
        id: '175'
      },
      {
        name: 'Ptuj',
        id: '096'
      },
      {
        name: 'Race-Fram',
        id: '098'
      },
      {
        name: 'Radece',
        id: '099'
      },
      {
        name: 'Radenci',
        id: '100'
      },
      {
        name: 'Radlje ob Dravi',
        id: '101'
      },
      {
        name: 'Radovljica',
        id: '102'
      },
      {
        name: 'Ravne na Koroskem',
        id: '103'
      },
      {
        name: 'Razkrizje',
        id: '176'
      },
      {
        name: 'Recica ob Savinji',
        id: '209'
      },
      {
        name: 'Rence-Vogrsko',
        id: '201'
      },
      {
        name: 'Ribnica',
        id: '104'
      },
      {
        name: 'Ribnica na Poboriu',
        id: '177'
      },
      {
        name: 'Rogaska Slatina',
        id: '106'
      },
      {
        name: 'Rogasovci',
        id: '105'
      },
      {
        name: 'Rogatec',
        id: '107'
      },
      {
        name: 'Ruse',
        id: '108'
      },
      {
        name: 'Salovci',
        id: '033'
      },
      {
        name: 'Selnica ob Dravi',
        id: '178'
      },
      {
        name: 'Semic',
        id: '109'
      },
      {
        name: 'Sempeter-Vrtojba',
        id: '183'
      },
      {
        name: 'Sencur',
        id: '117'
      },
      {
        name: 'Sentilj',
        id: '118'
      },
      {
        name: 'Sentjernej',
        id: '119'
      },
      {
        name: 'Sentjur',
        id: '120'
      },
      {
        name: 'Sentrupert',
        id: '211'
      },
      {
        name: 'Sevnica',
        id: '110'
      },
      {
        name: 'Sezana',
        id: '111'
      },
      {
        name: 'Skocjan',
        id: '121'
      },
      {
        name: 'Skofja Loka',
        id: '122'
      },
      {
        name: 'Skofljica',
        id: '123'
      },
      {
        name: 'Slovenj Gradec',
        id: '112'
      },
      {
        name: 'Slovenska Bistrica',
        id: '113'
      },
      {
        name: 'Slovenske Konjice',
        id: '114'
      },
      {
        name: 'Smarje pri elsah',
        id: '124'
      },
      {
        name: 'Smarjeske Toplice',
        id: '206'
      },
      {
        name: 'Smartno ob Paki',
        id: '125'
      },
      {
        name: 'Smartno pri Litiji',
        id: '194'
      },
      {
        name: 'Sodrazica',
        id: '179'
      },
      {
        name: 'Solcava',
        id: '180'
      },
      {
        name: 'Sostanj',
        id: '126'
      },
      {
        name: 'Sredisce ob Dravi',
        id: '202'
      },
      {
        name: 'Starse',
        id: '115'
      },
      {
        name: 'Store',
        id: '127'
      },
      {
        name: 'Straza',
        id: '203'
      },
      {
        name: 'Sveta Ana',
        id: '181'
      },
      {
        name: 'Sveta Trojica v Slovenskih Goricah',
        id: '204'
      },
      {
        name: 'Sveta Andraz v Slovenskih Goricah',
        id: '182'
      },
      {
        name: 'Sveti Jurij',
        id: '116'
      },
      {
        name: 'Sveti Jurij v Slovenskih Goricah',
        id: '210'
      },
      {
        name: 'Sveti Tomaz',
        id: '205'
      },
      {
        name: 'Tabor',
        id: '184'
      },
      {
        name: 'Tisina',
        id: '010'
      },
      {
        name: 'Tolmin',
        id: '128'
      },
      {
        name: 'Trbovlje',
        id: '129'
      },
      {
        name: 'Trebnje',
        id: '130'
      },
      {
        name: 'Trnovska Vas',
        id: '185'
      },
      {
        name: 'Trzin',
        id: '186'
      },
      {
        name: 'Trzic',
        id: '131'
      },
      {
        name: 'Turnisce',
        id: '132'
      },
      {
        name: 'Velenje',
        id: '133'
      },
      {
        name: 'Velika Polana',
        id: '187'
      },
      {
        name: 'Velike Lasce',
        id: '134'
      },
      {
        name: 'Verzej',
        id: '188'
      },
      {
        name: 'Videm',
        id: '135'
      },
      {
        name: 'Vipava',
        id: '136'
      },
      {
        name: 'Vitanje',
        id: '137'
      },
      {
        name: 'Vodice',
        id: '138'
      },
      {
        name: 'Vojnik',
        id: '139'
      },
      {
        name: 'Vransko',
        id: '189'
      },
      {
        name: 'Vrhnika',
        id: '140'
      },
      {
        name: 'Vuzenica',
        id: '141'
      },
      {
        name: 'Zagorje ob Savi',
        id: '142'
      },
      {
        name: 'Zavrc',
        id: '143'
      },
      {
        name: 'Zrece',
        id: '144'
      },
      {
        name: 'Zalec',
        id: '190'
      },
      {
        name: 'Zelezniki',
        id: '146'
      },
      {
        name: 'Zetale',
        id: '191'
      },
      {
        name: 'Ziri',
        id: '147'
      },
      {
        name: 'Zirovnica',
        id: '192'
      },
      {
        name: 'Zuzemberk',
        id: '193'
      }
    ]
  },
  {
    countryName: 'Solomon Islands',
    countryShortCode: 'SB',
    regions: [
      {
        name: 'Central',
        id: 'CE'
      },
      {
        name: 'Choiseul',
        id: 'CH'
      },
      {
        name: 'Guadalcanal',
        id: 'GU'
      },
      {
        name: 'Honiara',
        id: 'CT'
      },
      {
        name: 'Isabel',
        id: 'IS'
      },
      {
        name: 'Makira-Ulawa',
        id: 'MK'
      },
      {
        name: 'Malaita',
        id: 'ML'
      },
      {
        name: 'Rennell and Bellona',
        id: 'RB'
      },
      {
        name: 'Temotu',
        id: 'TE'
      },
      {
        name: 'Western',
        id: 'WE'
      }
    ]
  },
  {
    countryName: 'Somalia',
    countryShortCode: 'SO',
    regions: [
      {
        name: 'Awdal',
        id: 'AW'
      },
      {
        name: 'Bakool',
        id: 'BK'
      },
      {
        name: 'Banaadir',
        id: 'BN'
      },
      {
        name: 'Bari',
        id: 'BR'
      },
      {
        name: 'Bay',
        id: 'BY'
      },
      {
        name: 'Galguduud',
        id: 'GA'
      },
      {
        name: 'Gedo',
        id: 'GE'
      },
      {
        name: 'Hiiraan',
        id: 'HI'
      },
      {
        name: 'Jubbada Dhexe',
        id: 'JD'
      },
      {
        name: 'Jubbada Hoose',
        id: 'JH'
      },
      {
        name: 'Mudug',
        id: 'MU'
      },
      {
        name: 'Nugaal',
        id: 'NU'
      },
      {
        name: 'Sanaag',
        id: 'SA'
      },
      {
        name: 'Shabeellaha Dhexe',
        id: 'SD'
      },
      {
        name: 'Shabeellaha Hoose',
        id: 'SH'
      },
      {
        name: 'Sool',
        id: 'SO'
      },
      {
        name: 'Togdheer',
        id: 'TO'
      },
      {
        name: 'Woqooyi Galbeed',
        id: 'WO'
      }
    ]
  },
  {
    countryName: 'South Africa',
    countryShortCode: 'ZA',
    regions: [
      {
        name: 'Eastern Cape',
        id: 'EC'
      },
      {
        name: 'Free State',
        id: 'FS'
      },
      {
        name: 'Gauteng',
        id: 'GT'
      },
      {
        name: 'KwaZulu-Natal',
        id: 'NL'
      },
      {
        name: 'Limpopo',
        id: 'LP'
      },
      {
        name: 'Mpumalanga',
        id: 'MP'
      },
      {
        name: 'Northern Cape',
        id: 'NC'
      },
      {
        name: 'North West',
        id: 'NW'
      },
      {
        name: 'Western Cape',
        id: 'WC'
      }
    ]
  },
  {
    countryName: 'South Georgia and South Sandwich Islands',
    countryShortCode: 'GS',
    regions: [
      {
        name: 'Bird Island'
      },
      {
        name: 'Bristol Island'
      },
      {
        name: 'Clerke Rocks'
      },
      {
        name: 'Montagu Island'
      },
      {
        name: 'Saunders Island'
      },
      {
        name: 'South Georgia'
      },
      {
        name: 'Southern Thule'
      },
      {
        name: 'Traversay Islands'
      }
    ]
  },
  {
    countryName: 'South Sudan',
    countryShortCode: 'SS',
    regions: [
      {
        name: 'Central Equatoria',
        id: 'CE'
      },
      {
        name: 'Eastern Equatoria',
        id: 'EE'
      },
      {
        name: 'Jonglei',
        id: 'JG'
      },
      {
        name: 'Lakes',
        id: 'LK'
      },
      {
        name: 'Northern Bahr el Ghazal',
        id: 'BN'
      },
      {
        name: 'Unity',
        id: 'UY'
      },
      {
        name: 'Upper Nile',
        id: 'NU'
      },
      {
        name: 'Warrap',
        id: 'WR'
      },
      {
        name: 'Western Bahr el Ghazal',
        id: 'BW'
      },
      {
        name: 'Western Equatoria',
        id: 'EW'
      }
    ]
  },
  {
    countryName: 'Spain',
    countryShortCode: 'ES',
    regions: [
      {
        name: 'Albacete',
        id: 'AB'
      },
      {
        name: 'Alicante',
        id: 'A'
      },
      {
        name: 'Almería',
        id: 'AN'
      },
      {
        name: 'Araba/Álava',
        id: 'VI'
      },
      {
        name: 'Asturias',
        id: 'O'
      },
      {
        name: 'Ávila',
        id: 'AV'
      },
      {
        name: 'Badajoz',
        id: 'BA'
      },
      {
        name: 'Barcelona',
        id: 'B'
      },
      {
        name: 'Bizkaia',
        id: 'BI'
      },
      {
        name: 'Burgos',
        id: 'BU'
      },
      {
        name: 'Cáceres',
        id: 'CC'
      },
      {
        name: 'Cádiz',
        id: 'CA'
      },
      {
        name: 'Cantabria',
        id: 'S'
      },
      {
        name: 'Castellón/Castelló',
        id: 'CS'
      },
      {
        name: 'Ceuta',
        id: 'CE'
      },
      {
        name: 'Ciudad Real',
        id: 'CR'
      },
      {
        name: 'Córdoba',
        id: 'CO'
      },
      {
        name: 'A Coruña',
        id: 'C'
      },
      {
        name: 'Cuenca',
        id: 'CU'
      },
      {
        name: 'Gipuzkoa',
        id: 'SS'
      },
      {
        name: 'Girona',
        id: 'GI'
      },
      {
        name: 'Granada',
        id: 'GR'
      },
      {
        name: 'Guadalajara',
        id: 'GU'
      },
      {
        name: 'Huelva',
        id: 'H'
      },
      {
        name: 'Huesca',
        id: 'HU'
      },
      {
        name: 'Illes Balears',
        id: 'PM'
      },
      {
        name: 'Jaén',
        id: 'J'
      },
      {
        name: 'León',
        id: 'LE'
      },
      {
        name: 'Lleida',
        id: 'L'
      },
      {
        name: 'Lugo',
        id: 'LU'
      },
      {
        name: 'Madrid',
        id: 'M'
      },
      {
        name: 'Málaga',
        id: 'MA'
      },
      {
        name: 'Melilla',
        id: 'ML'
      },
      {
        name: 'Murcia',
        id: 'MU'
      },
      {
        name: 'Navarra/Nafarroa',
        id: 'NA'
      },
      {
        name: 'Ourense',
        id: 'OR'
      },
      {
        name: 'Palencia',
        id: 'P'
      },
      {
        name: 'Las Palmas',
        id: 'GC'
      },
      {
        name: 'Pontevedra',
        id: 'PO'
      },
      {
        name: 'La Rioja',
        id: 'LO'
      },
      {
        name: 'Salamanca',
        id: 'SA'
      },
      {
        name: 'Santa Cruz de Tenerife',
        id: 'TF'
      },
      {
        name: 'Segovia',
        id: 'SG'
      },
      {
        name: 'Sevilla',
        id: 'SE'
      },
      {
        name: 'Soria',
        id: 'SO'
      },
      {
        name: 'Tarragona',
        id: 'T'
      },
      {
        name: 'Teruel',
        id: 'TE'
      },
      {
        name: 'Toledo',
        id: 'TO'
      },
      {
        name: 'Valencia/València',
        id: 'V'
      },
      {
        name: 'Valladolid',
        id: 'VA'
      },
      {
        name: 'Zamora',
        id: 'ZA'
      },
      {
        name: 'Zaragoza',
        id: 'Z'
      }
    ]
  },
  {
    countryName: 'Sri Lanka',
    countryShortCode: 'LK',
    regions: [
      {
        name: 'Basnahira',
        id: '1'
      },
      {
        name: 'Dakunu',
        id: '3'
      },
      {
        name: 'Madhyama',
        id: '2'
      },
      {
        name: 'Naegenahira',
        id: '5'
      },
      {
        name: 'Sabaragamuwa',
        id: '9'
      },
      {
        name: 'Uturu',
        id: '4'
      },
      {
        name: 'Uturumaeda',
        id: '7'
      },
      {
        name: 'Vayamba',
        id: '6'
      },
      {
        name: 'Uva',
        id: '8'
      }
    ]
  },
  {
    countryName: 'Sudan',
    countryShortCode: 'SD',
    regions: [
      {
        name: 'Al Bahr al Ahmar',
        id: 'RS'
      },
      {
        name: 'Al Jazirah',
        id: 'GZ'
      },
      {
        name: 'Al Khartum',
        id: 'KH'
      },
      {
        name: 'Al Qadarif',
        id: 'GD'
      },
      {
        name: 'An Nil al Abyad',
        id: 'NW'
      },
      {
        name: 'An Nil al Azraq',
        id: 'NB'
      },
      {
        name: 'Ash Shamaliyah',
        id: 'NO'
      },
      {
        name: 'Gharb Darfur',
        id: 'DW'
      },
      {
        name: 'Gharb Kurdufan',
        id: 'GK'
      },
      {
        name: 'Janub Darfur',
        id: 'DS'
      },
      {
        name: 'Janub Kurdufan',
        id: 'KS'
      },
      {
        name: 'Kassala',
        id: 'KA'
      },
      {
        name: 'Nahr an Nil',
        id: 'NR'
      },
      {
        name: 'Shamal Darfur',
        id: 'DN'
      },
      {
        name: 'Sharq Darfur',
        id: 'DE'
      },
      {
        name: 'Shiamal Kurdufan',
        id: 'KN'
      },
      {
        name: 'Sinnar',
        id: 'SI'
      },
      {
        name: 'Wasat Darfur Zalinjay',
        id: 'DC'
      }
    ]
  },
  {
    countryName: 'Suriname',
    countryShortCode: 'SR',
    regions: [
      {
        name: 'Brokopondo',
        id: 'BR'
      },
      {
        name: 'Commewijne',
        id: 'CM'
      },
      {
        name: 'Coronie',
        id: 'CR'
      },
      {
        name: 'Marowijne',
        id: 'MA'
      },
      {
        name: 'Nickerie',
        id: 'NI'
      },
      {
        name: 'Para',
        id: 'PR'
      },
      {
        name: 'Paramaribo',
        id: 'PM'
      },
      {
        name: 'Saramacca',
        id: 'SA'
      },
      {
        name: 'Sipaliwini',
        id: 'SI'
      },
      {
        name: 'Wanica',
        id: 'WA'
      }
    ]
  },
  {
    countryName: 'Eswatini',
    countryShortCode: 'SZ',
    regions: [
      {
        name: 'Hhohho',
        id: 'HH'
      },
      {
        name: 'Lubombo',
        id: 'LU'
      },
      {
        name: 'Manzini',
        id: 'MA'
      },
      {
        name: 'Shiselweni',
        id: 'SH'
      }
    ]
  },
  {
    countryName: 'Sweden',
    countryShortCode: 'SE',
    regions: [
      {
        name: 'Blekinge',
        id: 'K'
      },
      {
        name: 'Dalarna',
        id: 'W'
      },
      {
        name: 'Gävleborg',
        id: 'X'
      },
      {
        name: 'Gotland',
        id: 'I'
      },
      {
        name: 'Halland',
        id: 'N'
      },
      {
        name: 'Jämtland',
        id: 'Z'
      },
      {
        name: 'Jönköping',
        id: 'F'
      },
      {
        name: 'Kalmar',
        id: 'H'
      },
      {
        name: 'Kronoberg',
        id: 'G'
      },
      {
        name: 'Norrbotten',
        id: 'BD'
      },
      {
        name: 'Örebro',
        id: 'T'
      },
      {
        name: 'Östergötland',
        id: 'E'
      },
      {
        name: 'Skåne',
        id: 'M'
      },
      {
        name: 'Södermanland',
        id: 'D'
      },
      {
        name: 'Stockholm',
        id: 'AB'
      },
      {
        name: 'Uppsala',
        id: 'C'
      },
      {
        name: 'Värmland',
        id: 'S'
      },
      {
        name: 'Västerbotten',
        id: 'AC'
      },
      {
        name: 'Västernorrland',
        id: 'Y'
      },
      {
        name: 'Västmanland',
        id: 'U'
      },
      {
        name: 'Västra Götaland',
        id: 'O'
      }
    ]
  },
  {
    countryName: 'Switzerland',
    countryShortCode: 'CH',
    regions: [
      {
        name: 'Aargau',
        id: 'AG'
      },
      {
        name: 'Appenzell Ausserrhoden',
        id: 'AR'
      },
      {
        name: 'Appenzell Innerhoden',
        id: 'AI'
      },
      {
        name: 'Basel-Landschaft',
        id: 'BL'
      },
      {
        name: 'Basel-Stadt',
        id: 'BS'
      },
      {
        name: 'Bern',
        id: 'BE'
      },
      {
        name: 'Fribourg',
        id: 'FR'
      },
      {
        name: 'Genève',
        id: 'GE'
      },
      {
        name: 'Glarus',
        id: 'GL'
      },
      {
        name: 'Graubünden',
        id: 'GR'
      },
      {
        name: 'Jura',
        id: 'JU'
      },
      {
        name: 'Luzern',
        id: 'LU'
      },
      {
        name: 'Neuchâtel',
        id: 'NE'
      },
      {
        name: 'Nidwalden',
        id: 'NW'
      },
      {
        name: 'Obwalden',
        id: 'OW'
      },
      {
        name: 'Sankt Gallen',
        id: 'SG'
      },
      {
        name: 'Schaffhausen',
        id: 'SH'
      },
      {
        name: 'Schwyz',
        id: 'SZ'
      },
      {
        name: 'Solothurn',
        id: 'SO'
      },
      {
        name: 'Thurgau',
        id: 'TG'
      },
      {
        name: 'Ticino',
        id: 'TI'
      },
      {
        name: 'Uri',
        id: 'UR'
      },
      {
        name: 'Valais',
        id: 'VS'
      },
      {
        name: 'Vaud',
        id: 'VD'
      },
      {
        name: 'Zug',
        id: 'ZG'
      },
      {
        name: 'Zürich',
        id: 'ZH'
      }
    ]
  },
  {
    countryName: 'Syrian Arab Republic',
    countryShortCode: 'SY',
    regions: [
      {
        name: 'Al Hasakah',
        id: 'HA'
      },
      {
        name: 'Al Ladhiqiyah',
        id: 'LA'
      },
      {
        name: 'Al Qunaytirah',
        id: 'QU'
      },
      {
        name: 'Ar Raqqah',
        id: 'RA'
      },
      {
        name: 'As Suwayda\'',
        id: 'SU'
      },
      {
        name: 'Dar\'a',
        id: 'DR'
      },
      {
        name: 'Dayr az Zawr',
        id: 'DY'
      },
      {
        name: 'Dimashq',
        id: 'DI'
      },
      {
        name: 'Halab',
        id: 'HL'
      },
      {
        name: 'Hamah',
        id: 'HM'
      },
      {
        name: 'Hims',
        id: 'HI'
      },
      {
        name: 'Idlib',
        id: 'ID'
      },
      {
        name: 'Rif Dimashq',
        id: 'RD'
      },
      {
        name: 'Tartus',
        id: 'TA'
      }
    ]
  },
  {
    countryName: 'Taiwan',
    countryShortCode: 'TW',
    regions: [
      {
        name: 'Changhua',
        id: 'CHA'
      },
      {
        name: 'Chiayi',
        id: 'CYQ'
      },
      {
        name: 'Hsinchu',
        id: 'HSQ'
      },
      {
        name: 'Hualien',
        id: 'HUA'
      },
      {
        name: 'Kaohsiung',
        id: 'KHH'
      },
      {
        name: 'Keelung',
        id: 'KEE'
      },
      {
        name: 'Kinmen',
        id: 'KIN'
      },
      {
        name: 'Lienchiang',
        id: 'LIE'
      },
      {
        name: 'Miaoli',
        id: 'MIA'
      },
      {
        name: 'Nantou',
        id: 'NAN'
      },
      {
        name: 'Penghu',
        id: 'PEN'
      },
      {
        name: 'New Taipei',
        id: 'NWT'
      },
      {
        name: 'Pingtung',
        id: 'PIF'
      },
      {
        name: 'Taichung',
        id: 'TXG'
      },
      {
        name: 'Tainan',
        id: 'TNN'
      },
      {
        name: 'Taipei',
        id: 'TPE'
      },
      {
        name: 'Taitung',
        id: 'TTT'
      },
      {
        name: 'Taoyuan',
        id: 'TAO'
      },
      {
        name: 'Yilan',
        id: 'ILA'
      },
      {
        name: 'Yunlin',
        id: 'YUN'
      }
    ]
  },
  {
    countryName: 'Tajikistan',
    countryShortCode: 'TJ',
    regions: [
      {
        name: 'Dushanbe',
        id: 'DU'
      },
      {
        name: 'Kŭhistoni Badakhshon',
        id: 'GB'
      },
      {
        name: 'Khatlon',
        id: 'KT'
      },
      {
        name: 'Sughd',
        id: 'SU'
      }
    ]
  },
  {
    countryName: 'Tanzania, United Republic of',
    countryShortCode: 'TZ',
    regions: [
      {
        name: 'Arusha',
        id: '01'
      },
      {
        name: 'Coast',
        id: '19'
      },
      {
        name: 'Dar es Salaam',
        id: '02'
      },
      {
        name: 'Dodoma',
        id: '03'
      },
      {
        name: 'Iringa',
        id: '04'
      },
      {
        name: 'Kagera',
        id: '05'
      },
      {
        name: 'Kigoma',
        id: '08'
      },
      {
        name: 'Kilimanjaro',
        id: '09'
      },
      {
        name: 'Lindi',
        id: '12'
      },
      {
        name: 'Manyara',
        id: '26'
      },
      {
        name: 'Mara',
        id: '13'
      },
      {
        name: 'Mbeya',
        id: '14'
      },
      {
        name: 'Morogoro',
        id: '16'
      },
      {
        name: 'Mtwara',
        id: '17'
      },
      {
        name: 'Mwanza',
        id: '18'
      },
      {
        name: 'Pemba North',
        id: '06'
      },
      {
        name: 'Pemba South',
        id: '10'
      },
      {
        name: 'Rukwa',
        id: '20'
      },
      {
        name: 'Ruvuma',
        id: '21'
      },
      {
        name: 'Shinyanga',
        id: '22'
      },
      {
        name: 'Singida',
        id: '23'
      },
      {
        name: 'Tabora',
        id: '24'
      },
      {
        name: 'Tanga',
        id: '25'
      },
      {
        name: 'Zanzibar North',
        id: '07'
      },
      {
        name: 'Zanzibar Central/South',
        id: '11'
      },
      {
        name: 'Zanzibar Urban/West',
        id: '15'
      }
    ]
  },
  {
    countryName: 'Thailand',
    countryShortCode: 'TH',
    regions: [
      {
        name: 'Amnat Charoen',
        id: '37'
      },
      {
        name: 'Ang Thong',
        id: '15'
      },
      {
        name: 'Bueng Kan',
        id: '38'
      },
      {
        name: 'Buri Ram',
        id: '31'
      },
      {
        name: 'Chachoengsao',
        id: '24'
      },
      {
        name: 'Chai Nat',
        id: '18'
      },
      {
        name: 'Chaiyaphum',
        id: '36'
      },
      {
        name: 'Chanthaburi',
        id: '22'
      },
      {
        name: 'Chiang Mai',
        id: '50'
      },
      {
        name: 'Chiang Rai',
        id: '57'
      },
      {
        name: 'Chon Buri',
        id: '20'
      },
      {
        name: 'Chumphon',
        id: '86'
      },
      {
        name: 'Kalasin',
        id: '46'
      },
      {
        name: 'Kamphaeng Phet',
        id: '62'
      },
      {
        name: 'Kanchanaburi',
        id: '71'
      },
      {
        name: 'Khon Kaen',
        id: '40'
      },
      {
        name: 'Krabi',
        id: '81'
      },
      {
        name: 'Krung Thep Mahanakhon (Bangkok)',
        id: '10'
      },
      {
        name: 'Lampang',
        id: '52'
      },
      {
        name: 'Lamphun',
        id: '51'
      },
      {
        name: 'Loei',
        id: '42'
      },
      {
        name: 'Lop Buri',
        id: '16'
      },
      {
        name: 'Mae Hong Son',
        id: '58'
      },
      {
        name: 'Maha Sarakham',
        id: '44'
      },
      {
        name: 'Mukdahan',
        id: '49'
      },
      {
        name: 'Nakhon Nayok',
        id: '26'
      },
      {
        name: 'Nakhon Phathom',
        id: '73'
      },
      {
        name: 'Nakhon Phanom',
        id: '48'
      },
      {
        name: 'Nakhon Ratchasima',
        id: '30'
      },
      {
        name: 'Nakhon Sawan',
        id: '60'
      },
      {
        name: 'Nakhon Si Thammarat',
        id: '80'
      },
      {
        name: 'Nan',
        id: '55'
      },
      {
        name: 'Narathiwat',
        id: '96'
      },
      {
        name: 'Nong Bua Lam Phu',
        id: '39'
      },
      {
        name: 'Nong Khai',
        id: '43'
      },
      {
        name: 'Nonthaburi',
        id: '12'
      },
      {
        name: 'Pathum Thani',
        id: '13'
      },
      {
        name: 'Pattani',
        id: '94'
      },
      {
        name: 'Phangnga',
        id: '82'
      },
      {
        name: 'Phatthalung',
        id: '93'
      },
      {
        name: 'Phayao',
        id: '56'
      },
      {
        name: 'Phetchabun',
        id: '67'
      },
      {
        name: 'Phetchaburi',
        id: '76'
      },
      {
        name: 'Phichit',
        id: '66'
      },
      {
        name: 'Phitsanulok',
        id: '65'
      },
      {
        name: 'Phra Nakhon Si Ayutthaya',
        id: '14'
      },
      {
        name: 'Phrae',
        id: '54'
      },
      {
        name: 'Phuket',
        id: '83'
      },
      {
        name: 'Prachin Buri',
        id: '25'
      },
      {
        name: 'Prachuap Khiri Khan',
        id: '77'
      },
      {
        name: 'Ranong',
        id: '85'
      },
      {
        name: 'Ratchaburi',
        id: '70'
      },
      {
        name: 'Rayong',
        id: '21'
      },
      {
        name: 'Roi Et',
        id: '45'
      },
      {
        name: 'Sa Kaeo',
        id: '27'
      },
      {
        name: 'Sakon Nakhon',
        id: '47'
      },
      {
        name: 'Samut Prakan',
        id: '11'
      },
      {
        name: 'Samut Sakhon',
        id: '74'
      },
      {
        name: 'Samut Songkhram',
        id: '75'
      },
      {
        name: 'Saraburi',
        id: '19'
      },
      {
        name: 'Satun',
        id: '91'
      },
      {
        name: 'Sing Buri',
        id: '17'
      },
      {
        name: 'Si Sa ket',
        id: '33'
      },
      {
        name: 'Songkhla',
        id: '90'
      },
      {
        name: 'Sukhothai',
        id: '64'
      },
      {
        name: 'Suphan Buri',
        id: '72'
      },
      {
        name: 'Surat Thani',
        id: '84'
      },
      {
        name: 'Surin',
        id: '32'
      },
      {
        name: 'Tak',
        id: '63'
      },
      {
        name: 'Trang',
        id: '92'
      },
      {
        name: 'Trat',
        id: '23'
      },
      {
        name: 'Ubon Ratchathani',
        id: '34'
      },
      {
        name: 'Udon Thani',
        id: '41'
      },
      {
        name: 'Uthai Thani',
        id: '61'
      },
      {
        name: 'Uttaradit',
        id: '53'
      },
      {
        name: 'Yala',
        id: '95'
      },
      {
        name: 'Yasothon',
        id: '35'
      }
    ]
  },
  {
    countryName: 'Timor-Leste',
    countryShortCode: 'TL',
    regions: [
      {
        name: 'Aileu',
        id: 'AL'
      },
      {
        name: 'Ainaro',
        id: 'AN'
      },
      {
        name: 'Baucau',
        id: 'BA'
      },
      {
        name: 'Bobonaro',
        id: 'BO'
      },
      {
        name: 'Cova Lima',
        id: 'CO'
      },
      {
        name: 'Dili',
        id: 'DI'
      },
      {
        name: 'Ermera',
        id: 'ER'
      },
      {
        name: 'Lautem',
        id: 'LA'
      },
      {
        name: 'Liquica',
        id: 'LI'
      },
      {
        name: 'Manatuto',
        id: 'MT'
      },
      {
        name: 'Manufahi',
        id: 'MF'
      },
      {
        name: 'Oecussi',
        id: 'OE'
      },
      {
        name: 'Viqueque',
        id: 'VI'
      }
    ]
  },
  {
    countryName: 'Togo',
    countryShortCode: 'TG',
    regions: [
      {
        name: 'Centre',
        id: 'C'
      },
      {
        name: 'Kara',
        id: 'K'
      },
      {
        name: 'Maritime',
        id: 'M'
      },
      {
        name: 'Plateaux',
        id: 'P'
      },
      {
        name: 'Savannes',
        id: 'S'
      }
    ]
  },
  {
    countryName: 'Tokelau',
    countryShortCode: 'TK',
    regions: [
      {
        name: 'Atafu'
      },
      {
        name: 'Fakaofo'
      },
      {
        name: 'Nukunonu'
      }
    ]
  },
  {
    countryName: 'Tonga',
    countryShortCode: 'TO',
    regions: [
      {
        name: '\'Eua',
        id: '01'
      },
      {
        name: 'Ha\'apai',
        id: '02'
      },
      {
        name: 'Niuas',
        id: '03'
      },
      {
        name: 'Tongatapu',
        id: '04'
      },
      {
        name: 'Vava\'u',
        id: '05'
      }
    ]
  },
  {
    countryName: 'Trinidad and Tobago',
    countryShortCode: 'TT',
    regions: [
      {
        name: 'Arima',
        id: 'ARI'
      },
      {
        name: 'Chaguanas',
        id: 'CHA'
      },
      {
        name: 'Couva-Tabaquite-Talparo',
        id: 'CTT'
      },
      {
        name: 'Diefo Martin',
        id: 'DMN'
      },
      {
        name: 'Mayaro-Rio Claro',
        id: 'MRC'
      },
      {
        name: 'Penal-Debe',
        id: 'PED'
      },
      {
        name: 'Point Fortin',
        id: 'PTF'
      },
      {
        name: 'Port-of-Spain',
        id: 'POS'
      },
      {
        name: 'Princes Town',
        id: 'PRT'
      },
      {
        name: 'San Fernando',
        id: 'SFO'
      },
      {
        name: 'San Juan-Laventille',
        id: 'SJL'
      },
      {
        name: 'Sangre Grande',
        id: 'SGE'
      },
      {
        name: 'Siparia',
        id: 'SIP'
      },
      {
        name: 'Tobago',
        id: 'TOB'
      },
      {
        name: 'Tunapuna-Piarco',
        id: 'TUP'
      }
    ]
  },
  {
    countryName: 'Tunisia',
    countryShortCode: 'TN',
    regions: [
      {
        name: 'Ariana',
        id: '12'
      },
      {
        name: 'Beja',
        id: '31'
      },
      {
        name: 'Ben Arous',
        id: '13'
      },
      {
        name: 'Bizerte',
        id: '23'
      },
      {
        name: 'Gabes',
        id: '81'
      },
      {
        name: 'Gafsa',
        id: '71'
      },
      {
        name: 'Jendouba',
        id: '32'
      },
      {
        name: 'Kairouan',
        id: '41'
      },
      {
        name: 'Kasserine',
        id: '42'
      },
      {
        name: 'Kebili',
        id: '73'
      },
      {
        name: 'Kef',
        id: '33'
      },
      {
        name: 'Mahdia',
        id: '53'
      },
      {
        name: 'Medenine',
        id: '82'
      },
      {
        name: 'Monastir',
        id: '52'
      },
      {
        name: 'Nabeul',
        id: '21'
      },
      {
        name: 'Sfax',
        id: '61'
      },
      {
        name: 'Sidi Bouzid',
        id: '43'
      },
      {
        name: 'Siliana',
        id: '34'
      },
      {
        name: 'Sousse',
        id: '51'
      },
      {
        name: 'Tataouine',
        id: '83'
      },
      {
        name: 'Tozeur',
        id: '72'
      },
      {
        name: 'Tunis',
        id: '11'
      },
      {
        name: 'Zaghouan',
        id: '22'
      }
    ]
  },
  {
    countryName: 'Turkey',
    countryShortCode: 'TR',
    regions: [
      {
        name: 'Adana',
        id: '01'
      },
      {
        name: 'Adiyaman',
        id: '02'
      },
      {
        name: 'Afyonkarahisar',
        id: '03'
      },
      {
        name: 'Agri',
        id: '04'
      },
      {
        name: 'Aksaray',
        id: '68'
      },
      {
        name: 'Amasya',
        id: '05'
      },
      {
        name: 'Ankara',
        id: '06'
      },
      {
        name: 'Antalya',
        id: '07'
      },
      {
        name: 'Ardahan',
        id: '75'
      },
      {
        name: 'Artvin',
        id: '08'
      },
      {
        name: 'Aydin',
        id: '09'
      },
      {
        name: 'Balikesir',
        id: '10'
      },
      {
        name: 'Bartin',
        id: '74'
      },
      {
        name: 'Batman',
        id: '72'
      },
      {
        name: 'Bayburt',
        id: '69'
      },
      {
        name: 'Bilecik',
        id: '11'
      },
      {
        name: 'Bingol',
        id: '12'
      },
      {
        name: 'Bitlis',
        id: '13'
      },
      {
        name: 'Bolu',
        id: '14'
      },
      {
        name: 'Burdur',
        id: '15'
      },
      {
        name: 'Bursa',
        id: '16'
      },
      {
        name: 'Canakkale',
        id: '17'
      },
      {
        name: 'Cankiri',
        id: '18'
      },
      {
        name: 'Corum',
        id: '19'
      },
      {
        name: 'Denizli',
        id: '20'
      },
      {
        name: 'Diyarbakir',
        id: '21'
      },
      {
        name: 'Duzce',
        id: '81'
      },
      {
        name: 'Edirne',
        id: '22'
      },
      {
        name: 'Elazig',
        id: '23'
      },
      {
        name: 'Erzincan',
        id: '24'
      },
      {
        name: 'Erzurum',
        id: '25'
      },
      {
        name: 'Eskisehir',
        id: '26'
      },
      {
        name: 'Gaziantep',
        id: '27'
      },
      {
        name: 'Giresun',
        id: '28'
      },
      {
        name: 'Gumushane',
        id: '29'
      },
      {
        name: 'Hakkari',
        id: '30'
      },
      {
        name: 'Hatay',
        id: '31'
      },
      {
        name: 'Igdir',
        id: '76'
      },
      {
        name: 'Isparta',
        id: '32'
      },
      {
        name: 'Istanbul',
        id: '34'
      },
      {
        name: 'Izmir',
        id: '35'
      },
      {
        name: 'Kahramanmaras',
        id: '46'
      },
      {
        name: 'Karabuk',
        id: '78'
      },
      {
        name: 'Karaman',
        id: '70'
      },
      {
        name: 'Kars',
        id: '36'
      },
      {
        name: 'Kastamonu',
        id: '37'
      },
      {
        name: 'Kayseri',
        id: '38'
      },
      {
        name: 'Kilis',
        id: '79'
      },
      {
        name: 'Kirikkale',
        id: '71'
      },
      {
        name: 'Kirklareli',
        id: '39'
      },
      {
        name: 'Kirsehir',
        id: '40'
      },
      {
        name: 'Kocaeli',
        id: '41'
      },
      {
        name: 'Konya',
        id: '42'
      },
      {
        name: 'Kutahya',
        id: '43'
      },
      {
        name: 'Malatya',
        id: '44'
      },
      {
        name: 'Manisa',
        id: '45'
      },
      {
        name: 'Mardin',
        id: '47'
      },
      {
        name: 'Mersin',
        id: '33'
      },
      {
        name: 'Mugla',
        id: '48'
      },
      {
        name: 'Mus',
        id: '49'
      },
      {
        name: 'Nevsehir',
        id: '50'
      },
      {
        name: 'Nigde',
        id: '51'
      },
      {
        name: 'Ordu',
        id: '52'
      },
      {
        name: 'Osmaniye',
        id: '80'
      },
      {
        name: 'Rize',
        id: '53'
      },
      {
        name: 'Sakarya',
        id: '54'
      },
      {
        name: 'Samsun',
        id: '55'
      },
      {
        name: 'Sanliurfa',
        id: '63'
      },
      {
        name: 'Siirt',
        id: '56'
      },
      {
        name: 'Sinop',
        id: '57'
      },
      {
        name: 'Sirnak',
        id: '73'
      },
      {
        name: 'Sivas',
        id: '58'
      },
      {
        name: 'Tekirdag',
        id: '59'
      },
      {
        name: 'Tokat',
        id: '60'
      },
      {
        name: 'Trabzon',
        id: '61'
      },
      {
        name: 'Tunceli',
        id: '62'
      },
      {
        name: 'Usak',
        id: '64'
      },
      {
        name: 'Van',
        id: '65'
      },
      {
        name: 'Yalova',
        id: '77'
      },
      {
        name: 'Yozgat',
        id: '66'
      },
      {
        name: 'Zonguldak',
        id: '67'
      }
    ]
  },
  {
    countryName: 'Turkmenistan',
    countryShortCode: 'TM',
    regions: [
      {
        name: 'Ahal',
        id: 'A'
      },
      {
        name: 'Asgabat',
        id: 'S'
      },
      {
        name: 'Balkan',
        id: 'B'
      },
      {
        name: 'Dashoguz',
        id: 'D'
      },
      {
        name: 'Lebap',
        id: 'L'
      },
      {
        name: 'Mary',
        id: 'M'
      }
    ]
  },
  {
    countryName: 'Turks and Caicos Islands',
    countryShortCode: 'TC',
    regions: [
      {
        name: 'Turks and Caicos Islands'
      }
    ]
  },
  {
    countryName: 'Tuvalu',
    countryShortCode: 'TV',
    regions: [
      {
        name: 'Funafuti',
        id: 'FUN'
      },
      {
        name: 'Nanumanga',
        id: 'NMG'
      },
      {
        name: 'Nanumea',
        id: 'NMA'
      },
      {
        name: 'Niutao',
        id: 'NIT'
      },
      {
        name: 'Nui',
        id: 'NUI'
      },
      {
        name: 'Nukufetau',
        id: 'NKF'
      },
      {
        name: 'Nukulaelae',
        id: 'NKL'
      },
      {
        name: 'Vaitupu',
        id: 'VAU'
      }
    ]
  },
  {
    countryName: 'Uganda',
    countryShortCode: 'UG',
    regions: [
      {
        name: 'Abim',
        id: '317'
      },
      {
        name: 'Adjumani',
        id: '301'
      },
      {
        name: 'Amolatar',
        id: '314'
      },
      {
        name: 'Amuria',
        id: '216'
      },
      {
        name: 'Amuru',
        id: '319'
      },
      {
        name: 'Apac',
        id: '302'
      },
      {
        name: 'Arua',
        id: '303'
      },
      {
        name: 'Budaka',
        id: '217'
      },
      {
        name: 'Bududa',
        id: '223'
      },
      {
        name: 'Bugiri',
        id: '201'
      },
      {
        name: 'Bukedea',
        id: '224'
      },
      {
        name: 'Bukwa',
        id: '218'
      },
      {
        name: 'Buliisa',
        id: '419'
      },
      {
        name: 'Bundibugyo',
        id: '401'
      },
      {
        name: 'Bushenyi',
        id: '402'
      },
      {
        name: 'Busia',
        id: '202'
      },
      {
        name: 'Butaleja',
        id: '219'
      },
      {
        name: 'Dokolo',
        id: '318'
      },
      {
        name: 'Gulu',
        id: '304'
      },
      {
        name: 'Hoima',
        id: '403'
      },
      {
        name: 'Ibanda',
        id: '416'
      },
      {
        name: 'Iganga',
        id: '203'
      },
      {
        name: 'Isingiro',
        id: '417'
      },
      {
        name: 'Jinja',
        id: '204'
      },
      {
        name: 'Kaabong',
        id: '315'
      },
      {
        name: 'Kabale',
        id: '404'
      },
      {
        name: 'Kabarole',
        id: '405'
      },
      {
        name: 'Kaberamaido',
        id: '213'
      },
      {
        name: 'Kalangala',
        id: '101'
      },
      {
        name: 'Kaliro',
        id: '220'
      },
      {
        name: 'Kampala',
        id: '102'
      },
      {
        name: 'Kamuli',
        id: '205'
      },
      {
        name: 'Kamwenge',
        id: '413'
      },
      {
        name: 'Kanungu',
        id: '414'
      },
      {
        name: 'Kapchorwa',
        id: '206'
      },
      {
        name: 'Kasese',
        id: '406'
      },
      {
        name: 'Katakwi',
        id: '207'
      },
      {
        name: 'Kayunga',
        id: '112'
      },
      {
        name: 'Kibaale',
        id: '407'
      },
      {
        name: 'Kiboga',
        id: '103'
      },
      {
        name: 'Kiruhura',
        id: '418'
      },
      {
        name: 'Kisoro',
        id: '408'
      },
      {
        name: 'Kitgum',
        id: '305'
      },
      {
        name: 'Koboko',
        id: '316'
      },
      {
        name: 'Kotido',
        id: '306'
      },
      {
        name: 'Kumi',
        id: '208'
      },
      {
        name: 'Kyenjojo',
        id: '415'
      },
      {
        name: 'Lira',
        id: '307'
      },
      {
        name: 'Luwero',
        id: '104'
      },
      {
        name: 'Lyantonde',
        id: '116'
      },
      {
        name: 'Manafwa',
        id: '221'
      },
      {
        name: 'Maracha',
        id: '320'
      },
      {
        name: 'Masaka',
        id: '105'
      },
      {
        name: 'Masindi',
        id: '409'
      },
      {
        name: 'Mayuge',
        id: '214'
      },
      {
        name: 'Mbale',
        id: '209'
      },
      {
        name: 'Mbarara',
        id: '410'
      },
      {
        name: 'Mityana',
        id: '114'
      },
      {
        name: 'Moroto',
        id: '308'
      },
      {
        name: 'Moyo',
        id: '309'
      },
      {
        name: 'Mpigi',
        id: '106'
      },
      {
        name: 'Mubende',
        id: '107'
      },
      {
        name: 'Mukono',
        id: '108'
      },
      {
        name: 'Nakapiripirit',
        id: '311'
      },
      {
        name: 'Nakaseke',
        id: '115'
      },
      {
        name: 'Nakasongola',
        id: '109'
      },
      {
        name: 'Namutumba',
        id: '222'
      },
      {
        name: 'Nebbi',
        id: '310'
      },
      {
        name: 'Ntungamo',
        id: '411'
      },
      {
        name: 'Oyam',
        id: '321'
      },
      {
        name: 'Pader',
        id: '312'
      },
      {
        name: 'Pallisa',
        id: '210'
      },
      {
        name: 'Rakai',
        id: '110'
      },
      {
        name: 'Rukungiri',
        id: '412'
      },
      {
        name: 'Sembabule',
        id: '111'
      },
      {
        name: 'Sironko',
        id: '215'
      },
      {
        name: 'Soroti',
        id: '211'
      },
      {
        name: 'Tororo',
        id: '212'
      },
      {
        name: 'Wakiso',
        id: '113'
      },
      {
        name: 'Yumbe',
        id: '313'
      }
    ]
  },
  {
    countryName: 'Ukraine',
    countryShortCode: 'UA',
    regions: [
      {
        name: 'Cherkaska oblast',
        id: '71'
      },
      {
        name: 'Chernihivska oblast',
        id: '74'
      },
      {
        name: 'Chernivetska oblast',
        id: '77'
      },
      {
        name: 'Dnipropetrovska oblast',
        id: '12'
      },
      {
        name: 'Donetska oblast',
        id: '14'
      },
      {
        name: 'Ivano-Frankivska oblast',
        id: '26'
      },
      {
        name: 'Kharkivska oblast',
        id: '63'
      },
      {
        name: 'Khersonska oblast',
        id: '65'
      },
      {
        name: 'Khmelnytska oblast',
        id: '68'
      },
      {
        name: 'Kyivska oblast',
        id: '32'
      },
      {
        name: 'Kirovohradska oblast',
        id: '35'
      },
      {
        name: 'Luhanska oblast',
        id: '09'
      },
      {
        name: 'Lvivska oblast',
        id: '46'
      },
      {
        name: 'Mykolaivska oblast',
        id: '48'
      },
      {
        name: 'Odeska oblast',
        id: '51'
      },
      {
        name: 'Poltavska oblast',
        id: '53'
      },
      {
        name: 'Rivnenska oblast',
        id: '56'
      },
      {
        name: 'Sumska oblast',
        id: '59'
      },
      {
        name: 'Ternopilska oblast',
        id: '61'
      },
      {
        name: 'Vinnytska oblast',
        id: '05'
      },
      {
        name: 'Volynska oblast',
        id: '07'
      },
      {
        name: 'Zakarpatska oblast',
        id: '21'
      },
      {
        name: 'Zaporizka oblast',
        id: '23'
      },
      {
        name: 'Zhytomyrska oblast',
        id: '18'
      },
      {
        name: 'Avtonomna Respublika Krym',
        id: '43'
      },
      {
        name: 'Kyiv',
        id: '30'
      },
      {
        name: 'Sevastopol',
        id: '40'
      }
    ]
  },
  {
    countryName: 'United Arab Emirates',
    countryShortCode: 'AE',
    regions: [
      {
        name: 'Abu Dhabi',
        id: 'AZ'
      },
      {
        name: 'Ajman',
        id: 'AJ'
      },
      {
        name: 'Dubai',
        id: 'DU'
      },
      {
        name: 'Fujairah',
        id: 'FU'
      },
      {
        name: 'Ras al Khaimah',
        id: 'RK'
      },
      {
        name: 'Sharjah',
        id: 'SH'
      },
      {
        name: 'Umm Al Quwain',
        id: 'UQ'
      }
    ]
  },
  {
    countryName: 'United Kingdom',
    countryShortCode: 'GB',
    regions: [
      {
        name: 'Aberdeen City',
        id: 'ABE'
      },
      {
        name: 'Aberdeenshire',
        id: 'ABD'
      },
      {
        name: 'Angus',
        id: 'ANS'
      },
      {
        name: 'Antrim and Newtownabbey',
        id: 'ANN'
      },
      {
        name: 'Ards and North Down',
        id: 'AND'
      },
      {
        name: 'Argyll and Bute',
        id: 'AGB'
      },
      {
        name: 'Armagh City, Banbridge and Craigavon',
        id: 'ABC'
      },
      {
        name: 'Barking and Dagenham',
        id: 'BDG'
      },
      {
        name: 'Barnet',
        id: 'BNE'
      },
      {
        name: 'Barnsley',
        id: 'BNS'
      },
      {
        name: 'Bath and North East Somerset',
        id: 'BAS'
      },
      {
        name: 'Bedford',
        id: 'BDF'
      },
      {
        name: 'Belfast City',
        id: 'BFS'
      },
      {
        name: 'Berkshire',
        id: 'BRK'
      },
      {
        name: 'Bexley',
        id: 'BEX'
      },
      {
        name: 'Birmingham',
        id: 'BIR'
      },
      {
        name: 'Blackburn with Darwen',
        id: 'BBD'
      },
      {
        name: 'Blackpool',
        id: 'BPL'
      },
      {
        name: 'Blaenau Gwent',
        id: 'BGW'
      },
      {
        name: 'Bolton',
        id: 'BOL'
      },
      {
        name: 'Bournemouth, Christchurch and Poole',
        id: 'BCP'
      },
      {
        name: 'Bracknell Forest',
        id: 'BRC'
      },
      {
        name: 'Bradford',
        id: 'BRD'
      },
      {
        name: 'Brent',
        id: 'BEN'
      },
      {
        name: 'Bridgend',
        id: 'BGE'
      },
      {
        name: 'Brighton and Hove',
        id: 'BNH'
      },
      {
        name: 'Bristol, City of',
        id: 'BST'
      },
      {
        name: 'Bromley',
        id: 'BRY'
      },
      {
        name: 'Buckinghamshire',
        id: 'BKM'
      },
      {
        name: 'Bury',
        id: 'BUR'
      },
      {
        name: 'Caerphilly',
        id: 'CAY'
      },
      {
        name: 'Calderdale',
        id: 'CLD'
      },
      {
        name: 'Cambridgeshire',
        id: 'CAM'
      },
      {
        name: 'Camden',
        id: 'CMD'
      },
      {
        name: 'Cardiff',
        id: 'CRF'
      },
      {
        name: 'Carmarthenshire',
        id: 'CMN'
      },
      {
        name: 'Causeway Coast and Glens',
        id: 'CCG'
      },
      {
        name: 'Central Bedfordshire',
        id: 'CBF'
      },
      {
        name: 'Ceredigion',
        id: 'CGN'
      },
      {
        name: 'Cheshire East',
        id: 'CHE'
      },
      {
        name: 'Cheshire West and Chester',
        id: 'CHW'
      },
      {
        name: 'Clackmannanshire',
        id: 'CLK'
      },
      {
        name: 'Conwy',
        id: 'CWY'
      },
      {
        name: 'Cornwall',
        id: 'CON'
      },
      {
        name: 'Coventry',
        id: 'COV'
      },
      {
        name: 'Croydon',
        id: 'CRY'
      },
      {
        name: 'Cumbria',
        id: 'CMA'
      },
      {
        name: 'Darlington',
        id: 'DAL'
      },
      {
        name: 'Denbighshire',
        id: 'DEN'
      },
      {
        name: 'Derby',
        id: 'DER'
      },
      {
        name: 'Derbyshire',
        id: 'DBY'
      },
      {
        name: 'Derry and Strabane',
        id: 'DRS'
      },
      {
        name: 'Devon',
        id: 'DEV'
      },
      {
        name: 'Doncaster',
        id: 'DNC'
      },
      {
        name: 'Dorset',
        id: 'DOR'
      },
      {
        name: 'Dudley',
        id: 'DUD'
      },
      {
        name: 'Dumfries and Galloway',
        id: 'DGY'
      },
      {
        name: 'Dundee City',
        id: 'DND'
      },
      {
        name: 'Durham, County',
        id: 'DUR'
      },
      {
        name: 'Ealing',
        id: 'EAL'
      },
      {
        name: 'East Ayrshire',
        id: 'EAY'
      },
      {
        name: 'East Dunbartonshire',
        id: 'EDU'
      },
      {
        name: 'East Lothian',
        id: 'ELN'
      },
      {
        name: 'East Renfrewshire',
        id: 'ERW'
      },
      {
        name: 'East Riding of Yorkshire',
        id: 'ERY'
      },
      {
        name: 'East Sussex',
        id: 'ESX'
      },
      {
        name: 'Edinburgh, City of',
        id: 'EDH'
      },
      {
        name: 'Eilean Siar',
        id: 'ELS'
      },
      {
        name: 'Enfield',
        id: 'ENF'
      },
      {
        name: 'Essex',
        id: 'ESS'
      },
      {
        name: 'Falkirk',
        id: 'FAL'
      },
      {
        name: 'Fermanagh and Omagh',
        id: 'FMO'
      },
      {
        name: 'Fife',
        id: 'FIF'
      },
      {
        name: 'Flintshire',
        id: 'FLN'
      },
      {
        name: 'Gateshead',
        id: 'GAT'
      },
      {
        name: 'Glasgow City',
        id: 'GLG'
      },
      {
        name: 'Gloucestershire',
        id: 'GLS'
      },
      {
        name: 'Greenwich',
        id: 'GRE'
      },
      {
        name: 'Gwynedd',
        id: 'GWN'
      },
      {
        name: 'Hackney',
        id: 'HCK'
      },
      {
        name: 'Halton',
        id: 'HAL'
      },
      {
        name: 'Hammersmith and Fulham',
        id: 'HMF'
      },
      {
        name: 'Hampshire',
        id: 'HAM'
      },
      {
        name: 'Haringey',
        id: 'HRY'
      },
      {
        name: 'Harrow',
        id: 'HRW'
      },
      {
        name: 'Hartlepool',
        id: 'HPL'
      },
      {
        name: 'Havering',
        id: 'HAV'
      },
      {
        name: 'Herefordshire',
        id: 'HEF'
      },
      {
        name: 'Hertfordshire',
        id: 'HRT'
      },
      {
        name: 'Highland',
        id: 'HLD'
      },
      {
        name: 'Hillingdon',
        id: 'HIL'
      },
      {
        name: 'Hounslow',
        id: 'HNS'
      },
      {
        name: 'Inverclyde',
        id: 'IVC'
      },
      {
        name: 'Isle of Anglesey',
        id: 'AGY'
      },
      {
        name: 'Isle of Wight',
        id: 'IOW'
      },
      {
        name: 'Isles of Scilly',
        id: 'IOS'
      },
      {
        name: 'Islington',
        id: 'ISL'
      },
      {
        name: 'Kensington and Chelsea',
        id: 'KEC'
      },
      {
        name: 'Kent',
        id: 'KEN'
      },
      {
        name: 'Kingston upon Hull',
        id: 'KHL'
      },
      {
        name: 'Kingston upon Thames',
        id: 'KTT'
      },
      {
        name: 'Kirklees',
        id: 'KIR'
      },
      {
        name: 'Knowsley',
        id: 'KWL'
      },
      {
        name: 'Lambeth',
        id: 'LBH'
      },
      {
        name: 'Lancashire',
        id: 'LAN'
      },
      {
        name: 'Leeds',
        id: 'LDS'
      },
      {
        name: 'Leicester',
        id: 'LCE'
      },
      {
        name: 'Leicestershire',
        id: 'LEC'
      },
      {
        name: 'Lewisham',
        id: 'LEW'
      },
      {
        name: 'Lincolnshire',
        id: 'LIN'
      },
      {
        name: 'Lisburn and Castlereagh',
        id: 'LBC'
      },
      {
        name: 'Liverpool',
        id: 'LIV'
      },
      {
        name: 'London, City of',
        id: 'LND'
      },
      {
        name: 'Luton',
        id: 'LUT'
      },
      {
        name: 'Manchester',
        id: 'MAN'
      },
      {
        name: 'Medway',
        id: 'MDW'
      },
      {
        name: 'Merthyr Tydfil',
        id: 'MTY'
      },
      {
        name: 'Merton',
        id: 'MRT'
      },
      {
        name: 'Mid and East Antrim',
        id: 'MEA'
      },
      {
        name: 'Mid-Ulster',
        id: 'MUL'
      },
      {
        name: 'Middlesbrough',
        id: 'MDB'
      },
      {
        name: 'Midlothian',
        id: 'MLN'
      },
      {
        name: 'Milton Keynes',
        id: 'MIK'
      },
      {
        name: 'Monmouthshire',
        id: 'MON'
      },
      {
        name: 'Moray',
        id: 'MRY'
      },
      {
        name: 'Neath Port Talbot',
        id: 'NTL'
      },
      {
        name: 'Newcastle upon Tyne',
        id: 'NET'
      },
      {
        name: 'Newham',
        id: 'NWM'
      },
      {
        name: 'Newport',
        id: 'NWP'
      },
      {
        name: 'Newry, Mourne and Down',
        id: 'NMD'
      },
      {
        name: 'Norfolk',
        id: 'NFK'
      },
      {
        name: 'North Ayrshire',
        id: 'NAY'
      },
      {
        name: 'North East Lincolnshire',
        id: 'NEL'
      },
      {
        name: 'North Lanarkshire',
        id: 'NLK'
      },
      {
        name: 'North Lincolnshire',
        id: 'NLN'
      },
      {
        name: 'North Somerset',
        id: 'NSM'
      },
      {
        name: 'North Tyneside',
        id: 'NTY'
      },
      {
        name: 'North Yorkshire',
        id: 'NYK'
      },
      {
        name: 'Northamptonshire',
        id: 'NTH'
      },
      {
        name: 'Northumberland',
        id: 'NBL'
      },
      {
        name: 'Nottingham',
        id: 'NGM'
      },
      {
        name: 'Nottinghamshire',
        id: 'NTT'
      },
      {
        name: 'Oldham',
        id: 'OLD'
      },
      {
        name: 'Orkney Islands',
        id: 'ORK'
      },
      {
        name: 'Oxfordshire',
        id: 'OXF'
      },
      {
        name: 'Pembrokeshire',
        id: 'PEM'
      },
      {
        name: 'Perth and Kinross',
        id: 'PKN'
      },
      {
        name: 'Peterborough',
        id: 'PTE'
      },
      {
        name: 'Plymouth',
        id: 'PLY'
      },
      {
        name: 'Portsmouth',
        id: 'POR'
      },
      {
        name: 'Powys',
        id: 'POW'
      },
      {
        name: 'Reading',
        id: 'RDG'
      },
      {
        name: 'Redbridge',
        id: 'RDB'
      },
      {
        name: 'Redcar and Cleveland',
        id: 'RCC'
      },
      {
        name: 'Renfrewshire',
        id: 'RFW'
      },
      {
        name: 'Rhondda Cynon Taff',
        id: 'RCT'
      },
      {
        name: 'Richmond upon Thames',
        id: 'RIC'
      },
      {
        name: 'Rochdale',
        id: 'RCH'
      },
      {
        name: 'Rotherham',
        id: 'ROT'
      },
      {
        name: 'Rutland',
        id: 'RUT'
      },
      {
        name: 'Salford',
        id: 'SLF'
      },
      {
        name: 'Sandwell',
        id: 'SAW'
      },
      {
        name: 'Scottish Borders',
        id: 'SCB'
      },
      {
        name: 'Sefton',
        id: 'SFT'
      },
      {
        name: 'Sheffield',
        id: 'SHF'
      },
      {
        name: 'Shetland Islands',
        id: 'ZET'
      },
      {
        name: 'Shropshire',
        id: 'SHR'
      },
      {
        name: 'Slough',
        id: 'SLG'
      },
      {
        name: 'Solihull',
        id: 'SOL'
      },
      {
        name: 'Somerset',
        id: 'SOM'
      },
      {
        name: 'South Ayrshire',
        id: 'SAY'
      },
      {
        name: 'South Gloucestershire',
        id: 'SGC'
      },
      {
        name: 'South Lanarkshire',
        id: 'SLK'
      },
      {
        name: 'South Tyneside',
        id: 'STY'
      },
      {
        name: 'Southampton',
        id: 'STH'
      },
      {
        name: 'Southend-on-Sea',
        id: 'SOS'
      },
      {
        name: 'Southwark',
        id: 'SWK'
      },
      {
        name: 'St. Helens',
        id: 'SHN'
      },
      {
        name: 'Staffordshire',
        id: 'STS'
      },
      {
        name: 'Stirling',
        id: 'STG'
      },
      {
        name: 'Stockport',
        id: 'SKP'
      },
      {
        name: 'Stockton-on-Tees',
        id: 'STT'
      },
      {
        name: 'Stoke-on-Trent',
        id: 'STE'
      },
      {
        name: 'Suffolk',
        id: 'SFK'
      },
      {
        name: 'Sunderland',
        id: 'SND'
      },
      {
        name: 'Surrey',
        id: 'SRY'
      },
      {
        name: 'Sutton',
        id: 'STN'
      },
      {
        name: 'Swansea',
        id: 'SWA'
      },
      {
        name: 'Swindon',
        id: 'SWD'
      },
      {
        name: 'Tameside',
        id: 'TAM'
      },
      {
        name: 'Telford and Wrekin',
        id: 'TFW'
      },
      {
        name: 'Thurrock',
        id: 'THR'
      },
      {
        name: 'Torbay',
        id: 'TOB'
      },
      {
        name: 'Torfaen',
        id: 'TOF'
      },
      {
        name: 'Tower Hamlets',
        id: 'TWH'
      },
      {
        name: 'Trafford',
        id: 'TRF'
      },
      {
        name: 'Vale of Glamorgan, The',
        id: 'VGL'
      },
      {
        name: 'Wakefield',
        id: 'WKF'
      },
      {
        name: 'Walsall',
        id: 'WLL'
      },
      {
        name: 'Waltham Forest',
        id: 'WFT'
      },
      {
        name: 'Wandsworth',
        id: 'WND'
      },
      {
        name: 'Warrington',
        id: 'WRT'
      },
      {
        name: 'Warwickshire',
        id: 'WAR'
      },
      {
        name: 'West Berkshire',
        id: 'WBK'
      },
      {
        name: 'West Dunbartonshire',
        id: 'WDU'
      },
      {
        name: 'West Lothian',
        id: 'WLN'
      },
      {
        name: 'West Sussex',
        id: 'WSX'
      },
      {
        name: 'Westminster',
        id: 'WSM'
      },
      {
        name: 'Wigan',
        id: 'WGN'
      },
      {
        name: 'Wiltshire',
        id: 'WIL'
      },
      {
        name: 'Windsor and Maidenhead',
        id: 'WNM'
      },
      {
        name: 'Wirral',
        id: 'WRL'
      },
      {
        name: 'Wokingham',
        id: 'WOK'
      },
      {
        name: 'Wolverhampton',
        id: 'WLV'
      },
      {
        name: 'Worcestershire',
        id: 'WOR'
      },
      {
        name: 'Wrexham',
        id: 'WRX'
      },
      {
        name: 'York',
        id: 'YOR'
      }
    ]
  },
  {
    countryName: 'United States',
    countryShortCode: 'US',
    regions: [
      {
        name: 'Alabama',
        id: 'AL'
      },
      {
        name: 'Alaska',
        id: 'AK'
      },
      {
        name: 'American Samoa',
        id: 'AS'
      },
      {
        name: 'Arizona',
        id: 'AZ'
      },
      {
        name: 'Arkansas',
        id: 'AR'
      },
      {
        name: 'California',
        id: 'CA'
      },
      {
        name: 'Colorado',
        id: 'CO'
      },
      {
        name: 'Connecticut',
        id: 'CT'
      },
      {
        name: 'Delaware',
        id: 'DE'
      },
      {
        name: 'District of Columbia',
        id: 'DC'
      },
      {
        name: 'Micronesia',
        id: 'FM'
      },
      {
        name: 'Florida',
        id: 'FL'
      },
      {
        name: 'Georgia',
        id: 'GA'
      },
      {
        name: 'Guam',
        id: 'GU'
      },
      {
        name: 'Hawaii',
        id: 'HI'
      },
      {
        name: 'Idaho',
        id: 'ID'
      },
      {
        name: 'Illinois',
        id: 'IL'
      },
      {
        name: 'Indiana',
        id: 'IN'
      },
      {
        name: 'Iowa',
        id: 'IA'
      },
      {
        name: 'Kansas',
        id: 'KS'
      },
      {
        name: 'Kentucky',
        id: 'KY'
      },
      {
        name: 'Louisiana',
        id: 'LA'
      },
      {
        name: 'Maine',
        id: 'ME'
      },
      {
        name: 'Marshall Islands',
        id: 'MH'
      },
      {
        name: 'Maryland',
        id: 'MD'
      },
      {
        name: 'Massachusetts',
        id: 'MA'
      },
      {
        name: 'Michigan',
        id: 'MI'
      },
      {
        name: 'Minnesota',
        id: 'MN'
      },
      {
        name: 'Mississippi',
        id: 'MS'
      },
      {
        name: 'Missouri',
        id: 'MO'
      },
      {
        name: 'Montana',
        id: 'MT'
      },
      {
        name: 'Nebraska',
        id: 'NE'
      },
      {
        name: 'Nevada',
        id: 'NV'
      },
      {
        name: 'New Hampshire',
        id: 'NH'
      },
      {
        name: 'New Jersey',
        id: 'NJ'
      },
      {
        name: 'New Mexico',
        id: 'NM'
      },
      {
        name: 'New York',
        id: 'NY'
      },
      {
        name: 'North Carolina',
        id: 'NC'
      },
      {
        name: 'North Dakota',
        id: 'ND'
      },
      {
        name: 'Northern Mariana Islands',
        id: 'MP'
      },
      {
        name: 'Ohio',
        id: 'OH'
      },
      {
        name: 'Oklahoma',
        id: 'OK'
      },
      {
        name: 'Oregon',
        id: 'OR'
      },
      {
        name: 'Palau',
        id: 'PW'
      },
      {
        name: 'Pennsylvania',
        id: 'PA'
      },
      {
        name: 'Puerto Rico',
        id: 'PR'
      },
      {
        name: 'Rhode Island',
        id: 'RI'
      },
      {
        name: 'South Carolina',
        id: 'SC'
      },
      {
        name: 'South Dakota',
        id: 'SD'
      },
      {
        name: 'Tennessee',
        id: 'TN'
      },
      {
        name: 'Texas',
        id: 'TX'
      },
      {
        name: 'Utah',
        id: 'UT'
      },
      {
        name: 'Vermont',
        id: 'VT'
      },
      {
        name: 'Virgin Islands',
        id: 'VI'
      },
      {
        name: 'Virginia',
        id: 'VA'
      },
      {
        name: 'Washington',
        id: 'WA'
      },
      {
        name: 'West Virginia',
        id: 'WV'
      },
      {
        name: 'Wisconsin',
        id: 'WI'
      },
      {
        name: 'Wyoming',
        id: 'WY'
      },
      {
        name: 'Armed Forces Americas',
        id: 'AA'
      },
      {
        name: 'Armed Forces Europe, Canada, Africa and Middle East',
        id: 'AE'
      },
      {
        name: 'Armed Forces Pacific',
        id: 'AP'
      }
    ]
  },
  {
    countryName: 'United States Minor Outlying Islands',
    countryShortCode: 'UM',
    regions: [
      {
        name: 'Baker Island',
        id: '81'
      },
      {
        name: 'Howland Island',
        id: '84'
      },
      {
        name: 'Jarvis Island',
        id: '86'
      },
      {
        name: 'Johnston Atoll',
        id: '67'
      },
      {
        name: 'Kingman Reef',
        id: '89'
      },
      {
        name: 'Midway Islands',
        id: '71'
      },
      {
        name: 'Navassa Island',
        id: '76'
      },
      {
        name: 'Palmyra Atoll',
        id: '95'
      },
      {
        name: 'Wake Island',
        id: '79'
      },
      {
        name: 'Bajo Nuevo Bank',
        id: 'BN'
      },
      {
        name: 'Serranilla Bank',
        id: 'SB'
      }
    ]
  },
  {
    countryName: 'Uruguay',
    countryShortCode: 'UY',
    regions: [
      {
        name: 'Artigas',
        id: 'AR'
      },
      {
        name: 'Canelones',
        id: 'CA'
      },
      {
        name: 'Cerro Largo',
        id: 'CL'
      },
      {
        name: 'Colonia',
        id: 'CO'
      },
      {
        name: 'Durazno',
        id: 'DU'
      },
      {
        name: 'Flores',
        id: 'FS'
      },
      {
        name: 'Florida',
        id: 'FD'
      },
      {
        name: 'Lavalleja',
        id: 'LA'
      },
      {
        name: 'Maldonado',
        id: 'MA'
      },
      {
        name: 'Montevideo',
        id: 'MO'
      },
      {
        name: 'Paysandú',
        id: 'PA'
      },
      {
        name: 'Río Negro',
        id: 'RN'
      },
      {
        name: 'Rivera',
        id: 'RV'
      },
      {
        name: 'Rocha',
        id: 'RO'
      },
      {
        name: 'Salto',
        id: 'SA'
      },
      {
        name: 'San José',
        id: 'SJ'
      },
      {
        name: 'Soriano',
        id: 'SO'
      },
      {
        name: 'Tacuarembó',
        id: 'TA'
      },
      {
        name: 'Treinta y Tres',
        id: 'TT'
      }
    ]
  },
  {
    countryName: 'Uzbekistan',
    countryShortCode: 'UZ',
    regions: [
      {
        name: 'Toshkent shahri',
        id: 'TK'
      },
      {
        name: 'Andijon',
        id: 'AN'
      },
      {
        name: 'Buxoro',
        id: 'BU'
      },
      {
        name: 'Farg‘ona',
        id: 'FA'
      },
      {
        name: 'Jizzax',
        id: 'JI'
      },
      {
        name: 'Namangan',
        id: 'NG'
      },
      {
        name: 'Navoiy',
        id: 'NW'
      },
      {
        name: 'Qashqadaryo (Qarshi)',
        id: 'QA'
      },
      {
        name: 'Samarqand',
        id: 'SA'
      },
      {
        name: 'Sirdaryo (Guliston)',
        id: 'SI'
      },
      {
        name: 'Surxondaryo (Termiz)',
        id: 'SU'
      },
      {
        name: 'Toshkent wiloyati',
        id: 'TO'
      },
      {
        name: 'Xorazm (Urganch)',
        id: 'XO'
      },
      {
        name: 'Qoraqalpog‘iston Respublikasi (Nukus)',
        id: 'QR'
      }
    ]
  },
  {
    countryName: 'Vanuatu',
    countryShortCode: 'VU',
    regions: [
      {
        name: 'Malampa',
        id: 'MAP'
      },
      {
        name: 'Pénama',
        id: 'PAM'
      },
      {
        name: 'Sanma',
        id: 'SAM'
      },
      {
        name: 'Shéfa',
        id: 'SEE'
      },
      {
        name: 'Taféa',
        id: 'TAE'
      },
      {
        name: 'Torba',
        id: 'TOB'
      }
    ]
  },
  {
    countryName: 'Venezuela, Bolivarian Republic of',
    countryShortCode: 'VE',
    regions: [
      {
        name: 'Dependencias Federales',
        id: 'W'
      },
      {
        name: 'Distrito Federal',
        id: 'A'
      },
      {
        name: 'Amazonas',
        id: 'Z'
      },
      {
        name: 'Anzoátegui',
        id: 'B'
      },
      {
        name: 'Apure',
        id: 'C'
      },
      {
        name: 'Aragua',
        id: 'D'
      },
      {
        name: 'Barinas',
        id: 'E'
      },
      {
        name: 'Bolívar',
        id: 'F'
      },
      {
        name: 'Carabobo',
        id: 'G'
      },
      {
        name: 'Cojedes',
        id: 'H'
      },
      {
        name: 'Delta Amacuro',
        id: 'Y'
      },
      {
        name: 'Falcón',
        id: 'I'
      },
      {
        name: 'Guárico',
        id: 'J'
      },
      {
        name: 'Lara',
        id: 'K'
      },
      {
        name: 'Mérida',
        id: 'L'
      },
      {
        name: 'Miranda',
        id: 'M'
      },
      {
        name: 'Monagas',
        id: 'N'
      },
      {
        name: 'Nueva Esparta',
        id: 'O'
      },
      {
        name: 'Portuguesa',
        id: 'P'
      },
      {
        name: 'Sucre',
        id: 'R'
      },
      {
        name: 'Táchira',
        id: 'S'
      },
      {
        name: 'Trujillo',
        id: 'T'
      },
      {
        name: 'Vargas',
        id: 'X'
      },
      {
        name: 'Yaracuy',
        id: 'U'
      },
      {
        name: 'Zulia',
        id: 'V'
      }
    ]
  },
  {
    countryName: 'Vietnam',
    countryShortCode: 'VN',
    regions: [
      {
        name: 'An Giang',
        id: '44'
      },
      {
        name: 'Bà Rịa - Vũng Tàu',
        id: '43'
      },
      {
        name: 'Bình Dương',
        id: '57'
      },
      {
        name: 'Bình Phước',
        id: '58'
      },
      {
        name: 'Bình Định',
        id: '31'
      },
      {
        name: 'Bình Thuận',
        id: '40'
      },
      {
        name: 'Bạc Liêu',
        id: '55'
      },
      {
        name: 'Bắc Giang',
        id: '54'
      },
      {
        name: 'Bắc Kạn',
        id: '53'
      },
      {
        name: 'Bắc Ninh',
        id: '56'
      },
      {
        name: 'Bến Tre',
        id: '50'
      },
      {
        name: 'Cao Bằng',
        id: '04'
      },
      {
        name: 'Cà Mau',
        id: '59'
      },
      {
        name: 'Đắk Lắk',
        id: '33'
      },
      {
        name: 'Đắk Nông',
        id: '72'
      },
      {
        name: 'Điện Biên',
        id: '71'
      },
      {
        name: 'Đồng Nai',
        id: '39'
      },
      {
        name: 'Đồng Tháp',
        id: '45'
      },
      {
        name: 'Gia Lai',
        id: '30'
      },
      {
        name: 'Hà Giang',
        id: '03'
      },
      {
        name: 'Hà Nam',
        id: '63'
      },
      {
        name: 'Hà Tây',
        id: '15'
      },
      {
        name: 'Hà Tĩnh',
        id: '23'
      },
      {
        name: 'Hải Dương',
        id: '61'
      },
      {
        name: 'Hậu Giang',
        id: '73'
      },
      {
        name: 'Hòa Bình',
        id: '14'
      },
      {
        name: 'Hưng Yên',
        id: '66'
      },
      {
        name: 'Khánh Hòa',
        id: '34'
      },
      {
        name: 'Kiên Giang',
        id: '47'
      },
      {
        name: 'Kon Tum',
        id: '28'
      },
      {
        name: 'Lai Châu',
        id: '01'
      },
      {
        name: 'Lâm Đồng',
        id: '35'
      },
      {
        name: 'Lạng Sơn',
        id: '09'
      },
      {
        name: 'Lào Cai',
        id: '02'
      },
      {
        name: 'Long An',
        id: '41'
      },
      {
        name: 'Nam Định',
        id: '67'
      },
      {
        name: 'Nghệ An',
        id: '22'
      },
      {
        name: 'Ninh Bình',
        id: '18'
      },
      {
        name: 'Ninh Thuận',
        id: '36'
      },
      {
        name: 'Phú Thọ',
        id: '68'
      },
      {
        name: 'Phú Yên',
        id: '32'
      },
      {
        name: 'Quảng Bình',
        id: '24'
      },
      {
        name: 'Quảng Nam',
        id: '27'
      },
      {
        name: 'Quảng Ngãi',
        id: '29'
      },
      {
        name: 'Quảng Ninh',
        id: '13'
      },
      {
        name: 'Quảng Trị',
        id: '25'
      },
      {
        name: 'Sóc Trăng',
        id: '52'
      },
      {
        name: 'Sơn La',
        id: '05'
      },
      {
        name: 'Tây Ninh',
        id: '37'
      },
      {
        name: 'Thái Bình',
        id: '20'
      },
      {
        name: 'Thái Nguyên',
        id: '69'
      },
      {
        name: 'Thanh Hóa',
        id: '21'
      },
      {
        name: 'Thừa Thiên–Huế',
        id: '26'
      },
      {
        name: 'Tiền Giang',
        id: '46'
      },
      {
        name: 'Trà Vinh',
        id: '51'
      },
      {
        name: 'Tuyên Quang',
        id: '07'
      },
      {
        name: 'Vĩnh Long',
        id: '49'
      },
      {
        name: 'Vĩnh Phúc',
        id: '70'
      },
      {
        name: 'Yên Bái',
        id: '06'
      },
      {
        name: 'Cần Thơ',
        id: 'CT'
      },
      {
        name: 'Đà Nẵng',
        id: 'DN'
      },
      {
        name: 'Hà Nội',
        id: 'HN'
      },
      {
        name: 'Hải Phòng',
        id: 'HP'
      },
      {
        name: 'Hồ Chí Minh (Sài Gòn)',
        id: 'SG'
      }
    ]
  },
  {
    countryName: 'Virgin Islands, British',
    countryShortCode: 'VG',
    regions: [
      {
        name: 'Anegada',
        id: 'ANG'
      },
      {
        name: 'Jost Van Dyke',
        id: 'JVD'
      },
      {
        name: 'Tortola',
        id: 'TTA'
      },
      {
        name: 'Virgin Gorda',
        id: 'VGD'
      }
    ]
  },
  {
    countryName: 'Virgin Islands, U.S.',
    countryShortCode: 'VI',
    regions: [
      {
        name: 'St. Thomas',
        id: 'STH'
      },
      {
        name: 'St. John',
        id: 'SJO'
      },
      {
        name: 'St. Croix',
        id: 'SCR'
      }
    ]
  },
  {
    countryName: 'Wallis and Futuna',
    countryShortCode: 'WF',
    regions: [
      {
        name: 'Alo',
        id: 'ALO'
      },
      {
        name: 'Sigave',
        id: 'SIG'
      },
      {
        name: 'Wallis',
        id: 'WAL'
      }
    ]
  },
  {
    countryName: 'Western Sahara',
    countryShortCode: 'EH',
    regions: [
      {
        name: 'Es Smara',
        id: 'ESM'
      },
      {
        name: 'Boujdour',
        id: 'BOD'
      },
      {
        name: 'Laâyoune',
        id: 'LAA'
      },
      {
        name: 'Aousserd',
        id: 'AOU'
      },
      {
        name: 'Oued ed Dahab',
        id: 'OUD'
      }
    ]
  },
  {
    countryName: 'Yemen',
    countryShortCode: 'YE',
    regions: [
      {
        name: 'Abyān',
        id: 'AB'
      },
      {
        name: '\'Adan',
        id: 'AD'
      },
      {
        name: 'Aḑ Ḑāli\'',
        id: 'DA'
      },
      {
        name: 'Al Bayḑā\'',
        id: 'BA'
      },
      {
        name: 'Al Ḩudaydah',
        id: 'HU'
      },
      {
        name: 'Al Jawf',
        id: 'JA'
      },
      {
        name: 'Al Mahrah',
        id: 'MR'
      },
      {
        name: 'Al Maḩwīt',
        id: 'MW'
      },
      {
        name: '\'Amrān',
        id: 'AM'
      },
      {
        name: 'Dhamār',
        id: 'DH'
      },
      {
        name: 'Ḩaḑramawt',
        id: 'HD'
      },
      {
        name: 'Ḩajjah',
        id: 'HJ'
      },
      {
        name: 'Ibb',
        id: 'IB'
      },
      {
        name: 'Laḩij',
        id: 'LA'
      },
      {
        name: 'Ma\'rib',
        id: 'MA'
      },
      {
        name: 'Raymah',
        id: 'RA'
      },
      {
        name: 'Şā‘dah',
        id: 'SD'
      },
      {
        name: 'Şan‘ā\'',
        id: 'SN'
      },
      {
        name: 'Shabwah',
        id: 'SH'
      },
      {
        name: 'Tā‘izz',
        id: 'TA'
      }
    ]
  },
  {
    countryName: 'Zambia',
    countryShortCode: 'ZM',
    regions: [
      {
        name: 'Central',
        id: '02'
      },
      {
        name: 'Copperbelt',
        id: '08'
      },
      {
        name: 'Eastern',
        id: '03'
      },
      {
        name: 'Luapula',
        id: '04'
      },
      {
        name: 'Lusaka',
        id: '09'
      },
      {
        name: 'Northern',
        id: '05'
      },
      {
        name: 'North-Western',
        id: '06'
      },
      {
        name: 'Southern',
        id: '07'
      },
      {
        name: 'Western',
        id: '01'
      }
    ]
  },
  {
    countryName: 'Zimbabwe',
    countryShortCode: 'ZW',
    regions: [
      {
        name: 'Bulawayo',
        id: 'BU'
      },
      {
        name: 'Harare',
        id: 'HA'
      },
      {
        name: 'Manicaland',
        id: 'MA'
      },
      {
        name: 'Mashonaland Central',
        id: 'MC'
      },
      {
        name: 'Mashonaland East',
        id: 'ME'
      },
      {
        name: 'Mashonaland West',
        id: 'MW'
      },
      {
        name: 'Masvingo',
        id: 'MV'
      },
      {
        name: 'Matabeleland North',
        id: 'MN'
      },
      {
        name: 'Matabeleland South',
        id: 'MS'
      },
      {
        name: 'Midlands',
        id: 'MI'
      }
    ]
  }
]
