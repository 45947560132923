<script setup>
import { computed, onMounted, ref, watch } from 'vue'
import dayjs from 'dayjs'
import { ArrowPathIcon, CheckIcon, XMarkIcon, } from '@heroicons/vue/24/solid'
import { useRoute } from 'vue-router'
import { useI18n } from 'vue-i18n'
import { useHead } from '@unhead/vue'
import BaseTable from '@/components/base/BaseTable.vue'
import PageHeading from '@/components/common/PageHeading.vue'
import RequestFilters from '@/components/partials/RequestFilters.vue'
import { campaignService } from '@/services/campaign'
import { requestService } from '@/services/requests'
import {
  formatRequestDeniedReason,
  formatRequestDeniedReasonShorted
} from '@/helpers/format-request-denied-reason'
import { useUserStore } from '@/stores/user'
import RequestDetails from '@/components/partials/RequestDetails.vue'
import Button from '@/components/ui/button/Button.vue'
import EmptyState from '@/components/common/EmptyState.vue'
import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  BreadcrumbList,
  BreadcrumbPage,
  BreadcrumbSeparator,
} from '@/components/ui/breadcrumb'
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger
} from '@/components/ui/tooltip'
import { getBrowserIcon, getDeviceIcon, getOsIcon } from '@/helpers/requests'
import { formatDate } from '@/helpers/date'

const { t, locale } = useI18n()
useHead({
  title: t('pages.requests.title')
})
const route = useRoute()
const userStore = useUserStore()
const cursor = ref(undefined)

const isLoading = ref(false)
const hasError = ref(false)
const requests = ref([])
const campaigns = ref([])
const isLoadingCampaigns = ref(false)

const requestTableHeader = [
  {
    label: t('common.created_at'),
    value: 'created_at',
    format: (value) => {
      return formatDate(value, {
        locale: locale.value
      })
    }
  },
  {
    label: t('common.status'),
    value: 'denied_reason'
  },
  {
    label: t('campaign'),
    value: 'campaign'
  },
  {
    label: t('common.page'),
    value: 'page'
  },
  ...(userStore.requestRaw
    ? [
        {
          label: 'IP',
          value: 'ip'
        }
      ]
    : []),
  ...(userStore.requestRaw
    ? [
        {
          label: 'ISP',
          value: 'isp'
        }
      ]
    : []),
  {
    label: t('common.device'),
    value: 'device'
  },
  {
    label: 'os',
    value: 'os'
  },
  {
    label: t('common.browser'),
    value: 'browser'
  },
  {
    label: t('common.location'),
    value: 'country'
  },
  {
    label: t('common.domain'),
    value: 'domain'
  },
  {
    label: t('common.referrer'),
    value: 'referer'
  },
]

const searchParams = computed(() => {
  const payload = {
    limit: route.query.limit
  }

  payload.campaign = route.query.campaign
  payload.allowed = route.query.allowed
  payload.isp = route.query.isp
  payload.device = route.query.device
  payload.domain = route.query.domain
  payload.referer = route.query.referer
  payload.country = route.query.country

  if (route.query?.start_date) {
    payload.start_date = dayjs(route.query?.start_date).unix() * 1000
  }

  if (route.query?.end_date) {
    payload.end_date = dayjs(route.query?.end_date).unix() * 1000
  }

  if (route.query.cursor) {
    payload.cursor = route.query.cursor
  }

  return payload
})

/**
 * @param {Record<string, any>} params
 */
async function fetchRequests(params) {
  isLoading.value = true
  try {
    const { data } = await requestService.findAll(params)

    if (!params.cursor) {
      requests.value = data.data
    } else {
      requests.value = [...requests.value, ...data.data]
    }
  } catch (error) {
    hasError.value = true
  } finally {
    isLoading.value = false
  }
}

async function fetchCampaigns(params = {
  page: 1,
  limit: 100
}) {
  isLoadingCampaigns.value = true

  try {
    const { data } = await campaignService.findAll(params)
    campaigns.value = data.data
  } catch (error) {
    campaigns.value = []
  } finally {
    isLoadingCampaigns.value = false
  }
}

function findCampaignById(id) {
  return campaigns.value.find(item => Number(item.id) === id)
}

onMounted(() => {
  fetchCampaigns({
    page: 1,
    limit: 100
  })
  fetchRequests(searchParams.value)
})

watch(
  () => [route.query],
  () => {
    fetchRequests(searchParams.value)
  },
  {
    deep: true
  }
)

watch(
  () => cursor.value,
  () => {
    fetchRequests(searchParams.value)
  },
  {
    deep: true
  }
)
</script>

<template>
  <div class="space-y-5">
    <div class="space-y-2">
      <Breadcrumb>
        <BreadcrumbList>
          <BreadcrumbItem>
            <BreadcrumbLink as-child>
              <RouterLink to="/">
                {{ $t('common.dashboard') }}
              </RouterLink>
            </BreadcrumbLink>
          </BreadcrumbItem>

          <BreadcrumbSeparator />

          <BreadcrumbItem>
            <BreadcrumbPage>
              {{ $t('pages.requests.breadcrumb.requests') }}
            </BreadcrumbPage>
          </BreadcrumbItem>
        </BreadcrumbList>
      </Breadcrumb>

      <PageHeading
        :title="$t('pages.requests.title')"
      >
        <template #actions>
          <Button
            variant="outline"
            @click="fetchRequests(searchParams)"
          >
            <ArrowPathIcon class="mr-2 size-5" />
            {{ $t('reload') }}
          </Button>
          <RequestFilters :campaigns="campaigns" />
        </template>
      </PageHeading>
    </div>

    <div>
      <BaseTable
        :headers="requestTableHeader"
        :columns="requests"
        :loading="isLoading"
        :error="hasError"
      >
        <template #col-denied_reason="{ column }">
          <div class="flex gap-2">
            <CheckIcon
              v-if="column.allowed"
              class="size-5 text-success"
            />
            <TooltipProvider
              :disabled="column.allowed"
            >
              <Tooltip>
                <TooltipTrigger class="flex gap-2">
                  <XMarkIcon
                    v-if="!column.allowed"
                    class="size-5 text-destructive"
                  />
                  <span
                    v-if="!column.allowed"
                    class="text-destructive"
                  >{{
                    formatRequestDeniedReasonShorted(column.denied_reason, $t)
                  }}</span>
                </TooltipTrigger>
                <TooltipContent>
                  <p>
                    {{ formatRequestDeniedReason(column.denied_reason, $t)
                      ?? column.denied_reason }}
                  </p>
                </TooltipContent>
              </Tooltip>
            </TooltipProvider>
          </div>
        </template>
        <template #col-campaign="{ row }">
          <router-link
            class="text-blue-500 underline"
            :to="{ name: 'Campaign', params: { id: row.id } }"
          >
            {{ findCampaignById(row.id)?.name }}
          </router-link>
        </template>
        <template #col-ip="{ column }">
          <div class="flex flex-col gap-2">
            <span>{{ column?.ip }}</span>
            <span class="text-xs">{{ column?.isp }}</span>
          </div>
        </template>
        <template #col-device="{ column }">
          <component
            :is="getDeviceIcon(column.device)"
            class="size-6"
          />
        </template>
        <template #col-browser="{ column }">
          <component
            :is="getBrowserIcon(column.browser)"
            class="size-6"
          />
        </template>
        <template #col-os="{ column }">
          <component
            :is="getOsIcon(column.os)"
            class="size-6"
          />
        </template>
        <template #col-country="{ column }">
          <div class="flex items-center">
            <img
              class="inline-block w-10"
              :src="`/img/flags/${column.country?.toLowerCase()}.svg`"
              :alt="column.country"
              loading="lazy"
            >
          </div>
        </template>
        <template #expand="{ column }">
          <RequestDetails :request="column" />
        </template>
        <template #pagination>
          <Button
            class="w-full"
            :loading="isLoading"
            :disabled="!requests.length"
            variant="outline"
            @click="$router.push({ query: {
              ...$route.query,
              cursor: requests[requests.length - 1].created_at,
            } })"
          >
            {{ $t('load_more') }}
          </Button>
        </template>
        <template #empty>
          <EmptyState
            :title="$t('pages.requests.empty_state.title')"
            :description="$t('pages.requests.empty_state.description')"
          />
        </template>
      </BaseTable>
    </div>
  </div>
</template>
