import ReportsView from '@/views/ReportsView.vue'

export default [
  {
    path: '/reports',
    name: 'Reports',
    component: ReportsView,
    meta: {
      layout: 'default',
      requiresAuth: true,
      requiredCloakingSubscription: true
    }
  }
]
