import { isLocal } from '@/helpers/is-local'

export function clarity() {
  function setup() {
    if (isLocal) {
      console.log('Clarity disabled in local environment')
      return
    }

    const scriptContent = `(function(c,l,a,r,i,t,y){
        c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)};
        t=l.createElement(r);t.async=1;t.src="https://www.clarity.ms/tag/"+i;
        y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y);
    })(window, document, "clarity", "script", "fh9218g002");`

    const script = document.createElement('script')
    script.innerHTML = scriptContent
    document.head.appendChild(script)
  }

  function setEmail(email) {
    if (isLocal) {
      return
    }
    window.clarity('identify', email)
  }

  return {
    setup,
    setEmail
  }
}
