import Invoices from '@/views/settings/Invoices.vue'
import PaymentMethods from '@/views/settings/PaymentMethods.vue'
import Profile from '@/views/settings/Profile.vue'
import SettingsTab from '@/views/settings/SettingsTab.vue'
import Subscription from '@/views/settings/subscription/Subscription.vue'
import UpgradeView from '@/views/settings/subscription/UpgradeView.vue'

export default [
  {
    path: '/settings',
    component: SettingsTab,
    name: 'Settings',
    meta: {
      layout: 'default',
      requiresAuth: true
    },
    redirect: {
      name: 'Profile'
    },
    children: [
      {
        name: 'Profile',
        path: '/settings/profile',
        component: Profile,
        meta: {
          layout: 'default',
          requiresAuth: true
        }
      },
      {
        path: '/settings/subscriptions',
        name: 'Subscription',
        component: Subscription,
        meta: {
          layout: 'default',
          requiresAuth: true
        }
      },

      {
        path: '/settings/payments',
        name: 'Invoices',
        component: Invoices,
        meta: {
          layout: 'default',
          requiresAuth: true
        }
      },
      {
        path: '/settings/payment-method',
        name: 'PaymentMethod',
        component: PaymentMethods,
        meta: {
          layout: 'default',
          requiresAuth: true
        }
      }
    ]
  },
  {
    path: '/settings/subscriptions/:id/upgrade',
    name: 'upgrade',
    component: UpgradeView,
    meta: {
      layout: 'default',
      requiresAuth: true,
      requiredSubscription: true
    },
    props: true
  },
]
