<script setup>
import { ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { useHead } from '@unhead/vue'
import { toast } from 'vue-sonner'
import PageHeading from '@/components/common/PageHeading.vue'
import { wait } from '@/helpers/wait.js'
import Button from '@/components/ui/button/Button.vue'
import Label from '@/components/ui/label/Label.vue'
import Textarea from '@/components/ui/textarea/Textarea.vue'
import Field from '@/components/ui/field/Field.vue'
import { Card, CardContent, CardFooter } from '@/components/ui/card'
import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  BreadcrumbList,
  BreadcrumbPage,
  BreadcrumbSeparator,
} from '@/components/ui/breadcrumb'

const { t } = useI18n()
useHead({
  title: t('pages.plugins.crypto_text.title')
})

const result = ref(null)
const isLoading = ref(false)

async function submitForm(event) {
  const formData = new FormData(event.target)
  const text = formData.get('text')

  isLoading.value = true
  await wait(2000)
  isLoading.value = false

  result.value = encryptTextV1(text)

  // copy to clipboard
  await copyToClipboard(result.value)
}

function encryptTextV1(text) {
  let a = ''
  const c = text.split('')
  for (let b = 0; c.length !== b; b += 1) {
    const d = c
    switch (d[b]) {
      case 'a':
        a += '\u0430'
        break
      case 'c':
        a += '\u0441'
        break
      case 'e':
        a += '\u0435'
        break
      case 'i':
        a += '\u0456'
        break
      case 'j':
        a += '\u0458'
        break
      case 'o':
        a += '\u043E'
        break
      case 'p':
        a += '\u0440'
        break
      case 's':
        a += '\u0455'
        break
      case 'x':
        a += '\u0445'
        break
      case 'y':
        a += '\u0443'
        break
      case 'A':
        a += '\u0410'
        break
      case 'B':
        a += '\u0412'
        break
      case 'C':
        a += '\u0421'
        break
      case 'E':
        a += '\u0415'
        break
      case 'H':
        a += '\u041D'
        break
      case 'I':
        a += 'I'
        break
      case 'K':
        a += '\u039A'
        break
      case 'M':
        a += '\u041C'
        break
      case 'N':
        a += '\u039D'
        break
      case 'O':
        a += '\u041E'
        break
      case 'P':
        a += '\u0420'
        break
      case 'S':
        a += '\u0405'
        break
      case 'T':
        a += '\u0422'
        break
      case 'X':
        a += '\u0425'
        break
      case 'Y':
        a += '\u03A5'
        break
      case 'Z':
        a += '\u0396'
        break
      case ' ':
        a += ''
      default:
        a += d[b]
    }
  }
  return a
}

async function copyToClipboard(text) {
  try {
    await navigator.clipboard.writeText(text)

    toast.success(t('pages.plugins.crypto_text.copied'))
  } catch (error) {
    console.error(error)
    toast.error(t('pages.plugins.crypto_text.error'))
  }
}
</script>

<template>
  <div class="space-y-5">
    <div class="space-y-2">
      <Breadcrumb>
        <BreadcrumbList>
          <BreadcrumbItem>
            <BreadcrumbLink as-child>
              <RouterLink to="/">
                {{ $t('common.dashboard') }}
              </RouterLink>
            </BreadcrumbLink>
          </BreadcrumbItem>

          <BreadcrumbSeparator />

          <BreadcrumbItem>
            <BreadcrumbLink>
              <BreadcrumbLink as-child>
                <RouterLink to="/plugins">
                  {{
                    $t('pages.plugins.title')
                  }}
                </RouterLink>
              </BreadcrumbLink>
            </BreadcrumbLink>
          </BreadcrumbItem>

          <BreadcrumbSeparator />

          <BreadcrumbItem>
            <BreadcrumbPage>
              {{
                $t('pages.plugins.crypto_text.title')
              }}
            </BreadcrumbPage>
          </BreadcrumbItem>
        </BreadcrumbList>
      </Breadcrumb>

      <PageHeading
        :title="$t('pages.plugins.crypto_text.title')"
      />
    </div>

    <form @submit.prevent="submitForm">
      <fieldset
        class="space-y-8"
        :disabled="isLoading"
      >
        <Card>
          <CardContent>
            <div>
              <div>
                <Field>
                  <Label>
                    {{ $t('pages.plugins.crypto_text.text') }}
                  </Label>
                  <Textarea
                    id="text"
                    required
                    name="text"
                    rows="10"
                  />
                </Field>
              </div>
              <ul class="mt-2 list-inside list-disc text-sm text-muted-foreground">
                <li>
                  {{ $t('pages.plugins.crypto_text.recommendation1') }}
                </li>
                <li>
                  {{ $t('pages.plugins.crypto_text.recommendation2') }}
                </li>
              </ul>
            </div>
          </CardContent>

          <CardFooter>
            <Button
              :loading="isLoading"
              type="submit"
            >
              {{ $t('pages.plugins.crypto_text.button') }}
            </Button>
          </CardFooter>
        </Card>
      </fieldset>
    </form>

    <Card v-if="result">
      <CardContent>
        <div>
          <Label for="result">
            {{ $t('common.result') }}
          </Label>
          <div class="mt-2">
            <div
              class="select-none rounded-md border border-border px-2 py-5"
            >
              <span class="text-sm text-muted-foreground">
                {{ result }}
              </span>
            </div>
          </div>
        </div>
      </CardContent>
      <CardFooter>
        <div>
          <Button @click="copyToClipboard(result)">
            {{ $t('common.copy') }}
          </Button>
        </div>
      </CardFooter>
    </Card>
  </div>
</template>
