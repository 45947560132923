<script setup>
import { computed, ref, watch } from 'vue'
import axios from 'axios'
import BaseSpinner from '../base/BaseSpinner.vue'
import countries from '@/static/countries'
import { cepMask } from '@/helpers/masks'
import { countryList } from '@/static/countryList'
import Label from '@/components/ui/label/Label.vue'
import ErrorMessage from '@/components/ui/error-message/ErrorMessage.vue'
import Input from '@/components/ui/input/Input.vue'
import Field from '@/components/ui/field/Field.vue'
import ComboBox from '@/components/ui/combobox/ComboBox.vue'

const props = defineProps({
  errors: {
    type: Object,
    required: false,
    default: () => ({})
  },
  isIntl: {
	  type: Boolean,
	  required: false,
	  default: false
  }
})

const isLoadingZipCode = ref(false)

const model = defineModel({
  required: true
})

const countriesMapped = computed(() => {
  return countryList.map((country) => {
    const key = props.isIntl ? 'name_int' : 'name'
    return {
      ...country,
      name: country[key]
    }
  })
})

const states = computed(
  () =>
    countries.find(country => country.countryShortCode === model.value.country)
      ?.regions ?? []
)

/**
 * @param {string} zipcode
 */
async function fetchCep(zipcode) {
  isLoadingZipCode.value = true

  try {
    const { data } = await axios.get(`https://viacep.com.br/ws/${zipcode}/json/`)
    model.value.street = data.logradouro || model.value.street
    model.value.number = data.numero || model.value.number
    model.value.complement = data.complemento || model.value.complement
    model.value.district = data.bairro || model.value.district
    model.value.city = data.localidade || model.value.city
    model.value.state = data.uf || model.value.state
  } catch (error) {
    console.error(error)
  } finally {
    isLoadingZipCode.value = false
  }
}

watch(() => model.value.postal_code, () => {
  if (model.value.country !== 'BR') {
    return
  }

  if (model.value.postal_code && model.value.postal_code.length > 8) {
    const clearedPostalCode = model.value.postal_code.replace(/\D/g, '')
    fetchCep(clearedPostalCode)
  }
})
</script>

<template>
  <div class="grid grid-cols-1 gap-6 sm:grid-cols-6">
    <div class="col-span-full">
      <Field>
        <Label
          required
          for="country"
        >
          {{ $t("components.update_address.country") }}
        </Label>
        <div>
          <ComboBox
            v-model="model.country"
            :options="countriesMapped"
            :placeholder="$t('components.country_select.placeholder')"
            :empty="$t('components.country_select.empty')"
            :invalid="errors?.address?.country"
          />
        </div>
        <ErrorMessage :message="errors?.address?.country" />
      </Field>
    </div>
    <div
      v-if="model.country === 'BR'"
      class="col-span-6"
    >
      <Field>
        <Label
          for="zipcode"
          required
        >
          {{ $t("components.update_address.zip_code") }}
        </Label>
        <div class="relative w-full max-w-sm items-center">
          <span class="absolute inset-y-0 start-0 flex items-center justify-center px-2">
            <BaseSpinner v-if="isLoadingZipCode" />
          </span>
          <Input
            v-model="model.postal_code"
            v-maska="cepMask"
            class="relative"
            type="tel"
            label="CEP"
            maxlength="9"
            autocomplete="postal-code"
            placeholder="00000-000"
            :loading="isLoadingZipCode"
            :disabled="isLoadingZipCode"
            :invalid="errors?.address?.postal_code"
            :class="{
              'pl-10': isLoadingZipCode,
            }"
          />
        </div>

        <ErrorMessage :message="errors?.address?.postal_code" />
      </Field>
    </div>
    <div
      v-if="model.country === 'BR'"
      class="col-span-6 sm:col-span-3 md:col-span-4"
    >
      <Field>
        <Label
          for="street"
          required
        >
          {{ $t("components.update_address.street") }}
        </Label>
        <Input
          id="street"
          v-model="model.street"
          type="text"
          autocomplete="street-address"
          :invalid="errors?.address?.street"
          :disabled="isLoadingZipCode"
        />
        <ErrorMessage :message="errors?.address?.street" />
      </Field>
    </div>
    <div
      v-if="model.country === 'BR'"
      class="col-span-6 sm:col-span-3 md:col-span-2"
    >
      <Field>
        <Label
          for="number"
          required
        >
          {{ $t("components.update_address.number") }}
        </Label>
        <Input
          id="number"
          v-model="model.number"
          name="number"
          type="text"
          autocomplete="address-line1"
          :invalid="errors?.address?.number"
          :disabled="isLoadingZipCode"
        />
        <ErrorMessage :message="errors?.address?.number" />
      </Field>
    </div>
    <div
      v-if="model.country === 'BR'"
      class="col-span-6"
    >
      <Field>
        <Label for="complement">
          {{ $t("components.update_address.complement") }}
        </Label>
        <Input
          id="complement"
          v-model="model.complement"
          name="complement"
          type="text"
          :invalid="errors?.address?.complement"
          :disabled="isLoadingZipCode"
        />
        <ErrorMessage :message="errors?.address?.complement" />
      </Field>
    </div>
    <div
      v-if="model.country === 'BR'"
      class="col-span-6 sm:col-span-2"
    >
      <Field>
        <Label
          required
          for="district"
        >
          {{ $t("components.update_address.district") }}
        </Label>
        <Input
          id="district"
          v-model="model.district"
          type="text"
          :invalid="errors?.address?.district"
          :disabled="isLoadingZipCode"
        />
        <ErrorMessage :message="errors?.address?.district" />
      </Field>
    </div>
    <div
      v-if="model.country === 'BR'"
      class="col-span-6 sm:col-span-2"
    >
      <Field>
        <Label
          required
          for="city"
        >
          {{ $t("components.update_address.city") }}
        </Label>
        <Input
          id="city"
          v-model="model.city"
          type="text"
          :invalid="errors?.address?.city"
          :disabled="isLoadingZipCode"
        />
        <ErrorMessage :message="errors?.address?.city" />
      </Field>
    </div>
    <div
      v-if="model.country === 'BR'"
      class="col-span-6 sm:col-span-2"
    >
      <Field>
        <Label
          required
          for="state"
        >
          {{ $t("components.update_address.state") }}
        </Label>
        <ComboBox
          v-model="model.state"
          :options="states"
          :placeholder="$t('components.state_select.placeholder')"
          :empty="$t('components.state_select.empty')"
          :invalid="errors?.address?.state"
          :disabled="isLoadingZipCode"
        />
        <ErrorMessage :message="errors?.address?.state" />
      </Field>
    </div>
  </div>
</template>
