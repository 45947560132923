<script setup>
import { useVModel } from '@vueuse/core'
import { cn } from '@/lib/utils'

const props = defineProps({
  class: {
    type: null,
    required: false
  },
  defaultValue: {
    type: [String, Number],
    required: false
  },
  modelValue: {
    type: [String, Number],
    required: false
  },
})

const emits = defineEmits(['update:modelValue'])

const modelValue = useVModel(props, 'modelValue', emits, {
  passive: true,
  defaultValue: props.defaultValue,
})
</script>

<template>
  <textarea
    v-model="modelValue"
    :class="
      cn(
        'flex min-h-[60px] w-full rounded-md border-0  bg-transparent px-3 text-sm shadow-sm transition-colors file:bg-transparent file:text-sm ring-1 ring-inset ring-input file:font-medium placeholder:text-muted-foreground focus-visible:outline-none  disabled:cursor-not-allowed disabled:opacity-50 focus:ring-inset focus:ring-primary focus:ring-2 py-1.5 peer leading-6',
        props.class,
      )
    "
  />
</template>
