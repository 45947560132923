<template>
  <svg
    id="Livello_1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    version="1.1"
    x="0px"
    y="0px"
    viewBox="0 0 493 493"
    enable-background="new 0 0 493 493"
    xml:space="preserve"
  >
    <g>
      <path
        fill="#7882FF"
        d="M488.5,173c-6.1-45.8-22.3-87.3-50.8-117.7h0C407.3,26.8,365.8,10.6,320,4.5c-43.7-5.8-73.5-4.4-73.5-4.4   S216.7-1.3,173,4.5c-45.8,6.1-87.3,22.3-117.6,50.8C26.8,85.7,10.6,127.2,4.5,173c-5.8,43.7-4.4,73.5-4.4,73.5s-1.4,29.8,4.4,73.5   c6.1,45.8,22.3,87.3,50.8,117.7c30.4,28.6,71.9,44.8,117.6,50.9c43.7,5.8,73.5,4.4,73.5,4.4s29.8,1.4,73.5-4.4   c45.8-6.1,87.3-22.3,117.6-50.9h0c28.6-30.4,44.8-71.9,50.8-117.7c5.8-43.7,4.4-73.5,4.4-73.5S494.3,216.7,488.5,173"
      />
      <path
        fill="#FFFFFF"
        d="M372.7,210c19.5,70-21.5,142.6-91.5,162.1c-70,19.5-142.6-21.5-162.1-91.5c-19.5-70,21.5-142.6,91.5-162.1   C280.6,99,353.2,140,372.7,210"
      />
      <path
        fill="#FFFFFF"
        d="M372.7,210c7.3,26.3,6.1,52.9-1.9,76.9c-0.6,1.9-1.3,3.9-2.1,5.8"
      />
      <path
        fill="#FFFFFF"
        d="M370.6,210c5.1,18.4,6.1,37,3.4,54.7"
      />
      <path
        fill="#4D5CC1"
        d="M333.9,337.7L333.9,337.7c-33.3,0-73.8-8.5-114.2-23.8c-40.4-15.3-76.3-35.9-101.2-58   c-40.4-35.9-37.9-63.7-32.8-77.1c5.1-13.4,21.7-35.9,75.7-35.9h0c33.3,0,73.8,8.5,114.2,23.8c40.4,15.3,76.3,35.9,101.2,58   c40.4,35.9,37.9,63.7,32.8,77.1C404.6,315.2,388,337.7,333.9,337.7 M161.5,184.6c-25,0-35.7,6.2-36.8,9   c-1.1,2.8,2.9,14.6,21.6,31.2c20.9,18.5,53.1,36.9,88.3,50.2c35.2,13.4,71.5,21.1,99.4,21.1h0c25,0,35.7-6.2,36.8-9   c1.1-2.8-2.9-14.6-21.5-31.2c-20.9-18.5-53.1-36.9-88.3-50.2C225.6,192.3,189.4,184.6,161.5,184.6L161.5,184.6L161.5,184.6z"
      />
      <path
        fill="#FFFFFF"
        d="M124.7,194l245.9,93.4c8.2-24.2,9.5-51,2.1-77.4c-19.5-70-92-111-162.1-91.5   C170.4,129.7,139.8,158.4,124.7,194"
      />
    </g>
  </svg>
</template>
