<script setup>
import { reactive, ref } from 'vue'
import { toast } from 'vue-sonner'
import { CheckCircleIcon, EnvelopeIcon } from '@heroicons/vue/24/solid'
import { useI18n } from 'vue-i18n'
import { useHead } from '@unhead/vue'
import { authService } from '@/services/auth'
import Button from '@/components/ui/button/Button.vue'
import Label from '@/components/ui/label/Label.vue'
import Input from '@/components/ui/input/Input.vue'
import ErrorMessage from '@/components/ui/error-message/ErrorMessage.vue'
import Field from '@/components/ui/field/Field.vue'
import PageHeading from '@/components/common/PageHeading.vue'

const { t } = useI18n()
useHead({
  title: t('pages.auth.forgot_password.title')
})

const form = reactive({
  email: '',
})
const isLoading = ref(false)
const errors = reactive({})
const isSent = ref(false)

function onSubmit() {
  sendResetPasswordEmail({
    email: form.email,
  })
}

async function sendResetPasswordEmail({ email }) {
  isLoading.value = true

  try {
    await authService.sendEmailForgotPassword(email)
    isSent.value = true

    toast.success(t('pages.auth.forgot_password.toast.success.title'), {
      description: t('pages.auth.forgot_password.toast.success.description'),
    })
  } catch (e) {
    if (e.response?.status === 422) {
      errors.email = e.response.data.data
    }

    toast.error(e.response?.data?.message, {
      description: e.response?.data?.action || e.message,
    })
  } finally {
    isLoading.value = false
  }
}
</script>

<template>
  <div>
    <div>
      <img
        class="h-12 w-auto dark:invert"
        src="../../assets/img/logo-black.svg"
        alt="Cloakup"
      >
      <PageHeading
        :title="$t('pages.auth.forgot_password.title')"
        class="mt-6"
      />
      <p class="mt-2 text-sm">
        <span class="text-muted-foreground">
          {{ $t('common.or') }}
        </span>
        {{ " " }}
        <router-link
          :to="{ name: 'SignIn' }"
          class="font-medium"
        >
          {{ $t("pages.auth.forgot_password.sign_in") }}
        </router-link>
      </p>
    </div>

    <section class="mt-8">
      <div
        v-if="isSent"
        class="rounded bg-green-50 p-4"
      >
        <div class="flex">
          <div class="shrink-0">
            <CheckCircleIcon
              class="size-5 text-green-400"
              aria-hidden="true"
            />
          </div>
          <div class="ml-3">
            <p class="text-sm font-medium text-green-800">
              {{ $t("pages.auth.forgot_password.email_sent") }}
            </p>
          </div>
        </div>
      </div>
      <div class="mt-4">
        <form @submit.prevent="onSubmit">
          <fieldset
            :disabled="isSent || isLoading"
            class="space-y-6"
          >
            <Field>
              <Label
                required
                for="email"
              >
                {{ $t('common.email') }}
              </Label>
              <Input
                id="email"
                v-model="form.email"
                name="email"
                type="email"
                autocomplete="email"
                required
              >
                <template #leading>
                  <EnvelopeIcon class="text-muted-secondary size-5" />
                </template>
              </Input>
              <ErrorMessage :message="errors?.email" />
            </Field>
            <Button
              type="submit"
              block
              :loading="isLoading"
            >
              {{ $t("pages.auth.forgot_password.sent") }}
            </Button>
          </fieldset>
        </form>
      </div>
    </section>
  </div>
</template>
