import client from '@/plugins/axios'

export const cloudProviderService = {
  resource: '/cloud-provider',

  findAll(params = {}) {
    return client.get(this.resource, {
      params
    })
  },

  create(data) {
    return client.post(this.resource, data)
  },

  destroy(id) {
    return client.delete(`${this.resource}/${id}`)
  },

  getRegions(cloudProviderId) {
    return client.get(`${this.resource}/${cloudProviderId}/regions`)
  }
}
