<script setup>
import { ArrowTopRightOnSquareIcon, PlusIcon } from '@heroicons/vue/24/solid'
import { reactive, ref, watch } from 'vue'
import { toast } from 'vue-sonner'
import { useI18n } from 'vue-i18n'
import BaseError from '@/components/base/BaseError.vue'
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger
} from '@/components/ui/dialog'
import { domainService } from '@/services/domain.js'
import Button from '@/components/ui/button/Button.vue'
import Input from '@/components/ui/input/Input.vue'
import Label from '@/components/ui/label/Label.vue'
import Field from '@/components/ui/field/Field.vue'
import Description from '@/components/ui/description/Description.vue'

const emit = defineEmits(['created'])
const { t } = useI18n()
const form = reactive({
  name: '',
})
const isLoading = ref(false)
const errors = ref({})
const isOpen = ref(false)

function onSubmit() {
  createDomain(form)
}

async function createDomain(form) {
  isLoading.value = true

  try {
    await domainService.create(form)

    toast.success(t('components.create_domain_modal.toast.success.title'), {
      description: t('components.create_domain_modal.toast.success.description'),
    })

    isOpen.value = false

    emit('created')
  } catch (e) {
    if (e.response?.status === 422) {
      errors.value = e.response.data.data
    }

    toast.error(e.response?.data?.message, {
      description: e.response?.data?.action || e.message,
    })
  } finally {
    isLoading.value = false
  }
}

watch(isOpen, (value) => {
  if (value) {
    errors.value = {}
    form.name = ''
  }
})
</script>

<template>
  <Dialog v-model:open="isOpen">
    <DialogTrigger>
      <Button type="button">
        <PlusIcon class="size-5" />
        <span class="ml-2">
          {{ $t('components.create_domain_modal.button') }}
        </span>
      </Button>
    </DialogTrigger>
    <DialogContent>
      <DialogHeader>
        <DialogTitle> {{ $t('components.create_domain_modal.title') }}</DialogTitle>
        <DialogDescription>
          {{ $t('components.create_domain_modal.description') }}
        </DialogDescription>
      </DialogHeader>

      <div class="mt-4">
        <form
          id="domain-form"
          @submit.prevent="onSubmit"
        >
          <fieldset :disabled="isLoading">
            <Field>
              <Label
                required
                for="domain"
              >
                {{ $t('common.domain') }}
              </Label>
              <Input
                id="domain"
                v-model="form.name"
                required
                name="domain"
                type="text"
                :placeholder="$t('components.create_domain_modal.your_domain')"
              />
              <Description>
                {{ $t('components.create_domain_modal.only_sub_domains') }}
                <strong>{{ $t('components.create_domain_modal.subdomains') }}</strong>
                {{ $t('components.create_domain_modal.are_allowed') }}
              </Description>
              <BaseError v-if="errors?.name">
                {{ errors?.name }}
              </BaseError>
            </Field>
          </fieldset>
        </form>
      </div>

      <DialogFooter class="mt-4">
        <div class="mr-auto">
          <Button
            variant="link"
            as-child
            class="text-blue-500 underline dark:text-blue-400"
          >
            <a
              target="_blank"
              href="https://help.cloakup.me/pt-br/article/como-integrar-via-dominio-6utgmc/"
            >
              <ArrowTopRightOnSquareIcon class="mr-1 inline-block size-4" />
              {{ $t('components.create_domain_modal.help') }}
            </a>
          </Button>
        </div>

        <DialogClose as-child>
          <Button
            type="button"
            variant="secondary"
            :disabled="isLoading"
          >
            {{ $t('common.cancel') }}
          </Button>
        </DialogClose>
        <Button
          :loading="isLoading"
          type="submit"
          form="domain-form"
          :disabled="isLoading"
        >
          {{ $t('common.save') }}
        </Button>
      </DialogFooter>
    </DialogContent>
  </Dialog>
</template>
