<script setup>
import { ChevronRightIcon } from '@heroicons/vue/20/solid'
import { BookOpenIcon, LifebuoyIcon, PercentBadgeIcon } from '@heroicons/vue/24/solid'
import { useI18n } from 'vue-i18n'
import Button from '@/components/ui/button/Button.vue'
import Card from '@/components/ui/card/Card.vue'
import CardContent from '@/components/ui/card/CardContent.vue'

const { t } = useI18n()

const links = [
  {
    name: t('pages.thankyou.links.installation.name'),
    href: 'https://help.cloakup.me/pt-br/article/como-instalar-o-cloakup-corretamente-2hjvv6/',
    description: t('pages.thankyou.links.installation.description'),
    icon: BookOpenIcon,
    external: true
  },
  {
    name: t('pages.thankyou.links.referral.name'),
    href: '/referral',
    description: t('pages.thankyou.links.referral.description'),
    icon: PercentBadgeIcon,
    external: false
  },
  {
    name: t('pages.thankyou.links.central.name'),
    href: 'https://help.cloakup.me',
    description: t('pages.thankyou.links.central.description'),
    icon: LifebuoyIcon,
    external: true
  },
]
</script>

<template>
  <div class="mx-auto min-h-screen max-w-md py-4">
    <header class="flex w-full items-center justify-center px-3 sm:p-6">
      <img
        class="h-8 w-auto dark:invert"
        src="../assets/img/logo-black.svg"
        alt="Cloakup"
      >
    </header>
    <div class="pt-16">
      <div>
        <div class="text-title text-center text-3xl font-bold">
          {{ $t('pages.thankyou.title') }}
        </div>
        <div class="text-center text-lg text-muted-foreground">
          {{ $t('pages.thankyou.description') }}
        </div>
        <div class="mt-8 text-center ">
          <Button as-child>
            <RouterLink to="/">
              {{ $t('pages.thankyou.button') }}
            </RouterLink>
          </Button>
        </div>
      </div>
      <div class="pt-16">
        <div>
          <ul
            role="list"
            class="space-y-3"
          >
            <li
              v-for="(link, linkIdx) in links"
              :key="linkIdx"
              class="relative"
            >
              <component
                :is="link.external ? 'a' : 'router-link'"
                :to="link.external ? undefined : link.href"
                :href="link.external ? link.href : undefined"
                :target="link.external ? '_blank' : undefined"
                class="cursor-pointer"
              >
                <Card class="hover:bg-muted/25">
                  <CardContent class="flex gap-x-6">
                    <div class="flex size-10 flex-none items-center justify-center rounded-lg shadow-sm ring-1 ring-border">
                      <component
                        :is="link.icon"
                        class="size-6 text-primary	"
                        aria-hidden="true"
                      />
                    </div>
                    <div class="flex-auto">
                      <h3 class="text-title text-sm font-semibold leading-6">
                        <span
                          class="absolute inset-0"
                          aria-hidden="true"
                        />
                        {{ link.name }}
                      </h3>
                      <p class="mt-2 text-sm leading-6 text-muted-foreground">
                        {{ link.description }}
                      </p>
                    </div>
                    <div class="flex-none self-center">
                      <ChevronRightIcon
                        class="size-5 text-muted-foreground"
                        aria-hidden="true"
                      />
                    </div>
                  </CardContent>
                </Card>
              </component>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>
