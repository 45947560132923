import { useUserStore } from '@/stores/user'

export const can = {
  mounted(el, bind) {
    const { user } = useUserStore()

    if (bind.value === undefined) {
      return
    }

    if (!user) {
      return
    }

    if (user?.status !== 'active') {
      el.remove()
    }
  }
}
