<script setup>
import { loadStripe } from '@stripe/stripe-js'
import { onMounted, ref } from 'vue'

import {
  RadioGroup,
  RadioGroupDescription,
  RadioGroupLabel,
  RadioGroupOption
} from '@headlessui/vue'
import { CheckCircleIcon } from '@heroicons/vue/24/solid'
import { cardService } from '@/services/card'
import BaseModal from '@/components/base/BaseModal.vue'
import Button from '@/components/ui/button/Button.vue'

const props = defineProps()
const emit = defineEmits(['update:modelValue', 'paid', 'close'])

const cards = ref([])
const showCreateCardModal = ref(false)
const isLoading = ref(false)
const selectedPaymentMethod = ref({
  token: null
})
let stripe = null

function closeModal() {
  emit('close')
}

async function onSubmit() {
  if (!stripe) {
    return
  }

  isLoading.value = true

  try {
    await stripe.confirmCardPayment(props.clientSecret, {
      payment_method: selectedPaymentMethod.value?.token
    })

    closeModal()
    emit('paid')
  } catch (err) {

  } finally {
    isLoading.value = false
  }
}

async function getAllCards() {
  isLoading.value = true
  try {
    const { data } = await cardService.findAll({
      page: 1,
      limit: 50,
      gateway: 'stripe'
    })
    cards.value = [...cards.value, ...data.data]

    const defaultCard = cards.value.find(card => card.default)
    if (defaultCard) {
      selectedPaymentMethod.value = defaultCard
    } else {
      selectedPaymentMethod.value = cards.value[0]
    }
  } catch (error) {

  } finally {
    isLoading.value = false
  }
}

onMounted(async () => {
  getAllCards()
  stripe = await loadStripe(import.meta.env.VITE_STRIPE_PK)
})
</script>

<template>
  <div>
    <BaseModal
      :open="props.open"
      title="Pagar fatura"
      @close="emit('close')"
    >
      <form
        id="pay-invoice-form"
        @submit.prevent="onSubmit"
      >
        <fieldset :disabled="isLoading">
          <div>
            <div class="grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
              <div class="sm:col-span-6">
                <div>
                  <RadioGroup v-model="selectedPaymentMethod">
                    <div
                      class="mt-4 grid grid-cols-1 gap-y-6 sm:grid-cols-2 sm:gap-x-4"
                    >
                      <RadioGroupOption
                        v-for="card in cards"
                        :key="card.token"
                        v-slot="{ checked, active }"
                        as="div"
                        :value="card"
                      >
                        <div
                          class="relative flex cursor-pointer rounded-lg border bg-white p-4 shadow-sm focus:outline-none"
                          :class="[
                            checked ? 'border-transparent' : 'border-neutral-300',
                            active
                              ? 'border-primary ring-2 ring-primary'
                              : '',
                          ]"
                        >
                          <span class="flex flex-1">
                            <span class="flex flex-col">
                              <RadioGroupLabel
                                as="span"
                                class="block text-sm font-medium "
                              >
                                {{ card.brand }}
                              </RadioGroupLabel>
                              <RadioGroupDescription
                                v-if="card.last_four_digits"
                                as="span"
                                class="mt-6 text-sm font-medium text-muted-foreground"
                              >
                                **** **** **** {{ card.last_four_digits }}
                              </RadioGroupDescription>
                            </span>
                          </span>
                          <CheckCircleIcon
                            class="size-5 text-primary"
                            :class="[
                              !checked ? 'invisible' : '',
                            ]"
                            aria-hidden="true"
                          />
                          <span
                            class="pointer-events-none absolute -inset-px rounded-lg"
                            :class="[
                              active ? 'border' : 'border-2',
                              checked
                                ? 'border-primary'
                                : 'border-transparent',
                            ]"
                            aria-hidden="true"
                          />
                        </div>
                      </RadioGroupOption>
                    </div>
                  </RadioGroup>
                </div>
              </div>
            </div>
          </div>
        </fieldset>
      </form>
      <template #footer>
        <div class="sm:flex sm:flex-row-reverse">
          <Button
            form="pay-invoice-form"
            type="submit"
            class="w-full sm:ml-3 sm:w-auto"
            :loading="isLoading"
          >
            Pagar
          </Button>
          <Button
            type="button"
            variant="secondary"
            class="mt-3 w-full sm:mt-0 sm:w-auto"
            @click="closeModal"
          >
            Cancelar
          </Button>
        </div>
      </template>
    </BaseModal>
  </div>
</template>
