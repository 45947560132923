import client from '@/plugins/axios'

export const cardService = {
  resource: '/cards',
  findAll(params = {}) {
    return client.get(this.resource, {
      params
    })
  },
  create(data) {
    return client.post(this.resource, data)
  },
  toggleDefault(id) {
    return client.put(`${this.resource}/${id}/toggle-default`)
  },
  remove(id) {
    return client.delete(`${this.resource}/${id}`)
  }
}
