<script setup>
import { useHead } from '@unhead/vue'
import { useI18n } from 'vue-i18n'
import SectionHeading from '@/components/common/SectionHeading.vue'
import UpdatePassword from '@/components/partials/settings/profile/UpdatePassword.vue'
import UpdateProfile from '@/components/partials/settings/profile/UpdateProfile.vue'
import UpdateDocument from '@/components/partials/settings/profile/UpdateDocument.vue'
import UpdateAddress from '@/components/partials/settings/profile/UpdateAddress.vue'
import UpdateLanguage from '@/components/partials/settings/profile/UpdateLanguage.vue'

const { t } = useI18n()
useHead({
  title: t('pages.settings.profile.title')
})
</script>

<template>
  <div class="space-y-12">
    <div class="grid grid-cols-1 gap-x-8 gap-y-10 border-b border-border pb-12 md:grid-cols-3">
      <div>
        <SectionHeading
          :title="$t('components.update_profile.title')"
          :description="$t('components.update_profile.description')"
        />
      </div>
      <div class="max-w-2xl md:col-span-2">
        <UpdateProfile />
      </div>
    </div>
    <section class="grid grid-cols-1 gap-x-8 gap-y-10 border-b border-border pb-12 md:grid-cols-3">
      <div>
        <SectionHeading
          :title="$t('components.update_address.title')"
          :description="$t('components.update_address.description')"
        />
      </div>
      <div class="max-w-2xl md:col-span-2">
        <UpdateAddress />
      </div>
    </section>
    <section class="grid grid-cols-1 gap-x-8 gap-y-10 border-b border-border pb-12 md:grid-cols-3">
      <div>
        <SectionHeading
          :title="$t('components.update_tax_id.title')"
          :description="$t('components.update_tax_id.description')"
        />
      </div>
      <div class="max-w-2xl md:col-span-2">
        <UpdateDocument />
      </div>
    </section>
    <section
      class="grid grid-cols-1 gap-x-8 gap-y-10 border-b border-border pb-12 md:grid-cols-3"
    >
      <div>
        <SectionHeading
          :title="$t('components.update_language.title')"
          :description="$t('components.update_language.description')"
        />
      </div>
      <div class="max-w-2xl md:col-span-2">
        <UpdateLanguage />
      </div>
    </section>
    <section class="grid grid-cols-1 gap-x-8 gap-y-10 pb-12 md:grid-cols-3">
      <div>
        <SectionHeading
          :title="$t('components.update_password.title')"
          :description="$t('components.update_password.description')"
        />
      </div>
      <div class="max-w-2xl md:col-span-2">
        <UpdatePassword />
      </div>
    </section>
  </div>
</template>
