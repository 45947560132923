<script setup>
import { computed } from 'vue'
import BaseSpinner from '@/components/base/BaseSpinner.vue'
import Banner from '@/components/common/Banner.vue'
import { useUserStore } from '@/stores/user'
import TheSidebar from '@/components/layout/TheSidebar.vue'
import SidebarMobile from '@/components/layout/SidebarMobile.vue'

const store = useUserStore()

const user = computed(() => store.user)
const isPendingUpgrade = computed(() => user.value?.status === 'pending')
</script>

<template>
  <div class="min-h-screen bg-background">
    <TheSidebar />
    <div class="flex flex-1 flex-col md:pl-72">
      <SidebarMobile />

      <main class="flex-1">
        <Banner
          v-if="isPendingUpgrade"
          :msg="{
            long: 'Sua conta esta pendente, faça upgrade para continuar usando',
            short: 'Upgrade pendente',
          }"
          action="Clique aqui para fazer upgrade"
          :link="{ name: 'upgrade' }"
        />
        <div class="py-6">
          <div class="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
            <router-view v-slot="{ Component }">
              <Suspense timeout="0">
                <component
                  :is="Component"
                  :key="$route.params?.id"
                />
                <template #fallback>
                  <div class="flex min-h-screen items-center justify-center">
                    <BaseSpinner class="text-primary" />
                  </div>
                </template>
              </Suspense>
            </router-view>
          </div>
        </div>
      </main>
    </div>
  </div>
</template>
