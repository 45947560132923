<script setup>
import { h, onMounted, ref, watch } from 'vue'
import RadioGroupCards from '@/components/RadioGroupCards.vue'

const model = defineModel({
  required: true,
})

const selected = ref(undefined)

function bindSelected(value) {
  const platforms = ['facebook', 'tiktok', 'google', 'kwai']

  platforms.forEach((platform) => {
    model.value[platform] = (platform === value)
  })
}

function bindModel(value) {
  const platforms = ['facebook', 'tiktok', 'google', 'kwai']

  for (const platform of platforms) {
    if (value[platform]) {
      selected.value = platform
      break
    }
  }
}

const options = [
  {
    value: 'facebook',
    label: 'Facebook',
    icon: h('img', {
      src: '/img/networks/facebook.svg',
      alt: 'Facebook',
      class: 'size-12 grayscale dark:invert',
    }),
  },
  {
    value: 'tiktok',
    label: 'Tiktok',
    icon: h('img', {
      src: '/img/networks/tiktok.svg',
      alt: 'Tiktok',
      class: 'size-12 grayscale dark:invert',
    }),
  },
  {
    value: 'google',
    label: 'Google Ads',
    icon: h('img', {
      src: '/img/networks/googleads.svg',
      alt: 'Google Ads',
      class: 'size-12 grayscale',
    }),
  },
  {
    value: 'kwai',
    label: 'Kwai',
    icon: h('img', {
      src: '/img/networks/kwai.svg',
      alt: 'Kwai',
      class: 'size-12 grayscale dark:invert',
    }),
  },
]

watch(selected, () => {
  bindSelected(selected.value)
})

onMounted(() => {
  bindModel(model.value)
})
</script>

<template>
  <RadioGroupCards
    v-model="selected"
    :options="options"
  />
</template>
