<script setup>
import { computed, onBeforeUnmount, ref, watch } from 'vue'
import { toast } from 'vue-sonner'
import dayjs from 'dayjs'
import EmptyCard from '@/components/EmptyCard.vue'
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle
} from '@/components/ui/card'
import DashboardChart from '@/components/partials/dashboard/DashboardChart.vue'
import { reportService } from '@/services/report-service'
import { fillMissingDates, fillMissingHours } from '@/helpers/date'
import { formatNumber } from '@/helpers/format'
import ProgressIndicator from '@/components/common/ProgressIndicator.vue'

const props = defineProps({
  campaignId: {
    type: String,
    required: false
  },
  startDate: {
    type: Date,
    required: false,
  },
  endDate: {
    type: Date,
    required: false,
  }
})
const controller = new AbortController()

const currentTab = ref('all')
const isLoading = ref(false)
const requests = ref([])

const allowedRequests = computed(() => {
  return requests.value.reduce((acc, item) => {
    return acc + item.allowed
  }, 0)
})
const blockedRequests = computed(() => {
  return requests.value.reduce((acc, item) => {
    return acc + item.denied
  }, 0)
})
const totalRequests = computed(() => {
  return allowedRequests.value + blockedRequests.value
})
const isSameDay = computed(() => {
  return dayjs(props.startDate).isSame(dayjs(props.endDate), 'day')
})

async function fetchByAllowed(params) {
  isLoading.value = true

  try {
    const { data } = await reportService.groupByAllowed({
      params,
      signal: controller.signal
    })

    const date = {
      start: props.startDate ? props.startDate : dayjs().startOf('day').toDate(),
      end: props.endDate ? props.endDate : dayjs().endOf('day').toDate(),
    }

    const isSameDay = dayjs(date.start).isSame(dayjs(date.end), 'day')

    if (isSameDay) {
      requests.value = fillMissingHours(date.start, date.end, data)
    } else {
      requests.value = fillMissingDates(date.start, date.end, data)
    }
  } catch (e) {
    if (e.message === 'canceled') {
      return
    }

    toast.error(e.response?.data?.message, {
      description: e.response?.data?.action || e.message,
    })
  } finally {
    isLoading.value = false
  }
}

watch(() => [props.startDate, props.endDate, props.campaignId], () => {
  if (!props.campaignId) {
    return
  }

  fetchByAllowed({
    start_date: dayjs(props.startDate).unix() * 1000,
    end_date: dayjs(props.endDate).unix() * 1000,
    campaign: props.campaignId
  })
}, {
  immediate: true
})

onBeforeUnmount(() => {
  controller.abort()
})
</script>

<template>
  <Card>
    <CardHeader class="flex flex-col items-stretch space-y-0 border-b p-0 sm:flex-row">
      <div class="flex flex-1 flex-col justify-center gap-1 px-6 py-5 sm:py-6">
        <CardTitle>
          {{ $t('components.requests_per_day.title') }}
        </CardTitle>
        <CardDescription>
          {{ $t('components.requests_per_day.description') }}
        </CardDescription>
      </div>
      <div class="grid grid-cols-1 sm:grid-cols-3">
        <button
          class="relative z-30 flex flex-1 flex-col justify-center gap-1 border-t px-6 py-4 text-left even:border-l data-[active=true]:bg-muted/50 sm:border-l sm:border-t-0 sm:px-8"
          :data-active="currentTab === 'all'"
          @click="currentTab = 'all'"
        >
          <span class="text-xs text-muted-foreground">
            {{ $t('components.requests_per_day.all') }}
          </span>
          <span class="text-lg font-bold leading-none sm:text-3xl">
            {{ formatNumber(totalRequests) }}
          </span>
        </button>
        <button
          class="relative z-30 flex flex-1 flex-col justify-center gap-1 border-t px-6 py-4 text-left even:border-l data-[active=true]:bg-muted/50 sm:border-l sm:border-t-0 sm:px-8"
          :data-active="currentTab === 'allowed'"
          @click="currentTab = 'allowed'"
        >
          <span class="text-xs text-muted-foreground">
            {{ $t('components.requests_per_day.allowed') }}
          </span>
          <span class="text-lg font-bold leading-none sm:text-3xl">
            {{ formatNumber(allowedRequests) }}
          </span>
        </button>
        <button
          class="relative z-30 flex flex-1 flex-col justify-center gap-1 border-t px-6 py-4 text-left even:border-l data-[active=true]:bg-muted/50 sm:border-l sm:border-t-0 sm:px-8 "
          :data-active="currentTab === 'blocked'"
          @click="currentTab = 'blocked'"
        >
          <span class="text-xs text-muted-foreground">
            {{ $t('components.requests_per_day.blocked') }}
          </span>
          <span class="text-lg font-bold leading-none sm:text-3xl">
            {{ formatNumber(blockedRequests) }}
          </span>
        </button>
      </div>
    </CardHeader>
    <div class="h-2">
      <ProgressIndicator v-if="isLoading" />
    </div>

    <CardContent>
      <EmptyCard
        v-if="totalRequests === 0"
        class="p-6"
        :message="!campaignId ? $t('pages.reports.missing_selected_campaign') : undefined"
      />
      <DashboardChart
        v-else
        :data="requests"
        :is-same-day="isSameDay"
      />
    </CardContent>
  </Card>
</template>
