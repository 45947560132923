<script setup>
import { InboxIcon } from '@heroicons/vue/24/solid'
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@/components/ui/select'

const props = defineProps({
  options: {
    type: Array,
    required: true,
    default: () => []
  },
})

const model = defineModel({
  required: true
})
</script>

<template>
  <Select v-model="model">
    <SelectTrigger
      id="campaign"
      name="campaign"
    >
      <div class="flex items-center gap-2">
        <InboxIcon class="size-5" />
        <SelectValue placeholder="Selecione uma campanha" />
      </div>
    </SelectTrigger>
    <SelectContent>
      <SelectGroup>
        <SelectItem
          v-if="options?.length === 0"
        >
          {{ $t('pages.campaign.empty_state.title') }}
        </SelectItem>
        <SelectItem
          v-for="campaign in props.options"
          :key="campaign.name"
          :value="campaign.id"
        >
          {{ campaign.name }}
        </SelectItem>
      </SelectGroup>
    </SelectContent>
  </Select>
</template>
