<script setup>
import { ref, watch } from 'vue'
import { useRoute } from 'vue-router'
import { useI18n } from 'vue-i18n'
import { useHead } from '@unhead/vue'
import BaseTable from '@/components/base/BaseTable.vue'
import PageHeading from '@/components/common/PageHeading.vue'
import Pagination from '@/components/common/Pagination.vue'
import CloudProviderCreateModal from '@/components/partials/cloud-providers/CloudProviderCreateModal.vue'
import CloudProviderDeleteModal from '@/components/partials/cloud-providers/CloudProviderDeleteModal.vue'
import { formatDate } from '@/helpers/date'
import { cloudProviderService } from '@/services/cloud-provider'
import EmptyState from '@/components/common/EmptyState.vue'
import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  BreadcrumbList,
  BreadcrumbPage,
  BreadcrumbSeparator,
} from '@/components/ui/breadcrumb'

const { t, locale } = useI18n()
useHead({
  title: t('pages.cloud_providers.title')
})
const route = useRoute()

const cloudProviders = ref([])
const isLoading = ref(false)
const hasError = ref(false)

const count = ref(0)

async function fetchCloudProviders({
  page,
  limit
}) {
  isLoading.value = true
  try {
    const { data } = await cloudProviderService.findAll({
      page,
      limit
    })
    cloudProviders.value = data.data
    count.value = data.count
  } catch (error) {
    hasError.value = true
  } finally {
    isLoading.value = false
  }
}

watch(
  () => [route.query],
  () => {
    fetchCloudProviders({
      page: route.query.page,
      limit: route.query.limit
    })
  },
  {
    immediate: true
  }
)

const cloudProviderTableHeader = [
  {
    label: t('common.created_at'),
    value: 'created_at',
    format: value => formatDate(value.replace('.000Z', ''), {
      locale: locale.value
    })
  },
  {
    label: t('common.name'),
    value: 'name'
  },
  {
    label: t('common.provider'),
    value: 'provider'
  }
]

// delete
const showDeleteCloudProviderModal = ref(false)

function onDeleteCloudProvider(id) {
  cloudProviders.value = cloudProviders.value.filter(
    cloudProvider => cloudProvider.id !== id
  )

  showDeleteCloudProviderModal.value = false
}

async function onCreateCloudProvider() {
  await fetchCloudProviders({
    page: route.query.page,
    limit: route.query.limit
  })
}

const providersName = {
  digitalocean: 'Digital Ocean'
}
</script>

<template>
  <div class="space-y-5">
    <div class="space-y-2">
      <Breadcrumb>
        <BreadcrumbList>
          <BreadcrumbItem>
            <BreadcrumbLink as-child>
              <RouterLink to="/">
                Dashboard
              </RouterLink>
            </BreadcrumbLink>
          </BreadcrumbItem>

          <BreadcrumbSeparator />

          <BreadcrumbItem>
            <BreadcrumbPage>
              {{ $t("pages.cloud_providers.breadcrumb.providers") }}
            </BreadcrumbPage>
          </BreadcrumbItem>
        </BreadcrumbList>
      </Breadcrumb>

      <PageHeading
        :title="$t('pages.cloud_providers.title')"
        :description="$t('pages.cloud_providers.description')"
      >
        <template #actions>
          <CloudProviderCreateModal
            @created="onCreateCloudProvider"
          />
        </template>
      </PageHeading>
    </div>

    <div>
      <BaseTable
        :headers="cloudProviderTableHeader"
        :columns="cloudProviders"
        :loading="isLoading"
        :error="hasError"
      >
        <template #col-provider="{ column }">
          <div class="flex items-center gap-2">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 32 32"
              fill="#0069ff"
              class="size-5"
            >
              <path
                d="M16.098 32v-6.196c6.615 0 11.7-6.501 9.177-13.419a9.227 9.227 0 0 0-5.545-5.513C12.771 4.363 6.233 9.457 6.233 15.996H0C0 5.542 10.171-2.631 21.183.79c4.818 1.483 8.642 5.322 10.172 10.112C34.796 21.888 26.613 32 16.098 32Z"
              />
              <path
                d="M16.097 25.842H9.902v-6.159h6.195v6.159Zm-6.194 4.751h-4.78v-4.751h4.78v4.751Zm-4.779-4.751H1.109v-3.954h3.977v3.954h.038Z"
              />
            </svg>
            <span>{{ providersName[column.provider] }}</span>
          </div>
        </template>
        <template #actions="{ column }">
          <CloudProviderDeleteModal
            :id="column.id"
            @deleted="onDeleteCloudProvider"
          />
        </template>
        <template #pagination>
          <Pagination
            :total="count"
          />
        </template>
        <template #empty>
          <EmptyState
            :title="$t('pages.cloud_providers.empty_state.title')"
            :description="$t('pages.cloud_providers.empty_state.description')"
          />
        </template>
      </BaseTable>
    </div>
  </div>
</template>
