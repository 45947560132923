<script setup>
import { computed } from 'vue'
import { Skeleton } from '@/components/ui/skeleton'

const props = defineProps({
  list: {
    type: Array,
    required: true,
    validator: (value) => {
      return value.every((item) => {
        return item.hasOwnProperty('name') && item.hasOwnProperty('value')
      })
    }
  },
  loading: {
    type: Boolean,
    default: false
  }
})

const sumValue = computed(() => {
  return props.list.reduce((acc, item) => {
    return acc + item.value
  }, 0)
})

function getPercentage(value) {
  return ((value * 100) / sumValue.value).toFixed(2)
}
</script>

<template>
  <div class="flex justify-between space-x-6">
    <div class="relative w-full">
      <div
        v-if="loading"
        class="space-y-2"
      >
        <div
          v-for="i in 9"
          :key="i"
          class="flex items-center justify-between rounded-md px-2 hover:bg-muted/50"
        >
          <Skeleton class="h-5" />
        </div>
      </div>
      <div
        v-else
        class="space-y-2"
      >
        <div
          v-for="(item, index) in list"
          :key="item.name"
          class="flex items-center justify-between rounded-md px-2 hover:bg-muted/50"
        >
          <div
            class="flex h-9 items-center rounded-md bg-muted"
            :style="`width: ${getPercentage(item.value)}%`"
          >
            <div class="absolute left-2 ml-2 flex max-w-full">
              <slot
                name="name"
                :item="item.name"
                :index="index"
              >
                <p
                  class="text-title truncate whitespace-nowrap text-sm leading-6"
                >
                  {{ item.name }}
                </p>
              </slot>
            </div>
          </div>
          <div class="min-w-min text-right">
            <div class="flex h-9 items-center justify-end">
              <p class="truncate whitespace-nowrap text-sm font-semibold">
                <slot
                  name="value"
                  :item="item"
                  :index="index"
                >
                  {{ item.value }}
                </slot>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
