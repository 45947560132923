<script setup>
import { useVModel } from '@vueuse/core'
import { ExclamationCircleIcon } from '@heroicons/vue/24/solid'
import { cn } from '@/lib/utils'

defineOptions({
  inheritAttrs: false,
})

const props = defineProps({
  defaultValue: {
    type: [String, Number],
    required: false
  },
  modelValue: {
    type: [String, Number],
    required: false
  },
  class: {
    type: null,
    required: false
  },
  invalid: {
    type: Boolean,
    required: false
  }
})

const emits = defineEmits(['update:modelValue'])
const modelValue = useVModel(props, 'modelValue', emits, {
  passive: true,
  defaultValue: props.defaultValue,
})
</script>

<template>
  <div
    class="relative shadow-sm"
    :class="{
      'text-red-900': invalid,
    }"
  >
    <div
      v-if="$slots.leading"
      class="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3"
    >
      <slot name="leading" />
    </div>
    <input
      v-model="modelValue"
      v-bind="$attrs"
      :class="
        cn(
          'flex w-full rounded-md border-0  bg-transparent px-3 text-sm transition-colors file:bg-transparent file:text-sm ring-1 ring-inset ring-input file:font-medium placeholder:text-muted-foreground focus-visible:outline-none  focus:ring-inset focus:ring-primary focus:ring-2 py-1.5 peer leading-6 transition-colors',
          props.class,
          $slots.leading ? 'pl-10' : '',
          invalid ? 'ring-red-500 text-red-900 placeholder:text-red-300 focus:ring-red-500' : '',
        )
      "
    >
    <div class="absolute inset-y-0 right-0 flex items-center pr-3">
      <slot name="trailing" />
    </div>
  </div>
</template>
