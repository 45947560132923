import { isLocal } from '@/helpers/is-local'

export function crisp() {
  function setup() {
    if (isLocal) {
      console.log('Crisp disabled in local environment')
      return
    }

    const scriptContent = `window.$crisp = [];
        window.CRISP_WEBSITE_ID = "50f6983c-369f-45ec-829c-8edd95f24a52";
        (function () {
        d = document;
        s = d.createElement("script");
        s.src = "https://client.crisp.chat/l.js";
        s.async = 1;
        d.getElementsByTagName("head")[0].appendChild(s);
        })();`
    const script = document.createElement('script')
    script.innerHTML = scriptContent
    document.head.appendChild(script)

    if (window.$crisp) {
      $crisp.push(['safe', true])
    }
  }

  function setUserName(name) {
    window.$crisp?.push(['set', 'user:nickname', [name]])
  }

  function setUserEmail(email) {
    window.$crisp?.push(['set', 'user:email', [email]])
  }

  function setSubscription(subscriptions) {
    subscriptions.forEach((subscription) => {
      const plan = subscription.plan
      window.$crisp?.push([
        'set',
        'session:data',
        [[[plan.product, plan.price]]]
      ])
    })
  }

  function setPlanUsage(usage, limit) {
    window.$crisp?.push([
      'set',
      'session:data',
      [[['uso-do-plano', `${usage}/${limit}`]]]
    ])
  }

  function sendMessage(text) {
    window.$crisp?.push(['do', 'message:show', ['text', text]])
  }

  return {
    setUserName,
    setUserEmail,
    setSubscription,
    setup,
    setPlanUsage,
    sendMessage
  }
}
