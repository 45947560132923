import client from '@/plugins/axios'

export const serverService = {
  resource: '/servers',
  findAll(params = {}) {
    return client.get(this.resource, {
      params
    })
  },

  create(data) {
    return client.post(this.resource, data)
  },

  show(id) {
    return client.get(`${this.resource}/${id}`)
  },

  checkStatus(id) {
    return client.get(`${this.resource}/${id}/status`)
  },

  delete(id) {
    return client.delete(`${this.resource}/${id}`)
  }
}
