<script setup>
import { useRoute } from 'vue-router'
import Button from '../ui/button/Button.vue'
import Label from '../ui/label/Label.vue'
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@/components/ui/select'
import {
  Pagination,
  PaginationEllipsis,
  PaginationFirst,
  PaginationLast,
  PaginationList,
  PaginationListItem,
  PaginationNext,
  PaginationPrev,
} from '@/components/ui/pagination'

const props = defineProps({
  total: {
    type: Number,
    required: true
  },
})

const route = useRoute()

const PER_PAGES = [10, 25, 50, 100]

function isCurrentPage(page) {
  if (!route.query.page) {
    return page === 1
  }

  return page === Number.parseInt(route.query.page?.toString())
}
</script>

<template>
  <nav
    v-if="props.total > 0"
    class="flex items-center justify-between py-2"
    aria-label="Pagination"
  >
    <div
      v-if="props.total"
      class="flex  items-center"
    >
      <Select
        :model-value="$route.query.limit?.toString() || '10'"
        @update:model-value="(p) => $router.push({ query: { ...$route.query, limit: p } })"
      >
        <SelectTrigger>
          <SelectValue placeholder="Items por página" />
        </SelectTrigger>
        <SelectContent>
          <SelectItem
            v-for="item in PER_PAGES"
            :key="item"
            :value="item.toString()"
          >
            {{ item }} / {{ $t('components.pagination.per_page') }}
          </SelectItem>
        </SelectContent>
      </Select>
    </div>
    <div class="flex justify-between gap-4 ">
      <Pagination
        :total="props.total"
        :sibling-count="1"
        :items-per-page="parseInt($route.query.limit?.toString()) || 10"
        show-edges
        :page="parseInt($route.query.page?.toString()) || 1"
        @update:page="(p) => $router.push({ query: { ...$route.query, page: p } })"
      >
        <PaginationList
          v-slot="{ items }"
          class="flex items-center gap-1"
        >
          <PaginationFirst />
          <PaginationPrev />

          <template v-for="(item, index) in items">
            <PaginationListItem
              v-if="item.type === 'page'"
              :key="index"
              :value="item.value"
            >
              <Button
                class="size-8 p-0"
                :variant="isCurrentPage(item.value) ? 'default' : 'outline'"
              >
                {{ item.value }}
              </Button>
            </PaginationListItem>
            <PaginationEllipsis
              v-else
              :key="item.type"
              :index="index"
            />
          </template>

          <PaginationNext />
          <PaginationLast />
        </PaginationList>
      </Pagination>
    </div>
  </nav>
</template>
