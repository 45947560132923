<script setup>
import { computed } from 'vue'
import { useHead } from '@unhead/vue'
import { useI18n } from 'vue-i18n'
import { FunnelIcon, LifebuoyIcon, ServerIcon, StarIcon } from '@heroicons/vue/24/solid'
import PageHeading from '@/components/common/PageHeading.vue'
import SectionHeading from '@/components/common/SectionHeading.vue'
import { useUserStore } from '@/stores/user'
import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbList,
  BreadcrumbPage,
} from '@/components/ui/breadcrumb'
import {
  Card,
  CardContent,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle
} from '@/components/ui/card'
import { Button } from '@/components/ui/button'

const { t } = useI18n()

useHead({
  title: t('pages.dashboard.title')
})

const userStore = useUserStore()

const cloakupLink = computed(() => {
  return userStore.user?.cloakup_active
    ? {
        name: 'Campaigns'
      }
    : {
        name: 'CheckoutPlans',
        query: {
          product: 'cloakup',
        },
      }
})
const hostLink = computed(() => {
  return userStore.user?.host_active
    ? {
        name: 'Servers',
      }
    : {
        name: 'CheckoutPlans',
        query: {
          product: 'host',
        },
      }
})
</script>

<template>
  <div class="space-y-5">
    <div class="space-y-2">
      <Breadcrumb>
        <BreadcrumbList>
          <BreadcrumbItem>
            <BreadcrumbPage>
              {{ $t('common.dashboard') }}
            </BreadcrumbPage>
          </BreadcrumbItem>
        </BreadcrumbList>
      </Breadcrumb>
      <PageHeading :title="$t('pages.dashboard.title')" />
    </div>

    <div>
      <SectionHeading
        :title="$t('pages.dashboard.products.title')"
      />

      <div class="grid grid-cols-1 gap-4 sm:grid-cols-2  md:grid-cols-3">
        <Card>
          <CardHeader>
            <FunnelIcon class="size-8" />
            <CardTitle>
              {{ $t('pages.dashboard.traffic_filter.title') }}
            </CardTitle>
            <CardDescription>
              {{ $t('pages.dashboard.traffic_filter.description') }}
            </CardDescription>
          </CardHeader>
          <CardFooter>
            <Button
              as-child
              variant="secondary"
            >
              <RouterLink :to="cloakupLink">
                {{ $t('common.access') }}
              </RouterLink>
            </Button>
          </CardFooter>
        </Card>
        <Card>
          <CardHeader>
            <ServerIcon class="size-8" />
            <CardTitle>
              {{ $t('pages.dashboard.host.title') }}
            </CardTitle>
            <CardDescription>
              {{ $t('pages.dashboard.host.description') }}
            </CardDescription>
          </CardHeader>
          <CardFooter>
            <Button
              as-child
              variant="secondary"
            >
              <RouterLink :to="hostLink">
                {{ $t('common.access') }}
              </RouterLink>
            </Button>
          </CardFooter>
        </Card>
      </div>
    </div>

    <div>
      <SectionHeading
        :title="$t('pages.dashboard.link_utilities')"
      />

      <div class="grid grid-cols-1 gap-4 sm:grid-cols-2  md:grid-cols-3">
        <Card>
          <CardHeader>
            <LifebuoyIcon class="size-8" />
            <CardTitle>
              {{ $t('pages.dashboard.help_center.title') }}
            </CardTitle>
            <CardDescription>
              {{ $t('pages.dashboard.help_center.description') }}
            </CardDescription>
          </CardHeader>
          <CardFooter>
            <Button
              as-child
              variant="secondary"
            >
              <a
                href="https://help.cloakup.me/"
                target="_blank"
              >
                {{ $t('common.access') }}
              </a>
            </Button>
          </CardFooter>
        </Card>
        <Card>
          <CardHeader>
            <StarIcon class="size-8" />
            <CardTitle>
              {{ $t('pages.dashboard.subscription.title') }}
            </CardTitle>
            <CardDescription>
              {{ $t('pages.dashboard.subscription.description') }}
            </CardDescription>
          </CardHeader>
          <CardFooter>
            <Button
              as-child
              variant="secondary"
            >
              <RouterLink
                :to="{
                  name: 'Subscription',
                }"
              >
                {{ $t('common.access') }}
              </RouterLink>
            </Button>
          </CardFooter>
        </Card>
      </div>
    </div>

    <div>
      <SectionHeading
        :title="$t('pages.dashboard.partners.title')"
      />

      <div class="grid grid-cols-1 gap-4 sm:grid-cols-2  md:grid-cols-3">
        <Card>
          <CardHeader>
            <div>
              <img
                src="/img/partners/digitalocean.svg"
                class="max-h-8 object-contain"
              >
            </div>
            <CardDescription>
              {{ $t('pages.dashboard.digital_ocean.title') }}
              <span class="font-bold">
                {{ $t('pages.dashboard.digital_ocean.description') }}
              </span>
            </CardDescription>
          </CardHeader>
          <CardFooter>
            <Button
              as-child
              variant="secondary"
            >
              <a
                href="https://m.do.co/c/2a2dba22cf08"
                target="_blank"
              >
                {{ $t('common.access') }}
              </a>
            </Button>
          </CardFooter>
        </Card>
        <Card>
          <CardHeader>
            <div>
              <img
                src="/img/partners/smsfunnel.png"
                class="max-h-8  object-contain"
              >
            </div>
            <CardDescription>
              {{ $t('pages.dashboard.sms_funnel.title') }}
              <span class="font-bold">
                {{ $t('pages.dashboard.sms_funnel.description') }}
              </span>
            </CardDescription>
          </CardHeader>
          <CardFooter>
            <Button
              as-child
              variant="secondary"
            >
              <a
                href="https://gosite.cc/free-5830"
                target="_blank"
              >
                {{ $t('common.access') }}
              </a>
            </Button>
          </CardFooter>
        </Card>
      </div>
    </div>
  </div>
</template>
