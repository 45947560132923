import ForgotPassword from '@/views/auth/ForgotPassword.vue'
import ResetPassword from '@/views/auth/ResetPassword.vue'
import SignIn from '@/views/auth/SignIn.vue'
import SignUp from '@/views/auth/SignUp.vue'

export default [
  {
    path: '/auth/sign-in',
    name: 'SignIn',
    component: SignIn,
    meta: {
      layout: 'auth',
      requiresAuth: false
    }
  },
  {
    path: '/auth/sign-up',
    name: 'SignUp',
    component: SignUp,
    meta: {
      layout: 'auth',
      requiresAuth: false
    }
  },
  {
    path: '/auth/forgot-password',
    name: 'ForgotPassword',
    component: ForgotPassword,
    meta: {
      layout: 'auth',
      requiresAuth: false
    }
  },
  {
    path: '/auth/reset-password',
    name: 'ResetPassword',
    component: ResetPassword,
    meta: {
      layout: 'auth',
      requiresAuth: false
    }
  }
]
