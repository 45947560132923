<script setup>
import { computed, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger
} from '@/components/ui/dialog'
import { useUserStore } from '@/stores/user'
import Button from '@/components/ui/button/Button.vue'
import Label from '@/components/ui/label/Label.vue'
import Textarea from '@/components/ui/textarea/Textarea.vue'
import Field from '@/components/ui/field/Field.vue'
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@/components/ui/select'

const props = defineProps({
  product: {
    type: String,
    required: true
  }
})

const { t } = useI18n()

const reasons = {
  too_expensive: t('components.cancel_subscription_modal.reasons.too_expensive'),
  missing_features: t('components.cancel_subscription_modal.reasons.missing_features'),
  switched_service: t('components.cancel_subscription_modal.reasons.switched_service'),
  unused: t('components.cancel_subscription_modal.reasons.unused'),
  customer_service: t('components.cancel_subscription_modal.reasons.customer_service'),
  too_complex: t('components.cancel_subscription_modal.reasons.too_complex'),
  low_quality: t('components.cancel_subscription_modal.reasons.low_quality'),
  other: t('components.cancel_subscription_modal.reasons.other')
}

const isOpen = ref(false)
const store = useUserStore()
const option = ref()
const reason = ref()
const suggestion = ref('')

const user = computed(() => store.user)

function sendCancelForm() {
  $crisp.push(['do', 'chat:open'])
  $crisp.push([
    'do',
    'message:send',
    [
      'text',
      `
        Olá, gostaria de cancelar minha assinatura. \n
        Produto: ${props.product} \n
        E-mail: ${user.value.email} \n
        Motivo: ${option.value === 'other' ? `${reason.value}` : reasons[option.value]} \n
        Sugestão: ${suggestion.value} \n
      `
    ]
  ])
  isOpen.value = false

  updateSubscription()
}

function updateSubscription() {
  const subscription = store.user.subscriptions.find(s => s.plan.product === props.product)
  if (subscription) {
    subscription.status = 'canceled'
  }
}
</script>

<template>
  <Dialog v-model:open="isOpen">
    <DialogTrigger>
      <span
        class="cursor-pointer text-xs text-muted-foreground"
      >
        {{ $t('components.cancel_subscription_modal.action') }}
      </span>
    </DialogTrigger>
    <DialogContent>
      <DialogHeader>
        <DialogTitle>
          {{ $t('components.cancel_subscription_modal.title') }}
        </DialogTitle>
        <DialogDescription>
          {{ $t('components.cancel_subscription_modal.description') }}
        </DialogDescription>
      </DialogHeader>

      <div class="mt-4">
        <form
          id="cancel-subscription-form"
          class="space-y-4"
          @submit.prevent="sendCancelForm"
        >
          <div>
            <Field>
              <Label
                for="reason"
                required
              >{{ $t('components.cancel_subscription_modal.reason') }}</Label>
              <Select
                v-model="option"
                required
              >
                <SelectTrigger
                  id="reason"
                  name="reason"
                >
                  <SelectValue :placeholder="$t('common.select_a_option')" />
                </SelectTrigger>
                <SelectContent position="popper">
                  <SelectItem
                    v-for="(lang, i) in Object.keys(reasons)"
                    :key="`Lang${i}`"
                    :value="lang"
                  >
                    <div class="flex flex-row  items-center gap-2">
                      {{ reasons[lang] }}
                    </div>
                  </SelectItem>
                </SelectContent>
              </Select>
              <Textarea
                v-if="option === 'other'"
                id="reason"
                v-model="reason"
                name="reason"
                minlength="10"
                maxlength="200"
                required
              />
            </Field>
          </div>
          <div>
            <Field>
              <Label for="suggestion">
                {{ $t('components.cancel_subscription_modal.suggestion') }}
              </Label>
              <Textarea
                id="suggestion"
                v-model="suggestion"
                optional
                name="suggestion"
              />
            </Field>
          </div>
        </form>
      </div>

      <DialogFooter class="mt-4">
        <DialogClose as-child>
          <Button
            type="button"
            variant="secondary"
          >
            {{ $t('components.cancel_subscription_modal.cancel') }}
          </Button>
        </DialogClose>
        <Button
          type="submit"
          form="cancel-subscription-form"
          variant="destructive"
        >
          {{ $t('components.cancel_subscription_modal.confirm') }}
        </Button>
      </DialogFooter>
    </DialogContent>
  </Dialog>
</template>
