<script setup>
defineProps({
  network: {
    type: String,
    required: true
  }
})

const networks = {
  facebook: '/img/networks/facebook.svg',
  tiktok: '/img/networks/tiktok.svg',
  google: '/img/networks/googleads.svg',
  kwai: '/img/networks/kwai.svg',
}
</script>

<template>
  <img
    :src="networks[network]"
    :alt="network"
  >
</template>
