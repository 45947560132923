<script setup>
import { useI18n } from 'vue-i18n'
import BaseTable from '@/components/base/BaseTable.vue'
import Pagination from '@/components/common/Pagination.vue'
import { formatCurrency } from '@/helpers/currency'
import EmptyState from '@/components/common/EmptyState.vue'
import TransactionStatusBadge from '@/components/partials/transactions/TransactionStatusBadge.vue'

defineProps({
  transactions: {
    type: Array,
    required: true
  },
  isLoading: {
    type: Boolean,
    default: false
  },
  hasError: {
    type: Boolean,
    default: false
  },
  count: {
    type: Number,
    default: 0
  }
})

const { t } = useI18n()

const isDeposit = type => type === 'deposit'
const isWithdraw = type => type === 'withdraw'
const isSuccess = status => status === 'success'
const isFailed = status => status === 'failed'

const tableHeader = [
  {
    label: t('common.type'),
    value: 'type',
    format: value => (value === 'deposit' ? 'Comissão' : 'Saque')
  },
  {
    label: t('common.description'),
    value: 'description'
  },
  {
    label: t('common.amount'),
    value: 'amount'
  },
  {
    label: t('common.status'),
    value: 'status'
  }
]
</script>

<template>
  <BaseTable
    :loading="isLoading"
    :error="hasError"
    :headers="tableHeader"
    :columns="transactions"
  >
    <template #col-amount="{ column }">
      <span
        :class="[
          isWithdraw(column.type) && isSuccess(column.status)
            ? 'font-semibold text-red-500'
            : isDeposit(column.type) && isSuccess(column.status)
              ? 'font-semibold text-green-500'
              : 'text-muted-foreground',
          isFailed(column.status) ? 'line-through' : '',
        ]"
      >
        {{ isWithdraw(column.type) ? "-" : "+" }}
        {{ formatCurrency(column.amount, column.currency) }}
      </span>
    </template>
    <template #col-status="{ row }">
      <TransactionStatusBadge :status="row" />
    </template>
    <template #pagination>
      <Pagination
        :total="count"
      />
    </template>
    <template #empty>
      <EmptyState
        :title="$t('components.transaction_table.empty_state.title')"
      />
    </template>
  </BaseTable>
</template>
