/**
 *
 * @param {string} value
 * @param {Function} t
 */
export function formatRequestDeniedReason(value, t) {
  return value
    ?.split(',')
    .map((reason) => {
      return t(`requests.denied_reasons.${reason}`)
    })
    .join(', ')
}

/**
 *
 * @param {string} value
 * @param {Function} t
 */
export function formatRequestDeniedReasonShorted(value, t) {
  if (!value) {
    return value
  }

  const format = formatRequestDeniedReason(value, t)
  const length = format?.split(',').length

  if (length === 1) {
    return format
  }

  if (length > 1) {
    const diff = length - 1
    return `${format?.split(',')[0]} ${t('common.and_more')} ${diff}...`
  }
}
