<script setup>
import { computed, ref, watch } from 'vue'
import { toast } from 'vue-sonner'
import FormStepCount from '@/components/common/FormStepCount.vue'
import PlanRadio from '@/components/partials/pay/PlanRadio.vue'
import { plansService } from '@/services/plans'

const props = defineProps({
  currentPlan: {
    type: Object,
    required: true
  }
})

const emit = defineEmits(['select-plan'])

const plans = ref([])
const isLoadingPlan = ref(false)
const selectedPlan = ref(null)

const filterPlans = computed(() =>
  plans.value
    .filter(plan => plan?.price > props.currentPlan.price)
    .filter(plan => plan?.cycle === props.currentPlan.cycle)
    .filter(plan => plan?.trial_days === 0)
)

async function fetchPlans(params) {
  isLoadingPlan.value = true
  try {
    const { data } = await plansService.findAll(params)
    plans.value = data.sort((a, b) => a.price - b.price)
  } catch (e) {
    toast.error(e.response?.data?.message, {
      description: e.response?.data?.action || e.message,
    })
  } finally {
    isLoadingPlan.value = false
  }
}
await fetchPlans({
  product: props.currentPlan.product
})

function selectPlan(plan) {
  emit('select-plan', plan)
}

watch(selectedPlan, (newPlan) => {
  if (newPlan) {
    selectPlan(newPlan)
  }
})
</script>

<template>
  <div>
    <div class="flex gap-2">
      <FormStepCount step="1" />

      <div>
        <label class="text-base font-medium">
          {{ $t("components.upgrade_resume.step_plans.title") }}
        </label>
        <p class="text-sm leading-5 text-muted-foreground">
          {{ $t("components.upgrade_resume.step_plans.description") }}
        </p>
      </div>
    </div>

    <div class="mt-5">
      <div v-if="!filterPlans.length">
        <div class="flex h-32 w-full items-center justify-center text-muted-foreground">
          <span>
            {{ $t("components.upgrade_resume.step_plans.no_plans") }}
          </span>
        </div>
      </div>
      <div v-else>
        <PlanRadio
          v-model="selectedPlan"
          :options="filterPlans"
          :disabled="isLoadingPlan"
        />
      </div>
    </div>
  </div>
</template>
