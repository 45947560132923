<script setup>
import { ref } from 'vue'
import {
  ComboboxAnchor,
  ComboboxInput,
  ComboboxRoot,
} from 'radix-vue'
import {
  TagsInput,
  TagsInputInput,
  TagsInputItem,
  TagsInputItemDelete,
  TagsInputItemText,
} from '@/components/ui/tags-input'
import TextAreaMultipleModal from '@/components/partials/campaign/TextAreaMultipleModal.vue'
import Button from '@/components/ui/button/Button.vue'

defineProps({
  placeholder: {
    type: String,
    default: ''
  },
})

const model = defineModel({
  default: () => [],
})
const open = ref(false)

function addMultiple(items) {
  model.value = [...model.value, ...items]
}

function removeAll() {
  model.value = []
}
</script>

<template>
  <div class="flex items-center  gap-2">
    <TagsInput
      v-model="model"
      class=" w-80 gap-0 px-0 "
      add-on-paste
      add-on-tab
      add-on-blur
    >
      <div class="flex flex-wrap items-center gap-2 px-3">
        <TagsInputItem
          v-for="item in model"
          :key="item"
          :value="item"
        >
          <TagsInputItemText />
          <TagsInputItemDelete />
        </TagsInputItem>
      </div>

      <ComboboxRoot
        v-model="model"
        v-model:open="open"
        class="w-full"
      >
        <ComboboxAnchor as-child>
          <ComboboxInput
            :placeholder="placeholder"
            as-child
            class="w-full border-none bg-transparent placeholder:text-muted-foreground focus:border-0 focus:outline-none focus:ring-0"
          >
            <TagsInputInput
              class="w-full px-3"
              :class="model.length > 0 ? 'mt-2' : 'py-1'"
              @keydown.enter.prevent
            />
          </ComboboxInput>
        </ComboboxAnchor>
      </ComboboxRoot>
    </TagsInput>
    <div class="flex  gap-2">
      <Button
        type="button"
        @click="removeAll"
      >
        {{ $t('common.clear') }}
      </Button>
      <TextAreaMultipleModal
        @add="addMultiple"
      />
    </div>
  </div>
</template>
