<script setup>
import { computed, reactive, ref, watch } from 'vue'
import { CommandLineIcon, DevicePhoneMobileIcon, GlobeAltIcon, InformationCircleIcon, LanguageIcon } from '@heroicons/vue/24/solid'
import { useI18n } from 'vue-i18n'
import { useRoute } from 'vue-router'
import BaseSwitch from '@/components/base/BaseSwitch.vue'
import Multiselect from '@/components/base/MultiSelect.vue'
import AllowBlockButton from '@/components/partials/campaign/AllowBlockButton.vue'
import CampaignPageOffersInput from '@/components/partials/campaign/CampaignPageOffersInput.vue'
import browserLanguages from '@/static/browserLanguages'
import { countryList } from '@/static/countryList'
import Button from '@/components/ui/button/Button.vue'
import Label from '@/components/ui/label/Label.vue'
import Input from '@/components/ui/input/Input.vue'
import ErrorMessage from '@/components/ui/error-message/ErrorMessage.vue'
import Field from '@/components/ui/field/Field.vue'
import { Card, CardFooter } from '@/components/ui/card/'
import TagInput from '@/components/TagInput.vue'
import QueryParamsForm from '@/components/partials/campaign/QueryParamsForm.vue'
import SectionHeading from '@/components/common/SectionHeading.vue'
import Description from '@/components/ui/description/Description.vue'
import { Alert, AlertDescription, AlertTitle } from '@/components/ui/alert'
import CampaignFilterInReviewWarningAlert from '@/components/partials/campaign/CampaignFilterInReviewWarningAlert.vue'
import CampaignPageTypeRadio from '@/components/partials/campaign/CampaignPageTypeRadio.vue'
import { RadioGroup, RadioGroupItem } from '@/components/ui/radio-group'

const props = defineProps({
  campaign: {
    type: Object,
    required: false,
    default: () => ({
      name: '',
      active: true,
      alias: null,
      domain: null,
      mode: 'basic',
      pages: {
        white: {
          type: 'content',
          content: ''
        },
        offers: [
          {
            content: '',
            share: 100,
            type: 'content'
          }
        ]
      },
      filters: {
        deny_always: false,
        tiktok: false,
        proxy: false,
        google: false,
        facebook: false,
        bots: false,
        kwai: false,
        cloakup_ai: true,
        adspy: false,
        geolocation: {
          allow: true,
          countries: []
        },
        device: {
          allow: true,
          devices: []
        },
        referer: {
          block_null: false,
          allow: true,
          domains: []
        },
        query: {
          allow: true,
          params: {},
          condition: 'some',
          remove_params: false,
          rules: {}
        },
        domain: {
          allow: true,
          domains: []
        },
        browser_language: {
          allow: true,
          languages: []
        },
        isp: {
          allow: true,
          isps: []
        },
        os: {
          allow: true,
          os: []
        },
        user_agent: {
          allow: true,
          user_agents: []
        },
        blacklist: [],
        whitelist: []
      }
    })
  },
  errors: {
    type: Object,
    required: false,
    default: () => ({})
  },
  isLoading: {
    type: Boolean,
    required: true,
    default: false
  },
  mode: {
    type: String,
    required: false,
    default: 'create'
  }
})

const emit = defineEmits(['submit'])
const { t, locale } = useI18n()
const route = useRoute()

const devices = [
  {
    id: 'desktop',
    name: t('common.devices.desktop'),
  },
  {
    id: 'smartphone',
    name: t('common.devices.smartphone'),
  },
  {
    id: 'tablet',
    name: t('common.devices.tablet'),
  },
  {
    id: 'unknown',
    name: t('common.devices.unknown'),
  }
]

const os = [
  {
    id: 'android',
    name: t('common.oss.android')
  },
  {
    id: 'ios',
    name: t('common.oss.ios')
  },
  {
    id: 'windows',
    name: t('common.oss.windows')
  },
  {
    id: 'mac',
    name: t('common.oss.mac')
  },
  {
    id: 'linux',
    name: t('common.oss.linux')
  },
  {
    id: 'ubuntu',
    name: t('common.oss.ubuntu')
  },
  {
    id: 'other',
    name: t('common.oss.other')
  },
  {
    id: 'unknown',
    name: t('common.oss.unknown')
  }
]

const form = reactive({
  ...props.campaign
})
const showInReviewWarning = ref(false)

const countriesMapped = computed(() => {
  return countryList.map((country) => {
    const key = locale.value !== 'pt-BR' ? 'name_int' : 'name'
    return {
      ...country,
      name: country[key]
    }
  }).sort((a, b) => a.name.localeCompare(b.name))
})
const browserLanguagesMapped = computed(() => {
  return browserLanguages.map((language) => {
    const key = locale.value !== 'pt-BR' ? 'name_int' : 'name'
    return {
      ...language,
      name: language[key]
    }
  }).sort((a, b) => a.name.localeCompare(b.name))
})

function submitForm() {
  const formatOffersPage = form.pages.offers
    .map(item => ({
      type: item.type,
      content: item.content,
      share: Number(item.share)
    }))

  const payload = {
    name: form.name,
    pages: {
      white: {
        type: form.pages.white.type,
        content: form.pages.white.content
      },
      offers: formatOffersPage
    },
    mode: form.mode || route.query.mode,
    active: form.active,
    alias: form.alias,
    domain: form.domain,
    filters: {
      deny_always: form.filters.deny_always || false,
      facebook: form.filters.facebook || false,
      google: form.filters.google || false,
      bots: form.filters.bots || false,
      tiktok: form.filters.tiktok || false,
      proxy: form.filters.proxy || false,
      kwai: form.filters.kwai || false,
      cloakup_ai: form.filters.cloakup_ai || false,
      adspy: form.filters.adspy || false,
      geolocation: {
        allow: form.filters.geolocation.allow,
        countries: form.filters.geolocation.countries
      },
      device: {
        allow: form.filters.device.allow,
        devices: form.filters.device.devices
      },
      referer: {
        block_null: form.filters.referer.block_null,
        allow: form.filters.referer.allow,
        domains: form.filters.referer.domains
      },
      query: {
        allow: form.filters.query.allow,
        params: form.filters.query.params,
        condition: form.filters.query.condition || 'some',
        remove_params: form.filters.query.remove_params || false,
        rules: form.filters.query.rules || {}
      },
      domain: {
        domains: form.filters.domain.domains,
        allow: form.filters.domain.allow ?? true
      },
      browser_language: {
        allow: form.filters.browser_language.allow,
        languages: form.filters.browser_language.languages
      },
      isp: {
        allow: form.filters.isp.allow,
        isps: form.filters.isp.isps
      },
      os: {
        allow: form.filters.os.allow,
        os: form.filters.os.os
      },
      user_agent: {
        allow: form.filters.user_agent.allow,
        user_agents: form.filters.user_agent.user_agents
      },
      whitelist: form.filters.whitelist,
      blacklist: form.filters.blacklist
    },
  }

  emit('submit', payload)
}

watch(() => form.filters.deny_always, () => {
  showInReviewWarning.value = form.filters.deny_always
})
</script>

<template>
  <div>
    <form @submit.prevent="submitForm()">
      <fieldset :disabled="isLoading">
        <div class="space-y-6">
          <!-- Detalhes -->
          <div>
            <div class="grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
              <div class="col-span-6">
                <Field>
                  <Label
                    required
                    for="campaign-name"
                  >
                    {{ $t('common.name') }}
                  </Label>
                  <Input
                    id="campaign-name"
                    v-model="form.name"
                    name="campaign-name"
                    type="text"
                    required
                  />
                  <ErrorMessage :message="errors?.name" />
                </Field>
              </div>
              <div class="col-span-6">
                <BaseSwitch
                  v-model="form.active"
                  :label="`${$t('components.campaign_save_form.campaign_status')} (${form.active ? $t('components.campaign_status_badge.active') : $t('components.campaign_status_badge.disabled')
                  })`"
                />
              </div>
            </div>
          </div>

          <!-- Página Segura -->
          <div>
            <div>
              <SectionHeading
                :title="$t('components.campaign_save_form.safe_page.title')"
                :description="$t('components.campaign_save_form.safe_page.description')"
              />
            </div>
            <div class="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
              <div class="col-span-6">
                <div class="col-span-3 space-y-4 lg:col-span-1">
                  <div>
                    <Field>
                      <Label
                        for="white_page"
                        required
                      >
                        {{ $t('components.campaign_save_form.page_url') }}
                      </Label>
                      <Input
                        id="white_page"
                        v-model="form.pages.white.content"
                        name="white_page"
                        type="text"
                      />
                      <ErrorMessage
                        v-if="errors?.pages?.white"
                        :message="errors?.pages?.white"
                      />
                    </Field>
                  </div>
                  <Field>
                    <Label>
                      {{ $t('exhibition_page_method') }}
                    </Label>
                    <CampaignPageTypeRadio
                      v-model="form.pages.white.type"
                      page="white"
                    />
                  </Field>
                </div>
              </div>
            </div>
          </div>

          <!-- Página Oferta -->
          <div>
            <div>
              <SectionHeading
                :title="$t('components.campaign_save_form.offer_page.title')"
                :description="$t('components.campaign_save_form.offer_page.description')"
              />
            </div>
            <div class="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
              <div class="col-span-6">
                <CampaignPageOffersInput
                  v-model="form.pages.offers"
                  :errors="errors"
                />
              </div>
            </div>
          </div>

          <!-- Predefinidos -->
          <div class="border-b border-border pb-6">
            <div>
              <SectionHeading
                title="Filtros"
                description="Adicione os filtros que você deseja para sua campanha."
              />
            </div>
            <div class="mt-10 grid grid-cols-1 gap-6 sm:grid-cols-6">
              <div class="col-span-6">
                <BaseSwitch
                  v-model="form.filters.cloakup_ai"
                  :label="$t('components.campaign_save_form.filters.cloakup_ai.title')"
                  :description="$t('components.campaign_save_form.filters.cloakup_ai.description')"
                />
              </div>
              <div class="col-span-6">
                <BaseSwitch
                  v-model="form.filters.facebook"
                  :label="$t('components.campaign_save_form.filters.facebook.title')"
                  :description="$t('components.campaign_save_form.filters.facebook.description')"
                />
              </div>
              <div class="col-span-6">
                <BaseSwitch
                  v-model="form.filters.google"
                  :label="$t('components.campaign_save_form.filters.google.title')"
                  :description="$t('components.campaign_save_form.filters.google.description')"
                />
              </div>
              <div class="col-span-6">
                <BaseSwitch
                  v-model="form.filters.tiktok"
                  :label="$t('components.campaign_save_form.filters.tiktok.title')"
                  :description="$t('components.campaign_save_form.filters.tiktok.description')"
                />
              </div>
              <div class="col-span-6">
                <BaseSwitch
                  v-model="form.filters.kwai"
                  :label="$t('components.campaign_save_form.filters.kwai.title')"
                  :description="$t('components.campaign_save_form.filters.kwai.description')"
                />
              </div>
              <div class="col-span-6">
                <BaseSwitch
                  v-model="form.filters.adspy"
                  :label="$t('components.campaign_save_form.filters.adspy.title')"
                  :description="$t('components.campaign_save_form.filters.adspy.description')"
                />
              </div>
              <div class="col-span-6">
                <BaseSwitch
                  v-model="form.filters.bots"
                  :label="$t('components.campaign_save_form.filters.bots.title')"
                  :description="$t('components.campaign_save_form.filters.bots.description')"
                />
              </div>
              <div class="col-span-6">
                <BaseSwitch
                  v-model="form.filters.proxy"
                  :label="$t('components.campaign_save_form.filters.proxy.title')"
                  :description="$t('components.campaign_save_form.filters.proxy.description')"
                />
              </div>
              <div class="col-span-6">
                <BaseSwitch
                  v-model="form.filters.deny_always"
                  :label="$t('components.campaign_save_form.filters.deny_always.title')"
                  :description="$t('components.campaign_save_form.filters.deny_always.description')"
                />
              </div>
            </div>
          </div>

          <!-- Filtro de geolocalização -->
          <div class="border-b border-border pb-6">
            <SectionHeading
              :title="$t('components.campaign_save_form.filters.geolocation.title')"
              :description="$t('components.campaign_save_form.filters.geolocation.description')"
            />

            <div class="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
              <div class="col-span-6">
                <AllowBlockButton v-model="form.filters.geolocation.allow" />
              </div>
              <div class="col-span-6">
                <div class="pt-5">
                  <Multiselect
                    v-model="form.filters.geolocation.countries"
                    :options="countriesMapped"
                    :icon="GlobeAltIcon"
                    :placeholder="$t('components.campaign_save_form.filters.geolocation.select.placeholder')"
                    :empty="$t('components.campaign_save_form.filters.geolocation.select.empty')"
                  />
                  <ErrorMessage
                    v-if="errors?.filters?.geolocation?.countries"
                    :message="errors?.filters?.geolocation?.countries"
                  />
                </div>
              </div>
            </div>
          </div>

          <!-- Filtro de dispositivo -->
          <div class="border-b border-border pb-6">
            <SectionHeading
              :title="$t('components.campaign_save_form.filters.device.title')"
              :description="$t('components.campaign_save_form.filters.device.description')"
            />

            <div class="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
              <div class="col-span-6">
                <AllowBlockButton v-model="form.filters.device.allow" />
              </div>
              <div class="col-span-6">
                <Multiselect
                  v-model="form.filters.device.devices"
                  :options="devices"
                  :icon="DevicePhoneMobileIcon"
                  :placeholder="$t('components.campaign_save_form.filters.device.select.placeholder')"
                  :empty="$t('components.campaign_save_form.filters.device.select.empty')"
                />
                <ErrorMessage
                  v-if="errors?.filters?.device?.devices"
                  :message="errors?.filters?.device?.devices"
                />
              </div>
            </div>
          </div>

          <!-- Filtro de parâmetros de URL -->
          <div class="border-b border-border pb-6">
            <SectionHeading
              :title="$t('components.campaign_save_form.filters.query.title')"
              :description="$t('components.campaign_save_form.filters.query.description')"
            />

            <div class="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
              <div class="col-span-6">
                <AllowBlockButton v-model="form.filters.query.allow" />
              </div>
              <div class="col-span-6">
                <Field>
                  <QueryParamsForm
                    v-model="form.filters.query"
                  />

                  <ErrorMessage
                    v-if="errors?.filters?.query?.params"
                    :message="errors?.filters?.query?.params"
                  />
                </Field>
              </div>

              <div class="col-span-6">
                <legend class="text-sm font-semibold leading-6 text-muted-foreground">
                  {{ $t('components.campaign_save_form.filters.query.comparator_mode.title') }}
                </legend>
                <div class="mt-2 space-y-6">
                  <RadioGroup
                    v-model="form.filters.query.condition"
                  >
                    <div class="flex  items-center space-x-2">
                      <RadioGroupItem
                        id="every"
                        value="every"
                      />
                      <div class="flex flex-col gap-x-2">
                        <Label
                          for="every"
                        >
                          {{ $t('components.campaign_save_form.filters.query.comparator_mode.every.title') }}
                        </Label>
                        <Description>
                          {{ $t('components.campaign_save_form.filters.query.comparator_mode.every.description') }}
                        </Description>
                      </div>
                    </div>
                    <div class="flex items-center space-x-2">
                      <RadioGroupItem
                        id="some"
                        value="some"
                      />
                      <div class="flex flex-col gap-x-2">
                        <Label for="some">
                          {{ $t('components.campaign_save_form.filters.query.comparator_mode.some.title') }}
                        </Label>
                        <Description>
                          {{ $t('components.campaign_save_form.filters.query.comparator_mode.some.description') }}
                        </Description>
                      </div>
                    </div>
                  </RadioGroup>
                </div>
              </div>

              <div class="col-span-6">
                <BaseSwitch
                  v-model="form.filters.query.remove_params"
                  :label="$t('components.campaign_save_form.filters.query.remove_params.title')"
                  :description="$t('components.campaign_save_form.filters.query.remove_params.description')"
                />
              </div>
            </div>
          </div>

          <!-- Filtro de linguagem do navegador -->
          <div class="border-b border-border pb-6">
            <SectionHeading
              :title="$t('components.campaign_save_form.filters.browser_language.title')"
              :description="$t('components.campaign_save_form.filters.browser_language.description')"
            />

            <div class="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
              <div class="col-span-6">
                <AllowBlockButton v-model="form.filters.browser_language.allow" />
              </div>

              <div class="sm:col-span-6">
                <Multiselect
                  v-model="form.filters.browser_language.languages"
                  :options="browserLanguagesMapped"
                  :icon="LanguageIcon"
                  :placeholder="$t('components.campaign_save_form.filters.browser_language.select.placeholder')"
                  :empty="$t('components.campaign_save_form.filters.browser_language.select.empty')"
                />
                <ErrorMessage
                  v-if="errors?.filters?.browser_language?.languages"
                  :message="errors?.filters?.browser_language?.languages"
                />
              </div>
            </div>
          </div>

          <!-- Filtro de referencia -->
          <div class="border-b border-border pb-6">
            <SectionHeading
              :title="$t('components.campaign_save_form.filters.referer.title')"
              :description="$t('components.campaign_save_form.filters.referer.description')"
            />

            <div class="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
              <div class="col-span-6">
                <AllowBlockButton v-model="form.filters.referer.allow" />
              </div>
              <div class="col-span-6">
                <TagInput
                  v-model="form.filters.referer.domains"
                  :placeholder="$t('common.type_separate_by_comma')"
                />
                <ErrorMessage
                  v-if="errors?.filters?.referer?.domains"
                  :message="errors?.filters?.referer?.domains"
                />
              </div>
              <div class="col-span-6">
                <Alert>
                  <InformationCircleIcon class="size-5" />
                  <AlertTitle>
                    {{ $t('components.campaign_save_form.filters.referer.alert.title') }}
                  </AlertTitle>
                  <AlertDescription>
                    {{ $t('components.campaign_save_form.filters.referer.alert.message') }}
                    <strong>facebook.com</strong> {{ $t('common.and') }}
                    <strong>*.facebook.com</strong>
                  </AlertDescription>
                </Alert>
              </div>
              <div class="col-span-6">
                <BaseSwitch
                  v-model="form.filters.referer.block_null"
                  :label="$t('components.campaign_save_form.filters.referer.block_null.title')"
                  :description="$t('components.campaign_save_form.filters.referer.block_null.description')"
                />
              </div>
            </div>
          </div>

          <!-- Filtro de sistema operacional -->
          <div class="border-b border-border pb-6">
            <SectionHeading
              :title="$t('components.campaign_save_form.filters.os.title')"
              :description="$t('components.campaign_save_form.filters.os.description')"
            />

            <div class="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
              <div class="col-span-6">
                <AllowBlockButton v-model="form.filters.os.allow" />
              </div>

              <div class="sm:col-span-6">
                <Multiselect
                  v-model="form.filters.os.os"
                  :options="os"
                  :icon="CommandLineIcon"
                  :placeholder="$t('components.campaign_save_form.filters.os.select.placeholder')"
                  :empty="$t('components.campaign_save_form.filters.os.select.empty')"
                />
                <ErrorMessage
                  v-if="errors?.filters?.os?.os"
                  :message="errors?.filters?.os?.os"
                />
              </div>
            </div>
          </div>

          <!-- Filtro de user agent -->
          <div class="border-b border-border pb-6">
            <SectionHeading
              :title="$t('components.campaign_save_form.filters.user_agent.title')"
              :description="$t('components.campaign_save_form.filters.user_agent.description')"
            />

            <div class="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
              <div class="col-span-6">
                <AllowBlockButton v-model="form.filters.user_agent.allow" />
              </div>
              <div class="col-span-6">
                <Field>
                  <TagInput
                    v-model="form.filters.user_agent.user_agents"
                    :placeholder="$t('common.type_separate_by_comma')"
                  />
                  <ErrorMessage
                    v-if="errors?.filters?.user_agent?.user_agents"
                    :message="errors?.filters?.user_agent?.user_agents"
                  />
                </Field>
              </div>
            </div>
          </div>

          <!-- Filtro de ISP -->
          <div class="border-b border-border pb-6">
            <SectionHeading
              :title="$t('components.campaign_save_form.filters.isp.title')"
              :description="$t('components.campaign_save_form.filters.isp.description')"
            />

            <div class="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
              <div class="col-span-6">
                <AllowBlockButton v-model="form.filters.isp.allow" />
              </div>
              <div class="col-span-6">
                <div class="mt-1">
                  <TagInput
                    v-model="form.filters.isp.isps"
                    :placeholder="$t('common.type_separate_by_comma')"
                  />
                </div>
                <ErrorMessage
                  v-if="errors?.filters?.isp?.isps"
                  :message="errors?.filters?.isp?.isps"
                />
              </div>
            </div>
          </div>

          <!-- Filtro de domínio -->
          <div class="border-b border-border pb-6">
            <SectionHeading
              :title="$t('components.campaign_save_form.filters.domain.title')"
              :description="$t('components.campaign_save_form.filters.domain.description')"
            />

            <div class="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
              <div class="col-span-6">
                <AllowBlockButton v-model="form.filters.domain.allow" />
              </div>
              <div class="col-span-6">
                <div class="mt-1">
                  <TagInput
                    v-model="form.filters.domain.domains"
                    :placeholder="$t('common.type_separate_by_comma')"
                  />
                </div>
                <ErrorMessage
                  v-if="errors?.filters?.domain?.domains"
                  :message="errors?.filters?.domain?.domains"
                />
              </div>
              <div class="col-span-6">
                <Alert>
                  <InformationCircleIcon class="size-5" />
                  <AlertTitle>
                    {{ $t('components.campaign_save_form.filters.domain.alert.title') }}
                  </AlertTitle>
                  <AlertDescription>
                    {{ $t('components.campaign_save_form.filters.domain.alert.message') }}
                    <strong>Javascript</strong>
                  </AlertDescription>
                </Alert>
              </div>
            </div>
          </div>

          <!-- Filtro de IP -->
          <div>
            <SectionHeading
              :title="$t('components.campaign_save_form.filters.ip.title')"
              :description="$t('components.campaign_save_form.filters.ip.description')"
            />

            <div class="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
              <div class="sm:col-span-6">
                <Field>
                  <Label for="blacklist">Blacklist</Label>
                  <TagInput
                    id="blacklist"
                    v-model="form.filters.blacklist"
                    :placeholder="$t('common.type_separate_by_comma')"
                  />
                  <template
                    v-if="errors?.filters && Object?.keys(errors?.filters)?.filter(key => key.includes('blacklist'))"
                  >
                    <ErrorMessage
                      v-for="(error) in Object.keys(errors?.filters).filter(key => key.includes('blacklist'))"
                      :key="error"
                      :message="`${errors?.filters?.[error]}`"
                    />
                  </template>
                </Field>
              </div>
              <div class="col-span-6">
                <Field>
                  <Label for="whitelist">Whitelist</Label>
                  <TagInput
                    id="whitelist"
                    v-model="form.filters.whitelist"
                    :placeholder="$t('common.type_separate_by_comma')"
                  />
                  <Description>
                    {{ $t('components.campaign_save_form.filters.ip.get_your_ip') }} <a
                      href="https://www.whatismyip.com/"
                      target="_blank"
                      class="text-blue-600 underline hover:text-blue-800"
                    >
                      {{ $t('components.campaign_save_form.filters.ip.access_this_site') }}
                    </a>
                  </Description>
                  <template
                    v-if="errors?.filters && Object?.keys(errors?.filters)?.filter(key => key.includes('whitelist'))"
                  >
                    <ErrorMessage
                      v-for="(error) in Object.keys(errors?.filters).filter(key => key.includes('whitelist'))"
                      :key="error"
                      :message="`${errors?.filters?.[error]}`"
                    />
                  </template>
                </Field>
              </div>
            </div>
          </div>

          <div class="sticky bottom-2">
            <Card>
              <CardFooter class="pt-6">
                <div class="flex items-center justify-between">
                  <div class="flex items-center gap-x-6">
                    <Button
                      type="submit"
                      :loading="isLoading"
                    >
                      {{
                        $t('common.save')
                      }}
                    </Button>
                  </div>
                </div>
              </CardFooter>
            </Card>
          </div>
        </div>
      </fieldset>
    </form>

    <CampaignFilterInReviewWarningAlert v-model="showInReviewWarning" />
  </div>
</template>
