import ServerView from '@/views/server/ServerView.vue'
import ServersView from '@/views/server/ServersView.vue'

export default [
  {
    path: '/servers',
    name: 'Servers',
    component: ServersView,
    meta: {
      layout: 'default',
      requiresAuth: true,
      requiredHostSubscription: true
    }
  },
  {
    path: '/servers/:id',
    name: 'Server',
    component: ServerView,
    meta: {
      layout: 'default',
      requiresAuth: true,
      requiredHostSubscription: true
    }
  }
]
