<script setup>
import { computed } from 'vue'
import { toast } from 'vue-sonner'
import { InformationCircleIcon, PlusIcon, TrashIcon } from '@heroicons/vue/24/solid'
import { useI18n } from 'vue-i18n'
import Button from '@/components/ui/button/Button.vue'
import ErrorMessage from '@/components/ui/error-message/ErrorMessage.vue'
import Label from '@/components/ui/label/Label.vue'
import Input from '@/components/ui/input/Input.vue'
import Field from '@/components/ui/field/Field.vue'
import { Alert, AlertDescription, AlertTitle } from '@/components/ui/alert'
import {
  NumberField,
  NumberFieldContent,
  NumberFieldDecrement,
  NumberFieldIncrement,
  NumberFieldInput,
} from '@/components/ui/number-field'
import CampaignPageTypeRadio from '@/components/partials/campaign/CampaignPageTypeRadio.vue'

defineProps({
  errors: {
    type: Object,
    required: true,
    default: () => ({})
  }
})

const { t } = useI18n()

const model = defineModel({
  required: true
})

// offer input
const isUnbalanced = computed(() => {
  if (model.value.length === 0) {
    return false
  }
  const total = model.value.reduce((acc, page) => acc + Number(page.share), 0)
  return total < 100 || total > 100
})

function balanceOffers() {
  model.value.forEach((page) => {
    const share = (100 / model.value.length).toFixed(2)

    if (page === model.value[model.value.length - 1]) {
      page.share = (100 - share * (model.value.length - 1)).toFixed(2)
    } else {
      page.share = share
    }
  })
}

function addOffer() {
  const share = 100 - model.value.length

  model.value.push({
    content: '',
    share,
    type: 'content'
  })

  balanceOffers()
}

function removeOffer(index) {
  if (model.value.length === 1) {
    toast.error(t('components.campaign_page_offer_input.toast.error.title'), {
      description: t('components.campaign_page_offer_input.toast.error.description'),
    })

    return
  }

  model.value.splice(index, 1)

  balanceOffers()
}
</script>

<template>
  <div>
    <ul>
      <li
        v-for="(offer, index) in model"
        :key="index"
        class="col-span-2 pb-8 lg:col-span-1"
      >
        <div
          class="flex flex-1 gap-4"
          :class="{
            'pt-6': index > 0,
          }"
        >
          <div class="flex-1 space-y-4">
            <div class="flex flex-col  gap-2 sm:flex-row">
              <Field class="flex-1">
                <Label
                  required
                  :for="`offer_page_url-${index}`"
                >
                  {{ $t('components.campaign_page_offer_input.page_url') }}
                </Label>
                <Input
                  :id="`offer_page_url-${index}`"
                  v-model="offer.content"
                  :name="`offer_page_url-${index}`"
                  type="text"
                  required
                />
                <ErrorMessage :message="errors.pages && errors.pages[`offers[${index}]`]" />
              </Field>
              <Field>
                <NumberField
                  id="percent"
                  v-model="offer.share"
                  :max="100"
                  :min="0.01"
                  :step="0.01"
                >
                  <Label :for="`offer_share-${index}`">
                    {{ $t('components.campaign_page_offer_input.traffic_division') }}
                  </Label>
                  <NumberFieldContent>
                    <NumberFieldDecrement />
                    <NumberFieldInput
                      :id="`offer_share-${index}`"
                      :name="`offer_share-${index}`"
                    />
                    <NumberFieldIncrement />
                  </NumberFieldContent>
                </NumberField>
              </Field>
            </div>
            <Field>
              <Label>
                {{ $t('exhibition_page_method') }}
              </Label>
              <CampaignPageTypeRadio
                v-model="offer.type"
                :page="`offer-${index}`"
              />
            </Field>
          </div>
          <div class="pt-8">
            <button
              class="rounded-full bg-red-600 p-2 text-white shadow-sm hover:bg-red-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-600"
              type="button"
              rounded
              @click="removeOffer(index)"
            >
              <TrashIcon class="size-5" />
            </button>
          </div>
        </div>
        <div class="relative mt-12">
          <div
            class="absolute inset-0 flex items-center"
            aria-hidden="true"
          >
            <div class="w-full border-t border-border" />
          </div>
          <div
            class="relative"
            :class="[
              model.length === index + 1 ? 'flex justify-center' : 'hidden',
            ]"
          >
            <Button
              type="button"
              variant="secondary"
              @click="addOffer"
            >
              <PlusIcon class="mr-2 size-5" />

              {{ $t('components.campaign_page_offer_input.add_page') }}
            </Button>
          </div>
        </div>
      </li>
    </ul>

    <div
      v-if="isUnbalanced"
      class="mt-5"
    >
      <Alert variant="destructive">
        <InformationCircleIcon class="size-5" />
        <AlertTitle>
          {{ $t('components.campaign_page_offer_input.alert.title') }}
        </AlertTitle>
        <AlertDescription>
          {{ $t('components.campaign_page_offer_input.alert.message') }}
        </AlertDescription>

        <div>
          <Button
            type="button"
            class="mt-2"
            variant="destructive"
            @click="balanceOffers"
          >
            {{ $t('components.campaign_page_offer_input.balance') }}
          </Button>
        </div>
      </Alert>
    </div>
  </div>
</template>
