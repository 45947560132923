<script setup>
const props = defineProps({
  dir: {
    type: String,
    default: 'col'
  },
})
</script>

<template>
  <div
    class="*:has-[:disabled]:pointer-events-none *:has-[:disabled]:opacity-50"
    :class="[
      props.dir === 'col' ? 'flex flex-col gap-y-2' : 'flex items-center gap-x-2 ',
    ]"
  >
    <slot />
  </div>
</template>
