<script setup>
import { reactive, ref } from 'vue'
import { toast } from 'vue-sonner'
import { useI18n } from 'vue-i18n'
import { userService } from '@/services/user'
import { useUserStore } from '@/stores/user'
import Button from '@/components/ui/button/Button.vue'
import Label from '@/components/ui/label/Label.vue'
import Input from '@/components/ui/input/Input.vue'
import ErrorMessage from '@/components/ui/error-message/ErrorMessage.vue'
import Field from '@/components/ui/field/Field.vue'

const { t } = useI18n()
const store = useUserStore()

const isLoading = ref(false)
const errors = ref({})
const form = reactive({
  document: store.user.document
})

function submit() {
  const payload = {
    document: form.document?.replace(/\D/g, '')
  }

  updateDocument(payload)
}

async function updateDocument(form) {
  isLoading.value = true
  errors.value = {}
  try {
    await userService.updateDocument(form)

    toast.success(t('components.update_tax_id.toast.success.title'), {
      description: t('components.update_tax_id.toast.success.description'),
    })
  } catch (e) {
    if (e.response?.status === 422) {
      errors.value = e.response.data?.data
    }

    toast.error(e.response?.data?.message, {
      description: e.response?.data?.action || e.message,
    })
  } finally {
    isLoading.value = false
  }
}
</script>

<template>
  <form @submit.prevent="submit">
    <fieldset :disabled="isLoading">
      <div class="grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
        <div class="col-span-6">
          <Field>
            <Label for="document">
              {{ $t("components.update_tax_id.tax_id") }}
            </Label>
            <Input
              id="document"
              v-model="form.document"
              type="tel"
              name="document"
              :invalid="errors?.document"
            />
            <ErrorMessage :message="errors?.document" />
          </Field>
        </div>
      </div>
      <div class="mt-6 flex items-center justify-end">
        <Button
          type="submit"
          :loading="isLoading"
        >
          {{ $t('common.save') }}
        </Button>
      </div>
    </fieldset>
  </form>
</template>
