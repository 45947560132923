import { isLocal } from '@/helpers/is-local'

export function gtag() {
  function setup() {
    if (isLocal) {
      console.log('Google Tag disabled in local environment')
      return
    }

    const script = document.createElement('script')
    script.src = 'https://www.googletagmanager.com/gtag/js?id=AW-16571741400'
    script.async = true
    document.head.appendChild(script)

    const script2 = document.createElement('script')
    script2.innerHTML = `
      window.dataLayer = window.dataLayer || [];
      function gtag(){dataLayer.push(arguments);}
      gtag('js', new Date());

      gtag('config', 'AW-16571741400');
    `
    document.head.appendChild(script2)
  }

  return {
    setup
  }
}
