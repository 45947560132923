<script setup>
import { useRouter } from 'vue-router'
import { ref, watch } from 'vue'
import { storeToRefs } from 'pinia'
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
} from '@/components/ui/alert-dialog'
import { useAuthStore } from '@/stores/auth'

const store = useAuthStore()
const { isExpired } = storeToRefs(store)
const router = useRouter()

const isOpen = ref(false)

async function logout() {
  isExpired.value = false
  await store.signOut()
  await router.push({
    name: 'SignIn',
    query: {
      redirect_to: router.currentRoute.value.fullPath
    }
  })
}

watch(isExpired, (_, __) => {
  isOpen.value = isExpired.value
})
</script>

<template>
  <AlertDialog
    :open="isOpen"
  >
    <AlertDialogContent
      :disable-outside-pointer-events="true"
      :trap-focus="true"
    >
      <AlertDialogHeader>
        <AlertDialogTitle>Sua sessão expirou</AlertDialogTitle>
        <AlertDialogDescription>
          Faça login novamente para continuar usando o sistema.
        </AlertDialogDescription>
      </AlertDialogHeader>
      <AlertDialogFooter>
        <AlertDialogAction @click="logout">
          Entrar novamente
        </AlertDialogAction>
      </AlertDialogFooter>
    </AlertDialogContent>
  </AlertDialog>
</template>
