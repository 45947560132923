<script setup>
import { ref } from 'vue'
import Button from '@/components/ui/button/Button.vue'
import Label from '@/components/ui/label/Label.vue'
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger
} from '@/components/ui/dialog'
import Textarea from '@/components/ui/textarea/Textarea.vue'
import BaseRadio from '@/components/base/BaseRadio.vue'
import Field from '@/components/ui/field/Field.vue'
import { RadioGroup, RadioGroupItem } from '@/components/ui/radio-group'

const emit = defineEmits(['add'])

const isOpen = ref(false)
const separator = ref('comma')
const content = ref('')

function formatContent() {
  if (!content.value) {
    return []
  }

  if (separator.value === 'comma') {
    return content.value.split(',').map(item => item.trim())
  }
  return content.value.split('\n').map(item => item.trim())
}

function save() {
  const formattedContent = formatContent()
  emit('add', formattedContent)
  isOpen.value = false
  content.value = ''
  separator.value = 'comma'
}
</script>

<template>
  <div>
    <Dialog v-model:open="isOpen">
      <DialogTrigger>
        <Button
          type="button"
          variant="secondary"
        >
          {{ $t('components.text_area_multiple_modal.button') }}
        </Button>
      </DialogTrigger>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>
            {{ $t('components.text_area_multiple_modal.add_multiple') }}
          </DialogTitle>
          <DialogDescription>
            {{ $t('components.text_area_multiple_modal.description') }}
          </DialogDescription>
        </DialogHeader>

        <div class="space-y-4">
          <Field>
            <Label>
              {{ $t('components.text_area_multiple_modal.content') }}
            </Label>
            <Textarea
              id="content"
              v-model="content"
              name="content"
            />
          </Field>
          <Field>
            <Label>
              {{ $t('components.text_area_multiple_modal.separator') }}
            </Label>
            <div>
              <RadioGroup
                v-model="separator"
              >
                <div class="flex  items-center space-x-2">
                  <RadioGroupItem
                    id="comma"
                    value="comma"
                  />
                  <Label
                    for="comma"
                  >
                    {{ $t('components.text_area_multiple_modal.comma') }}
                  </Label>
                </div>
                <div class="flex  items-center space-x-2">
                  <RadioGroupItem
                    id="break-line"
                    value="break-line"
                  />
                  <Label
                    for="break-line"
                  >
                    {{ $t('components.text_area_multiple_modal.break-line') }}
                  </Label>
                </div>
              </RadioGroup>
            </div>
          </Field>
        </div>

        <DialogFooter class="mt-4">
          <DialogClose as-child>
            <Button
              type="button"
              variant="secondary"
            >
              {{ $t('common.cancel') }}
            </Button>
          </DialogClose>
          <Button
            type="submit"
            @click="save"
          >
            {{ $t('common.save') }}
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  </div>
</template>
