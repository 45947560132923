<script setup>
import { ref } from 'vue'
import { CaretSortIcon, CheckIcon } from '@radix-icons/vue'
import { cn } from '@/lib/utils'
import { Button } from '@/components/ui/button'
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
} from '@/components/ui/command'
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from '@/components/ui/popover'

const props = defineProps({
  options: {
    type: Array,
    required: true
  },
  icon: {
    required: false
  },
  placeholder: {
    type: String,
    default: 'Select framework...',
  },
  empty: {
    type: String,
    default: 'No framework found.',
  },
  disabled: {
    type: Boolean,
    default: false,
  },
  loading: {
    type: Boolean,
    default: false,
  },
  by: {
    type: String,
    default: 'name',
  }
})

const model = defineModel({
  required: true,
})

const open = ref(false)

function reset() {
  model.value = []
}

function selectAll() {
  model.value = props.options.map(option => option.id)
}
</script>

<template>
  <Popover
    v-model:open="open"
  >
    <PopoverTrigger as-child>
      <Button
        variant="outline"
        role="combobox"
        :aria-expanded="open"
        class="w-full justify-between truncate text-left font-normal"
        :disabled="disabled"
        :loading="loading"
      >
        <div class="flex items-center ">
          <component
            :is="icon"
            v-if="!loading"
            class="mr-2 size-5 shrink-0 opacity-50"
          />

          <p class="max-w-52 truncate">
            {{ model.length > 0
              ? model.map(option => options.find(c => c.id === option)).map(option => option[props.by]).join(', ')
              : props.placeholder }}
          </p>
        </div>
        <CaretSortIcon class="ml-2 size-4 shrink-0 opacity-50" />
      </Button>
    </PopoverTrigger>
    <PopoverContent class="h-[300px] w-full justify-start p-0 text-left">
      <Command
        class="justify-start text-left"
      >
        <CommandInput
          class="h-9 border-none bg-transparent"
          :placeholder="props.placeholder"
        />
        <CommandEmpty>
          {{ props.empty }}
        </CommandEmpty>
        <CommandList>
          <div
            class="flex justify-between"
          >
            <Button
              variant="link"
              @click="selectAll"
            >
              {{ $t('common.all') }}
            </Button>
            <Button
              variant="link"
              @click="reset"
            >
              {{ $t('common.clear') }}
            </Button>
          </div>
          <CommandGroup>
            <CommandItem
              v-for="option in options"
              :key="option.id"
              :value="option[props.by]"
              @select="(ev) => {
                ev.preventDefault()
                if (model.includes(option.id)) {
                  model.splice(model.indexOf(option.id), 1)
                }
                else {
                  model.push(option.id)
                }
              }"
            >
              {{ option[props.by] }}
              <CheckIcon
                :class="cn(
                  'ml-auto h-4 w-4',
                  model.includes(option.id) ? 'opacity-100' : 'opacity-0',
                )"
              />
            </CommandItem>
          </CommandGroup>
        </CommandList>
      </Command>
    </PopoverContent>
  </Popover>
</template>
