import client from '../plugins/axios'

export const userService = {
  resource: '/user',
  update(data) {
    return client.put(`${this.resource}`, {
      ...data
    })
  },

  getInvited(params = {}) {
    return client.get(`${this.resource}/referral/invited`, {
      params
    })
  },

  updateProfile(profile) {
    return client.put(`${this.resource}`, profile)
  },

  updateAddress(data) {
    return client.patch(`${this.resource}/address`, data)
  },

  updateDocument(data) {
    return client.patch(`${this.resource}/document`, data)
  },

  updatePassword(data) {
    return client.patch(`${this.resource}/password`, data)
  },

  updateLanguage(data) {
    return client.patch(`${this.resource}/language`, data)
  },

}
